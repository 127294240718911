import { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { InternalLink } from "../InternalLink";
import SubmitSnackbar from "../SubmitSnackbar";
import IosShareIcon from "@mui/icons-material/IosShare";

const LinkEntity = ({ url, label, copyBtn = true, maxWidth = undefined }) => {
  const [sb, setSb] = useState({ severity: "success", message: "" });
  const sbSuccess = (msg) => setSb({ severity: "success", message: msg });
  const sbError = (msg) => setSb({ severity: "error", message: msg });
  const sbClose = () => setSb((prev) => ({ ...prev, message: "" }));

  const fullUrl = `${window.origin}${url}`;

  const handleClick = () => {
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => sbSuccess("Link Copied!"))
      .catch((err) => sbError(err.message));
  };

  if (!copyBtn) {
    return <InternalLink to={url}>{label}</InternalLink>;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "baseline" }}>
      <Box
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth,
        }}
      >
        <Tooltip title={label} arrow>
          <InternalLink to={url}>{label}</InternalLink>
        </Tooltip>
      </Box>
      <IconButton size="small" onClick={handleClick}>
        <IosShareIcon fontSize="small" />
      </IconButton>
      <SubmitSnackbar
        open={!!sb.message}
        onClose={sbClose}
        message={sb.message}
        severity={sb.severity}
      />
    </Box>
  );
};

export default LinkEntity;
