import { Controller, useForm } from "react-hook-form";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";

const FormLevelError = ({ errorList }) => {
  if (errorList.length === 0) {
    return null;
  }

  const Wrapper = ({ children }) => (
    <Grid item xs={12}>
      <Alert severity="error" sx={{ mb: 1 }}>
        {children}
      </Alert>
    </Grid>
  );

  if (errorList.length === 1) {
    return (
      <Wrapper>
        <Typography>{errorList[0]}</Typography>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {errorList.map((e, i) => (
        <Typography key={i}> • {e}</Typography>
      ))}
    </Wrapper>
  );
};

const initVals = (fields, initialValues) => {
  if (initialValues) {
    return fields.reduce((acc, cur) => {
      if (cur.type) {
        return acc;
      }
      if (cur.name in initialValues) {
        acc[cur.name] = initialValues[cur.name];
      } else if ("defaultValue" in cur.component) {
        acc[cur.name] = cur.component.defaultValue;
      } else {
        acc[cur.name] = "";
      }
      return acc;
    }, {});
  } else {
    return fields.reduce((acc, cur) => {
      if (cur.type) {
        return acc;
      }
      if ("defaultValue" in cur.component) {
        acc[cur.name] = cur.component.defaultValue;
      } else {
        acc[cur.name] = "";
      }
      return acc;
    }, {});
  }
};

const StdForm = ({ onComplete, onCancel, submitCall, fields, initialVals }) => {
  const defaults = initVals(fields, initialVals);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: defaults,
  });

  const [formLevelError, setFormLevelError] = useState([]);

  const addFormLevelError = (err) => {
    setFormLevelError((prev) => [...prev, err]);
  };

  const clearFormLevelErrors = () => {
    setFormLevelError([]);
  };

  const onSubmit = async (data) => {
    try {
      const result = await submitCall(data);
      clearFormLevelErrors();
      if (result.status === "validation") {
        Object.entries(result.errors).forEach(([fld, val]) => {
          if (!(fld in defaults)) {
            addFormLevelError(val);
            return;
          }
          fld === "" ? addFormLevelError(val) : setError(fld, { message: val });
        });
        return;
      }

      if (result.status === "ok") {
        onComplete(result, reset);
        return;
      }

      addFormLevelError(result.message);
    } catch (err) {
      clearFormLevelErrors();
      addFormLevelError(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <FormLevelError errorList={formLevelError} />
        {fields.map((fld, i) => {
          if (fld.type === "plain") {
            const CompEl = fld.component;
            const extraProps = fld.extraProps || {};
            if (!!fld.disableGrid) {
              return <CompEl key={i} {...extraProps} />;
            } else {
              return (
                <Grid item key={i} xs={fld.width || 12}>
                  <CompEl {...extraProps} />
                </Grid>
              );
            }
          }

          if (fld.type === "section") {
            const sx = {};
            if (fld.subheader) {
              sx.marginBottom = "-10px";
            }
            const gridSx = {};
            if (fld.subheader) {
              gridSx.paddingBottom = 1;
            }
            return (
              <Grid item sx={gridSx} key={i} xs={12}>
                <Typography sx={sx} variant="h6" color="text.secondary">
                  {fld.label}
                </Typography>
                {fld.subheader && (
                  <Typography variant="caption" color="text.secondary">
                    {fld.subheader}
                  </Typography>
                )}
              </Grid>
            );
          }

          const CtrlEl = fld.component;
          const label = fld.label;
          const extraProps = fld.extraProps || {};
          return (
            <Grid item key={i} xs={fld.width || 12}>
              <Controller
                name={fld.name}
                control={control}
                render={({ field }) => (
                  <CtrlEl
                    {...field}
                    label={label}
                    fullWidth
                    size="small"
                    error={!!errors[fld.name]}
                    helperText={
                      !!errors[fld.name]
                        ? errors[fld.name].message
                        : fld.helperText || " "
                    }
                    {...extraProps}
                  />
                )}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "row-reverse", my: 2 }}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="outlined"
          onClick={() => {
            reset();
            clearFormLevelErrors();
          }}
          sx={{ ml: 1 }}
        >
          Reset
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </form>
  );
};

export default StdForm;
