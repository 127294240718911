import { Box, Chip, Link, Paper, Typography } from "@mui/material";
import TreatmentSiteDialog from "./TreatmentSiteDialog";
import { grey } from "@mui/material/colors";

const TreatmentSiteList = ({ treatmentSites, remSiteFn, siteDialogOpenFn }) => {
  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{ borderColor: grey[400], p: 2, mb: 3 }}
    >
      <Typography fontSize="larger" color={grey[700]} sx={{ mb: 1 }}>
        Treatment Sites
      </Typography>
      <Box sx={{ my: 3 }}>
        {treatmentSites.size === 0 && <Typography>None</Typography>}
        {treatmentSites
          .map((s) => (
            <Chip
              key={s}
              sx={{ ml: 1 }}
              onDelete={() => remSiteFn(s)}
              label={TreatmentSiteDialog.getLabelFromVal(s)}
            />
          ))
          .toArray()}
      </Box>
      <Box>
        <Link
          variant="caption"
          underline="none"
          component="button"
          type="button"
          onClick={siteDialogOpenFn}
          sx={{ mb: 3, mt: 1 }}
        >
          Add Treatment Site(s)
        </Link>
      </Box>
    </Paper>
  );
};

export default TreatmentSiteList;
