import { Button } from "@mui/material";

const PostButton = ({ cardState, formDirty }) => {
  const disabled = cardState === "error" || !formDirty;

  const color =
    cardState === "error"
      ? "error"
      : cardState === "success"
      ? "success"
      : "primary";

  const variant = cardState === "success" ? "contained" : "outlined";

  return (
    <Button variant={variant} color={color} type="submit" disabled={disabled}>
      Post
    </Button>
  );
};

export default PostButton;
