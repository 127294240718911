/**
 * @param {{
 *   type: "trueup",
 *   id: number,
 *   memo: string,
 *   user_id: number,
 *   user_name: string
 * }} ctx
 */
const LogEntryTrueup = ({ ctx }) => {
  return <>True Up performed by {ctx.user_name}</>;
};

export default LogEntryTrueup;
