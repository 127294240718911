import SectionHeading from "../PagePartials/SectionHeading";
import StdFormDialog from "../PagePartials/StdFormDialog";
import { TextField } from "@mui/material";
import RPPhoneNumberInput from "../FormControls/RPPhoneNumberInput";
import RPStatePicker from "../FormControls/RPStatePicker";
import DataTable from "../PagePartials/DataTable";
import { linkFmt } from "../../formatters";
import useDialogState from "../../HOC/useDialogState";
import { useNavigate } from "react-router-dom";
import SplitButton from "../StandardComponents/SplitButton";

const formFields = [
  { name: "name", component: TextField, label: "Practice Name" },
  { name: "street1", component: TextField, label: "Street 1", width: 8 },
  { name: "street2", component: TextField, label: "Street 2", width: 4 },
  { name: "city", component: TextField, label: "City", width: 5 },
  { name: "state", component: RPStatePicker, label: "State", width: 4 },
  { name: "zip", component: TextField, label: "Zip", width: 3 },
  {
    name: "main_phone",
    component: RPPhoneNumberInput,
    label: "Main Phone",
    width: 6,
  },
  {
    name: "main_fax",
    component: RPPhoneNumberInput,
    label: "Main Fax",
    width: 6,
  },
];

const COLUMNS = [
  {
    name: "name",
    label: "Practice",
    formatFn: linkFmt,
    formatArgs: (row) => [`/practices/${row.id}`],
  },
  { name: "count_rx", label: "Rxs", size: 70 },
  { name: "count_rep", label: "Reps", size: 60 },
  { name: "count_presc", label: "Prescribers", size: 100 },
  {
    name: "addr_city",
    label: "City",
    formatFn: (city, state) => `${city}, ${state}`,
    formatArgs: (row) => [row.addr_state],
    size: 170,
  },
];

const PagePractices = ({ pageData, api }) => {
  const { open, openFn, closeFn } = useDialogState();
  const navigate = useNavigate();

  return (
    <>
      <SectionHeading
        headingLabel="Practices"
        buttonEl={
          <SplitButton
            optsAndClicks={{
              "New Practice": openFn,
              "Change Engagement Email Content": () =>
                navigate("/practices/email"),
            }}
          />
        }
      />
      <DataTable data={pageData} cols={COLUMNS} />
      <StdFormDialog
        fields={formFields}
        onClose={closeFn}
        open={open}
        onComplete={(resp) => {
          navigate(`/practices/${resp.data.id}`);
        }}
        title="New Practice"
        submitCall={(data) => {
          return api.newPractice(data);
        }}
      />
    </>
  );
};

export default PagePractices;
