import { Card, CardContent, CardHeader } from "@mui/material";
import ConsumablesSummaryActions from "./ConsumablesSummaryCard/ConsumablesSummaryActions";
import ConsumablesSummaryTable from "./ConsumablesSummaryCard/ConsumablesSummaryTable";
import { useState } from "react";

/**
 * @param {Array<{
 *   mfgr_id: number,
 *   mfgr_name: string,
 *   item_id: number,
 *   item_name: string,
 *   item_model_num: string,
 *   qty: number,
 * }>} consumables
 * @param {number} agencyId
 */
const ConsumablesSummaryCard = ({ consumables, agencyId }) => {
  const [showZeros, setShowZeros] = useState(false);
  const [showDeprecated, setShowDeprecated] = useState(false);

  const filters = {
    showZeros,
    showDeprecated,
    toggleShowZeros: () => setShowZeros((prev) => !prev),
    toggleShowDeprecated: () => setShowDeprecated((prev) => !prev),
  };

  const filteredList = consumables.filter((c) => {
    return !showZeros ? c.qty !== 0 : true;
  });

  // for stability
  filteredList.sort((a, b) => a.item_id - b.item_id);

  return (
    <Card>
      <CardHeader
        title="Consumables Summary"
        action={<ConsumablesSummaryActions filters={filters} />}
      />
      <CardContent>
        <ConsumablesSummaryTable
          consumables={filteredList}
          agencyId={agencyId}
        />
      </CardContent>
    </Card>
  );
};

export default ConsumablesSummaryCard;
