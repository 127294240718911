import { useState } from "react";
import {
  Alert,
  Box,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import UploaderControl from "../StandardComponents/UploaderControl";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

const GenScriptBtn = ({ noGenReason, submitting, onClick }) => {
  const canGenScript = !noGenReason;
  const variant = canGenScript ? "contained" : "outlined";
  const color = canGenScript ? "primary" : "error";
  const disabled = submitting || !canGenScript;
  const btnLabel = canGenScript ? "Generate Script Document" : noGenReason;

  return (
    <LoadingButton
      fullWidth
      sx={{ height: "100px" }}
      disabled={disabled}
      color={color}
      variant={variant}
      onClick={onClick}
      loading={submitting}
    >
      {btnLabel}
    </LoadingButton>
  );
};

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  const [state, setState] = useState({
    errorMsg: "",
    error: false,
    submitting: false,
  });

  const submitGenDoc = () => {
    setState({ errMsg: "", error: false, submitting: true });
    api
      .genRxDoc(initialVals.id)
      .then((resp) => {
        if (resp.status !== "ok") {
          setState({ errMsg: resp.message, error: true, submitting: false });
          return;
        }
        setState({ errMsg: "", error: false, submitting: false });
        refresh();
        handleClose();
      })
      .catch((err) => {
        setState({ errMsg: err.message, error: true, submitting: false });
      });
  };

  const handleClose = () => {
    setState({ errMsg: "", error: false, submitting: false });
    onClose();
  };

  const genUrl = (apiBase) =>
    [apiBase, "attachments/rx/", initialVals.id].join("");

  const canGenerateScript = !initialVals.rxer_can_generate;

  const UploadBtnProps = canGenerateScript
    ? { variant: "outlined", color: "warning" }
    : { variant: "contained", color: "primary" };

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 900, minWidth: 900 } }}
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Box>Upload / Generate Prescription Document</Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {state.error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {state.errMsg}
          </Alert>
        )}
        <Box>
          <GenScriptBtn
            noGenReason={initialVals.rxer_can_generate}
            submitting={state.submitting}
            onClick={submitGenDoc}
          />
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box>
          <UploaderControl
            genUrl={genUrl}
            multiple={false}
            apiToken={api.getToken()}
            onAbort={() => handleClose()}
            title="Upload Hard Copy Script"
            onComplete={() => {
              refresh();
              handleClose();
            }}
            sx={{ height: "100px" }}
            BtnProps={UploadBtnProps}
          />
        </Box>
      </DialogContent>
    </MuiDialog>
  );
};
