import { forwardRef } from "react";
import RHFLoadOnRenderAutocomplete from "./RHFLoadOnRenderAutocomplete";

const RHFUserPicker = forwardRef(
  ({ control, label, name, api, rules = undefined, ...rest }, ref) => {
    const optCall = () => api.acUsers();

    return (
      <RHFLoadOnRenderAutocomplete
        control={control}
        label={label}
        ref={ref}
        name={name}
        rules={rules}
        optionListCall={optCall}
        {...rest}
      />
    );
  }
);

export default RHFUserPicker;
