import LinkEntity from "./LinkEntity";
import RxIcon from "../Icons/RxIcon";

const LinkRx = ({ id, copyBtn = true }) => {
  const label = LinkRx.lblPriByCtx({ id });
  const url = LinkRx.url(id);
  return <LinkEntity url={url} label={label} copyBtn={copyBtn} />;
};

LinkRx.url = (id) => `/rxs/${id}`;
LinkRx.lblPriByCtx = ({ id }) => `RX-${id.toString().padStart(4, "0")}`;
LinkRx.icon = RxIcon;
LinkRx.subheader = (ctx) =>
  ctx.generation === 0
    ? "Initial"
    : ctx.generation === 1
    ? "Extension"
    : `Extension ${ctx.generation}`;

export default LinkRx;
