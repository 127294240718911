import { useParams } from "react-router-dom";
import { Alert, AlertTitle, Stack } from "@mui/material";
import AgencySweepCard from "./PageCommissions/AgencySweepCard";
import SectionHeading from "../PagePartials/SectionHeading";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import SweepTable from "./PageCommissions/SweepTable";
import LockIcon from "@mui/icons-material/Lock";

const PageCommissions = ({ pageData, api, refresh }) => {
  const { payoutDate } = useParams();

  const hasSweeps = !!pageData.sweeps.length;

  return (
    <>
      <BackLinkHeader title="Back to Payout Days List" to="/commission-dates" />
      <SectionHeading
        headingLabel={`Commissions for ${pageData.payout_date}`}
      />
      {pageData.locked_on && (
        <Alert
          severity="error"
          sx={{ mb: 2 }}
          icon={<LockIcon fontSize="inherit" />}
        >
          <AlertTitle>Payout Date Locked</AlertTitle> Only the swept commissions
          targeted to this payout date will be shown.
        </Alert>
      )}
      {hasSweeps && <SweepTable sweeps={pageData.sweeps} />}
      {pageData.locked_on === null && (
        <Stack direction="column" spacing={2}>
          {pageData.commissions.map((a) => {
            return (
              <AgencySweepCard
                payoutDate={payoutDate}
                key={a.id}
                agencyInfo={a}
                api={api}
                refresh={refresh}
              />
            );
          })}
        </Stack>
      )}
    </>
  );
};

export default PageCommissions;
