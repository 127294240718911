import { useState } from "react";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";

const mkOnClickHandler =
  (api, setState, targetsCurrent, onNewVerified) => () => {
    setState({ error: null, isFetching: true });
    api
      .massTextVerify(targetsCurrent)
      .then((resp) => {
        if (resp.status === "ok") {
          setState({ error: null, isFetching: false });
          onNewVerified(resp.data.text, resp.data.reps);
          return;
        }
        setState({ error: resp.message, isFetching: false });
      })
      .catch((err) => {
        setState({ error: err.message, isFetching: false });
      });
  };

/**
 * @param {object} api
 * @param {boolean} disabled
 * @param {string} targetsCurrent
 * @param {function} onNewVerified
 */
const VerifyTargetsButton = ({
  api,
  disabled,
  targetsCurrent,
  onNewVerified,
}) => {
  const [fetchState, setFetchState] = useState({
    error: null,
    isFetching: false,
  });

  const startIcon = fetchState.error ? (
    <Tooltip title={fetchState.error}>
      <ErrorIcon />
    </Tooltip>
  ) : !disabled ? (
    <WarningIcon />
  ) : undefined;
  const endIcon = fetchState.isFetching ? (
    <CircularProgress size={20} />
  ) : undefined;
  const color = fetchState.error ? "error" : "warning";

  return (
    <Button
      variant="outlined"
      color={color}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth
      onClick={mkOnClickHandler(
        api,
        setFetchState,
        targetsCurrent,
        onNewVerified
      )}
    >
      Verify Texting Targets
    </Button>
  );
};

export default VerifyTargetsButton;
