import { useContext, useState } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import {
  CircularProgress,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CommentList from "./Comment/CommentList";
import CommentNewForm from "./Comment/CommentNewForm";
import ApiDataContext from "../../ApiDataContext";

const CAT_INDEX = {
  service: 0,
  billing: 1,
  sales: 2,
};

const ROLE_TO_CAT = {
  "patient-exp": "service",
  "practice-exp": "service",
  logistics: "service",
  billing: "billing",
  finance: "billing",
  sales: "sales",
  rep: "sales",
  intake: "billing",
};

const TYPE_TO_FNNAME = {
  intake: "listIntakeComments",
  treatment: "listTreatmentComments",
  unit: "listUnitComments",
  agency: "listAgencyComments",
  ticket: "listTicketComments",
};

const TYPE_TO_MUTFNNAME = {
  intake: "addIntakeComment",
  treatment: "addTreatmentComment",
  unit: "addUnitComment",
  agency: "addAgencyComment",
  ticket: "addTicketComment",
};

const mkFetcher = (api) => async (key) => {
  const [, type, id] = key.split("-");
  const fetcher = api[TYPE_TO_FNNAME[type]];
  if (fetcher === undefined)
    throw new Error(`comment type '${type}' not understood`);
  const res = await fetcher(id);
  if (res.status !== "ok") throw new Error(res.message);
  return res.data;
};

const mkMutateFetcher =
  (api) =>
  async (key, { arg }) => {
    const [, type, id] = key.split("-");
    const fetcher = api[TYPE_TO_MUTFNNAME[type]];
    if (fetcher === undefined)
      throw new Error(`comment type '${type}' not understood`);
    return await fetcher(id, arg);
  };

/**
 * @param {"intake"|"treatment"|"unit"|"agency"|"ticket"} type
 * @param {number} id
 * @param {object} api
 * @param {boolean} [ignoreCategories=false]
 * @param {boolean} [disablePublic=false]
 * @param {boolean} [readOnly=false]
 * @param {string|false} [embedPhone=false]
 * @param {string} [embedPatientName=""]
 * @param {function} [onNewComment=function]
 */
const CommentFeed = ({
  type,
  id,
  api,
  ignoreCategories = false,
  disablePublic = false,
  readOnly = false,
  embedPhone = false,
  embedPatientName = "",
  onNewComment = () => {},
}) => {
  const { me } = useContext(ApiDataContext);
  const initialTab = ROLE_TO_CAT[me.primary_role] || "service";
  const [curTab, setCurTab] = useState(initialTab);

  const { data, error, isLoading } = useSWR(
    `comments-${type}-${id}`,
    mkFetcher(api)
  );

  const { trigger, isMutating } = useSWRMutation(
    `comments-${type}-${id}`,
    mkMutateFetcher(api)
  );

  const handleNewComment = (val) => {
    trigger(val).then(() => {
      onNewComment();
    });
  };

  if (error) return <Typography>Error: {error.message}</Typography>;
  if (isLoading) return <CircularProgress />;

  const parsed = data.reduce(
    (acc, c) => {
      const cat = CAT_INDEX[c.category];
      if (cat !== undefined) acc[CAT_INDEX[c.category]].push(c);
      return acc;
    },
    [[], [], []]
  );

  const comments = parsed[CAT_INDEX[curTab]];

  return (
    <Paper variant="outlined" sx={ignoreCategories ? { pt: 2 } : {}}>
      {!ignoreCategories && (
        <>
          <Tabs
            centered
            sx={{ ml: 9 }}
            value={curTab}
            onChange={(ev, newVal) => {
              setCurTab(newVal);
            }}
          >
            <Tab
              index={0}
              value="service"
              label={`Service (${parsed[0].length})`}
            />
            <Tab
              index={1}
              value="billing"
              label={`Billing (${parsed[1].length})`}
            />
            <Tab
              index={2}
              value="sales"
              label={`Sales (${parsed[2].length})`}
            />
          </Tabs>
          <Divider sx={{ mb: 2 }} />
        </>
      )}
      {!readOnly && (
        <CommentNewForm
          curTab={curTab}
          onNewComment={handleNewComment}
          isMutating={isMutating}
          disablePublic={disablePublic}
          ignoreCategories={ignoreCategories}
        />
      )}
      <CommentList
        comments={ignoreCategories ? [...data] : comments}
        api={api}
        listKey={`comments-${type}-${id}`}
        readOnly={readOnly}
        embedPhone={curTab === "service" ? embedPhone : undefined}
        embedPatientName={embedPatientName}
      />
    </Paper>
  );
};

export default CommentFeed;
