import useSWR from "swr";
import { genericSWRFetcher, hydrateDateObj } from "../../../utility";
import TicketDashboardTable from "./TicketDashboardTable";
import { Alert, CircularProgress } from "@mui/material";
import { addDays, format } from "date-fns";
import { useEffect } from "react";

/**
 * @param {Object<function>} api
 * @param {string} category
 * @param {[Date|null, Date|null]} dateLimiter
 * @param children
 * @param setFollowUpDatesSetFn
 */
const TicketDashboardListLoader = ({
  api,
  category,
  dateLimiter,
  children,
  setFollowUpDatesSetFn = () => {},
}) => {
  // check if dateLimiter is fully set then set the key
  const fetcher = genericSWRFetcher(api.ticketDashboardList, (key) => [
    key.split("-")[1],
  ]);
  const { data, error, isLoading } = useSWR(
    `ticketDashboard-${category}`,
    fetcher
  );

  useEffect(() => {
    if (!data) return;
    const followUpDates = new Set();
    data.forEach((t) =>
      followUpDates.add(format(new Date(t.follow_up_on), "yyyy-MM-dd"))
    );
    setFollowUpDatesSetFn(followUpDates);
  }, [setFollowUpDatesSetFn, data]);

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (data.length === 0) {
    return children;
  }

  data.forEach((t, i) => {
    t.follow_up_on = hydrateDateObj(t.follow_up_on);
    t.created_on = hydrateDateObj(t.created_on);
    t.last_activity_on = hydrateDateObj(t.last_activity_on);
    t.finished_on = hydrateDateObj(t.finished_on);
    t.last_viewed = hydrateDateObj(t.last_viewed);
    data[i] = t;
  });

  let filteredData = data;
  if (dateLimiter[0] && dateLimiter[1]) {
    filteredData = data.filter(
      (t) =>
        t.follow_up_on >= dateLimiter[0] &&
        t.follow_up_on <= addDays(dateLimiter[1], 1)
    );
  }

  return <TicketDashboardTable tickets={filteredData} />;
};

export default TicketDashboardListLoader;
