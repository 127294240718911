import { forwardRef } from "react";
import useSWR from "swr";
import { Autocomplete, TextField } from "@mui/material";
import { genericSWRFetcher } from "../../utility";

const processSlotProps = (slotProps) => {
  if (!slotProps.textField) {
    slotProps.textField = {};
  }
};

const BillerUserAutocomplete = forwardRef(
  ({ api, slotProps = {}, value, onChange, ...props }, ref) => {
    const { data, isLoading, error } = useSWR(
      "acBillerUsers",
      genericSWRFetcher(api.acBillerUsers)
    );

    processSlotProps(slotProps);
    const options = data ?? [];

    const pickedValue = options.find((i) => i.value === value) ?? null;

    return (
      <Autocomplete
        ref={ref}
        options={options}
        getOptionKey={(option) => option.value}
        loading={isLoading}
        renderInput={(params) => (
          <TextField {...params} {...slotProps.textField} />
        )}
        value={pickedValue}
        onChange={(_, newVal) => {
          onChange(newVal?.value ?? null);
        }}
        {...props}
      />
    );
  }
);

export default BillerUserAutocomplete;
