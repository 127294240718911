import { useState } from "react";
import { Button } from "@mui/material";

const formatSsn = (ssn) =>
  [ssn.substring(0, 3), ssn.substring(3, 5), ssn.substring(5, 9)].join("-");

const MaskedSSN = ({ ssn, defaultText }) => {
  const [shown, setShown] = useState(false);
  const toggleShown = () => setShown((prev) => !prev);

  if (!ssn) {
    return defaultText || null;
  }

  const display = shown ? formatSsn(ssn) : "●●●-●●-●●●●";

  return (
    <Button sx={{ color: "text.secondary" }} onClick={toggleShown}>
      {display}
    </Button>
  );
};

export default MaskedSSN;
