import { Box, Typography } from "@mui/material";

const NotesBlock = ({ notes, ...rest }) => {
  const notesSep = notes.split("\n").map((n) => n || <br />);
  return (
    <Box {...rest}>
      {notesSep.map((n, i) => (
        <Typography key={i}>{n}</Typography>
      ))}
    </Box>
  );
};

export default NotesBlock;
