import { forwardRef, useContext } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";
import ApiDataContext from "../../ApiDataContext";

const PTN_NPI = new RegExp("NPI: (\\d{10})");

const RPPrescriberPicker = forwardRef(({ api, ...rest }, ref) => {
  const { npiBlacklist } = useContext(ApiDataContext);

  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acPrescribers()}
      getOptionDisabled={(option) => {
        const matches = PTN_NPI.exec(option.label);
        if (!matches) return false;
        return !!(matches[1] && npiBlacklist.includes(matches[1]));
      }}
    />
  );
});

RPPrescriberPicker.defaultValue = null;

export default RPPrescriberPicker;
