import { forwardRef, useState } from "react";
import RPSsnInput from "../FormControls/RPSsnInput";

const NSsnField = forwardRef(({ fieldRecord, updater }, ref) => {
  const [val, setVal] = useState(fieldRecord.value);

  return (
    <RPSsnInput
      name={fieldRecord.name}
      label={fieldRecord.label}
      helperText={fieldRecord.errstr}
      error={fieldRecord.error}
      onChange={(newVal) => setVal(newVal)}
      value={val}
      onBlur={() => updater(fieldRecord.name, val)}
      inputRef={ref}
      size="small"
      fullWidth
    />
  );
});

export default NSsnField;
