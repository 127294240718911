import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IncentivesToSweepTableRow from "./IncentivesToSweepTableRow";

const IncentivesToSweepTable = ({
  incentives,
  fields,
  control,
  setValue,
  disabled,
}) => {
  if (incentives.length === 0) return null;

  return (
    <TableContainer>
      <Typography>
        <strong>Incentive Ledger</strong>
      </Typography>
      <Table
        size="small"
        sx={{
          "& tbody td": { py: 0 },
          "& > tbody > tr > td:nth-of-type(4)": { textAlign: "center" },
          "& > tbody > tr > td:nth-of-type(5)": { textAlign: "center" },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Memo</TableCell>
            <TableCell sx={{ px: 0, width: "22px" }}></TableCell>
            <TableCell sx={{ width: "120px", textAlign: "right" }}>
              Amount
            </TableCell>
            <TableCell sx={{ width: "170px" }}>Event Date</TableCell>
            <TableCell sx={{ width: "80px", textAlign: "center" }}>
              Ignore
            </TableCell>
            <TableCell sx={{ width: "80px", textAlign: "center" }}>
              Accept
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {incentives.map((i) => (
            <IncentivesToSweepTableRow
              key={i.id}
              control={control}
              fields={fields}
              i={i}
              setValue={setValue}
              disabled={disabled}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IncentivesToSweepTable;
