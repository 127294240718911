import { Tooltip } from "@mui/material";

const HorizIconToolTip = ({ title, children }) => {
  return (
    <Tooltip title={title} placement="top" arrow>
      {children}
    </Tooltip>
  );
};

export default HorizIconToolTip;
