import { useForm } from "react-hook-form";
import { useEffect } from "react";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import StandardDialog from "../../StandardComponents/StandardDialog";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import Icd10PickerForm from "../PageIntake/Icd10PickerForm";
import Icd10PickerList from "../PageIntake/Icd10PickerList";
import Icd10Diagnosis from "../PageIntake/Icd10Diagnosis";

const genDefaultValues = (icd10s, diag) => {
  const formStateIcd10s = icd10s.map((i) => {
    i.diag = i.description === diag;
    return i;
  });
  return { icd10s: formStateIcd10s };
};

const Icd10ChangeForm = ({
  open,
  onClose,
  api,
  refresh,
  eocId,
  icd10s,
  diag,
}) => {
  const defaultValues = genDefaultValues(icd10s, diag);
  const { watch, setValue, getValues, handleSubmit, setError, reset } = useForm(
    {
      defaultValues,
    }
  );

  useEffect(() => {
    reset(genDefaultValues(icd10s, diag));
  }, [reset, icd10s, diag]);

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      refresh();
      onClose();
    }
  );

  const onSubmit = (data) => {
    apiWrapper(api.updateIcd10s(eocId, data));
  };

  const handleOnClose = () => {
    onClose();
    reset();
    clearFormError();
  };

  const addCodeFn = (code) => {
    const icds = getValues("icd10s");
    // exists, so don't add it again
    if (icds.find((i) => i.code === code.code)) return;
    code.diag = false;
    const newIcds = [...icds, code];
    setValue("icd10s", newIcds);
  };

  const remCodeFn = (code) => {
    const icds = getValues("icd10s");
    const newIcds = icds.filter((i) => i.code !== code.code);
    setValue("icd10s", newIcds);
  };

  const selCodeFn = (code) => {
    const icds = getValues("icd10s");
    const newIcds = icds.map((i) => {
      if (i.code === code.code) {
        i.diag = !i.diag;
      } else {
        i.diag = false;
      }
      return i;
    });
    setValue("icd10s", newIcds);
  };

  const icd10FormFields = watch("icd10s");

  return (
    <StandardDialog open={open} onClose={handleOnClose} title="Update ICD-10s">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container>
          <Grid2 xs={12} sx={{ mt: -3 }}>
            <Icd10PickerForm api={api} addCodeFn={addCodeFn} />
          </Grid2>
          <Grid2 xs={12}>
            <Icd10PickerList
              codes={icd10FormFields}
              selCodeFn={selCodeFn}
              remCodeFn={remCodeFn}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Icd10Diagnosis codes={icd10FormFields} />
          </Grid2>
          <Grid2 xs={12} sx={{ textAlign: "right" }}>
            <Button
              type="submit"
              variant="contained"
              disabled={formErrState.submitting}
            >
              Update
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default Icd10ChangeForm;
