import { FormControl, MenuItem, Select } from "@mui/material";

const LABELS = {
  1: "Q1 - First Quarter",
  2: "Q2 - Second Quarter",
  3: "Q3 - Third Quarter",
  4: "Q4 - Fourth Quarter",
};

const clamp = (min, max, val) => Math.max(min, Math.min(val, max));

const BGQuarterSelect = ({ min = 1, max = 4, ...rest }) => {
  const minClamped = clamp(1, 4, min);
  const maxClamped = clamp(1, 4, max);
  const amount = Math.max(maxClamped - minClamped + 1, 1);
  const quarters = Array(amount)
    .fill(minClamped)
    .map((m, i) => m + i);

  return (
    <FormControl>
      <Select {...rest} displayEmpty>
        <MenuItem value="">Select Quarter</MenuItem>
        {quarters.map((m) => (
          <MenuItem key={m} value={m}>
            {LABELS[m]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BGQuarterSelect;
