import { Alert, Stack, Typography } from "@mui/material";
import { FILTER_CTRL_MAP } from "../../../utility";

const ReportFilterList = ({ fields, control, api }) => {
  if (fields.length === 0) {
    return <Alert severity="info">No filters defined for this report.</Alert>;
  }

  return fields.map((f, i) => {
    const Filter = FILTER_CTRL_MAP[f.type];

    if (!Filter) {
      return <Alert severity="error">Filter {f.type} not understood!</Alert>;
    }

    return (
      <Stack key={f.id} direction="row" sx={{ pt: 1, alignItems: "center" }}>
        <Typography sx={{ minWidth: "270px" }}>{f.desc}</Typography>
        <Filter
          name={`filterVals.${i}.value`}
          control={control}
          meta={f.meta}
          api={api}
          helperText=""
        />
      </Stack>
    );
  });
};

export default ReportFilterList;
