import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPPracticePicker = forwardRef(({ api, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acPractices()}
    />
  );
});

RPPracticePicker.defaultValue = null;

export default RPPracticePicker;
