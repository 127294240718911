import { forwardRef } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import RHFPosIntField from "../../FormControlsRHF/RHFPosIntField";
import SerializedItemIcon from "../../StandardComponents/Icons/SerializedItemIcon";
import ConsumableItemIcon from "../../StandardComponents/Icons/ConsumableItemIcon";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RHFReceiveConsumableField from "./ReceiveConsumableField";
import ReceiveSerialButton from "./ReceiveSerialButton";

const ItemIcon = forwardRef(({ item, ...props }, ref) => {
  if (item.item_type === "serialized") {
    return (
      <SerializedItemIcon
        ref={ref}
        sx={{ mt: "5px", color: "text.secondary" }}
        {...props}
      />
    );
  }
  return (
    <ConsumableItemIcon
      ref={ref}
      sx={{ mt: "5px", color: "text.secondary" }}
      {...props}
    />
  );
});

/**
 * @param {Array<{
 *   id: string,
 *   hlabel: string,
 *   item_type: string,
 *   model_number: string,
 *   item_id: number,
 *   qty: number,
 *   qty_received: number,
 *   qty_orig: number
 * }>} items
 * @param {{
 *   control: any,
 *   onRemoveItem: function,
 *   fieldPrefix: string,
 * }|null} [editConfig=null]
 * @param {{
 *   control: any,
 *   fieldPrefix: string,
 *   onSerialOpen: function(number),
 * }|null} [receiveConfig=null]
 */
const ItemCollectionTable = ({
  items,
  editConfig = null,
  receiveConfig = null,
}) => {
  const editable = !!editConfig;
  const onRemoveItem = editConfig?.onRemoveItem;
  const fieldPrefix = editConfig?.fieldPrefix || receiveConfig?.fieldPrefix;
  const control = editConfig?.control || receiveConfig?.control;

  return (
    <Table
      size="small"
      sx={{ "& td": { py: 0, px: 1 }, "& th": { py: 0, px: 1 } }}
    >
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "40px" }}></TableCell>
          <TableCell>Item</TableCell>
          <TableCell sx={{ width: "180px" }}>Model Number</TableCell>
          <TableCell sx={{ width: "90px" }}>Qty</TableCell>
          <TableCell sx={{ width: "90px" }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i, idx) => {
          return (
            <TableRow key={i.id}>
              <TableCell>
                <Tooltip title={`${i.item_type} - ID: ${i.item_id}`}>
                  <ItemIcon item={i} />
                </Tooltip>
              </TableCell>
              <TableCell>{i.hlabel}</TableCell>
              <TableCell>{i.model_number}</TableCell>
              <TableCell>
                {!!editable ? (
                  <RHFPosIntField
                    control={control}
                    name={`${fieldPrefix}.${idx}.qty`}
                    helperText=""
                    sx={{ m: 0 }}
                    inputProps={{
                      sx: { p: "4px" },
                    }}
                  />
                ) : (
                  i.qty
                )}
              </TableCell>
              <TableCell sx={!!receiveConfig ? { p: 0 } : {}}>
                {!!editable && (
                  <IconButton
                    onClick={() => onRemoveItem(i.item_id)}
                    size="small"
                  >
                    <RemoveCircleIcon color="error" fontSize="small" />
                  </IconButton>
                )}
                {!!receiveConfig && i.item_type === "consumable" && (
                  <RHFReceiveConsumableField
                    size="small"
                    sx={{ minWidth: "90px" }}
                    helperText=""
                    control={control}
                    name={`${fieldPrefix}.${idx}.qty_received`}
                    minVal={i.qty_orig}
                    maxVal={i.qty}
                  />
                )}
                {!!receiveConfig && i.item_type === "serialized" && (
                  <ReceiveSerialButton
                    control={control}
                    name={`${fieldPrefix}.${idx}`}
                    onClick={receiveConfig.onSerialOpen}
                  />
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ItemCollectionTable;
