import StandardDialog from "../../StandardComponents/StandardDialog";
import { Button, Stack } from "@mui/material";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";

const EnrollApprovalDialog = ({
  open,
  onClose,
  api,
  therapy,
  item_code,
  npi,
  status,
  refresh,
}) => {
  const { apiWrapper, formErrState } = useStdFormErrors(
    () => {},
    {},
    () => {
      refresh();
      onClose();
    }
  );

  const submitReset = () => {
    apiWrapper(
      api.setEnrollStatus(npi, {
        therapy,
        item_code,
        status: "needs-review",
      })
    );
  };

  const submitSus = () => {
    apiWrapper(
      api.setEnrollStatus(npi, {
        therapy,
        item_code,
        status: "suspect",
      })
    );
  };

  const submitGood = () => {
    apiWrapper(
      api.setEnrollStatus(npi, {
        therapy,
        item_code,
        status: "accepted",
      })
    );
  };

  return (
    <StandardDialog
      open={open}
      onClose={onClose}
      title="Set Signature Approval"
      actions={
        <Button disabled={status === "needs-review"} onClick={submitReset}>
          Reset
        </Button>
      }
    >
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <Stack spacing={2} direction="row-reverse">
        <Button
          disabled={status === "accepted"}
          variant="contained"
          color="success"
          sx={{ height: "150px" }}
          fullWidth
          onClick={submitGood}
        >
          Looks Good!
        </Button>
        <Button
          disabled={status === "suspect"}
          variant="outlined"
          color="error"
          sx={{ height: "150px" }}
          fullWidth
          onClick={submitSus}
        >
          Kinda Suspect?
        </Button>
      </Stack>
    </StandardDialog>
  );
};

export default EnrollApprovalDialog;
