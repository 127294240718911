import { useController } from "react-hook-form";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { forwardRef } from "react";

const RHFDateRangePicker = forwardRef(
  ({ name, control, rules = undefined, size, helperText, ...rest }, ref) => {
    const { field } = useController({ name, control, rules });

    return (
      <DateRangePicker
        value={field.value}
        onAccept={field.onChange}
        inputRef={ref}
        slotProps={{
          textField: {
            size,
            helperText,
            margin: "dense",
          },
        }}
      />
    );
  }
);

export default RHFDateRangePicker;
