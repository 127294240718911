import { useForm } from "react-hook-form";
import { useEffect } from "react";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import StandardDialog from "../../StandardComponents/StandardDialog";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import { Button } from "@mui/material";
import RHFTextField from "../../FormControlsRHF/RHFTextField";

const genDefaultValues = (title, description) => {
  return { title, description };
};

const TicketDescriptionFormDialog = ({
  open,
  onClose,
  onSuccess,
  api,
  title,
  description,
  ticketId,
}) => {
  const defaultValues = genDefaultValues(title, description);

  const { handleSubmit, control, setError, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(genDefaultValues(title, description));
  }, [reset, title, description]);

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      onSuccess();
      onClose();
    }
  );

  const onSubmit = (data) => {
    apiWrapper(api.updateTicketDescription(ticketId, data));
  };

  return (
    <StandardDialog
      open={open}
      onClose={onClose}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      title="Update Task Title / Description"
      actions={
        <Button
          disabled={formErrState.submitting}
          type="submit"
          variant="contained"
        >
          Save Change
        </Button>
      }
    >
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <RHFTextField control={control} name="title" />
      <RHFTextField
        multiline
        control={control}
        name="description"
        rows={10}
        InputProps={{ sx: { fontFamily: "monospace", fontSize: "smaller" } }}
      />
    </StandardDialog>
  );
};

export default TicketDescriptionFormDialog;
