import { forwardRef, useState } from "react";
import RPDateInput from "../FormControls/RPDateInput";

const NDateField = forwardRef(({ fieldRecord, updater, ...rest }, ref) => {
  const [val, setVal] = useState(fieldRecord.value || "");

  return (
    <RPDateInput
      name={fieldRecord.name}
      label={fieldRecord.label}
      helperText={fieldRecord.errstr}
      error={fieldRecord.error}
      onChange={setVal}
      onBlur={() => updater(fieldRecord.name, val)}
      value={val}
      ref={ref}
      size="small"
      fullWidth
      {...rest}
    />
  );
});

export default NDateField;
