import { useState } from "react";
import PatientSummaryCard from "../PagePartials/PatientSummaryCard";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
} from "@mui/material";
import KeyValueList from "../PagePartials/KeyValueList";
import SplitButton from "../StandardComponents/SplitButton";
import GenerateLabelFormDialog from "./PageServiceCycle/GenerateLabelFormDialog";
import ShippingCard from "./PageServiceCycle/ShippingCard.js";
import { encodeUUIDToBase64url } from "../../utility";
import AttachmentListCard from "../PagePartials/AttachmentListCard";
import useDialogState from "../../HOC/useDialogState";
import { Dialog as SrvcEnd } from "../BusinessActions/SrvcEnd";
import { Dialog as SrvcStart } from "../BusinessActions/SrvcStart";
import { Dialog as SrvcPrefPickup } from "../BusinessActions/SrvcPrefPickup";
import CommentFeed from "../StandardComponents/CommentFeed";
import { fmtUpsServiceCodeLabel } from "../../formatters";

const tlItemList = [
  ["init_on", { label: "Created" }],
  ["acked_on", { label: "Initial Call" }],
  ["packed_on", { label: "Unit Packed" }],
  [
    "shipped_on",
    {
      label: "Unit Shipped",
      labelSubheader: (d) =>
        d.pref_ship_on ? `Planned: ${d.pref_ship_on}` : null,
    },
  ],
  [
    "arrived_on",
    {
      label: "Unit Arrived",
      labelSubheader: (d) =>
        d.pref_arrive_on ? `Planned: ${d.pref_arrive_on}` : null,
    },
  ],
  ["fit_on", { label: "Patient Fit" }],
  ["service_start_on", { label: "Service Started" }],
  ["service_end_on", { label: "Service Ended" }],
  [
    "picked_up_on",
    {
      label: "Unit Picked Up",
      labelSubheader: (d) =>
        d.pref_pickup_on ? `Planned: ${d.pref_pickup_on}` : null,
    },
  ],
  ["returned_on", { label: "Unit Returned" }],
  ["reprocessed_on", { label: "Reprocessed" }],
  ["closed_on", { label: "Closed" }],
];

const TimelineSCItem = ({
  ignoreConnector = false,
  label,
  date,
  labelSubheader,
}) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent variant="body2">{date}</TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          color="primary"
          variant={date ? "filled" : "outlined"}
        ></TimelineDot>
        {!ignoreConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography>{label}</Typography>
        {labelSubheader && (
          <Typography variant="caption">{labelSubheader}</Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

const shippingAddress = (pageData) => {
  const d = pageData;
  const addr = [
    d.addr_street1,
    d.addr_street2,
    d.addr_city,
    d.addr_state,
    d.addr_zip,
  ]
    .filter((x) => !!x)
    .join(", ");
  if (!addr) return "[NOT ENTERED]";
  return addr;
};

const PageServiceCycle = ({ pageData, refresh, api }) => {
  const [genLabelOpen, setGenLabelOpen] = useState(false);
  const handleOpenGenLabel = () => setGenLabelOpen(true);
  const handleCloseGenLabel = () => setGenLabelOpen(false);

  const {
    open: openServiceEnd,
    openFn: handleOpenServiceEnd,
    closeFn: handleCloseServiceEnd,
  } = useDialogState();
  const {
    open: openServiceStart,
    openFn: handleOpenServiceStart,
    closeFn: handleCloseServiceStart,
  } = useDialogState();
  const {
    open: openPrefPickup,
    openFn: handleOpenPrefPickup,
    closeFn: handleClosePrefPickup,
  } = useDialogState();

  const dataDump = {
    Channel: pageData.service_channel,
    "Shipping Address": shippingAddress(pageData),
    Status: pageData.cur_status,
    "Planned Ship Method": fmtUpsServiceCodeLabel(
      pageData.pref_service_code,
      ""
    ),
  };

  if (pageData.pic_token)
    dataDump["PIC Link"] =
      "https://central.wrs.us/token/" +
      encodeUUIDToBase64url(pageData.pic_token);

  if (pageData.service_channel === "drop-ship") {
    dataDump["Pickup Plan"] = pageData.pickup_plan;
    dataDump["Pickup Notes"] = "";
  }

  const optsAndClicks = {};

  if (
    pageData.service_channel === "drop-ship" &&
    !!pageData.pref_arrive_on &&
    !pageData.shipped_on
  ) {
    optsAndClicks["Generate Label"] = handleOpenGenLabel;
  }

  if (pageData.service_start_on && pageData.arrived_on) {
    optsAndClicks["Set Service Start"] = handleOpenServiceStart;
  }

  if (pageData.service_end_on) {
    optsAndClicks["Set Service End"] = handleOpenServiceEnd;
  }

  if (
    pageData.cur_status === "pref_pickup" ||
    pageData.cur_status === "service_end"
  ) {
    optsAndClicks["Set Pickup Plan"] = handleOpenPrefPickup;
  }

  const showMemo =
    pageData.service_channel === "drop-ship" && pageData.pickup_plan_memo;

  const shipWithoutContact =
    pageData.pref_arrive_on !== null && pageData.treat.acked_on === null;

  const isAborted = pageData.aborted_on !== null;

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <PatientSummaryCard
            navCtx={pageData.nav}
            actionArea={<SplitButton optsAndClicks={optsAndClicks} />}
          />
        </Grid2>
        {isAborted && (
          <Grid2 xs={12}>
            <Alert severity="error">
              <AlertTitle>
                Service Cycle Aborted on {pageData.aborted_on}
              </AlertTitle>
              Reason: {pageData.abort_reason}
            </Alert>
          </Grid2>
        )}
        {shipWithoutContact && (
          <Grid2 xs={12}>
            <Alert severity="error">
              This patient has not been contacted but we are moving forward with
              shipping them a unit anyway!
            </Alert>
          </Grid2>
        )}
        <Grid2 xs={7}>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <KeyValueList kvData={dataDump} />
            {showMemo && (
              <Box
                sx={{
                  whiteSpace: "pre-line",
                  fontFamily: "monospace",
                  mx: 2,
                  mt: -2,
                  p: 1,
                  fontSize: "smaller",
                }}
              >
                {pageData.pickup_plan_memo}
              </Box>
            )}
          </Paper>
          <ShippingCard
            sendLabel={pageData.send_label}
            returnLabel={pageData.return_label}
            api={api}
            srvcId={pageData.id}
            refresh={refresh}
          />
        </Grid2>
        <Grid2 xs={5}>
          <Card variant="outlined">
            <CardHeader title="Timeline" />
            <CardContent sx={{ p: 0 }}>
              <Timeline position="left">
                {tlItemList.map(([i, d]) => (
                  <TimelineSCItem
                    key={i}
                    label={d.label}
                    date={pageData[i]}
                    ignoreConnector={i === "closed_on"}
                    labelSubheader={
                      d.labelSubheader ? d.labelSubheader(pageData) : null
                    }
                  />
                ))}
              </Timeline>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 xs={12}>
          <AttachmentListCard
            editable
            attachmentData={pageData.attachments}
            refresh={refresh}
            api={api}
            targetEntity="service_cycles"
            targetId={pageData.id}
          />
        </Grid2>
        <Grid2 xs={12}>
          <CommentFeed api={api} type="treatment" id={pageData.treat_id} />
        </Grid2>
      </Grid2>
      <SrvcStart
        open={openServiceStart}
        api={api}
        refresh={refresh}
        onClose={handleCloseServiceStart}
        initialVals={pageData}
      />
      <SrvcEnd
        open={openServiceEnd}
        api={api}
        refresh={refresh}
        onClose={handleCloseServiceEnd}
        initialVals={pageData}
      />
      <GenerateLabelFormDialog
        open={genLabelOpen}
        onClose={handleCloseGenLabel}
        api={api}
        refresh={refresh}
        initialVals={pageData}
      />
      <SrvcPrefPickup
        api={api}
        refresh={refresh}
        initialVals={pageData}
        open={openPrefPickup}
        onClose={handleClosePrefPickup}
      />
    </>
  );
};

export default PageServiceCycle;
