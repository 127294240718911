import { forwardRef } from "react";
import { getCurrencyMaskGenerator, useWebMask } from "react-hook-mask";
import { TextField } from "@mui/material";

const maskGenerator = getCurrencyMaskGenerator({
  prefix: "$",
  thousandSeparator: ",",
  centsSeparator: ".",
});

const RPCurrencyInput = forwardRef(
  (
    { value: outerValue, onChange: outerOnChange, keepMask, ...props },
    outerRef
  ) => {
    const { value, onChange, ref } = useWebMask({
      maskGenerator,
      value: outerValue,
      onChange: outerOnChange,
      ref: outerRef,
      keepMask,
    });

    return (
      <TextField value={value} onChange={onChange} inputRef={ref} {...props} />
    );
  }
);

export default RPCurrencyInput;
