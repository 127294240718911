import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
} from "@mui/material";
import KeyValueList from "../../PagePartials/KeyValueList";
import { moneyFmt } from "../../../formatters";
import EditIcon from "@mui/icons-material/Edit";
import TicketContextMenu from "../../PagePartials/Tickets/TicketContextMenu";
import { InternalLink } from "../../StandardComponents/InternalLink";

const PaymentDetailsBox = ({
  ident,
  org_id,
  porg_name,
  payor_name,
  issued,
  cleared,
  total,
  onEditReqFn,
  onVoidClick,
  isVoidable,
  formDirty,
  api,
  paymentId,
}) => {
  const title = `Check ${ident}`;
  const payor = `${porg_name} - ${payor_name}`;
  const displayData = {
    "Entered Date": issued,
    "Lockbox Date": cleared,
    Total: moneyFmt(total),
  };

  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardHeader
        titleTypographyProps={{ variant: "h6" }}
        subheaderTypographyProps={{ variant: "caption" }}
        title={title}
        subheader={<InternalLink to={`/orgs/${org_id}`}>{payor}</InternalLink>}
        sx={{ mb: 0, pb: 0 }}
        action={
          <Stack direction="row" spacing={1}>
            <IconButton onClick={onEditReqFn} disabled={formDirty}>
              <EditIcon />
            </IconButton>
            <TicketContextMenu
              api={api}
              context={{
                type: "payments",
                ident: paymentId,
                label: `Check ${ident}`,
              }}
            />
          </Stack>
        }
      />
      <CardContent sx={{ mt: 0, pt: 0 }}>
        <KeyValueList kvData={displayData} />
      </CardContent>
      <CardActions sx={{ flexDirection: "row" }}>
        <Button variant="outlined" disabled={!isVoidable} onClick={onVoidClick}>
          Void Payment
        </Button>
      </CardActions>
    </Card>
  );
};

export default PaymentDetailsBox;
