import { useState } from "react";
import {
  addDays,
  format,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import SnoozeIcon from "@mui/icons-material/Snooze";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import { toZonedTime } from "date-fns-tz";

const morningOfNextDays = (days) => {
  const now = new Date();
  return setHours(
    setMinutes(setSeconds(setMilliseconds(addDays(now, days), 0), 0), 0),
    7
  );
};

const SnoozeControl = ({
  orderId,
  disabled,
  snoozed,
  snooze_until,
  onSnoozeRequest,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (ev) => setAnchorEl(ev.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  if (snoozed) {
    const untilFmt = format(
      toZonedTime(new Date(snooze_until), "America/Detroit"),
      "MM/dd/yyyy"
    );
    return (
      <Tooltip title={`Snoozed until ${untilFmt}`} arrow placement="left">
        <IconButton
          color="error"
          disabled={disabled}
          size="small"
          onClick={() => {
            onSnoozeRequest(orderId, new Date());
            handleMenuClose();
          }}
        >
          <AlarmOffIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      <IconButton
        disabled={disabled}
        size="small"
        id={`snooze-${orderId}`}
        onClick={handleMenuOpen}
      >
        <SnoozeIcon fontSize="small" />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            onSnoozeRequest(orderId, morningOfNextDays(1));
            handleMenuClose();
          }}
        >
          Snooze for 1 day
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSnoozeRequest(orderId, morningOfNextDays(3));
            handleMenuClose();
          }}
        >
          Snooze for 3 days
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSnoozeRequest(orderId, morningOfNextDays(7));
            handleMenuClose();
          }}
        >
          Snooze for 7 days
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSnoozeRequest(orderId, null);
            handleMenuClose();
          }}
        >
          Custom...
        </MenuItem>
      </Menu>
    </>
  );
};

export default SnoozeControl;
