import { WSBGRepLink, WSProductionCell, WSUserAvatar } from "./ws-shared";
import { InternalLink } from "../Components/StandardComponents/InternalLink";

const repListColDefs = [
  {
    label: "Rep",
    cell: (r) => (
      <WSBGRepLink
        rep_id={r.id}
        rep_name={r.name}
        bg_current={r.bg_current}
        bg_baseline={r.bg_baseline}
        bg_age={r.bg_age}
      />
    ),
  },
  {
    label: "Production",
    cell: (r) => (
      <WSProductionCell bg_current={r.bg_current} bg_baseline={r.bg_baseline} />
    ),
    width: "160px",
  },
  {
    label: "Agency",
    cell: (r) => (
      <InternalLink to={`/agencies/${r.agency_id}`}>
        {r.agency_name}
      </InternalLink>
    ),
  },
  {
    label: "Age",
    cell: (r) => r.bg_age,
    width: "65px",
  },
  {
    label: "Guide",
    cell: (r) => (
      <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
    ),
    width: "80px",
  },
];

//"rep-bleeders", "rep-dead", "rep-baby"
export const salesSection = {
  title: "Sales Leadership Team",
  key: "sec-sales",
  buckets: ["rep-gainers", "rep-bleeders", "rep-babies"],
  filterBuilderFn: (data) => {
    if (!data) return null;
    const list = data.reduce((acc, cur) => {
      if (!acc[cur.guide_id])
        acc[cur.guide_id] = { count: 0, guide: cur.guide_name };
      acc[cur.guide_id].count += 1;
      return acc;
    }, {});

    return {
      opts: Object.fromEntries(
        Object.entries(list).map(([value, dat]) => {
          return [value, `(${dat.count}) ${dat.guide}`];
        })
      ),
      matchingCol: "guide_id",
    };
  },
};

export const repGainers = [
  "rep-gainers",
  {
    title: "Rep Gainers",
    description: "Reps trending upward in production",
    columns: repListColDefs,
  },
];

export const repBleeders = [
  "rep-bleeders",
  {
    title: "Rep Bleeders",
    description: "Reps that are trending down in production",
    columns: repListColDefs,
  },
];

export const repBabies = [
  "rep-babies",
  {
    title: "Fresh Reps",
    description: "Reps that are new and haven't gotten a script yet.",
    columns: repListColDefs,
  },
];
