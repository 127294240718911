import { useController } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";

const RHFCheckboxSimple = ({
  name,
  control,
  label,
  rules = undefined,
  extraLabelProps = {},
  extraOnChange = undefined,
  ...rest
}) => {
  const { field } = useController({ name, control, rules });

  const extraOnChangeFn = extraOnChange || (() => {});

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={field.name}
          onBlur={field.onBlur}
          inputRef={field.ref}
          onChange={(...vals) => {
            extraOnChangeFn(...vals);
            field.onChange(...vals);
          }}
          checked={field.value}
          {...rest}
        />
      }
      label={label}
      {...extraLabelProps}
    />
  );
};

export default RHFCheckboxSimple;
