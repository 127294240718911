import { InternalLink } from "../../StandardComponents/InternalLink";

const CTX_LINK_MAP = {
  treatments: (i) => `/treatments/${i}`,
  prescriptions: (i) => `/rxs/${i}`,
  orders: (i) => `/orders/${i}`,
  service_cycles: (i) => `/service-cycle/${i}`,
  inv_serials: (i) => `/units/${i}`,
  reps: (i) => `/reps/${i}`,
  agencies: (i) => `/agencies/${i}`,
  payments: (i) => `/payments/${i}`,
  payor_orgs: (i) => `/orgs/${i}`,
  practices: (i) => `/practices/${i}`,
  practice_contacts: (i) => `/practice-contacts/${i}`,
  prescribers: (i) => `/prescribers/${i}`,
  intake: (i) => `/intake/${i}`,
};

/**
 * @param {{
 *   label: string,
 *   type: TicketContext,
 *   ident: number,
 * } | null | undefined} context
 * @param {string | undefined} [def='']
 * @constructor
 */
const TicketContextLink = ({ context, def = "" }) => {
  const defVal = def ?? "";

  if (!context) {
    return defVal;
  }

  const linkGenFn = CTX_LINK_MAP[context.type];

  if (!linkGenFn) {
    return context.label;
  }

  return (
    <InternalLink to={linkGenFn(context.ident)}>{context.label}</InternalLink>
  );
};

export default TicketContextLink;
