import { TableCell, TableHead, TableRow } from "@mui/material";

const DataTableHead = ({ cols }) => {
  return (
    <TableHead>
      <TableRow>
        {cols.map((c) => {
          const cellProps = {};
          const label = c.label || c.name;
          if (c.size) {
            cellProps.width = c.size;
            cellProps.sx = { maxWidth: c.size };
          }
          return (
            <TableCell key={c.key || c.name} {...cellProps}>
              {label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default DataTableHead;
