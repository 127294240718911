import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const MediaContent = ({ attachment, loadState, errMsg, attachData }) => {
  const isObj = attachment.mime === "application/pdf";
  const isDl =
    attachment.mime ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  useEffect(() => {
    if (!attachData || !isDl) return;
    const url = URL.createObjectURL(attachData);
    const linkEl = document.createElement("a");
    document.body.appendChild(linkEl);
    linkEl.style = "display: none";
    linkEl.href = url;
    linkEl.download = attachment.title;
    linkEl.click();
    URL.revokeObjectURL(linkEl);
    document.body.removeChild(linkEl);
  }, [attachData, attachment.title, isDl]);

  if (loadState === "loading") {
    return <Typography>Loading...</Typography>;
  }
  if (loadState === "error") {
    return <Typography>{errMsg}</Typography>;
  }

  const url = URL.createObjectURL(attachData);

  return (
    <Box sx={{ textAlign: "center" }}>
      {isObj ? (
        <object
          title={attachment.title}
          type="application/pdf"
          width="1100"
          style={{ height: "85vh" }}
          data={url}
        >
          <p>PDF Error</p>
        </object>
      ) : isDl ? (
        <Typography>File downloaded!</Typography>
      ) : (
        <img src={url} style={{ maxWidth: "800px" }} alt={attachment.title} />
      )}
    </Box>
  );
};

const MediaShowDialog = ({ api, attachment, open, onClose }) => {
  const [attachData, setAttachData] = useState(null);
  const [reqState, setReqState] = useState("loading");
  const [errMsg, setErrMsg] = useState("");

  const handleOnClose = () => {
    setReqState("loading");
    setAttachData(null);
    setErrMsg("");
    onClose();
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    api
      .getAttachment(attachment.id)
      .then((blob) => {
        setAttachData(blob);
        setReqState("loaded");
      })
      .catch((err) => {
        setReqState("error");
        setErrMsg(err.message);
      });
  }, [api, attachment?.id, open]);

  if (!attachment) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      PaperProps={{ sx: { width: 1150, minWidth: 1150 } }}
      disableScrollLock
    >
      <DialogTitle>{attachment.title}</DialogTitle>
      <DialogContent>
        <MediaContent
          attachData={attachData}
          attachment={attachment}
          loadState={reqState}
          errMsg={errMsg}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MediaShowDialog;
