import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";

const NotesEditForm = ({ npi, notes, api, onSuccess }) => {
  const { control, handleSubmit, setError } = useForm({
    defaultValues: { notes },
  });
  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    { notes },
    onSuccess
  );
  const textFldRef = useRef();

  useEffect(() => {
    textFldRef.current.focus();
    textFldRef.current.select();
  }, []);

  const onSubmit = (data) => {
    apiWrapper(api.updatePrescNotes(npi, data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent sx={{ pt: 0 }}>
        <FormLevelErrorAlert formErrStruct={formErrState} />
        <RHFTextField
          ref={textFldRef}
          name="notes"
          control={control}
          multiline
          rows={8}
          InputProps={{ sx: { fontFamily: "monospace" } }}
        />
      </CardContent>
      <CardActions sx={{ flexDirection: "row-reverse" }}>
        <Button type="submit">Save</Button>
      </CardActions>
    </form>
  );
};

const ActionButton = ({ editMode, setEditMode }) => {
  if (editMode) {
    return (
      <IconButton onClick={() => setEditMode(false)} size="small">
        <ClearIcon fontSize="small" />
      </IconButton>
    );
  }
  return (
    <IconButton onClick={() => setEditMode(true)} size="small">
      <EditIcon fontSize="small" />
    </IconButton>
  );
};

const NotesDispEditForm = ({ npi, notes, api, refresh }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Card variant="outlined">
      <CardHeader
        title="Notes"
        titleTypographyProps={{ variant: "h6" }}
        sx={{ pb: 0 }}
        action={<ActionButton editMode={editMode} setEditMode={setEditMode} />}
      />
      {editMode ? (
        <NotesEditForm
          npi={npi}
          notes={notes}
          api={api}
          refresh={refresh}
          onSuccess={() => {
            setEditMode(false);
            refresh();
          }}
        />
      ) : (
        <CardContent>
          <Box sx={{ minHeight: "220px" }}>
            <Typography
              sx={{ fontFamily: "monospace", whiteSpace: "pre-line" }}
            >
              {notes}
            </Typography>
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default NotesDispEditForm;
