import { useState } from "react";
import BGYearSelect from "./BGYearSelect";
import BGQuarterSelect from "./BGQuarterSelect";
import { Stack } from "@mui/material";
import { getQuarter } from "date-fns";

const getQuarterMin = (year) => {
  if (year === 2023) return 2;
  return 1;
};

const getQuarterMax = (year) => {
  const now = new Date();
  if (year === now.getFullYear()) return getQuarter(now);
  return 4;
};

const BGYearQuarterSelect = ({ onSelectFn }) => {
  const [selectedVal, setSelectedVal] = useState({ year: null, quarter: null });

  const quarterDisabled = selectedVal.year === null;
  const quarterMin = getQuarterMin(selectedVal.year);
  const quarterMax = getQuarterMax(selectedVal.year);

  return (
    <Stack direction="row" spacing={2}>
      <BGYearSelect
        value={selectedVal.year ?? ""}
        onChange={(ev) => {
          const newState = {
            year: ev.target.value === "" ? null : ev.target.value,
            quarter: null,
          };
          setSelectedVal(newState);
        }}
        size="small"
        sx={{ width: "135px" }}
      />
      <BGQuarterSelect
        value={selectedVal.quarter ?? ""}
        onChange={(ev) => {
          const newState = {
            year: selectedVal.year,
            quarter: ev.target.value === "" ? null : ev.target.value,
          };
          setSelectedVal(newState);
          onSelectFn(newState);
        }}
        disabled={quarterDisabled}
        size="small"
        sx={{ width: "200px" }}
        min={quarterMin}
        max={quarterMax}
      />
    </Stack>
  );
};

export default BGYearQuarterSelect;
