import { forwardRef } from "react";
import RPLanguagePicker from "../FormControls/RPLanguagePicker";

const NLanguageField = forwardRef(({ fieldRecord, updater }, ref) => {
  return (
    <RPLanguagePicker
      name={fieldRecord.name}
      label={fieldRecord.label}
      helperText={fieldRecord.errstr}
      error={fieldRecord.error}
      onChange={(newVal) => updater(fieldRecord.name, newVal)}
      value={fieldRecord.value || null}
      ref={ref}
      size="small"
      fullWidth
    />
  );
});

export default NLanguageField;
