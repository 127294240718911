import { useRef, useState } from "react";
import { Box, Button, TextField } from "@mui/material";

const Icd10PickerForm = ({ api, addCodeFn }) => {
  const [iptCode, setIptCode] = useState("");
  const [codeSelErr, setCodeSelErr] = useState("");
  const inputEl = useRef(null);

  const handleIptCodeChange = (e) => setIptCode(e.target.value);

  const handleSubmit = () => {
    setCodeSelErr("");
    api
      .icd10Search(iptCode)
      .then((resp) => {
        if (resp.status === "validation") {
          setCodeSelErr(resp.errors.code);
          return;
        }
        if (resp.status === "error") {
          setCodeSelErr(resp.message);
          return;
        }
        resp.data.diag = false;
        addCodeFn(resp.data);
        setIptCode("");
        inputEl.current.focus();
      })
      .catch((err) => setCodeSelErr(err.message));
  };

  return (
    <Box sx={{ mt: 3 }}>
      <TextField
        sx={{ width: "200px" }}
        name="code_selector"
        size="small"
        label="Enter Code"
        helperText={!!codeSelErr ? codeSelErr : " "}
        error={!!codeSelErr}
        value={iptCode}
        onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
        onChange={handleIptCodeChange}
        inputRef={inputEl}
      />
      <Button
        sx={{ width: "150px", height: "40px", ml: 2 }}
        type="button"
        variant="contained"
        disabled={!iptCode}
        onClick={() => handleSubmit()}
      >
        Add Code
      </Button>
    </Box>
  );
};

export default Icd10PickerForm;
