import StandardDialog from "../../StandardComponents/StandardDialog";
import ReportFilterForm from "./Filters/ReportFilterForm";

const ReportFilterDialog = ({ api, refresh, open, onClose, reportConfig }) => {
  if (reportConfig === null) return null;

  return (
    <StandardDialog
      open={open}
      onClose={onClose}
      title={`Run ${reportConfig.label}`}
    >
      <ReportFilterForm
        filters={reportConfig.filters}
        api={api}
        refresh={refresh}
        reportName={reportConfig.name}
      />
    </StandardDialog>
  );
};

export default ReportFilterDialog;
