import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import SectionHeading from "../PagePartials/SectionHeading";
import SplitButton from "../StandardComponents/SplitButton";
import KeyValueList from "../PagePartials/KeyValueList";
import StdConfirmSubmitDialog from "../PagePartials/StdConfirmSubmitDialog";
import PurchaseOrderStepper from "./PagePurchaseOrder/PurchaseOrderStepper";
import PurchaseOrderSetSerials from "./PagePurchaseOrder/PurchaseOrderSetSerials";

import { InternalLink } from "../StandardComponents/InternalLink";
import TextboxEditableCard from "../StandardComponents/TextboxEditableCard";
import AddItemControl from "./PagePurchaseOrder/AddItemControl";
import ApiDataContext from "../../ApiDataContext";
import { useFieldArray, useForm } from "react-hook-form";
import ItemCollectionTable from "./PagePurchaseOrder/ItemCollectionTable";
import { itemByIdOrDie } from "../../items";
import useStdFormErrors from "../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../StandardComponents/FormLevelErrorAlert";
import UserActionChip from "../StandardComponents/UserActionChip";
import ReceiveQuickButtonGroup from "./PagePurchaseOrder/ReceiveQuickButtonGroup";
import useDialogState from "../../HOC/useDialogState";
import ReceivePOButton from "./PagePurchaseOrder/ReceivePOButton";
import { dateObjFormatToAnnArborDateTime } from "../../utility";

const STATUS_TO_OPTS = {
  open: ["Approve PO", "Delete PO"],
  "in-progress": ["Receive PO", "Cancel PO"],
  partial: ["Receive PO"],
  cancelled: [],
  closed: [],
};

const genDefaultValues = (pageData) => {
  return {
    lines: pageData.lines.map((l) => ({
      item_id: l.item_id,
      hlabel: l.item_hlabel,
      model_number: l.item_model_number,
      item_type: l.item_type,
      qty: l.qty,
      qty_received: l.qty_received,
      qty_orig: l.qty_received,
      serials: [],
    })),
  };
};

const PagePurchaseOrder = ({ pageData, api, refresh }) => {
  const navigate = useNavigate();
  const { items } = useContext(ApiDataContext);
  const defaultValues = genDefaultValues(pageData);
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      clearFormError();
      refresh();
      handleCloseReceiveForm();
    }
  );

  const { fields, append, remove } = useFieldArray({ name: "lines", control });

  useEffect(() => {
    reset(genDefaultValues(pageData));
  }, [pageData, reset]);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const handleOpenDeleteConfirm = () => setDeleteConfirm(true);
  const handleCloseDeleteConfirm = () => setDeleteConfirm(false);

  const [approveConfirm, setApproveConfirm] = useState(false);
  const handleOpenApproveConfirm = () => setApproveConfirm(true);
  const handleCloseApproveConfirm = () => setApproveConfirm(false);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const handleOpenCancelConfirm = () => setCancelConfirm(true);
  const handleCloseCancelConfirm = () => setCancelConfirm(false);

  const [receiveForm, setReceiveForm] = useState(false);
  const handleOpenReceiveForm = () => setReceiveForm(true);
  const handleCloseReceiveForm = () => setReceiveForm(false);

  const {
    open: serialsForm,
    openFn: handleOpenSerialsForm,
    closeFn: handleCloseSerialsForm,
  } = useDialogState(false);

  const poData = {
    Recipient: (
      <InternalLink
        sx={{ typography: "body2" }}
        to={`/agencies/${pageData.agency_id}`}
      >
        {pageData.agency_name}
      </InternalLink>
    ),
    Manufacturer: pageData.mfgr_name,
    Status: pageData.status,
  };

  const finalLabel = pageData.received_on
    ? "Received"
    : pageData.cancelled_on
    ? "Cancelled"
    : "Received";

  const timelineData = {
    Created: (
      <UserActionChip
        user_name={pageData.created_by_name}
        user_pic={pageData.created_by_pic}
        date={pageData.created_on}
      />
    ),
    Approved: pageData.approved_on ? (
      <UserActionChip
        user_name={pageData.approved_by_name}
        user_pic={pageData.approved_by_pic}
        date={pageData.approved_on}
      />
    ) : (
      ""
    ),
    [finalLabel]:
      pageData.received_on || pageData.cancelled_on ? (
        <UserActionChip
          user_name={pageData.received_by_name || pageData.cancelled_by_name}
          user_pic={pageData.received_by_pic || pageData.cancelled_by_pic}
          date={pageData.received_on || pageData.cancelled_on}
        />
      ) : (
        ""
      ),
  };

  const optionsByStatus = (status, lineCount) => {
    const splitBtnOpts = {
      "Approve PO": handleOpenApproveConfirm,
      "Delete PO": handleOpenDeleteConfirm,
      "Receive PO": handleOpenReceiveForm,
      "Cancel PO": handleOpenCancelConfirm,
    };

    const valid = STATUS_TO_OPTS[status];
    if (!valid) {
      return {};
    }
    for (const [act] of Object.entries(splitBtnOpts)) {
      if (!valid.includes(act)) {
        delete splitBtnOpts[act];
      }
    }

    if (lineCount === 0 || isDirty) delete splitBtnOpts["Approve PO"];

    return splitBtnOpts;
  };

  const saveDisabled = formErrState.submitting || !isDirty;

  const onSubmit = (data) => {
    if (!receiveForm) {
      const payload = data.lines.map((d) => ({
        item_id: d.item_id,
        qty: d.qty,
      }));
      return apiWrapper(api.poSetLines(pageData.id, payload));
    }

    const payload = data.lines.map((d) => ({
      item_id: d.item_id,
      qty_received: d.qty_received,
      serials: d.serials,
    }));
    return apiWrapper(api.receivePo(pageData.id, { lines: payload }));
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <BackLinkHeader title="Back to PO List" to="/purchase-orders" />
        <SectionHeading
          headingLabel={`Purchase Order PO-${pageData.id
            .toString()
            .padStart(4, "0")}`}
          buttonEl={
            <SplitButton
              optsAndClicks={optionsByStatus(pageData.status, fields.length)}
              disabled={receiveForm}
            />
          }
        />
        <Paper variant="outlined" sx={{ p: 2 }}>
          <PurchaseOrderStepper poStatus={pageData.status} />
        </Paper>
        <Stack direction="row" spacing={3}>
          <Paper variant="outlined" sx={{ width: "100%" }}>
            <KeyValueList kvData={poData} boldedKeys />
          </Paper>
          <Paper variant="outlined" sx={{ width: "100%" }}>
            <KeyValueList
              kvData={timelineData}
              boldedKeys
              ignoreTypos={[
                "Created",
                "Approved",
                "Closed",
                "Cancelled",
                "Received",
              ]}
            />
          </Paper>
        </Stack>
        {pageData.status === "open" && (
          <Paper variant="outlined" sx={{ p: 2 }}>
            <AddItemControl
              api={api}
              mfgrId={pageData.mfgr_id}
              removeList={fields.map((l) => l.item_id)}
              onItemAdd={(itemId, qty) => {
                const pickedItem = itemByIdOrDie(items, itemId);
                const formModel = {
                  item_id: pickedItem.id,
                  hlabel: pickedItem.hlabel,
                  item_type: pickedItem.item_type,
                  model_number: pickedItem.model_number,
                  qty: qty,
                  qty_received: 0,
                  qty_orig: 0,
                };
                append(formModel);
              }}
            />
          </Paper>
        )}
        <Paper variant="outlined" sx={{ p: 2 }}>
          <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ReceiveQuickButtonGroup
              open={receiveForm}
              onClose={handleCloseReceiveForm}
              onReset={() => reset()}
              onReceiveAllConsumables={() => {
                fields.forEach((f, i) => {
                  if (f.item_type === "consumable")
                    setValue(`lines.${i}.qty_received`, f.qty, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                });
              }}
            />
            <ItemCollectionTable
              items={fields}
              editConfig={
                pageData.status === "open"
                  ? {
                      control,
                      onRemoveItem: (itemId) => {
                        const idxToRemove = fields.findIndex(
                          (f) => f.item_id === itemId
                        );
                        remove(idxToRemove);
                      },
                      setValue,
                      fieldPrefix: "lines",
                    }
                  : null
              }
              receiveConfig={
                receiveForm
                  ? {
                      control: control,
                      fieldPrefix: "lines",
                      onSerialOpen: (field) => {
                        handleOpenSerialsForm(field);
                      },
                    }
                  : null
              }
            />
            {pageData.status === "open" && (
              <Box sx={{ mt: 2, textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={saveDisabled}
                >
                  Save
                </Button>
              </Box>
            )}
            {receiveForm && (
              <Box sx={{ mt: 2, textAlign: "right" }}>
                <ReceivePOButton
                  disabled={saveDisabled}
                  control={control}
                  name="lines"
                />
              </Box>
            )}
          </form>
        </Paper>
        {pageData.serials.length > 0 && (
          <Paper sx={{ p: 2 }} variant="outlined">
            <Typography fontSize="large">Received Serial Numbers</Typography>
            <Divider />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Serial</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Received</TableCell>
                  <TableCell>Current Agency</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageData.serials.map((s) => {
                  return (
                    <TableRow key={s.id}>
                      <TableCell>
                        <InternalLink to={`/units/${s.id}`}>
                          {s.serial_num}
                        </InternalLink>
                      </TableCell>
                      <TableCell>{s.hlabel}</TableCell>
                      <TableCell>
                        {dateObjFormatToAnnArborDateTime(s.created_on, false)}
                      </TableCell>
                      <TableCell>
                        <InternalLink to={`/agencies/${s.loc_agency}`}>
                          {s.agency_name}
                        </InternalLink>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        )}
        <Divider />
        <TextboxEditableCard
          rows={4}
          onSubmit={(data, apiWrapper) =>
            apiWrapper(api.poSetNotes(pageData.id, data.content))
          }
          content={pageData.notes}
          onSuccess={() => {
            refresh();
          }}
          minHeight="110px"
        />
      </Stack>
      <StdConfirmSubmitDialog
        title="Delete PO"
        onSubmit={() => api.deletePo(pageData.id)}
        open={deleteConfirm}
        onComplete={() => navigate("/purchase-orders")}
        handleClose={handleCloseDeleteConfirm}
      >
        Permanently delete this Purchase Order?
      </StdConfirmSubmitDialog>
      <StdConfirmSubmitDialog
        title="Approve PO"
        onSubmit={() => api.approvePo(pageData.id)}
        open={approveConfirm}
        onComplete={() => {
          refresh();
          handleCloseApproveConfirm();
        }}
        handleClose={handleCloseApproveConfirm}
      >
        This means the PO has been submitted to the manufacturer and we expect
        delivery some time in the future.
      </StdConfirmSubmitDialog>
      <StdConfirmSubmitDialog
        title="Cancel PO"
        onSubmit={() => api.cancelPo(pageData.id)}
        open={cancelConfirm}
        onComplete={() => {
          refresh();
          handleCloseCancelConfirm();
        }}
        handleClose={handleCloseCancelConfirm}
      >
        This this stops the PO from ever being received. You will not be able to
        "re-open" the PO.
      </StdConfirmSubmitDialog>
      {!!serialsForm && (
        <PurchaseOrderSetSerials
          open={!!serialsForm}
          onClose={handleCloseSerialsForm}
          onCommit={(serials, itemId) => {
            const idx = fields.findIndex((l) => l.item_id === itemId);
            const fldPrefix = `lines.${idx}`;
            setValue(`${fldPrefix}.serials`, serials, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue(
              `${fldPrefix}.qty_received`,
              serialsForm.qty_orig + serials.length,
              {
                shouldDirty: true,
                shouldTouch: true,
              }
            );
          }}
          itemId={serialsForm.item_id}
          amtRequested={serialsForm.qty - serialsForm.qty_received}
          initialValue={serialsForm.serials || []}
        />
      )}
    </>
  );
};

export default PagePurchaseOrder;
