import { TableCell, TableRow, Tooltip } from "@mui/material";
import { moneyFmt } from "../../../formatters";
import RHFCheckboxSimple from "../../FormControlsRHF/RHFCheckboxSimple";
import { dateObjFormatToAnnArborDateTime } from "../../../utility";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const fieldFind = (fields, id) => {
  const found = fields.findIndex((f) => f.incid === id);
  if (found === -1) throw new Error("did not find field in field list");

  return [found, fields[found]];
};

const IncentivesToSweepTableRow = ({
  i,
  control,
  fields,
  setValue,
  disabled,
}) => {
  const [idx] = fieldFind(fields, i.id);
  const eventTime = dateObjFormatToAnnArborDateTime(i.created_on, false);

  return (
    <TableRow>
      <TableCell>{i.memo}</TableCell>
      <TableCell sx={{ px: 0 }}>
        {i.reimbursable ? (
          <Tooltip title="Reimbursable" arrow placement="left">
            <CurrencyExchangeIcon fontSize="small" color="success" />
          </Tooltip>
        ) : (
          ""
        )}
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>{moneyFmt(i.amount)}</TableCell>
      <TableCell>{eventTime}</TableCell>
      <TableCell>
        <RHFCheckboxSimple
          extraLabelProps={{ sx: { m: 0 } }}
          control={control}
          size="small"
          name={`incentives.${idx}.ignore`}
          disabled={disabled}
          extraOnChange={(ev) => {
            if (ev.target.checked) {
              setValue(`incentives.${idx}.sweep`, false);
            }
          }}
        />
      </TableCell>
      <TableCell>
        <RHFCheckboxSimple
          extraLabelProps={{ sx: { m: 0 } }}
          control={control}
          size="small"
          name={`incentives.${idx}.sweep`}
          disabled={disabled}
          extraOnChange={(ev) => {
            if (ev.target.checked) {
              setValue(`incentives.${idx}.ignore`, false);
            }
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default IncentivesToSweepTableRow;
