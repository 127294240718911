import { forwardRef, useContext } from "react";
import ApiDataContext from "../../ApiDataContext";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const xformSAToFormData = (salesAreas) => {
  return salesAreas.map((sa) => ({ value: sa.id, label: sa.name }));
};

const RPSalesAreaPicker = forwardRef((props, ref) => {
  const { salesAreas } = useContext(ApiDataContext);
  const salesAreaValues = xformSAToFormData(salesAreas);

  return <RPAutoCompleteCore options={salesAreaValues} {...props} ref={ref} />;
});

RPSalesAreaPicker.defaultValue = null;

export default RPSalesAreaPicker;
