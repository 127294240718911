import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import HomeIcon from "@mui/icons-material/Home";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { phoneFmt } from "../../../formatters";
import { useNavigate } from "react-router-dom";
import LinkRxer from "../../StandardComponents/Link/LinkRxer";
import SignatureIcon from "../../StandardComponents/Icons/SignatureIcon";

const PrescSigIcon = ({ programs, therapy, program }) => {
  const sigCount = programs?.[therapy]?.[program]?.sigs?.length;
  if (!sigCount) return null;
  const color =
    therapy === "cct" ? "info" : therapy === "sam" ? "success" : undefined;
  return (
    <Tooltip
      title={`Signatures for ${therapy.toUpperCase()}`}
      arrow
      placement="top"
    >
      <SignatureIcon color={color} sx={{ width: 32, height: 32, pt: "6px" }} />
    </Tooltip>
  );
};

const prefContactInfo = (c) => {
  if (c.pref_cont_method === "email") {
    return c.email || phoneFmt(c.phone);
  }

  return phoneFmt(c.phone) || c.email;
};

const ContactListItem = ({ c }) => {
  if (!c) {
    return (
      <ListItem sx={{ py: 0 }}>
        <ListItemIcon>
          <ContactPageIcon sx={{ color: "error.dark" }} />
        </ListItemIcon>
        <ListItemText primary={"Primary Contact"} secondary={"Not Set"} />
      </ListItem>
    );
  }

  return (
    <ListItem sx={{ py: 0 }}>
      <ListItemIcon>
        <ContactPageIcon />
      </ListItemIcon>
      <ListItemText
        primary={`${c.name_first} ${c.name_last}`}
        secondary={prefContactInfo(c)}
      />
    </ListItem>
  );
};

const PrescriberCard = ({ presc, locs, conts, onEditRequest }) => {
  const navigate = useNavigate();
  const loc = locs.find((l) => l.id === presc.prac_loc_id);
  const contact = conts.find((c) => c.id === presc.contact_id);

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardHeader
        sx={{ mb: 0, pb: 0 }}
        title={
          <LinkRxer
            id={presc.npi}
            credential={presc.credential}
            name_first={presc.name_first}
            name_last={presc.name_last}
          />
        }
        subheader={`NPI ${presc.npi}`}
        subheaderTypographyProps={{ variant: "caption" }}
        action={
          <Stack direction="row" spacing={1}>
            <PrescSigIcon
              programs={presc.programs}
              therapy="cct"
              program="vt4"
            />
            <PrescSigIcon
              programs={presc.programs}
              therapy="sam"
              program="sam2"
            />
            <IconButton
              onClick={() => {
                onEditRequest(presc);
              }}
            >
              <ModeEditIcon />
            </IconButton>
          </Stack>
        }
      />
      <CardContent sx={{ p: 0, m: 0, "&:last-child": { p: 0 } }}>
        <Grid container>
          <Grid item xs={7}>
            <List dense sx={{ width: "100%" }}>
              <ListItem sx={{ py: 0 }}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${loc.addr_street1} ${loc.addr_street2}`}
                  secondary={`${loc.addr_city}, ${loc.addr_state} ${loc.addr_zip}`}
                />
              </ListItem>
              <ContactListItem c={contact} />
              <ListItem sx={{ py: 0 }}>
                <ListItemButton
                  onClick={() => {
                    navigate(`/reps/${presc.rep.id}`);
                  }}
                  sx={{ p: 0 }}
                >
                  <ListItemIcon>
                    <FaceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={presc.rep.name}
                    secondary={presc.rep.agency_name}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={5}>
            <Box sx={{ width: "100%", height: "100%" }}>
              <Skeleton
                sx={{
                  bgcolor: (theme) => theme.background.construction,
                }}
                width="100%"
                height="100%"
                animation={false}
                variant="rectangular"
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PrescriberCard;
