import { forwardRef } from "react";
import { DatePicker } from "@mui/x-date-pickers-pro";

const RPDatePickerInput = forwardRef(
  ({ value, size = "small", error = "", helperText = "", ...rest }, ref) => {
    const slotProps = {
      textField: {
        helperText,
        size,
        error,
      },
    };
    return (
      <DatePicker
        value={value}
        slotProps={slotProps}
        inputRef={ref}
        {...rest}
      />
    );
  }
);

export default RPDatePickerInput;
