import { useState } from "react";
import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/**
 * @param {string} title
 * @param {ReactNode} children
 * @param {"success" | "info" | "warning" | "error"} severity
 * @param {boolean} defaultOpen
 */
const ExpandableAlert = ({
  title,
  children,
  severity = "info",
  defaultOpen = false,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Alert
      severity={severity}
      action={
        <IconButton onClick={() => setOpen((x) => !x)} size="small">
          {open ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )}
        </IconButton>
      }
    >
      <AlertTitle>{title}</AlertTitle>
      <Collapse in={open}>{children}</Collapse>
    </Alert>
  );
};

export default ExpandableAlert;
