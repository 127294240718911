import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { linkFmt } from "../../../formatters";
import { dateObjFormatToSmallest, npiName } from "../../../utility";
import { InternalLink } from "../../StandardComponents/InternalLink";

const OnboardingsTable = ({ onboardings }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>NPI</TableCell>
          <TableCell>Prescriber</TableCell>
          <TableCell>Practice Name</TableCell>
          <TableCell>Submitted By</TableCell>
          <TableCell>Submitted On</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {onboardings.map((po) => (
          <TableRow key={po.id}>
            <TableCell>
              {linkFmt(po.npi, `/prescribers/onboarding/${po.id}`)}
            </TableCell>
            <TableCell>
              {npiName(
                po.npidb_name_first,
                po.npidb_name_last,
                po.npidb_credential
              )}
            </TableCell>
            <TableCell>{po.practice_name}</TableCell>
            <TableCell>
              <InternalLink to={`/reps/${po.rep_id}`}>
                {po.rep_name}
              </InternalLink>
            </TableCell>
            <TableCell>{dateObjFormatToSmallest(po.created_on)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const OnboardingsListCard = ({ onboardings }) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title="Prescribers Submitted from Central"
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent sx={{ pt: 0 }}>
        {onboardings.length === 0 ? (
          <Alert severity="success">
            Congrats! All Central prescriber submissions have been filed!
          </Alert>
        ) : (
          <OnboardingsTable onboardings={onboardings} />
        )}
      </CardContent>
    </Card>
  );
};

export default OnboardingsListCard;
