import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPRepPicker = forwardRef(({ api, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acReps()}
    />
  );
});

RPRepPicker.defaultValue = null;

export default RPRepPicker;
