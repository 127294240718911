import { forwardRef, useContext } from "react";
import ApiDataContext from "../../ApiDataContext";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const RPItemPicker = forwardRef(
  ({ mfgrId, removeItemIds = [], ...props }, ref) => {
    const { items } = useContext(ApiDataContext);
    const itemValues = items
      .filter((i) => i.mfgr_id === mfgrId)
      .filter((i) => !removeItemIds.includes(i.id))
      .map((i) => ({
        value: i.id,
        label: `${i.hlabel} (${i.model_number})`,
      }));

    return <RPAutoCompleteCore options={itemValues} {...props} ref={ref} />;
  }
);

RPItemPicker.defaultValue = null;

export default RPItemPicker;
