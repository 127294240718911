import { useForm } from "react-hook-form";
import { useEffect } from "react";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import StandardDialog from "../../StandardComponents/StandardDialog";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import { Button } from "@mui/material";
import RHFUserPicker from "../../FormControlsRHF/RHFUserPicker";

const genDefaultValues = (userId) => {
  return { userId };
};

const TicketReassignDialog = ({
  open,
  onClose,
  onSuccess,
  api,
  type,
  userId,
  ticketId,
}) => {
  const defaultValues = genDefaultValues(userId);

  const { handleSubmit, control, setError, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(genDefaultValues(userId));
  }, [reset, userId]);

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      onSuccess();
      onClose();
    }
  );

  const onSubmit = (data) => {
    const userId = parseInt(data.userId.value, 10);
    if (type === "owner") {
      return apiWrapper(api.updateTicketOwner(ticketId, userId));
    }
    return apiWrapper(api.updateTicketAssignee(ticketId, userId));
  };

  const title =
    type === "owner" ? "Change Task Ownership?" : "Change Task Assignee?";

  return (
    <StandardDialog
      open={open}
      onClose={onClose}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      title={title}
      actions={
        <Button
          disabled={formErrState.submitting}
          type="submit"
          variant="contained"
        >
          Save Change
        </Button>
      }
    >
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <RHFUserPicker
        control={control}
        name="userId"
        api={api}
        label={type === "owner" ? "New Task Owner" : "New Task Assignee"}
      />
    </StandardDialog>
  );
};

export default TicketReassignDialog;
