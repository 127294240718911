import { useCallback, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Typography,
} from "@mui/material";
import {
  BUCKET_LIST,
  BUCKET_SECTIONS,
  sectionByBucketKey,
  sectionByKey,
} from "../../buckets";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WsStorage from "../../ws-storage";
import BucketTabLoader from "./PageWorkspace/BucketTabLoader";
import BucketTab from "./PageWorkspace/BucketTab";
import BucketFilterControl from "./PageWorkspace/BucketFilterControl";

const wsState = new WsStorage(true);

const defaultMetaStruct = () =>
  Object.fromEntries(
    Object.keys(BUCKET_LIST).map((b) => {
      return [
        b,
        { count: null, loading: false, error: null, filterOpts: null },
      ];
    })
  );

const PageWorkspace = ({ api }) => {
  const [savedSection, savedBucket, savedFilter] = wsState.get();
  const [sectionTab, setSectionTab] = useState(savedSection);
  const [bucketTab, setBucketTab] = useState(savedBucket);
  const [sectionFilter, setSectionFilter] = useState(savedFilter);
  const [bucketMeta, setBucketMeta] = useState(defaultMetaStruct());

  const [sectionMenuOpen, setSectionMenuOpen] = useState(null);
  const sectionMenuHandleOpen = (ev) => setSectionMenuOpen(ev.target);
  const sectionMenuHandleClose = () => setSectionMenuOpen(null);

  const curSection = sectionByKey(sectionTab);
  const bucketTabs = curSection.buckets;

  const tabStateChangeFn = useCallback(
    (bucketKey, error, isLoading, count, filterOpts) => {
      setBucketMeta((prev) => {
        const newState = { ...prev };
        const bucketState = newState[bucketKey];
        bucketState.count = count;
        bucketState.error = error;
        bucketState.filterOpts = filterOpts;
        bucketState.isLoading = isLoading;
        return newState;
      });
    },
    []
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <IconButton sx={{ mr: 1 }} onClick={sectionMenuHandleOpen}>
          <MoreHorizIcon />
        </IconButton>
        <Typography variant="h4">Workspace - {curSection.title}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ width: "250px" }}>
          <BucketFilterControl
            opts={bucketMeta[bucketTab]?.filterOpts?.opts}
            value={sectionFilter || ""}
            onChange={(ev) => {
              const [, , filterState] = wsState.setFilterState(ev.target.value);
              setSectionFilter(filterState);
            }}
          />
        </Box>
      </Box>
      <Menu
        open={!!sectionMenuOpen}
        onClose={sectionMenuHandleClose}
        anchorEl={sectionMenuOpen}
      >
        {BUCKET_SECTIONS.map((bs) => (
          <MenuItem
            onClick={() => {
              sectionMenuHandleClose();
              const [newSec, newBucket, newFilter] = wsState.setSection(bs.key);
              setSectionTab(newSec);
              setBucketTab(newBucket);
              setSectionFilter(newFilter);
            }}
            key={bs.title}
          >
            {bs.title}
          </MenuItem>
        ))}
      </Menu>
      <Tabs
        variant="scrollable"
        value={bucketTab}
        onChange={(_, val) => {
          const [newSec, newBucket] = wsState.setBucket(val);
          setSectionTab(newSec);
          setBucketTab(newBucket);
        }}
      >
        {bucketTabs.map((bt) => {
          if (!BUCKET_LIST[bt]) {
            throw new Error(`bucket name ${bt} is not linked correctly`);
          }
          return (
            <BucketTab
              label={BUCKET_LIST[bt].title}
              value={bt}
              key={bt}
              error={bucketMeta[bt].error}
              isLoading={bucketMeta[bt].isLoading}
              count={bucketMeta[bt].count}
            />
          );
        })}
      </Tabs>
      {Object.entries(BUCKET_LIST).map(([bucketKey, bucketSpec]) => (
        <BucketTabLoader
          key={bucketKey}
          selectedKey={bucketTab}
          api={api}
          sectionKey={sectionByBucketKey(bucketKey)}
          bucketKey={bucketKey}
          tabStateChangeFn={tabStateChangeFn}
          bucketSpec={bucketSpec}
          filterState={sectionFilter}
        />
      ))}
    </>
  );
};

export default PageWorkspace;
