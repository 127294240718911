import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import KeyValueList from "../../PagePartials/KeyValueList";
import { phoneFmt } from "../../../formatters";
import RepRoleChipList from "../../StandardComponents/RepRoleChipList";
import {
  apiCallSimplify,
  dateObjFormatToAnnArborDateTime,
  dateObjFormatToWRSDate,
} from "../../../utility";
import StdConfirmSubmitDialog from "../../PagePartials/StdConfirmSubmitDialog";
import useDialogState from "../../../HOC/useDialogState";

const CentralAccessToggle = ({ repId, user, api, onSuccess, disabled }) => {
  const [submitting, setSubmitting] = useState(false);
  const hasCentralPerm = user.perms.includes("central:login");

  const handleChange = async () => {
    setSubmitting(true);
    try {
      const callPromise = hasCentralPerm
        ? api.repRevokeCentralAccess(repId)
        : api.repGrantCentralAccess(repId);
      await apiCallSimplify(callPromise);
      onSuccess();
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FormControlLabel
      control={<Switch checked={hasCentralPerm} onChange={handleChange} />}
      disabled={submitting || disabled}
      label="Central Access"
    />
  );
};

const RepHeaderCard = ({
  repId,
  repName,
  agencyId,
  agencyName,
  email,
  phone,
  serviceChannel,
  roles,
  createdOn,
  user,
  serviceRep,
  agencyRetired,
  api,
  refresh,
}) => {
  const {
    open: centralOpen,
    openFn: centralHandleOpen,
    closeFn: centralHandleClose,
  } = useDialogState();
  const hasCentralAccount = !!user;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Paper variant="outlined" sx={{ width: "100%", p: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>General</Typography>
          <Divider />
          <KeyValueList
            kvData={{
              Agency: (
                <InternalLink to={`/agencies/${agencyId}`}>
                  {agencyName}
                </InternalLink>
              ),
              "Service Rep": !!serviceRep ? (
                <InternalLink to={`/reps/${serviceRep.id}`}>
                  {serviceRep.name}
                </InternalLink>
              ) : (
                "[NONE]"
              ),
              Email: email,
              Phone: phoneFmt(phone),
              Channel: serviceChannel,
              Created: dateObjFormatToWRSDate(createdOn),
              Roles: <RepRoleChipList roleList={roles} />,
            }}
            ignoreTypos={["Roles"]}
            noItemXPadding
            noItemYPadding
          />
        </Paper>
        <Paper variant="outlined" sx={{ width: "100%", p: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Central</Typography>
          <Divider />
          {hasCentralAccount ? (
            <>
              <KeyValueList
                kvData={{
                  "User ID": user.id,
                  "Auth0 ID": (
                    <Box component="span" sx={{ fontFamily: "monospace" }}>
                      {user.auth0_id}
                    </Box>
                  ),
                  Permissions: user.perms,
                  "Last Auth0 Sync": dateObjFormatToAnnArborDateTime(
                    user.last_sync,
                    true
                  ),
                  "Last Login": dateObjFormatToAnnArborDateTime(
                    user.last_login,
                    true
                  ),
                  "Login Count": user.login_count,
                }}
                noItemXPadding
                noItemYPadding
              />
              <CentralAccessToggle
                repId={repId}
                user={user}
                api={api}
                disabled={agencyRetired}
                onSuccess={() => refresh()}
              />
            </>
          ) : (
            <Alert
              severity="warning"
              sx={{ mt: 2 }}
              action={
                <Button
                  disabled={agencyRetired}
                  size="small"
                  onClick={centralHandleOpen}
                  color="inherit"
                >
                  Fix
                </Button>
              }
            >
              Central account not setup.
            </Alert>
          )}
        </Paper>
      </Stack>
      <StdConfirmSubmitDialog
        title="Setup Central Account?"
        open={centralOpen}
        handleClose={centralHandleClose}
        onSubmit={() => api.repSetupUser(repId)}
        onComplete={() => {
          refresh();
          centralHandleClose();
        }}
      >
        Set {repName} up for a WRS Central Account? Let {repName} to go to{" "}
        <Box component="span" sx={{ fontWeight: "bold" }}>
          central.wrs.us
        </Box>{" "}
        and click the "Forgot password" link to get started!
      </StdConfirmSubmitDialog>
    </>
  );
};

export default RepHeaderCard;
