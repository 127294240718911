import { useContext, useEffect, useReducer } from "react";
import ApiDataContext from "../../../ApiDataContext";
import { Divider, Grid, Typography } from "@mui/material";
import KeyValueList from "../../PagePartials/KeyValueList";

const UI = {
  INIT: "init",
  LOADING: "loading",
  LOADED: "loaded",
  ERROR: "error",
};

const initialState = {
  loadState: UI.INIT,
  data: null,
};

const actFn = {
  "set-loading": (state) => ({ ...state, loadState: UI.LOADING }),
  "set-error": (state, errorMsg) => ({ loadState: UI.ERROR, data: errorMsg }),
  "load-success": (state, data) => ({ loadState: UI.LOADED, data }),
};

const reducer = (state, action) => {
  const fn = actFn[action.type];
  const args = action.args || [];
  return fn(state, ...args);
};

const PracticePreview = ({ practiceId, repInfo }) => {
  const { api } = useContext(ApiDataContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.loadState === UI.INIT) {
      dispatch({ type: "set-loading" });
      api
        .getPractice(practiceId)
        .then((resp) => {
          if (resp.status !== "ok") {
            dispatch({ type: "set-error", args: [resp.data.message] });
            return;
          }
          dispatch({ type: "load-success", args: [resp.data] });
        })
        .catch((err) => {
          dispatch({ type: "set-error", args: [err.message] });
        });
    }
  }, [state.loadState, api, practiceId]);

  if (state.loadState === UI.INIT || state.loadState === UI.LOADING) {
    return <Typography>Loading...</Typography>;
  }

  if (state.loadState === UI.ERROR) {
    return <Typography>Error loading: {state.data}</Typography>;
  }

  const prescInfo = {
    "Preferred Name": repInfo["Preferred Name"],
    "Prescriber Email": repInfo["Prescriber Email"],
  };

  const schedInfo = {
    "Scheduler Name": repInfo["Scheduler Name"],
    "Scheduler Phone": repInfo["Scheduler Phone"],
    "Scheduler Email": repInfo["Scheduler Email"],
  };

  return (
    <Grid container columns={48}>
      <Grid item xs={23}>
        <KeyValueList kvData={prescInfo} />
      </Grid>
      <Grid item xs={1}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={23}>
        <KeyValueList kvData={schedInfo} />
      </Grid>
    </Grid>
  );
};

export default PracticePreview;
