import { Tab, Tooltip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import ErrorIcon from "@mui/icons-material/Error";

const BucketTab = ({ error, isLoading, label, count, value, ...props }) => {
  let icon = undefined;
  if (error) {
    icon = (
      <Tooltip title={error.message}>
        <ErrorIcon color="error" />
      </Tooltip>
    );
  }

  if (isLoading) {
    icon = <SyncIcon />;
  }

  const finalLabel = count === null ? label : `${label} (${count})`;

  return (
    <Tab
      label={finalLabel}
      value={value}
      icon={icon}
      iconPosition="end"
      sx={{ py: 3 }}
      {...props}
    />
  );
};

export default BucketTab;
