import { FormControl, MenuItem, Select } from "@mui/material";

const LABELS = {
  1: "01 - January",
  2: "02 - February",
  3: "03 - March",
  4: "04 - April",
  5: "05 - May",
  6: "06 - June",
  7: "07 - July",
  8: "08 - August",
  9: "09 - September",
  10: "10 - October",
  11: "11 - November",
  12: "12 - December",
};

const clamp = (min, max, val) => Math.max(min, Math.min(val, max));

const BGMonthSelect = ({ min = 1, max = 12, ...rest }) => {
  const minClamped = clamp(1, 12, min);
  const maxClamped = clamp(1, 12, max);
  const amount = Math.max(maxClamped - minClamped + 1, 1);
  const months = Array(amount)
    .fill(minClamped)
    .map((m, i) => m + i);

  return (
    <FormControl>
      <Select {...rest} displayEmpty>
        <MenuItem value="">Select Month</MenuItem>
        {months.map((m) => (
          <MenuItem key={m} value={m}>
            {LABELS[m]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BGMonthSelect;
