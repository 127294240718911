import { Box, LinearProgress } from "@mui/material";

const DoubleLabelProgress = ({ value, color, leftLabel, rightLabel }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ minWidth: "150px" }}>{leftLabel}</Box>
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress variant="determinate" value={value} color={color} />
      </Box>
      <Box sx={{ minWidth: "100px", textAlign: "right" }}>{rightLabel}</Box>
    </Box>
  );
};

export default DoubleLabelProgress;
