import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import StdUserAvatar from "../../StandardComponents/StdUserAvatar";
import { dateObjFormatToAnnArborDateTime } from "../../../utility";
import { InternalLink } from "../../StandardComponents/InternalLink";
import { moneyFmt } from "../../../formatters";

const SweepTable = ({ sweeps }) => {
  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 4 }}>
      <Typography variant="h6">Sweep Entries</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Agency</TableCell>
            <TableCell sx={{ width: "180px" }}>Amount</TableCell>
            <TableCell sx={{ width: "250px" }}>Swept On</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sweeps.map((s) => {
            const sweptOnCell = (
              <Stack direction="row" spacing={1}>
                <StdUserAvatar user_name={s.user_name} user_pic={s.user_pic} />
                <Box>
                  {dateObjFormatToAnnArborDateTime(s.created_on, false)}
                </Box>
              </Stack>
            );
            return (
              <TableRow key={s.id}>
                <TableCell>
                  <InternalLink to={`/agencies/${s.agency_id}`}>
                    {s.agency}
                  </InternalLink>
                </TableCell>
                <TableCell>{moneyFmt(s.amount)}</TableCell>
                <TableCell>{sweptOnCell}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell sx={{ border: 0, textAlign: "right" }}>Total:</TableCell>
            <TableCell sx={{ border: 0, fontWeight: "bold" }}>
              {moneyFmt(sweeps.reduce((a, s) => a + s.amount, 0))}
            </TableCell>
            <TableCell sx={{ border: 0 }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SweepTable;
