import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPPackableItems = forwardRef(({ api, treatId, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acPackableConsumables(treatId)}
    />
  );
});

RPPackableItems.defaultValue = null;

export default RPPackableItems;
