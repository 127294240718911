import { InternalLink } from "../../StandardComponents/InternalLink";

/**
 * @param {{
 *   type: "scon",
 *   id: number,
 *   eoc_id: number,
 *   eoc_name: string,
 * }} ctx
 */
const LogEntryScon = ({ ctx }) => {
  const linkUrl = `/service-cycle/${ctx.id}`;
  const linkLabel = `SC-${ctx.id.toString().padStart(4, "0")}`;
  const patientUrl = `/patients/${ctx.eoc_id}`;
  const patientName = ctx.eoc_name;

  return (
    <>
      Deployed to patient{" "}
      <InternalLink to={patientUrl}>{patientName}</InternalLink> via{" "}
      <InternalLink to={linkUrl}>{linkLabel}</InternalLink>
    </>
  );
};

export default LogEntryScon;
