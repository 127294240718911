import RHFAutocomplete from "./RHFAutocomplete";

const OPTIONS = [
  { value: "wrs", label: "WRS" },
  { value: "rep", label: "Rep" },
];

const RHFComPrefPicker = (props) => {
  return <RHFAutocomplete options={OPTIONS} {...props} />;
};

RHFComPrefPicker.byVal = (val) => OPTIONS.find((o) => o.value === val);

export default RHFComPrefPicker;
