import { useState } from "react";
import SectionHeading from "../PagePartials/SectionHeading";
import StdFormDialog from "../PagePartials/StdFormDialog";
import { Stack, TextField } from "@mui/material";
import RPPrescriberPicker from "../FormControls/RPPrescriberPicker";
import PatientIntakeCard from "./PageIntake/PatientIntakeCard";

const PageIntakes = ({ pageData, refresh, api }) => {
  const [newPatientFormOpen, setNewPatientFormOpen] = useState(false);
  const handleOpenNewPatientForm = () => setNewPatientFormOpen(true);
  const handleCloseNewPatientForm = () => setNewPatientFormOpen(false);

  const FORM_NEW_PTN = [
    { name: "name_first", component: TextField, label: "First Name", width: 6 },
    { name: "name_last", component: TextField, label: "Last Name", width: 6 },
    {
      name: "prescriber_id",
      component: RPPrescriberPicker,
      label: "Prescriber",
      extraProps: { api },
    },
  ];

  return (
    <>
      <SectionHeading
        headingLabel="Intake"
        buttonLabel="New Patient"
        buttonOnClick={handleOpenNewPatientForm}
      />
      <Stack spacing={2}>
        <PatientIntakeCard title="Drafting" patients={pageData.drafting} />
        <PatientIntakeCard title="Incomplete" patients={pageData.incomplete} />
        <PatientIntakeCard
          title="Rejected (in last 30 days)"
          patients={pageData.rejected}
          lastCol="Rejected"
          finalDate
          showMemo
        />
        <PatientIntakeCard
          title="Accepted (in last 7 days)"
          patients={pageData.accepted}
          lastCol="Accepted"
          finalDate
          ptnLink
        />
      </Stack>
      <StdFormDialog
        open={newPatientFormOpen}
        title="Add New Patient"
        fields={FORM_NEW_PTN}
        submitCall={(data) => api.newIntake(data)}
        onComplete={() => {
          refresh();
          handleCloseNewPatientForm();
        }}
        onClose={handleCloseNewPatientForm}
      />
    </>
  );
};

export default PageIntakes;
