import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const opts = [
  { value: "Cms1500", label: "CMS 1500" },
  { value: "Invoice", label: "Invoice" },
];

const RPInvoiceFormatPicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={opts} />;
});

RPInvoiceFormatPicker.defaultValue = null;

export default RPInvoiceFormatPicker;
