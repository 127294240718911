import { InternalLink } from "../../StandardComponents/InternalLink";

/**
 * @param {{
 *   type: "txo-out",
 *   id: number,
 *   to_agnc_id: number,
 *   to_agnc_name: string
 * }} ctx
 */
const LogEntryTxoOut = ({ ctx }) => {
  const linkUrl = `/transfer-orders/${ctx.id}`;
  const linkLabel = `TO-${ctx.id.toString().padStart(4, "0")}`;
  const agncUrl = `/agencies/${ctx.to_agnc_id}`;
  const agncName = ctx.to_agnc_name;

  return (
    <>
      Transferred to <InternalLink to={agncUrl}>{agncName}</InternalLink> via{" "}
      <InternalLink to={linkUrl}>{linkLabel}</InternalLink>
    </>
  );
};

export default LogEntryTxoOut;
