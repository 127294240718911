import {
  Paper,
  Table,
  TableContainer,
  Typography,
  useTheme,
} from "@mui/material";
import DataTableHead from "./DataTable/DataTableHead";
import DataTableBody from "./DataTable/DataTableBody";
import { useCallback, useEffect, useState } from "react";

const DataTable = ({ cols, data, sort }) => {
  const theme = useTheme();

  // const [sortBy, setSortBy] = useState(sort);
  // const [sortDir, setSortDir] = useState("asc");
  const [sortedData, setSortedData] = useState([...data]);

  const sortBy = sort;
  const sortDir = "asc";

  const sortData = useCallback(() => {
    data.sort((a, b) => {
      const x = a[sortBy].toLowerCase();
      const y = b[sortBy].toLowerCase();

      const mod = sortDir === "asc" ? 1 : -1;
      if (x < y) return -1 * mod;
      else if (x > y) return 1 * mod;
      else return 0;
    });
    setSortedData([...data]);
  }, [data, sortBy, sortDir]);

  useEffect(() => {
    if (sort) {
      sortData();
    }
  }, [sortData, sort]);

  const shouldShowTable = data.length >= 1 && cols.length >= 1;
  if (!shouldShowTable) {
    return (
      <Paper
        sx={{
          p: 4,
          minHeight: 300,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: theme.palette.grey["400"], marginTop: "100px" }}
        >
          No Records
        </Typography>
      </Paper>
    );
  }

  return (
    <TableContainer>
      <Table sx={{ tableLayout: "fixed" }} size="small">
        <DataTableHead cols={cols} />
        <DataTableBody cols={cols} data={sort ? sortedData : data} />
      </Table>
    </TableContainer>
  );
};

export default DataTable;
