import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import LinkRxer from "../../StandardComponents/Link/LinkRxer";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CurrentRxIconButton from "../../StandardComponents/CurrentRxIconButton";
import useDialogState from "../../../HOC/useDialogState";
import MediaShowDialog from "../../PagePartials/MediaShowDialog";

const ScriptIconRO = ({ curRxDocId, onClick }) => {
  if (!curRxDocId) {
    return (
      <Tooltip title="No script document!">
        <span>
          <IconButton disabled>
            <ReportProblemIcon color="error" />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return <CurrentRxIconButton onClick={onClick} />;
};

const RxInfoCard = ({
  therapy,
  name_first,
  name_last,
  credential,
  npi,
  duration,
  service_channel,
  rxDoc,
  api,
}) => {
  const {
    open: rxShowDocOpen,
    openFn: handleShowRxDocOpen,
    closeFn: handleShowRxDocClose,
  } = useDialogState();

  return (
    <>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontSize: "larger", mb: 1 }}>
            Prescription
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Typography>Extension</Typography>
        </Box>
        <Divider />
        <List>
          <ListItem sx={{ py: "2px" }}>
            <ListItemText primary="Therapy" />
            <ListItemSecondaryAction>
              {therapy.toUpperCase()}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem sx={{ py: "2px" }}>
            <ListItemText primary="Prescriber" />
            <ListItemSecondaryAction>
              <LinkRxer
                id={npi}
                name_first={name_first}
                name_last={name_last}
                credential={credential}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem sx={{ py: "2px" }}>
            <ListItemText primary="Duration" />
            <ListItemSecondaryAction>{duration} days</ListItemSecondaryAction>
          </ListItem>
          <ListItem sx={{ py: "2px" }}>
            <ListItemText primary="Service Channel" />
            <ListItemSecondaryAction>{service_channel}</ListItemSecondaryAction>
          </ListItem>
          <ListItem sx={{ py: "2px" }}>
            <ListItemText primary="Rx Document" />
            <ListItemSecondaryAction>
              <ScriptIconRO
                curRxDocId={rxDoc?.id}
                onClick={handleShowRxDocOpen}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
      <MediaShowDialog
        open={rxShowDocOpen}
        onClose={handleShowRxDocClose}
        api={api}
        attachment={rxDoc}
      />
    </>
  );
};

export default RxInfoCard;
