import {
  Badge,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import TicketContextLink from "./TicketContextLink";
import TicketStatusPill from "./TicketStatusPill";
import ChatIcon from "@mui/icons-material/Chat";
import { useContext } from "react";
import ApiDataContext from "../../../ApiDataContext";
import StdUserAvatar from "../../StandardComponents/StdUserAvatar";
import { blueGrey, grey } from "@mui/material/colors";

/**
 * @param {Array<{
 *   id: number,
 *   title: string,
 *   context: {
 *     label: string,
 *     type: string,
 *     ident: number,
 *   }|null|undefined,
 *   status: string,
 *   snooze_until: Date | null,
 *   follow_up_on: Date,
 * }>} tickets
 * @returns {JSX.Element}
 * @constructor
 */
const TicketDashboardTable = ({ tickets }) => {
  const { me } = useContext(ApiDataContext);

  return (
    <Table
      size="small"
      padding="none"
      sx={{
        "& td": { py: "5px", px: "2px" },
        "& td:nth-of-type(1)": { width: "32px" },
        "& td:nth-of-type(1) div": {
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
        },
        "& td:nth-of-type(2)": { width: "28px" },
        "& td:nth-of-type(3)": { width: "74px" },
        "& td:nth-of-type(4)": {
          maxWidth: "150px",
          width: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        "& td:nth-of-type(5)": {
          maxWidth: "224px",
          width: "224px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        "& td:nth-of-type(6)": {
          width: "86px",
        },
      }}
    >
      <TableHead>
        <TableRow
          sx={{
            "& th": { py: "5px" },
          }}
        >
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Task</TableCell>
          <TableCell>Attached To</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tickets.map((t) => {
          const taskIdLabel = `TSK-${t.id.toString().padStart(4, "0")}`;
          const taskIdContent = (
            <InternalLink to={`/tasks/${t.id}`}>{taskIdLabel}</InternalLink>
          );
          const hasActivity = t.last_activity_on > t.last_viewed;
          let contextContent = (
            <TicketContextLink
              context={{
                label: t.context_label,
                type: t.context_type,
                ident: t.context_ident,
              }}
            />
          );

          let shownUser = (
            <StdUserAvatar
              user_name={t.assignee_name}
              user_pic={t.assignee_picture}
            />
          );
          if (t.assignee_id === me.id) {
            shownUser = (
              <StdUserAvatar
                user_name={t.requester_name}
                user_pic={t.requester_picture}
              />
            );
          }

          return (
            <TableRow
              key={t.id}
              sx={{
                backgroundColor: hasActivity ? "bgBlue" : "inherit",
                "& td": { fontWeight: hasActivity ? "bold" : "normal" },
              }}
            >
              <TableCell>
                <Box>
                  <Badge
                    badgeContent={t.comment_count}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    color={hasActivity ? "info" : undefined}
                    slotProps={{
                      badge: {
                        sx: {
                          color: hasActivity ? undefined : grey["100"],
                          backgroundColor: hasActivity
                            ? undefined
                            : blueGrey["A700"],
                          transform: "scale(0.8) translate(-50%, -30%)",
                        },
                      },
                    }}
                  >
                    <ChatIcon
                      color={hasActivity ? "info" : undefined}
                      sx={{
                        color: hasActivity ? undefined : "text.secondary",
                      }}
                    />
                  </Badge>
                </Box>
              </TableCell>
              <TableCell>{shownUser}</TableCell>
              <TableCell>{taskIdContent}</TableCell>
              <TableCell>{contextContent}</TableCell>
              <TableCell>{t.title}</TableCell>
              <TableCell>
                <TicketStatusPill ticket={t} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TicketDashboardTable;
