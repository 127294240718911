import { useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

/*
options = [
 { icon: Component, label: string, disabled: bool, onClick: fn, tooltip: string|undefined }
 OR
 { divider: true }
]
*/

const optByIdx = (options, idx) => options[idx] || 0;

/**
 * @param {Array<{
 *   icon: function,
 *   label: string,
 *   disabled: boolean,
 *   tooltip: string|undefined,
 *   onClick: function
 * }>} options
 * @constructor
 */
const ComplexSplitButton = ({ options }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [curIndex, setCurIndex] = useState(0);
  const anchorRef = useRef(null);

  if (options.length === 0) {
    return null;
  }

  const toggleMenuOpen = () => setMenuOpen((prev) => !prev);
  const closeMenu = () => setMenuOpen(false);

  const curOpt = optByIdx(options, curIndex) ?? options[0];

  const handleClickAwayMenuClose = (ev) => {
    if (anchorRef.current?.contains(ev.target)) return;
    closeMenu();
  };

  const handleClickMenuItem = (ev, idx) => {
    setCurIndex(idx);
    closeMenu();
    const opt = optByIdx(options, idx);
    opt.onClick(ev);
  };

  const CurIconCmp = curOpt.icon ? curOpt.icon : () => null;

  return (
    <>
      <ButtonGroup
        sx={{ "& .MuiButtonGroup-grouped.main-split": { minWidth: 140 } }}
        variant="contained"
        color="secondary"
        ref={anchorRef}
      >
        <Button
          onClick={curOpt.onClick}
          className="main-split"
          startIcon={curOpt.icon ? <CurIconCmp /> : undefined}
        >
          {curOpt.label}
        </Button>
        <Button size="small" onClick={toggleMenuOpen}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClickAwayMenuClose}>
                <MenuList id="split-button-menu" sx={{ minWidth: 184 }}>
                  {options.map((opt, idx) => {
                    let Icon = () => null;
                    if (opt.icon) {
                      Icon = opt.icon;
                    }
                    if (opt.divider === true)
                      return <Divider key={`div-${idx}`} />;
                    return (
                      <MenuItem
                        key={opt.label}
                        onClick={(ev) => handleClickMenuItem(ev, idx)}
                      >
                        {opt.icon ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                              alignItems: "middle",
                            }}
                          >
                            <Box
                              sx={{
                                height: "28px",
                                width: "28px",
                                py: "2px",
                              }}
                            >
                              <Icon fontSize="small" />
                            </Box>
                            <Box sx={{ height: "24px", width: "100%" }}>
                              {opt.label}
                            </Box>
                          </Box>
                        ) : (
                          opt.label
                        )}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ComplexSplitButton;
