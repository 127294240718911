import { TableCell } from "@mui/material";
import RPAvailableSerialPicker from "../../FormControls/RPAvailableSerialPicker";

const PlainValueCell = ({ type, serialNum, qty }) => {
  const label = type === "serialized" ? serialNum || "Unknown" : qty;
  return <TableCell>{label}</TableCell>;
};

const TableCellLineItemField = ({
  li,
  liEditable,
  api,
  agencyId,
  value,
  onChange,
}) => {
  if (!liEditable) {
    return (
      <PlainValueCell
        type={li.item_type}
        serialNum={li.serial_num}
        qty={li.qty}
      />
    );
  }

  if (liEditable && li.id !== liEditable.id) {
    return (
      <PlainValueCell
        type={li.item_type}
        serialNum={li.serial_num}
        qty={li.qty}
      />
    );
  }

  return (
    <TableCell>
      <RPAvailableSerialPicker
        api={api}
        agencyId={agencyId}
        itemId={li.item_id}
        size="small"
        value={value}
        onChange={onChange}
      />
    </TableCell>
  );
};

export default TableCellLineItemField;
