import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import { moneyFmt } from "../../../formatters";

const PaymentsTable = ({ data }) => {
  return (
    <Paper variant="outlined" sx={{ mt: 3 }}>
      <TableContainer>
        <Table
          size="small"
          sx={{
            "& tr > *": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            "& tr > td:nth-of-type(1)": {
              maxWidth: "130px",
              minWidth: "130px",
            },
            "& tr > td:nth-of-type(3)": {
              maxWidth: "115px",
              minWidth: "115px",
            },
            "& tr > td:nth-of-type(4)": {
              maxWidth: "115px",
              minWidth: "115px",
            },
            "& tr > td:nth-of-type(5)": {
              maxWidth: "120px",
              minWidth: "120px",
            },
            "& tr > td:nth-of-type(6)": { maxWidth: "56px", minWidth: "56px" },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Check #</TableCell>
              <TableCell>Payor</TableCell>
              <TableCell>Entered</TableCell>
              <TableCell>Lockbox</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((p) => (
              <TableRow
                key={p.id}
                sx={p.total === 0 ? { bgcolor: "bgError" } : {}}
              >
                <TableCell>
                  <InternalLink to={`/payments/${p.id}`}>
                    {p.ident}
                  </InternalLink>
                </TableCell>
                <TableCell>{`${p.porg_name}`}</TableCell>
                <TableCell>{p.issued_on}</TableCell>
                <TableCell>{p.cleared_on}</TableCell>
                <TableCell>
                  {p.total === 0 ? "VOIDED" : moneyFmt(p.total)}
                </TableCell>
                <TableCell>
                  <Tooltip title={p.user_name} arrow placement="left-end">
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      alt={p.user_name}
                      src={p.user_pic}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PaymentsTable;
