import { TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { basicTextFieldProps } from "./_defaults";
import { forwardRef } from "react";
import composeRefs from "@seznam/compose-react-refs";
import { parseIntB } from "../../utility";

const RHFPosIntField = forwardRef(
  ({ name, control, rules = undefined, ...rest }, outerRef) => {
    const {
      field,
      fieldState: { error },
    } = useController({ name, control, rules });

    return (
      <TextField
        name={field.name}
        value={field.value}
        inputRef={composeRefs(field.ref, outerRef)}
        onBlur={field.onBlur}
        onChange={(e) => {
          const parsed = parseIntB(e.target.value);
          if (null === parsed) {
            field.onChange(1);
            return;
          }
          if (parsed < 1) {
            field.onChange(1);
            return;
          }
          field.onChange(parsed);
        }}
        error={!!error}
        helperText={error?.message ?? " "}
        type="number"
        {...basicTextFieldProps}
        {...rest}
      />
    );
  }
);

export default RHFPosIntField;
