import { Box, Typography } from "@mui/material";
import AddButton from "./AddButton";

const SectionHeading = ({
  headingLabel,
  headingVariant,
  buttonLabel,
  buttonOnClick,
  buttonEl,
  sx,
  ...rest
}) => {
  return (
    <Box sx={{ mb: 4, display: "flex", ...sx }} {...rest}>
      <Typography variant={headingVariant || "h5"}>{headingLabel}</Typography>
      <Box sx={{ flexGrow: 1 }} />
      {buttonEl ||
        (buttonLabel && (
          <AddButton onClick={buttonOnClick}>{buttonLabel}</AddButton>
        ))}
    </Box>
  );
};

export default SectionHeading;
