import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { useController } from "react-hook-form";
import { format } from "date-fns";

const ReportFilterDateRange = ({ name, control }) => {
  const { field } = useController({ name, control });

  return (
    <DateRangePicker
      value={field.value.value}
      onChange={field.onChange}
      slotProps={{ textField: { size: "small" } }}
    />
  );
};

ReportFilterDateRange.blankValue = [null, null];
ReportFilterDateRange.transformValue = (val) => {
  if (!val) return [];
  const [start, finish] = val;
  return [
    start ? format(start, "yyyy-MM-dd") : null,
    finish ? format(finish, "yyyy-MM-dd") : null,
  ];
};

export default ReportFilterDateRange;
