import { useEffect, useState } from "react";
import { Record } from "immutable";
import RPOrgPicker from "../FormControls/RPOrgPicker";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Unstable_Grid2 as Grid2,
} from "@mui/material";
import RPPayorPicker from "../FormControls/RPPayorPicker";
import CloseIcon from "@mui/icons-material/Close";

const FormState = Record({
  org_id: null,
  payor_id: null,
});

const CompState = Record({
  showPayorPicker: false,
  errMsg: "",
  form: FormState(),
});

const stateInit = (orgId, payorId) => {
  const formState = FormState({ org_id: orgId, payor_id: payorId });
  return CompState({ form: formState });
};

const PayorPickerFormDialog = ({
  api,
  eocId,
  curOrgId,
  curPayorId,
  open,
  onClose,
  refresh,
}) => {
  const [state, setState] = useState(stateInit(curOrgId, curPayorId));

  // Sorry to my future self about this ugly hack. This is just a mechanism to forcibly unmount
  // the payor dropdown when the org id changes. By doing this is forces a re-pull of the payor
  // list when the org changes.
  useEffect(() => {
    if (state.showPayorPicker === false) {
      setState((prev) => prev.set("showPayorPicker", true));
    }
  }, [state.showPayorPicker]);

  const handleCloseRequest = (_, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    onClose();
    setState(stateInit(curOrgId, curPayorId));
  };

  const handleChangeFormVal = (fld, val) => {
    if (fld === "org_id") {
      setState((prev) => {
        return prev.withMutations((p) => {
          p.updateIn(["form", "org_id"], () => val);
          p.updateIn(["form", "payor_id"], () => null);
          p.set("showPayorPicker", false);
        });
      });
    } else {
      setState((prev) => prev.updateIn(["form", fld], () => val));
    }
  };

  const handleSetErr = (err) => setState((prev) => prev.set("errMsg", err));

  const submit = () => {
    api
      .setPayor(eocId, state.form.payor_id)
      .then((result) => {
        if (result.status === "ok") {
          handleCloseRequest();
          refresh();
          return;
        }
        handleSetErr(result.message);
      })
      .catch((err) => {
        handleSetErr(err.message);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseRequest}
      PaperProps={{ sx: { width: 900, minWidth: 900 } }}
      disableEscapeKeyDown
      disableScrollLock
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Box>Set Primary Payor</Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleCloseRequest}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 3 }}>
        <Grid2 container spacing={1}>
          {state.errMsg ? (
            <Grid2 xs={12}>
              <Alert severity="error">{state.errMsg}</Alert>
            </Grid2>
          ) : null}
          <Grid2 xs={6}>
            <RPOrgPicker
              api={api}
              label="Payor Organization"
              size="small"
              value={state.form.org_id}
              sx={{ py: 1 }}
              fullWidth
              onChange={(newVal) => handleChangeFormVal("org_id", newVal)}
            />
          </Grid2>
          <Grid2 xs={6}>
            {state.showPayorPicker && (
              <RPPayorPicker
                label="Billing Location"
                api={api}
                size="small"
                orgId={state.form.org_id}
                value={state.form.payor_id}
                fullWidth
                sx={{ py: 1 }}
                onChange={(newVal) => handleChangeFormVal("payor_id", newVal)}
              />
            )}
          </Grid2>
          <Grid2 xs={12}>
            <Stack direction="row-reverse" spacing={2}>
              <Button variant="contained" onClick={submit}>
                Set Primary Payor
              </Button>
            </Stack>
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
};

export default PayorPickerFormDialog;
