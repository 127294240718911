import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const MediaContent = ({ billUrl, loadState, errMsg }) => {
  if (loadState === "loading") {
    return <Typography>Loading...</Typography>;
  }
  if (loadState === "error") {
    return <Typography>{errMsg}</Typography>;
  }

  return (
    <Box sx={{ textAlign: "center" }}>
      <object
        type="application/pdf"
        width="850"
        style={{ height: "85vh" }}
        data={billUrl}
      >
        <p>PDF Error</p>
      </object>
    </Box>
  );
};

const setInitial = (setter) =>
  setter({ url: "", reqState: "loading", errMsg: "" });
const setSuccess = (setter, url) =>
  setter({ url, reqState: "loaded", errMsg: "" });
const setError = (setter, msg) =>
  setter({ url: "", reqState: "error", errMsg: msg });

const BillShowDialog = ({ api, billId, open, onClose }) => {
  const [state, setState] = useState({
    url: "",
    reqState: "loading",
    errMsg: "",
  });

  const handleOnClose = () => {
    setInitial(setState);
    onClose();
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    api
      .getBillUrl(billId)
      .then((resp) => {
        if (resp.status !== "ok") {
          setError(setState, resp.message);
          return;
        }
        setSuccess(setState, resp.data);
      })
      .catch((err) => setError(setState, err.message));
  }, [api, billId, open]);

  if (!state.url) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      PaperProps={{ sx: { width: 900, minWidth: 900 } }}
      disableScrollLock
    >
      <DialogTitle>{`INV-${billId.toString().padStart(4, "0")}`}</DialogTitle>
      <DialogContent>
        <MediaContent
          billUrl={state.url}
          loadState={state.reqState}
          errMsg={state.errMsg}
        />
      </DialogContent>
    </Dialog>
  );
};

export default BillShowDialog;
