import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { surgeryStr, ucWord } from "../../../utility";
import WarningIconButton from "../../StandardComponents/WarningIconButton";
import HorizIconToolTip from "../../StandardComponents/HorizIconToolTip";
import { icd10Fmt } from "../../../formatters";
import { useState } from "react";

const fmtInjurySite = (injurySite) =>
  injurySite
    .split("-")
    .reverse()
    .map((b) => ucWord(b))
    .join(" ");

const InjuryCard = ({ pageData, openDialog, hasCautions }) => {
  const [showCautions, setShowCautions] = useState(true);
  const toggleShowCautions = () => setShowCautions((prev) => !prev);

  return (
    <Card variant="outlined">
      <CardHeader
        sx={{ py: 1 }}
        title="Injury"
        titleTypographyProps={{ variant: "body1", sx: { fontSize: "larger" } }}
        action={
          <Stack direction="row" spacing={2}>
            <WarningIconButton
              onClick={toggleShowCautions}
              amt={pageData.cautions.length}
            />
          </Stack>
        }
      />
      <Divider />
      <CardContent sx={{ pt: 0 }}>
        <List sx={{ pt: 0 }}>
          <ListItem>
            <ListItemText primary="Surgery" />
            <ListItemSecondaryAction>
              {surgeryStr(pageData.surgical, pageData.date_of_surgery)}
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem sx={{ pb: 1 }}>
            <ListItemText primary="Date of Injury" />
            <ListItemSecondaryAction>
              {pageData.date_of_injury}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem sx={{ pb: 1 }}>
            <ListItemText primary="ICD-10(s)" />
            <ListItemSecondaryAction>
              <Box sx={{ width: "350px", textAlign: "right" }}>
                {pageData.icd10s.map((c) => (
                  <HorizIconToolTip key={c.code} title={c.description}>
                    <Chip
                      size="small"
                      label={icd10Fmt(c.code)}
                      sx={{ ml: "2px" }}
                    />
                  </HorizIconToolTip>
                ))}
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem sx={{ pb: 1 }}>
            <ListItemText primary="Injury Site(s)" />
            <ListItemSecondaryAction>
              {pageData.treatment_sites.map((i) => (
                <Chip
                  sx={{ ml: 1 }}
                  key={i}
                  size="small"
                  label={fmtInjurySite(i)}
                />
              ))}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Box sx={{ px: 2 }}>
          <Typography>Diagnosis</Typography>
          <Typography variant="body2" color="text.secondary">
            {pageData.diagnosis}
          </Typography>
        </Box>
      </CardContent>
      {hasCautions && (
        <Collapse in={showCautions}>
          <CardContent>
            <Stack direction="column" spacing={1}>
              {pageData.cautions.map((c) => (
                <Alert
                  key={c[0]}
                  variant="outlined"
                  severity="warning"
                  action={
                    <Button onClick={() => openDialog(c[1])} size="small">
                      Fix
                    </Button>
                  }
                >
                  {c[0]}
                </Alert>
              ))}
            </Stack>
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
};

export default InjuryCard;
