import { useState } from "react";
import {
  Alert,
  CircularProgress,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import useSWR from "swr";
import { apiCallSimplify, genericSWRFetcher } from "../../../utility";

const OrderDistributionSwitch = ({ api, currentState, userId, onSuccess }) => {
  const [submitState, setSubmitState] = useState("idle");

  const checked = currentState === "active";
  const disabled = submitState === "submitting";
  const changeState = currentState === "active" ? "paused" : "active";

  const handleChange = async () => {
    if (submitState !== "idle") {
      return;
    }

    setSubmitState("submitting");
    try {
      await apiCallSimplify(api.preauthPoolUpdate(userId, changeState));
      onSuccess();
    } catch (err) {
    } finally {
      setSubmitState("idle");
    }
  };

  return (
    <Switch checked={checked} disabled={disabled} onChange={handleChange} />
  );
};

const OrderDistributionPanel = ({ api }) => {
  const { data, isLoading, error, mutate } = useSWR(
    "preauth-user-pool",
    genericSWRFetcher(api.preauthUserPool)
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Distribution Pool
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table
          size="small"
          sx={{ paddingX: 0, "& tr:last-child td": { border: 0 } }}
        >
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((user) => {
                return (
                  <TableRow key={user.user_id}>
                    <TableCell>
                      <InternalLink to={`/rcm/dashboard/${user.user_id}`}>
                        {user.name}
                      </InternalLink>
                    </TableCell>
                    <TableCell sx={{ width: "90px" }}>
                      <OrderDistributionSwitch
                        api={api}
                        userId={user.user_id}
                        currentState={user.order_dist}
                        onSuccess={() => mutate()}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default OrderDistributionPanel;
