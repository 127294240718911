import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploaderControl from "../StandardComponents/UploaderControl";

const StdUploadDialog = ({
  open,
  api,
  onClose,
  refresh,
  title,
  genUrl,
  multiple = true,
}) => {
  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { width: 900, minWidth: 900 } }}
      onClose={onClose}
      disableScrollLock
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Box>{title || "Upload Files"}</Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <UploaderControl
          genUrl={genUrl}
          multiple={multiple}
          onComplete={() => {
            refresh();
            onClose();
          }}
          onAbort={() => onClose()}
          apiToken={api.getToken()}
        />
      </DialogContent>
    </Dialog>
  );
};

export default StdUploadDialog;
