import { useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import {
  BusinessActionManager,
  labelForRule,
  useDialogCtrl,
} from "../../BusinessActions/BusinessActionManager";
import { useNavigate } from "react-router-dom";
import JointSettingsList from "../../StandardComponents/JointSettingsList";
import KeyValueList from "../../PagePartials/KeyValueList";
import { srvcStatusCurrent, srvcStatusUpNext } from "../../../utility";
import WarningIconButton from "../../StandardComponents/WarningIconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const itemLabel = (item) => {
  if (!item) return "[Not Set]";

  return `${item.item_therapy.toUpperCase()}-${item.item_code.toUpperCase()} (${
    item.serial_num
  })`;
};

const SrvcCard = ({ srvc, eocRef, treatRef, api, refresh }) => {
  const navigate = useNavigate();
  const [showCautions, setShowCautions] = useState(true);
  const toggleShowCautions = () => setShowCautions((prev) => !prev);
  const cautionStrList = srvc.rules.map(([, c]) => c);
  const hasCautions = cautionStrList.length > 0;

  const cardTitle = srvcStatusCurrent(srvc.cur_status, srvc.relevant_date);
  const cardSubheader = srvcStatusUpNext(srvc.service_channel, srvc.cur_status);

  const { openStates, openDialog, closeDialogs } =
    useDialogCtrl(cautionStrList);

  const srvcWithSomeEoc = { ...srvc };
  srvcWithSomeEoc.eoc = {};
  srvcWithSomeEoc.eoc.name_first = eocRef.name_first;
  srvcWithSomeEoc.eoc.name_last = eocRef.name_last;
  srvcWithSomeEoc.eoc.addr_street1 = eocRef.addr_street1;
  srvcWithSomeEoc.eoc.addr_street2 = eocRef.addr_street2;
  srvcWithSomeEoc.eoc.addr_city = eocRef.addr_city;
  srvcWithSomeEoc.eoc.addr_state = eocRef.addr_state;
  srvcWithSomeEoc.eoc.addr_zip = eocRef.addr_zip;
  srvcWithSomeEoc.phone = eocRef.phone;
  srvcWithSomeEoc.email = eocRef.email;
  srvcWithSomeEoc.joint_size = eocRef.joint_size;
  srvcWithSomeEoc.item_id = treatRef.item_id;
  srvcWithSomeEoc.therapy = treatRef.therapy;
  srvcWithSomeEoc.latest_item_code =
    treatRef.rxs[treatRef.rxs.length - 1].item_code;

  const showSettings =
    srvcWithSomeEoc.cur_status === "pref_ship" &&
    srvcWithSomeEoc.therapy === "cct" &&
    srvcWithSomeEoc.latest_item_code === "vt4" &&
    treatRef.settings &&
    treatRef.settings[srvcWithSomeEoc.joint_size];

  srvcWithSomeEoc.settings = !!showSettings ? showSettings : null;

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          "& .MuiCardContent-root:last-child": { pb: 2 },
        }}
      >
        <CardHeader
          avatar={<Avatar sx={{ width: 36, height: 36 }}>Srv</Avatar>}
          title={cardTitle}
          subheader={cardSubheader}
          action={
            <Stack direction="row" spacing={1}>
              <WarningIconButton
                onClick={toggleShowCautions}
                amt={srvc.rules.length}
              />
              <IconButton
                size="small"
                onClick={() => navigate(`/service-cycle/${srvc.id}`)}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Stack>
          }
          sx={{ pb: 0 }}
        />
        <CardContent sx={{ pt: 0 }}>
          <KeyValueList
            kvData={{
              Unit: itemLabel(srvc.item),
              "Service Channel": srvc.service_channel,
            }}
          />
        </CardContent>
        {hasCautions && (
          <Collapse in={showCautions}>
            <CardContent sx={{ pt: 0 }}>
              <Stack direction="column" spacing={1}>
                {srvc.rules.map((r) => {
                  const lbl = labelForRule(r[1]);
                  return (
                    <Alert
                      key={r[0]}
                      variant="outlined"
                      severity="warning"
                      action={
                        <Button onClick={() => openDialog(r[1])} size="small">
                          {lbl}
                        </Button>
                      }
                    >
                      {r[0]}
                    </Alert>
                  );
                })}
              </Stack>
            </CardContent>
          </Collapse>
        )}
        {!!showSettings === true && (
          <Paper variant="outlined" sx={{ m: 1 }}>
            <JointSettingsList joint={showSettings} />
          </Paper>
        )}
      </Card>
      <BusinessActionManager
        pageData={srvcWithSomeEoc}
        openStates={openStates}
        closeDialogs={closeDialogs}
        actionList={cautionStrList}
        api={api}
        refresh={refresh}
      />
    </>
  );
};

export default SrvcCard;
