import StdFormDialog from "../PagePartials/StdFormDialog";
import RPDateInput from "../FormControls/RPDateInput";
import { reformatDate } from "../../utility";
import RPTextArea from "../FormControls/RPTextArea";
import RPPickupPicker from "../FormControls/RPPickupPicker";
import KeyValueList from "../PagePartials/KeyValueList";
import { Box } from "@mui/material";

const ContextData = ({ itemSerialNum, serviceEndDate }) => {
  return (
    <KeyValueList
      kvData={{
        "Item Serial Number": itemSerialNum,
        "Service End Date": serviceEndDate,
      }}
    />
  );
};

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  // console.log("SrvcPrefPickup initialVals", initialVals);

  const FIELDS = [
    {
      type: "plain",
      component: ContextData,
      extraProps: {
        itemSerialNum: initialVals?.item?.serial_num,
        serviceEndDate: initialVals?.service_end_on,
      },
      width: 6,
    },
    {
      type: "plain",
      component: Box,
      width: 6,
    },
    {
      name: "pref_pickup_on",
      label: "Planned Pickup Date",
      component: RPDateInput,
      width: 6,
    },
    {
      name: "pickup_plan",
      label: "Pickup Type",
      component: RPPickupPicker,
      width: 6,
    },
    {
      name: "pickup_plan_memo",
      label: "Pickup Notes",
      component: RPTextArea,
      width: 12,
    },
  ];

  return (
    <StdFormDialog
      open={open}
      onClose={onClose}
      title="Set Preferred Pickup"
      onComplete={() => {
        onClose();
        refresh();
      }}
      fields={FIELDS}
      submitCall={(data) => {
        const payload = {
          ...data,
          pref_pickup_on: reformatDate(data.pref_pickup_on),
        };
        return api.srvcSetPrefPickup(initialVals.id, payload);
      }}
      initialVals={initialVals}
    />
  );
};
