import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { moneyFmt } from "../../../../formatters";
import SackXmark from "../../../StandardComponents/Icons/SackXmark";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { dateObjFormatToAnnArborDate } from "../../../../utility";

const ARIgnoreIcon = ({
  balance,
  ar_ignored_on,
  ar_ignored_user_name,
  ignoreARFn,
}) => {
  if (balance === 0) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <CheckCircleIcon
          fontSize="small"
          color="success"
          sx={{ verticalAlign: "middle", mr: 1 }}
        />
      </Box>
    );
  }

  if (ar_ignored_on) {
    const date = dateObjFormatToAnnArborDate(new Date(ar_ignored_on));
    const tt = `AR Ignored On ${date} by ${ar_ignored_user_name}`;
    return (
      <Tooltip title={tt} arrow placement="top">
        <span>
          <IconButton disabled size="small">
            <SackXmark fontSize="smaller" sx={{ p: "1px" }} />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <IconButton onClick={ignoreARFn} size="small">
      <SackXmark color="error" fontSize="smaller" sx={{ p: "1px" }} />
    </IconButton>
  );
};

const OrderCollectionsTable = ({ lines, initiateARIgnoreFn }) => {
  return (
    <Table
      size="small"
      sx={{
        tableLayout: "fixed",
        "& tbody tr td": {
          pl: "5px",
          pr: "5px",
          py: "8px",
        },
      }}
    >
      <TableHead>
        <TableRow sx={{ "& th": { px: "1px" } }}>
          <TableCell>Description</TableCell>
          <TableCell sx={{ width: "40px" }}>Qty</TableCell>
          <TableCell sx={{ width: "80px" }}>Total</TableCell>
          <TableCell sx={{ width: "80px" }}>Balance</TableCell>
          <TableCell sx={{ width: "45px" }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((l) => {
          const sx = {};
          if (l.ar_ignored_on) sx.bgcolor = "bgError";

          const balance = !!l.ar_ignored_on ? (
            <Tooltip
              title={`Ignoring balance of ${moneyFmt(l.balance)}`}
              arrow
              placement="top"
            >
              <span>$0.00</span>
            </Tooltip>
          ) : (
            moneyFmt(l.balance)
          );
          return (
            <TableRow key={l.id} sx={sx}>
              <TableCell>{l.description}</TableCell>
              <TableCell>{l.qty}</TableCell>
              <TableCell>{moneyFmt(l.qty * l.charge)}</TableCell>
              <TableCell>{balance}</TableCell>
              <TableCell>
                <ARIgnoreIcon
                  balance={l.balance}
                  ar_ignored_on={l.ar_ignored_on}
                  ar_ignored_user_name={l.ar_ignored_user_name}
                  ignoreARFn={() => initiateARIgnoreFn(l.id)}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default OrderCollectionsTable;
