import { useState } from "react";
import { Button, CircularProgress, Input, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorIcon from "@mui/icons-material/Error";
import AttachmentIcon from "@mui/icons-material/Attachment";

const STAT_TO_PROPS = {
  idle: {
    disabled: false,
    color: "secondary",
    icon: <CloudUploadIcon />,
    text: "Attach File",
  },
  uploading: {
    disabled: true,
    color: "info",
    icon: <CircularProgress size={16} sx={{ mr: "2px" }} />,
    text: "Attach File",
  },
  uploaded: {
    disabled: false,
    color: "success",
    icon: <AttachmentIcon />,
    text: "File Attached",
  },
  error: {
    disabled: false,
    color: "error",
    icon: <ErrorIcon />,
    text: "Error",
  },
};

const AttachUploadButton = ({ genUrl, onUpload, onReset, api }) => {
  // states can be "idle", "uploading", "uploaded", "error"
  const [[status, dat], setState] = useState(["idle", null]);

  // debug
  // useEffect(() => {
  //   console.log("status changed", status, dat);
  // }, [status, dat]);

  const onChange = (ev) => {
    const fileObj = ev.target.files[0];
    onReset();
    if (!fileObj) {
      setState(["idle", null]);
      return;
    }
    const url = genUrl(process.env.REACT_APP_API_BASE, fileObj.name);
    const req = new Request(url, {
      method: "POST",
      body: fileObj,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${api.getToken()}`,
      },
    });
    setState(["uploading", null]);
    fetch(req)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "error") throw new Error(data.message);
        setState(["uploaded", data.data]);
        onUpload(data.data);
      })
      .catch((err) => {
        setState(["error", err]);
      });
  };

  const { disabled, color, icon, text } = STAT_TO_PROPS[status];

  return (
    <Tooltip title={dat?.message}>
      <Button
        variant="contained"
        color={color}
        startIcon={icon}
        disabled={disabled}
        component="label"
      >
        {text}
        <Input
          type="file"
          onChange={onChange}
          name="attachment"
          // styles taken from stack overflow
          sx={{
            clip: "rect(0 0 0 0)",
            clipPath: "inset(50%)",
            height: 1,
            overflow: "hidden",
            position: "absolute",
            bottom: 0,
            left: 0,
            whiteSpace: "nowrap",
            width: 1,
          }}
        />
      </Button>
    </Tooltip>
  );
};

export default AttachUploadButton;
