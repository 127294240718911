import { Alert, Typography } from "@mui/material";
import useSWR from "swr";
import { genericSWRFetcher } from "../../utility";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const PageTreatments = ({ api }) => {
  const fetcher = genericSWRFetcher(api.eocIdFromTreat, (key) => [
    key.split("-")[1],
  ]);

  const { treatId } = useParams();
  const { data, isLoading, error } = useSWR(`treatToEoc-${treatId}`, fetcher);
  const navigate = useNavigate();

  useEffect(() => {
    if (!data) return;
    const url = `/patients/${data}`;
    navigate(url, { replace: true });
  }, [navigate, data]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return <Typography>Redirecting...</Typography>;
};

export default PageTreatments;
