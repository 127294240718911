import { BUCKET_LIST, BUCKET_SECTIONS } from "./buckets";

const LS_KEY = "workspace";

const genDefSections = () =>
  Object.fromEntries(
    BUCKET_SECTIONS.map((bs) => {
      return [
        bs.key,
        {
          bucket: bs.buckets[0],
          filter: null,
        },
      ];
    })
  );

const sectionFromKey = (key) => {
  const sec = BUCKET_SECTIONS.find((bs) => bs.key === key);
  if (!sec) return null;
  return sec;
};

class WsStorage {
  #curSection = null;
  #sections = [];
  #debug = false;

  constructor(debug = false) {
    this.#debug = debug;
    this.#load();
    const sec = sectionFromKey(this.#curSection);
    if (!sec) {
      this.#log("section not recognized on load... starting over");
      this.#startOver();
      return;
    }

    if (!this.#sections) {
      this.#log("sections is not set?");
      this.#startOver();
      return;
    }

    Object.entries(this.#sections).forEach(([, secVal]) => {
      if (!BUCKET_LIST[secVal.bucket]) {
        this.#log(`bucket ${secVal.bucket} no longer exists... starting over`);
        this.#startOver();
      }
    });
  }

  #log(msg) {
    if (this.#debug) console.log(msg);
  }

  get() {
    return [
      this.#curSection,
      this.#sections[this.#curSection].bucket,
      this.#sections[this.#curSection]?.filter || null,
    ];
  }

  reset() {
    this.#startOver();
    return this.get();
  }

  setSection(sectionKey) {
    const sec = this.#sections[sectionKey];
    if (!sec) {
      const newSec = sectionFromKey(sectionKey);
      if (!newSec) return null;
      this.#sections[sectionKey] = {
        bucket: newSec.buckets[0],
        filter: null,
      };
      return [sectionKey, newSec.buckets[0], null];
    }
    this.#curSection = sectionKey;
    this.#save();
    return this.get();
  }

  setBucket(bucketKey) {
    const sec = sectionFromKey(this.#curSection);
    if (!sec.buckets.includes(bucketKey)) {
      return [
        this.#curSection,
        this.#sections[this.#curSection].bucket,
        this.#sections[this.#curSection].filter,
      ];
    }

    this.#sections[this.#curSection].bucket = bucketKey;
    this.#save();
    return this.get();
  }

  setFilterState(filterState) {
    this.#sections[this.#curSection].filter = filterState;
    this.#save();
    return this.get();
  }

  #startOver() {
    this.#log("startOver() called!");
    this.#curSection = "sec-crit";
    this.#sections = genDefSections();
    this.#save();
  }

  #load() {
    const workspace = localStorage.getItem(LS_KEY);
    if (null === workspace) {
      this.#log("got nothing from local storage, starting over");
      this.#startOver();
      return;
    }

    try {
      const parsed = JSON.parse(workspace);
      this.#curSection = parsed?.curSection;
      if (!this.#curSection) {
        this.#log("did not see curSection in local storage, starting over");
        this.#startOver();
        return;
      }
      if (null === sectionFromKey(this.#curSection)) {
        this.#log(
          `section ${this.#curSection} does not exist in code, starting over`
        );
        this.#startOver();
        return;
      }

      this.#sections = parsed?.sections;
      if (!this.#sections) {
        this.#log(`sections does not exist in local storage, starting over`);
        this.#startOver();
        return;
      }
      if (!this.#sections?.[this.#curSection]?.bucket) {
        this.#log(`local storage is not consistent, starting over`);
        this.#startOver();
      }
    } catch (err) {
      this.#log(`local storage parse error, starting over`);
      this.#startOver();
    }
  }

  #save() {
    const saveData = {
      curSection: this.#curSection,
      sections: this.#sections,
    };
    localStorage.setItem(LS_KEY, JSON.stringify(saveData));
  }
}

export default WsStorage;
