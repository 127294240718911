import { forwardRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { maybeFixLazyVal } from "../../utility";

const RPAutoCompleteCore = forwardRef(
  ({ onChange, label, helperText, error, onBlur, ...props }, ref) => {
    const newVal = maybeFixLazyVal(props.value, props.options);

    return (
      <Autocomplete
        {...props}
        value={newVal}
        autoSelect
        autoHighlight
        autoComplete
        clearOnEscape
        disableClearable
        isOptionEqualToValue={(option, value) => {
          return value.value === option.value;
        }}
        onChange={(_, newVal) => {
          const val = newVal?.value;
          undefined === val ? onChange(null) : onChange(val);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={onBlur}
            label={label}
            error={!!error}
            helperText={helperText}
            inputRef={ref}
          />
        )}
      />
    );
  }
);

export default RPAutoCompleteCore;
