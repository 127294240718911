import {
  Autocomplete,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { assign, createMachine } from "xstate";
import { forwardRef } from "react";
import { useController } from "react-hook-form";
import { useMachine } from "@xstate/react";
import composeRefs from "@seznam/compose-react-refs";
import { maybeFixLazyVal } from "../../utility";

const LoadingAdornment = ({ curState, retry, def }) => {
  if (curState.matches("loading")) {
    return <CircularProgress color="inherit" size={20} />;
  }

  if (curState.matches("error")) {
    return (
      <Tooltip title={curState.context.errorMessage}>
        <IconButton size="small" onClick={() => retry()}>
          <ErrorIcon color="error" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    );
  }

  return def;
};

const optionsLoadMachine = createMachine({
  id: "optionsLoadMachine",
  predictableActionArguments: true,
  context: {
    optList: [],
    errorMessage: "",
  },
  initial: "loading",
  states: {
    loading: {
      entry: ["makeApiCall"],
      on: {
        FAIL: { target: "error" },
        LOAD: { target: "loaded" },
      },
    },
    error: {
      entry: ["setErrorMessage"],
      on: {
        RETRY: { target: "loading" },
      },
      exit: ["clearErrorMessage"],
    },
    loaded: {
      entry: ["saveData"],
      type: "final",
    },
  },
});

const RHFLoadOnRenderAutocomplete = forwardRef(
  (
    {
      name,
      control,
      label,
      rules = undefined,
      optionListCall,
      ignoreErrors = false,
      ...rest
    },
    ref
  ) => {
    const {
      field,
      fieldState: { error },
    } = useController({ name, control, rules });

    const [current, send] = useMachine(optionsLoadMachine, {
      actions: {
        makeApiCall: () => {
          optionListCall()
            .then((response) => {
              if (response.status === "ok") {
                send({ type: "LOAD", data: response.data });
                return;
              }
              send({ type: "FAIL", message: response.message });
            })
            .catch((err) => send({ type: "FAIL", message: err.message }));
        },
        saveData: assign({ optList: (_, e) => e.data }),
        setErrorMessage: assign({ errorMessage: (_, e) => e.message }),
        clearErrorMessage: assign({ errorMessage: "" }),
      },
    });

    const retry = () => send({ type: "RETRY" });
    const newVal = maybeFixLazyVal(field.value, current.context.optList);

    const helperText = ignoreErrors ? "" : error?.message ?? " ";
    return (
      <Autocomplete
        name={field.name}
        value={newVal}
        onBlur={field.onBlur}
        onChange={(_, newVal) => field.onChange(newVal)}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={composeRefs(field.ref, ref)}
            error={!!error}
            helperText={helperText}
            size="small"
            label={label}
            fullWidth
            autoComplete="off"
            margin="dense"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <LoadingAdornment
                  curState={current}
                  retry={retry}
                  def={params.InputProps.endAdornment}
                />
              ),
            }}
          />
        )}
        loading={current.matches("loading")}
        options={current.context.optList}
        isOptionEqualToValue={(o, v) => o.label === v.label}
        autoSelect
        autoHighlight
        autoComplete
        clearOnEscape
        disableClearable
        {...rest}
      />
    );
  }
);

export default RHFLoadOnRenderAutocomplete;
