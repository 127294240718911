import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import StandardDialog from "../../StandardComponents/StandardDialog";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import RHFSwitchField from "../../FormControlsRHF/RHFSwitchField";
import AttachUploadButton from "../../StandardComponents/AttachUploadButton";

const genDefaultValues = () => {
  return {
    feedback: "",
    is_positive: true,
    attach_id: null,
  };
};

const PatientFeedbackForm = ({ open, onClose, eocId, api, refresh }) => {
  const defaultValues = genDefaultValues();
  const { control, handleSubmit, setError, reset, setValue } = useForm({
    defaultValues,
  });

  const handleClose = () => {
    reset(genDefaultValues());
    clearFormError();
    onClose();
  };

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    {},
    () => {
      handleClose();
      refresh();
    }
  );

  const onSubmit = (data) => {
    apiWrapper(api.createPatientFeedback(eocId, data));
  };

  return (
    <StandardDialog
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      title="Patient Feedback"
      open={open}
      onClose={handleClose}
      actions={
        <Button type="submit" variant="contained" color="primary">
          Add Feedback
        </Button>
      }
    >
      <FormLevelErrorAlert formErrStruct={formErrState} />
      <RHFTextField
        multiline
        control={control}
        name="feedback"
        InputProps={{ sx: { fontFamily: "monospace" } }}
        label="Patient Feedback"
        rows={5}
        helperText="Enter patient feedback in the first person from the patient's perspective. Example: 'I was very happy with the service I received.'"
      />
      <RHFSwitchField
        control={control}
        name="is_positive"
        label="This Is A Positive Review"
      />
      {open && (
        <AttachUploadButton
          genUrl={(apiBase, filename) =>
            `${apiBase}attachments/patient_feedback/0?title=${encodeURIComponent(
              filename
            )}`
          }
          onUpload={(response) => setValue("attach_id", response.id)}
          onReset={() => setValue("attach_id", null)}
          api={api}
        />
      )}
    </StandardDialog>
  );
};

export default PatientFeedbackForm;
