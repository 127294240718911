import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { dateObjFormatToSmallest } from "../../../utility";
import { InternalLink } from "../../StandardComponents/InternalLink";

const NoRecordsCardContent = () => (
  <CardContent>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: 4,
      }}
    >
      <Typography variant="h5" sx={{ color: grey[400] }}>
        No Data
      </Typography>
    </Box>
  </CardContent>
);

const combineName = (nameFirst, nameLast) => {
  if (!nameFirst && !nameLast) {
    return "(unknown)";
  }

  return `${nameFirst} ${nameLast}`.trim();
};

const PatientIntakeCardContent = ({
  patients,
  ptnLink,
  finalDate,
  lastCol = "Entered",
  showMemo,
}) => {
  return (
    <CardContent>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "90px" }}>Intake #</TableCell>
              <TableCell sx={{ width: "320px" }}>Patient</TableCell>
              <TableCell>Prescriber</TableCell>
              {showMemo && <TableCell>Memo</TableCell>}
              <TableCell sx={{ width: "110px" }}>{lastCol}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.map((p) => (
              <TableRow key={p.id}>
                <TableCell>
                  <InternalLink to={`/intake/${p.id}`}>
                    {p.id.toString().padStart(4, "0")}
                  </InternalLink>
                </TableCell>
                <TableCell>
                  {ptnLink ? (
                    <InternalLink to={`/patients/${p.id}`}>
                      {combineName(p.name_first, p.name_last)}
                    </InternalLink>
                  ) : (
                    combineName(p.name_first, p.name_last)
                  )}
                </TableCell>

                <TableCell>
                  <InternalLink to={`/prescribers/${p.npi}`}>
                    {p.presc_name_first} {p.presc_name_last}{" "}
                    {p.presc_credential}
                  </InternalLink>
                </TableCell>
                {showMemo && <TableCell>{p.status_memo}</TableCell>}
                <TableCell>
                  {finalDate
                    ? dateObjFormatToSmallest(new Date(p.final_status_on))
                    : dateObjFormatToSmallest(new Date(p.created_on))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  );
};

const PatientIntakeCard = ({
  title,
  patients,
  ptnLink,
  finalDate,
  lastCol = "Entered",
  showMemo = false,
}) => {
  const hasRecords = patients?.length > 0;

  return (
    <>
      <Card variant="outlined">
        {title && (
          <CardHeader title={title} titleTypographyProps={{ variant: "h6" }} />
        )}
        {hasRecords ? (
          <PatientIntakeCardContent
            patients={patients}
            ptnLink={ptnLink}
            finalDate={finalDate}
            lastCol={lastCol}
            showMemo={showMemo}
          />
        ) : (
          <NoRecordsCardContent />
        )}
      </Card>
    </>
  );
};

export default PatientIntakeCard;
