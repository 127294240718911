import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Stack,
} from "@mui/material";

const MissingDataAlert = ({ reqViolations }) => (
  <Alert severity="error">
    <AlertTitle>Missing Data</AlertTitle>
    <List disablePadding dense>
      {reqViolations.map((v) => (
        <ListItem
          key={v}
          sx={{
            pl: 1,
            ml: 2,
            display: "list-item",
            listStyleType: "disc",
            listStylePosition: "outside",
          }}
        >
          {v}
        </ListItem>
      ))}
    </List>
  </Alert>
);

const CautionDataAlert = ({ cautions }) => (
  <Alert severity="warning">
    <AlertTitle>Cautions</AlertTitle>
    <List disablePadding dense>
      {cautions.map((v) => (
        <ListItem
          key={v}
          sx={{
            pl: 1,
            ml: 2,
            display: "list-item",
            listStyleType: "disc",
            listStylePosition: "outside",
          }}
        >
          {v}
        </ListItem>
      ))}
    </List>
  </Alert>
);

const NoMissingDataAlert = () => (
  <Alert severity="success">Required Data Entered</Alert>
);

const NoCautionDataAlert = () => (
  <Alert severity="success">No Warnings Found</Alert>
);

const IntakeErrorCard = ({
  reqViolations,
  cautions,
  disableButton,
  onClickStatus,
  readOnly,
  showAction = true,
}) => {
  return (
    <Card variant="outlined" elevation={0} sx={{ mb: 2 }}>
      <CardHeader
        title="Data Integrity"
        titleTypographyProps={{ variant: "h6" }}
        action={
          !readOnly && (
            <Stack direction="row" spacing={1}>
              {showAction && (
                <Button
                  variant="contained"
                  disabled={disableButton}
                  onClick={onClickStatus}
                  color={!reqViolations.length ? "success" : "warning"}
                >
                  {!reqViolations.length ? "Accept" : "Mark Incomplete"}
                </Button>
              )}
            </Stack>
          )
        }
      />
      <CardContent>
        {!!reqViolations.length ? (
          <MissingDataAlert reqViolations={reqViolations} />
        ) : (
          <NoMissingDataAlert />
        )}
      </CardContent>
      <CardContent>
        {!!cautions.length ? (
          <CautionDataAlert cautions={cautions} />
        ) : (
          <NoCautionDataAlert />
        )}
      </CardContent>
    </Card>
  );
};

export default IntakeErrorCard;
