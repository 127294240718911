import { Fragment } from "react";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import { dateObjFormatToAnnArborDateTime } from "../../utility";
import { bytesToHumanReadable } from "../../formatters";
import { grey } from "@mui/material/colors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import AttachDownloadButton from "../StandardComponents/AttachDownloadButton";

const groupKeyToSectionTitle = {
  treats: "Treatment",
  rxs: "Prescription",
  ords: "Order",
  srvcs: "Service Cycle",
};

const groupKeyToUrl = {
  treats: "/patients/",
  rxs: "/rxs/",
  ords: "/orders/",
  srvcs: "/service-cycles/",
};

const mimeToCode = (mime) => {
  const lookup = {
    "application/pdf": "PDF",
    "image/png": "PNG",
    "image/gif": "GIF",
    "image/jpeg": "JPEG",
    "image/jpg": "JPEG",
    "image/tiff": "TIFF",
  };
  return lookup[mime] || "???";
};

const EntityAttachTable = ({ attachments, api }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "70px" }}>Type</TableCell>
          <TableCell>Title</TableCell>
          <TableCell sx={{ width: "95px" }}>Size</TableCell>
          <TableCell sx={{ width: "160px" }}>Date</TableCell>
          <TableCell sx={{ width: "40px" }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {attachments.map((a) => (
          <Fragment key={a.id}>
            <TableRow sx={{ ":hover": { bgcolor: grey[100] } }}>
              <TableCell>{mimeToCode(a.mime)}</TableCell>
              <TableCell>{a.title}</TableCell>
              <TableCell>{bytesToHumanReadable(a.size)}</TableCell>
              <TableCell>
                {dateObjFormatToAnnArborDateTime(a.created_on, false)}
              </TableCell>
              <TableCell sx={{ p: 0 }}>
                <AttachDownloadButton
                  attachId={a.id}
                  filename={a.title}
                  api={api}
                />
              </TableCell>
            </TableRow>
            {/*}
                <TableRow>
                  <TableCell colSpan={4} sx={{ pt: 0 }}>
                    <Typography variant="caption">
                      {a.tags.join(", ")}
                    </Typography>
                  </TableCell>
                </TableRow>
                */}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

const EntityCard = ({ eocId, groupKey, entity, api }) => {
  const groupTitle = groupKeyToSectionTitle[groupKey];
  const navigate = useNavigate();
  const link = `${groupKeyToUrl[groupKey]}${
    groupKey === "treats" ? eocId : entity.id
  }`;

  return (
    <Card variant="outlined">
      <CardHeader
        title={`${groupTitle} ${entity.title}`}
        titleTypographyProps={{ variant: "h6" }}
        action={
          <IconButton onClick={() => navigate(link)}>
            <ArrowForwardIcon />
          </IconButton>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        {entity.attachments.length === 0 ? (
          <Alert severity="info">No attachments</Alert>
        ) : (
          <EntityAttachTable attachments={entity.attachments} api={api} />
        )}
      </CardContent>
    </Card>
  );
};

const EntityGroup = ({ groupKey, entities, eocId, api }) => {
  return entities.map((e) => (
    <EntityCard
      key={`${groupKey}-${e.id}`}
      eocId={eocId}
      groupKey={groupKey}
      entity={e}
      api={api}
    />
  ));
};

const PageTreatmentAttachments = ({ pageData, api }) => {
  const link_title = [
    "Back to Patient",
    pageData.name_first,
    pageData.name_last,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <>
      <BackLinkHeader to={`/patients/${pageData.eoc_id}`} title={link_title} />
      <Stack spacing={3}>
        {Object.entries(pageData.attachList).map(([groupKey, entities]) => (
          <EntityGroup
            key={groupKey}
            eocId={pageData.eoc_id}
            groupKey={groupKey}
            entities={entities}
            api={api}
          />
        ))}
      </Stack>
    </>
  );
};

export default PageTreatmentAttachments;
