import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import StandardDialog from "../../StandardComponents/StandardDialog";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import RHFMoneyField from "../../FormControlsRHF/RHFMoneyField";
import RHFDateField from "../../FormControlsRHF/RHFDateField";
import { reformatDate } from "../../../utility";

const genDefaultVals = (initialVals) => {
  return {
    total: initialVals.total,
    ident: initialVals.ident,
    issued_on: initialVals.issued_on,
    cleared_on: initialVals.cleared_on,
  };
};

const PaymentEditFormDialog = ({
  api,
  refresh,
  open,
  onClose,
  paymentId,
  initialVals,
}) => {
  const defaultValues = genDefaultVals(initialVals);

  const { handleSubmit, control, setError, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(genDefaultVals(initialVals));
  }, [reset, initialVals]);

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      refresh();
      clearFormError();
      onClose();
    }
  );

  const onSubmit = (data) => {
    const payload = {
      ident: data.ident,
      issued_on: reformatDate(data.issued_on),
      cleared_on: reformatDate(data.cleared_on),
      total: parseInt(data.total, 10),
    };
    apiWrapper(api.updatePayment(paymentId, payload));
  };

  const handleClose = () => {
    reset();
    clearFormError();
    onClose();
  };

  return (
    <StandardDialog
      open={open}
      onClose={handleClose}
      title="Update Payment Info"
    >
      <FormLevelErrorAlert sx={{ mb: 2 }} formErrStruct={formErrState} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={3}>
          <Grid2 xs={8}>
            <RHFTextField control={control} name="ident" label="Check Number" />
          </Grid2>
          <Grid2 xs={4}>
            <RHFMoneyField control={control} name="total" label="Amount" />
          </Grid2>
          <Grid2 xs={6}>
            <RHFDateField
              control={control}
              name="issued_on"
              label="Entered Date"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFDateField
              control={control}
              name="cleared_on"
              label="Lockbox Date"
            />
          </Grid2>
          <Grid2 xs={12} sx={{ textAlign: "right" }}>
            <Button
              disabled={formErrState.submitting}
              type="submit"
              variant="contained"
            >
              Update
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default PaymentEditFormDialog;
