import { IconButton, Stack } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const AddAttachmentButton = ({ editable, onClick }) => {
  if (!editable) {
    return null;
  }
  return (
    <Stack direction="row">
      <IconButton onClick={onClick}>
        <AttachFileIcon />
      </IconButton>
    </Stack>
  );
};

export default AddAttachmentButton;
