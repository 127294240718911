import { useContext, useRef, useState } from "react";
import ApiDataContext from "../../../ApiDataContext";
import {
  Chip,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { apiCallSimplify } from "../../../utility";

const determineCCState = (ccList, curUserId) => {
  const inList = ccList.find((cc) => cc.user_id === curUserId);
  if (!inList) {
    return "none";
  }

  if (inList.cc_mode === "notify_on_all") return "all";
  return "close";
};

const STATE_TO_UI = {
  none: {
    label: "Track Task",
    icon: NotificationAddIcon,
    color: "default",
  },
  close: {
    label: "Tracking Task",
    icon: NotificationsIcon,
    color: "info",
  },
  all: {
    label: "Tracking Task",
    icon: NotificationsActiveIcon,
    color: "info",
  },
};

/**
 * @param {function} onTrackChangeFn
 * @param {Object<function>} api
 * @param {number} taskId
 * @param {Array<{ cc_mode: TicketCCMode, user_id: number }>} ccList
 * @param {number} assigneeId
 * @param {number} requesterId
 */
const TicketTrackThisIconMenu = ({
  onTrackChangeFn,
  api,
  taskId,
  ccList,
  assigneeId,
  requesterId,
}) => {
  const { me } = useContext(ApiDataContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [callState, setCallState] = useState({
    submitting: false,
    error: null,
  });
  const menuEl = useRef(null);

  if (me.id === assigneeId || me.id === requesterId) return null;

  const handleClick = () => setMenuOpen(true);
  const handleClose = () => setMenuOpen(false);

  const handleChange = async (newState) => {
    try {
      const apiCall = api.updateTicketCCMe(taskId, { new_cc_mode: newState });
      setCallState({
        submitting: true,
        error: null,
      });
      await apiCallSimplify(apiCall);
      setCallState({
        submitting: false,
        error: null,
      });
      onTrackChangeFn();
      setMenuOpen(false);
    } catch (err) {
      setCallState({
        submitting: false,
        error: err.message,
      });
    }
  };

  const ccState = determineCCState(ccList, me.id);
  const { label, icon: Icon, color } = STATE_TO_UI[ccState];

  return (
    <>
      <Chip
        ref={menuEl}
        onClick={handleClick}
        color={callState.error ? "error" : color}
        clickable
        label={callState.error ? callState.error : label}
        sx={{ typography: "body1" }}
        onDelete={handleClick}
        icon={<Icon />}
        deleteIcon={menuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        disabled={callState.submitting}
      />
      <Menu
        open={menuOpen}
        anchorEl={menuEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList disablePadding>
          <MenuItem
            onClick={() => handleChange("all")}
            disabled={ccState === "all"}
          >
            <ListItemIcon>
              <NotificationsActiveIcon />
            </ListItemIcon>
            Notify on All
          </MenuItem>
          <MenuItem
            onClick={() => handleChange("close")}
            disabled={ccState === "close"}
          >
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            Notify on Close
          </MenuItem>
        </MenuList>
        <Divider />
        <MenuItem
          onClick={() => handleChange("none")}
          disabled={ccState === "none"}
        >
          <ListItemIcon>
            <NotificationsOffIcon />
          </ListItemIcon>
          Stop Notifications
        </MenuItem>
      </Menu>
    </>
  );
};

export default TicketTrackThisIconMenu;
