import { useEffect, useMemo, useRef, useState } from "react";
import {
  alpha,
  Autocomplete,
  debounce,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ucWord } from "../../../utility";

const constructNavTarget = (selectedVal) => {
  return `/${selectedVal.type}/${selectedVal.ident}`;
};

const SearchControl = ({ api }) => {
  const [selectedVal, setSelectedVal] = useState(null);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [queryVal, setQueryVal] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const searchEl = useRef(null);
  const navigate = useNavigate();

  useHotkeys(
    "/",
    () => {
      if (!searchEl.current) return;
      searchEl.current.focus();
      searchEl.current.select();
    },
    { preventDefault: true }
  );

  const fetchQueryResults = useMemo(
    () =>
      debounce((term) => {
        setSearchInProgress(true);
        api
          .search(term)
          .then((resp) => {
            setSearchInProgress(false);
            if (resp.status !== "ok") {
              setOptions([]);
              return;
            }
            setOptions(resp.data);
            setIsOpen(true);
          })
          .catch((err) => {
            setSearchInProgress(false);
            console.error(err);
            setOptions([]);
          });
      }, 400),
    [api]
  );

  useEffect(() => {
    if (queryVal.length < 3) {
      setOptions([]);
      return;
    }
    fetchQueryResults(queryVal);
  }, [queryVal, fetchQueryResults]);

  return (
    <Autocomplete
      freeSolo
      filterOptions={(_) => _}
      autoComplete
      includeInputInList
      filterSelectedOptions
      clearOnBlur
      clearOnEscape
      disableClearable
      open={isOpen}
      autoSelect={false}
      autoHighlight
      inputValue={queryVal}
      value={selectedVal}
      loading={searchInProgress}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onKeyDown={(event) => {
        if (!searchInProgress) {
          return;
        }
        if (event.key === "Enter") {
          // Prevent's default 'Enter' behavior.
          event.defaultMuiPrevented = true;
          // your handler code
        }
      }}
      noOptionsText="Type to search..."
      onChange={(ev, val) => {
        if (val === "" || options.length === 0) return;
        if (!val.type) return;
        const url = constructNavTarget(val);
        navigate(url);
        searchEl.current?.blur();
        setSelectedVal(null);
      }}
      onInputChange={(ev, val, reason) => {
        if (queryVal === "" && val === "/") return;
        if (reason === "reset") {
          setQueryVal("");
          return;
        }
        setQueryVal(val);
      }}
      sx={{
        width: "300px",
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            placeholder='Press "/" to search'
            inputRef={searchEl}
            onBlur={() => setOptions([])}
            InputProps={{
              ...params.InputProps,
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: (theme) =>
                    alpha(theme.palette.common.white, 0.25),
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: (theme) =>
                    alpha(theme.palette.common.white, 0.25),
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: (theme) =>
                    alpha(theme.palette.common.white, 0.25),
                },
                backgroundColor: (theme) =>
                  alpha(theme.palette.common.white, 0.15),
                color: "white",
              },
              startAdornment: (
                <SearchIcon
                  sx={{ color: (theme) => theme.palette.common.white }}
                />
              ),
            }}
            sx={{ width: "300px" }}
          />
        );
      }}
      groupBy={(option) => {
        return ucWord(option.type);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid2 container>
              <Grid2 xs={12}>
                <Typography sx={{ mb: -1, pb: -1 }}>{option.label}</Typography>
              </Grid2>
              <Grid2 xs={12}>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  {option.subheader}
                </Typography>
              </Grid2>
            </Grid2>
          </li>
        );
      }}
      options={options}
    />
  );
};

export default SearchControl;
