import { useEffect, useMemo } from "react";
import { Alert } from "@mui/material";
import { Area, ComposedChart, Tooltip, XAxis, YAxis } from "recharts";
import useSWR from "swr";

const bgFetcher = (api) => async (key) => {
  const [, entity, ident, dayStart, dayEnd] = key.split("_");
  const resp = await api.bgData(entity, ident, dayStart, dayEnd);
  if (resp.status !== "ok") throw new Error(resp.message);
  return resp.data;
};

const BleederGainerGraph = ({
  api,
  entity,
  ident,
  dayStart,
  dayEnd,
  onData = () => {},
  height = 400,
  width = 850,
}) => {
  const key = `bg-data_${entity}_${ident}_${dayStart}_${dayEnd}`;
  const { data: rawData, error, isLoading } = useSWR(key, bgFetcher(api));

  const data = useMemo(
    () =>
      (rawData || []).map((d) => {
        return { ...d, denom_count: Math.round(d.denom_count * 100) / 100 };
      }),
    [rawData]
  );

  useEffect(() => {
    if (rawData) {
      onData(data);
    }
  }, [rawData, onData, data]);

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (isLoading || !rawData) {
    return <Alert severity="info">Loading...</Alert>;
  }

  if (rawData.length === 0) {
    return <Alert severity="info">No historical data to show.</Alert>;
  }

  return (
    <ComposedChart width={width} height={height} data={data}>
      <defs>
        <linearGradient id="colorBaseline" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#d32f2f" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#d32f2f" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorCurrent" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#4caf50" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#4caf50" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis dataKey="day" />
      <YAxis />
      <Tooltip />
      <Area
        type="monotone"
        name="Trailing 30 Average"
        dataKey="num_count"
        stroke="#2e7d32"
        strokeWidth={2}
        fill="url(#colorCurrent)"
        animationDuration={400}
      />
      <Area
        type="monotone"
        name="Trailing 90 Average"
        dataKey="denom_count"
        stroke="#d32f2f"
        strokeWidth={2}
        fill="url(#colorBaseline)"
        animationDuration={400}
      />
    </ComposedChart>
  );
};

export default BleederGainerGraph;
