import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePrescription } from "@fortawesome/pro-regular-svg-icons";
import { SvgIcon } from "@mui/material";

const RxIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <FontAwesomeIcon icon={faFilePrescription} />
    </SvgIcon>
  );
};

export default RxIcon;
