import useSWR from "swr";
import { useState } from "react";
import { genericSWRFetcher } from "../../utility";
import {
  Alert,
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { InternalLink } from "../StandardComponents/InternalLink";
import TicketContextLink from "../PagePartials/Tickets/TicketContextLink";
import { format } from "date-fns";
import CircleIcon from "@mui/icons-material/Circle";
import BackLinkHeader from "../PagePartials/BackLinkHeader";

const PAGE_SIZE = 50;

const PageNotificationList = ({ api }) => {
  const [amtToLoad, setAmtToLoad] = useState(PAGE_SIZE);
  const fetcher = genericSWRFetcher(api.listAllNotifications, (key) => [
    key.split("-")[1],
  ]);
  const { data, error, isLoading } = useSWR(
    `notificationList-${amtToLoad}`,
    fetcher
  );

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (isLoading || !data) {
    return <CircularProgress />;
  }

  return (
    <Stack direction="column">
      <BackLinkHeader title="Go Back" to={-1} />
      <Table
        size="small"
        padding="none"
        sx={{
          "& tr td": { py: "2px" },
          "& tr td:nth-of-type(1)": { width: "24px", textAlign: "center" },
          "& tr td:nth-of-type(2)": { width: "80px" },
          "& tr td:nth-of-type(6)": { width: "125px" },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Task</TableCell>
            <TableCell>Attached To</TableCell>
            <TableCell>Actor</TableCell>
            <TableCell>Notification</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((n) => {
            const taskLbl = `TSK-${n.ticket_id.toString().padStart(4, "0")}`;
            const taskUrl = `/tasks/${n.ticket_id}`;

            return (
              <TableRow
                key={n.id}
                sx={{ "& td": { fontWeight: n.read_on ? "normal" : "bold" } }}
              >
                <TableCell>
                  <CircleIcon
                    color={n.read_on ? undefined : "info"}
                    sx={{
                      height: "10px",
                      width: "10px",
                      color: n.read_on ? "text.disabled" : undefined,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <InternalLink to={taskUrl}>{taskLbl}</InternalLink>
                </TableCell>
                <TableCell>
                  <TicketContextLink
                    context={{
                      label: n.context_label,
                      type: n.context_type,
                      ident: n.context_ident,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    variant="outlined"
                    avatar={
                      <Avatar
                        alt={n.from_user_name}
                        src={n.from_user_picture}
                      />
                    }
                    label={n.from_user_name}
                  />
                </TableCell>
                <TableCell>{n.content}</TableCell>
                <TableCell>
                  {format(new Date(n.created_on), "EEE MMM d, yyyy")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        {data.length === amtToLoad && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={6}>
                <Button
                  fullWidth
                  onClick={() => setAmtToLoad((prev) => prev + PAGE_SIZE)}
                >
                  Load more...
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </Stack>
  );
};

export default PageNotificationList;
