import { TableCell, TableRow } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import { dateObjFormatToAnnArborDateTime } from "../../../utility";
import { useFormState } from "react-hook-form";

const COMM_PERC_PATTERN = new RegExp("^100$|^\\d{1,2}(?:\\.\\d{0,2})?$");

const COMM_FLAT_PATTERN = new RegExp("^\\d{1,4}(?:\\.\\d{0,2})?$");

const CommTableRow = ({
  commModel,
  serviceChannel,
  therapy,
  generation,
  name,
  control,
  commMatrix,
}) => {
  const { dirtyFields } = useFormState({ control, name });

  const InputProps = {};
  commModel === "receivables-v01"
    ? (InputProps.endAdornment = "%")
    : (InputProps.startAdornment = "$");

  const vldPattern =
    commModel === "receivables-v01" ? COMM_PERC_PATTERN : COMM_FLAT_PATTERN;

  const effective = commMatrix?.[name]?.effective_on;
  const effectiveStr = effective
    ? dateObjFormatToAnnArborDateTime(effective)
    : "Never";

  const sx = {};
  if (dirtyFields[name]) {
    sx["bgcolor"] = lightBlue[50];
  }

  return (
    <TableRow sx={sx}>
      <TableCell>{serviceChannel}</TableCell>
      <TableCell>{therapy}</TableCell>
      <TableCell>{generation}</TableCell>
      <TableCell>{effectiveStr}</TableCell>
      <TableCell>{commMatrix?.[name]?.created_by}</TableCell>
      <TableCell sx={{ p: 0 }}>
        <RHFTextField
          control={control}
          name={name}
          sx={{ m: 0, p: 0, "& input": { p: "2px" } }}
          helperText=""
          rules={{ pattern: vldPattern }}
          InputProps={InputProps}
        />
      </TableCell>
    </TableRow>
  );
};

export default CommTableRow;
