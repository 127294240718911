import { IconButton, TableCell } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const NoButtonCell = () => (
  <TableCell sx={{ textAlign: "right", visibility: "hidden" }}>
    <IconButton size="small">
      <EditIcon fontSize="small" />
    </IconButton>
  </TableCell>
);

const EditButtonCell = ({ onClick, disabled }) => (
  <TableCell sx={{ textAlign: "right" }}>
    <IconButton size="small" onClick={onClick} disabled={disabled}>
      <EditIcon fontSize="small" />
    </IconButton>
  </TableCell>
);

const AcceptCancelButtonCell = ({ onAcceptClick, onCancelClick }) => (
  <TableCell sx={{ textAlign: "right" }}>
    <IconButton size="small" onClick={onAcceptClick}>
      <CheckCircleIcon color="success" fontSize="small" />
    </IconButton>
    <IconButton size="small" onClick={onCancelClick}>
      <CancelIcon color="error" fontSize="small" />
    </IconButton>
  </TableCell>
);

const TableCellEditLineItem = ({
  li,
  liEditable,
  onMakeLineEditable,
  onClearLineEditable,
  onAcceptChange,
  hidden = false,
}) => {
  if (li.item_type === "consumable" || hidden) {
    return <NoButtonCell />;
  }

  if (liEditable === null) {
    return <EditButtonCell onClick={() => onMakeLineEditable(li)} />;
  }

  if (li.id !== liEditable.id) {
    return <EditButtonCell onClick={() => onMakeLineEditable(li)} disabled />;
  }

  return (
    <AcceptCancelButtonCell
      onAcceptClick={onAcceptChange}
      onCancelClick={onClearLineEditable}
    />
  );
};

export default TableCellEditLineItem;
