import StdFormDialog from "../PagePartials/StdFormDialog";
import { TextField } from "@mui/material";
import RPZipInput from "../FormControls/RPZipInput";
import RPStatePicker from "../FormControls/RPStatePicker";
import RPDateInput from "../FormControls/RPDateInput";
import RPGenderPicker from "../FormControls/RPGenderPicker";
import RPSsnInput from "../FormControls/RPSsnInput";
import RPLanguagePicker from "../FormControls/RPLanguagePicker";
import RPPhoneNumberInput from "../FormControls/RPPhoneNumberInput";
import { reformatDate } from "../../utility";
import RPOrgPicker from "../FormControls/RPOrgPicker";

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  const FIELDS = [
    { type: "section", label: "Personal" },
    { name: "name_first", label: "First Name", component: TextField, width: 4 },
    { name: "name_last", label: "Last Name", component: TextField, width: 5 },
    {
      name: "date_of_birth",
      label: "Date of Birth",
      component: RPDateInput,
      width: 3,
    },
    { name: "gender", label: "Gender", component: RPGenderPicker, width: 3 },
    {
      name: "ssn",
      label: "Social Security Number",
      component: RPSsnInput,
      width: 5,
    },
    {
      name: "lang",
      label: "Language Preference",
      component: RPLanguagePicker,
      width: 4,
    },
    { type: "section", label: "Contact" },
    {
      name: "addr_street1",
      label: "Street Address",
      component: TextField,
      width: 8,
    },
    {
      name: "addr_street2",
      label: "Apt / Suite",
      component: TextField,
      width: 4,
    },
    { name: "addr_city", label: "City", component: TextField, width: 5 },
    {
      name: "addr_state",
      label: "State",
      component: RPStatePicker,
      width: 4,
    },
    { name: "addr_zip", label: "Zip Code", component: RPZipInput, width: 3 },
    {
      name: "phone",
      label: "Phone Number",
      component: RPPhoneNumberInput,
      width: 4,
    },
    { name: "email", label: "Email Address", component: TextField, width: 8 },

    { type: "section", label: "Injury" },
    {
      name: "date_of_injury",
      label: "Date of Injury",
      component: RPDateInput,
      width: 3,
    },
    {
      name: "employer_name",
      label: "Employer Name",
      component: TextField,
      width: 5,
    },
    {
      name: "employer_phone",
      label: "Employer Phone",
      component: RPPhoneNumberInput,
      width: 4,
    },

    { type: "section", label: "Insurance" },
    {
      name: "initial_org_id",
      label: "Insurance Carrier",
      component: RPOrgPicker,
      width: 4,
      extraProps: { api },
    },
    {
      name: "claim_number",
      label: "Claim Number",
      component: TextField,
      width: 8,
    },
    {
      name: "ins_addr_street1",
      label: "Payor Street Address",
      component: TextField,
      width: 8,
    },
    {
      name: "ins_addr_street2",
      label: "Payor Suite/PO Box",
      component: TextField,
      width: 4,
    },
    {
      name: "ins_addr_city",
      label: "Payor City",
      component: TextField,
      width: 5,
    },
    {
      name: "ins_addr_state",
      label: "Payor State",
      component: RPStatePicker,
      width: 4,
    },
    {
      name: "ins_addr_zip",
      label: "Payor Zip",
      component: RPZipInput,
      width: 3,
    },

    { type: "section", label: "Adjuster" },
    {
      name: "adj_name",
      label: "Adjuster Name",
      component: TextField,
      width: 5,
    },
    {
      name: "adj_email",
      label: "Adjuster Email",
      component: TextField,
      width: 5,
    },
    {
      name: "adj_phone",
      label: "Adjuster Phone",
      component: RPPhoneNumberInput,
      width: 4,
    },
    {
      name: "adj_fax",
      label: "Adjuster Fax",
      component: RPPhoneNumberInput,
      width: 4,
    },

    { type: "section", label: "Nurse Case Manager" },
    {
      name: "ncm_name",
      label: "Nurse Case Manager Name",
      component: TextField,
      width: 5,
    },
    {
      name: "ncm_email",
      label: "Nurse Case Manager Email",
      component: TextField,
      width: 5,
    },
    {
      name: "ncm_phone",
      label: "Nurse Case Manager Phone",
      component: RPPhoneNumberInput,
      width: 4,
    },
    {
      name: "ncm_fax",
      label: "Nurse Case Manager Fax",
      component: RPPhoneNumberInput,
      width: 4,
    },
  ];
  return (
    <StdFormDialog
      open={open}
      onClose={onClose}
      title="Update Patient Demographics"
      onComplete={() => {
        onClose();
        refresh();
      }}
      fields={FIELDS}
      submitCall={(data) => {
        const payload = {
          ...data,
          date_of_birth: reformatDate(data.date_of_birth),
          date_of_injury: reformatDate(data.date_of_injury),
        };

        return api.ptnUpdateDemos(initialVals.id, payload);
      }}
      initialVals={initialVals}
    />
  );
};
