import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const opts = [
  { value: "Insurance", label: "Insurance" },
  { value: "CostContainment", label: "Cost Containment" },
  { value: "TPA", label: "TPA" },
  { value: "Legal", label: "Legal" },
];

const RPPayorTypePicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={opts} />;
});

RPPayorTypePicker.defaultValue = null;

export default RPPayorTypePicker;
