import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";

const BucketCard = ({ spec, data }) => {
  const [showLimited, setShowLimited] = useState(false);

  const { title, columns, loadCutoff } = spec;

  const limitedRows = showLimited
    ? data
    : data.filter(loadCutoff || ((r) => r));
  const hasBeenLimited = limitedRows.length !== data.length;
  const showLoadAll = hasBeenLimited && !showLimited;

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Table
          size="small"
          sx={{
            "& th": { p: "3px" },
            "& td": { p: "3px" },
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((c) => {
                const sx = {};
                if (c.width) sx.width = c.width;
                return (
                  <TableCell sx={sx} key={`col-${c.label}`}>
                    {c.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {limitedRows.map((r) => {
              const highlight = spec.highlight ? spec.highlight(r) : null;
              const rowSx = highlight ? { bgcolor: highlight } : undefined;
              return (
                <TableRow key={`row-${r.id}`} sx={rowSx}>
                  {columns.map((c) => (
                    <TableCell key={`c${c.label}-r${r.id}`} sx={c.sx}>
                      {c.cell(r)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {showLoadAll && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ textAlign: "center" }}
                >
                  <Button onClick={() => setShowLimited(true)}>
                    Load All...
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default BucketCard;
