import StdFormDialog from "../PagePartials/StdFormDialog";
import RPDateInput from "../FormControls/RPDateInput";
import { reformatDate } from "../../utility";

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  const FIELDS = [
    {
      name: "arrived_on",
      label: "Date Unit Delivered",
      component: RPDateInput,
      width: 6,
    },
  ];

  return (
    <StdFormDialog
      open={open}
      onClose={onClose}
      title="Mark Unit Delivered"
      onComplete={() => {
        onClose();
        refresh();
      }}
      fields={FIELDS}
      submitCall={(data) => {
        const payload = { arrived_on: reformatDate(data.arrived_on) };
        return api.srvcSetArrived(initialVals.id, payload);
      }}
      initialVals={initialVals}
    />
  );
};
