import { useContext } from "react";
import { Divider, List, ListSubheader, Paper } from "@mui/material";
import SideNavItem from "./SideNavItem";
import ApiDataContext from "../../../ApiDataContext";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FolderIcon from "@mui/icons-material/Folder";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChatIcon from "@mui/icons-material/Chat";
import ClipboardListCheckIcon from "../../StandardComponents/Icons/ClipboardListCheckIcon";
import MenuBookIcon from "@mui/icons-material/MenuBook";

const infoNavData = [
  { to: "/dashboard", label: "Dashboard", icon: DashboardIcon },
  { to: "/workspace", label: "Workspace", icon: WorkspacesIcon },
  { to: "/tasks", label: "Tasks", icon: ClipboardListCheckIcon },
  { to: "/reports", label: "Reports", icon: BrowserUpdatedIcon },
];

const salesNavData = [
  { to: "/agencies", label: "Agencies", icon: BusinessIcon },
  { to: "/practices", label: "Practices", icon: LocalHospitalIcon },
  {
    to: "/prescribers/onboarding",
    label: "Onboarding",
    icon: FolderIcon,
  },
];

const opsNavData = [
  { to: "/intake", label: "Intake", icon: FactCheckIcon },
  { to: "/patients", label: "Patients", icon: PersonIcon },
];

const invNavData = [
  { to: "/purchase-orders", label: "Purchase Orders", icon: ShoppingCartIcon },
  { to: "/transfer-orders", label: "Transfer Orders", icon: LocalShippingIcon },
];

const billNavData = [
  { to: "/rcm", label: "Orders", icon: MenuBookIcon },
  { to: "/orgs", label: "Payors", icon: CorporateFareIcon },
  { to: "/payments", label: "Payments", icon: LocalAtmIcon },
];

const financeNavData = [
  {
    to: "/commission-dates",
    label: "Commission Dates",
    icon: CalendarMonthIcon,
  },
];

//const adminNavData = [{ to: "/debug", label: "Debug", icon: BugReportIcon }];

const SideNav = () => {
  const { me } = useContext(ApiDataContext);

  const salesNavDataReal = [...salesNavData];
  if (me.perms.includes("rp:txtr")) {
    salesNavDataReal.push({
      to: "/txtr",
      label: "Mass Text Tool",
      icon: ChatIcon,
    });
  }

  return (
    <Paper variant="outlined">
      <List component="nav">
        <ListSubheader sx={{ pr: 0 }}>Information</ListSubheader>
        {infoNavData.map((n) => (
          <SideNavItem to={n.to} label={n.label} key={n.label} icon={n.icon} />
        ))}
      </List>
      <Divider />
      <List component="nav">
        <ListSubheader sx={{ pr: 0 }}>Sales</ListSubheader>
        {salesNavDataReal.map((n) => (
          <SideNavItem to={n.to} label={n.label} key={n.label} icon={n.icon} />
        ))}
      </List>
      <Divider />
      <List component="nav">
        <ListSubheader sx={{ pr: 0 }}>Operations</ListSubheader>
        {opsNavData.map((n) => (
          <SideNavItem to={n.to} label={n.label} key={n.label} icon={n.icon} />
        ))}
      </List>
      <Divider />
      <List component="nav">
        <ListSubheader sx={{ pr: 0 }}>Inventory</ListSubheader>
        {invNavData.map((n) => (
          <SideNavItem to={n.to} label={n.label} key={n.label} icon={n.icon} />
        ))}
      </List>
      <Divider />
      <List component="nav">
        <ListSubheader sx={{ pr: 0 }}>Billing</ListSubheader>
        {billNavData.map((n) => (
          <SideNavItem to={n.to} label={n.label} key={n.label} icon={n.icon} />
        ))}
      </List>
      <Divider />
      <List component="nav">
        <ListSubheader sx={{ pr: 0 }}>Finance</ListSubheader>
        {financeNavData.map((n) => (
          <SideNavItem to={n.to} label={n.label} key={n.label} icon={n.icon} />
        ))}
      </List>
    </Paper>
  );
};

export default SideNav;
