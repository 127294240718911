import { forwardRef, useState } from "react";
import RPZipInput from "../FormControls/RPZipInput";

const NZipField = forwardRef(({ fieldRecord, updater }, ref) => {
  const [val, setVal] = useState(fieldRecord.value);

  return (
    <RPZipInput
      name={fieldRecord.name}
      label={fieldRecord.label}
      helperText={fieldRecord.errstr}
      error={fieldRecord.error}
      onChange={(newVal) => setVal(newVal)}
      value={val}
      onBlur={() => updater(fieldRecord.name, val)}
      inputRef={ref}
      size="small"
      fullWidth
    />
  );
});

export default NZipField;
