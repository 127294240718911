import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { sanitizeStr } from "../../../utility";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";

const parseSerials = (rawStr) =>
  sanitizeStr(rawStr)
    .split(" ")
    .filter((s) => s.length > 4);

const PTN_SERIAL = new RegExp("^[^A-Za-z0-9]+$");

const validate = (frmState, amtRequested) => {
  frmState.isError = true;
  if (frmState.parsedAmt > amtRequested) {
    frmState.errorMsg = "Too many serial numbers entered.";
    return frmState;
  }

  if (frmState.parsed.some((s) => PTN_SERIAL.test(s))) {
    frmState.errorMsg = "Bad character in serial number.";
    return frmState;
  }

  const setAside = new Set(frmState.parsed);
  if (setAside.size < frmState.parsed.length) {
    frmState.errorMsg = "Duplicate serial numbers entered.";
    return frmState;
  }

  frmState.isError = false;
  return frmState;
};

const FeedBack = ({ frmState, amtRequested }) => {
  if (frmState.isError) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <ErrorIcon color="error" />
        <Box component="span" sx={{ color: "error" }}>
          {frmState.errorMsg}
        </Box>
      </Stack>
    );
  }

  if (frmState.parsedAmt === amtRequested) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <CheckCircleIcon color="success" />
        <Box component="span" sx={{ color: "success" }}>
          {frmState.parsedAmt} / {amtRequested}
        </Box>
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <WarningIcon color="warning" />
      <Box component="span" sx={{ color: "warning" }}>
        {frmState.parsedAmt} / {amtRequested}
      </Box>
    </Stack>
  );
};

const PurchaseOrderSetSerials = ({
  open,
  onClose,
  amtRequested,
  itemId,
  initialValue,
  onCommit,
}) => {
  const [formState, setFormState] = useState({
    raw: initialValue.join("\n"),
    isError: false,
    errorMsg: "",
    parsed: initialValue,
    parsedAmt: initialValue.length,
  });

  const handleIptChange = (newRawVal) => {
    const parsed = parseSerials(newRawVal);
    const newFormState = {
      raw: newRawVal,
      parsed,
      parsedAmt: parsed.length,
      isError: false,
      errorMsg: "",
    };

    validate(newFormState, amtRequested);

    setFormState(newFormState);
  };

  const handleClose = () => onClose();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: 700, minWidth: 700 } }}
      disableScrollLock
    >
      <DialogTitle>Enter Serial Numbers</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          multiline
          error={formState.isError}
          rows={10}
          value={formState.raw}
          onChange={(e) => handleIptChange(e.target.value)}
        />
        <Box sx={{ mt: 1 }}>
          <FeedBack frmState={formState} amtRequested={amtRequested} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={formState.isError}
          onClick={() => {
            onCommit(formState.parsed, itemId);
            handleClose();
          }}
        >
          Set Serials
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurchaseOrderSetSerials;
