import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { phoneFmt } from "../../../formatters";
import ListIconToolTip from "../../StandardComponents/ListIconToolTip";
import { formatAddr, ucWord } from "../../../utility";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
import CakeIcon from "@mui/icons-material/Cake";

const PersonalCard = ({ pageData }) => {
  const addr = formatAddr(pageData);

  return (
    <Paper variant="outlined">
      <List dense>
        <ListItem sx={{ py: 0 }}>
          <ListIconToolTip title="Home Address">
            <ListItemIcon>
              <IconButton disabled>
                <HomeIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          {addr === "" ? (
            <ListItemText primary="[NOT ENTERED]" />
          ) : (
            <ListItemText
              primary={addr.split("\n")[0] || ""}
              secondary={addr.split("\n")[1] || ""}
            />
          )}
        </ListItem>
        <ListItem sx={{ py: 0 }}>
          <ListIconToolTip title="Phone">
            <ListItemIcon>
              <IconButton disabled>
                <PhoneIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText
            primary={phoneFmt(pageData.phone, true) || "[NOT ENTERED]"}
          />
        </ListItem>
        <ListItem sx={{ py: 0 }}>
          <ListIconToolTip title="Email">
            <ListItemIcon>
              <IconButton disabled>
                <EmailIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText primary={pageData.email || "[NOT ENTERED]"} />
        </ListItem>
        <ListItem sx={{ py: 0 }}>
          <ListIconToolTip title="Date of Birth">
            <ListItemIcon>
              <IconButton disabled>
                <CakeIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText primary={pageData.date_of_birth} />
        </ListItem>
        <ListItem sx={{ py: 0 }}>
          <ListIconToolTip title="Language Preference">
            <ListItemIcon>
              <IconButton disabled>
                <LanguageIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText>{ucWord(pageData.lang)}</ListItemText>
        </ListItem>
      </List>
    </Paper>
  );
};

export default PersonalCard;
