import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const CONTACT_TYPES = [
  { value: "scheduler", label: "Scheduler" },
  { value: "office", label: "Office" },
  { value: "other", label: "Other" },
];

const RPContactTypePicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={CONTACT_TYPES} />;
});

RPContactTypePicker.defaultValue = null;

export default RPContactTypePicker;
