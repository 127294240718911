import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useDialogState from "../../HOC/useDialogState";
import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { nowDate, reformatDate } from "../../utility";
import PatientSummaryCard from "../PagePartials/PatientSummaryCard";
import { Dialog as AttachScriptDialog } from "../BusinessActions/AttachScript";
import KeyValueList from "../PagePartials/KeyValueList";
import { InternalLink } from "../StandardComponents/InternalLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrescription } from "@fortawesome/free-solid-svg-icons";
import SurgicalIcon from "../StandardComponents/SurgicalIcon";
import SplitButton from "../StandardComponents/SplitButton";
import AttributionCard from "./PageRx/AttributionCard";
import StdFormDialog from "../PagePartials/StdFormDialog";
import EligibilityCard from "./PageRx/EligibilityCard";
import RPDateInput from "../FormControls/RPDateInput";
import RPPracticeExtResponsePicker from "../FormControls/RPPracticeExtResponsePicker";
import RPPatientExtResponsePicker from "../FormControls/RPPatientExtResponsePicker";
import RPLmnPicker from "../FormControls/RPLmnPicker";
import EditIcon from "@mui/icons-material/Edit";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CurrentRxIconButton from "../StandardComponents/CurrentRxIconButton";
import MediaShowDialog from "../PagePartials/MediaShowDialog";
import AttachmentListCard from "../PagePartials/AttachmentListCard";
import CommentFeed from "../StandardComponents/CommentFeed";

const ScriptDocIcon = ({ curDoc, onClickProblem, onClickDoc }) => {
  if (!curDoc) {
    return (
      <Tooltip title="No script document!">
        <IconButton onClick={onClickProblem}>
          <ReportProblemIcon color="error" />
        </IconButton>
      </Tooltip>
    );
  }
  return <CurrentRxIconButton onClick={onClickDoc} />;
};

/**
 * Returns the current script attachment
 *
 * @param {Array<{tags: string[]}>} attachList
 * @returns {{
 *   id: number,
 *   mime: string,
 *   size: number,
 *   tags: string[],
 *   title: string
 * }|undefined}
 */
const findCurrentScript = (attachList) =>
  attachList.find((a) => a.tags.includes("_rx") && a.tags.includes("_current"));

const RxCardSubheader = ({ pageData }) => {
  const name = [
    pageData.attribution.rxer_name_first,
    pageData.attribution.rxer_name_last,
    pageData.attribution.rxer_credential,
  ]
    .filter((x) => !!x)
    .join(" ");
  return (
    <InternalLink to={`/prescribers/${pageData.rxer.npi}`}>{name}</InternalLink>
  );
};

const ptnExtResponseForm = [
  {
    name: "ext_ptn_res",
    label: "Response",
    component: RPPatientExtResponsePicker,
    width: 6,
  },
];

const pracExtResponseForm = (api, therapy) => [
  {
    name: "ext_prac_res",
    label: "Response",
    component: RPPracticeExtResponsePicker,
    width: 6,
  },
  {
    name: "cur_lmn",
    label: "LMN Template",
    component: RPLmnPicker,
    width: 6,
    extraProps: { api, therapy },
  },
];

const PageRx = ({ pageData, api, refresh }) => {
  const navigate = useNavigate();

  const {
    open: rxDocOpen,
    openFn: handleRxDocOpen,
    closeFn: handleRxDocClose,
  } = useDialogState();

  const {
    open: rxShowDocOpen,
    openFn: handleRxShowDocOpen,
    closeFn: handleRxShowDocClose,
  } = useDialogState();

  const [ptnExtOpen, setPtnExtOpen] = useState(false);
  const handlePtnExtOpen = () => setPtnExtOpen(true);
  const handlePtnExtClose = () => setPtnExtOpen(false);

  const [pracExtOpen, setPracExtOpen] = useState(false);
  const handlePracExtOpen = () => setPracExtOpen(true);
  const handlePracExtClose = () => setPracExtOpen(false);

  const [extendFormOpen, setExtendFormOpen] = useState(false);
  const handleOpenExtendForm = () => setExtendFormOpen(true);
  const handleCloseExtendForm = () => setExtendFormOpen(false);

  const {
    open: dateRecOpen,
    openFn: handleDateRecOpen,
    closeFn: handleDateRecClose,
  } = useDialogState();

  const generation = pageData.generation > 0 ? "Extension" : "Initial";

  const curRxDoc = findCurrentScript(pageData.attachments);

  const splitButton = (
    <SplitButton
      optsAndClicks={{
        "Update Date Received": handleDateRecOpen,
        "Replace Rx Document": handleRxDocOpen,
        "Patient Ext Response": handlePtnExtOpen,
        "Practice Ext Response": handlePracExtOpen,
      }}
      // disabled={pageData.has_been_extended}
    />
  );

  return (
    <>
      <Grid2 container columnSpacing={2} rowSpacing={2}>
        <Grid2 xs={12}>
          <PatientSummaryCard navCtx={pageData.nav} actionArea={splitButton} />
        </Grid2>

        {!curRxDoc && (
          <Grid2 xs={12}>
            <Alert
              severity="error"
              action={<Button onClick={handleRxDocOpen}>Fix</Button>}
            >
              <AlertTitle>No Supporting Document</AlertTitle>
              This script has no supporting documentation! This is something
              that needs to be fixed right away!
            </Alert>
          </Grid2>
        )}

        <Grid2 xs={7}>
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar sx={{ width: 36, height: 36 }}>
                  <FontAwesomeIcon icon={faPrescription} />
                </Avatar>
              }
              title={`${pageData.therapy.toUpperCase()} — ${
                pageData.duration
              } days`}
              subheader={<RxCardSubheader pageData={pageData} />}
              action={
                <Stack direction="row" spacing={1}>
                  <SurgicalIcon surgical={pageData.surgical} />
                  <IconButton disabled>
                    <EditIcon />
                  </IconButton>
                </Stack>
              }
            />
            <CardContent>
              <KeyValueList
                kvData={{
                  Generation: generation,
                  "Script Document": (
                    <ScriptDocIcon
                      curDoc={curRxDoc}
                      onClickProblem={handleRxDocOpen}
                      onClickDoc={handleRxShowDocOpen}
                    />
                  ),
                  "Date Received": pageData.date_received,
                  "Extendability Lock": pageData.ext_el_locked_on
                    ? "yes"
                    : "no",
                  "Extended?": pageData.has_been_extended ? "yes" : "no",
                }}
              />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 xs={5}>
          <Stack direction="column" spacing={2}>
            <AttributionCard
              agency_id={pageData.agency_id}
              agency_name={pageData.attribution.agency_name}
              sales_rep_id={pageData.sales_rep_id}
              sales_rep_name={pageData.attribution.sales_rep_name}
              service_rep_id={pageData.service_rep_id}
              service_rep_name={pageData.attribution.service_rep_name}
            />
            <EligibilityCard
              rx={pageData}
              onExtendClick={handleOpenExtendForm}
            />
          </Stack>
        </Grid2>
        <Grid2 xs={12}>
          <CommentFeed api={api} type="treatment" id={pageData.treat_id} />
        </Grid2>
        <Grid2 xs={12}>
          <AttachmentListCard
            editable
            attachmentData={pageData.attachments}
            refresh={refresh}
            api={api}
            targetEntity="prescriptions"
            targetId={pageData.id}
          />
        </Grid2>
      </Grid2>
      <StdFormDialog
        open={ptnExtOpen}
        onClose={handlePtnExtClose}
        title="Patient Extension Response"
        fields={ptnExtResponseForm}
        submitCall={(data) => api.ptnExtResponse(pageData.id, data)}
        onComplete={() => {
          handlePtnExtClose();
          refresh();
        }}
      />
      <StdFormDialog
        open={pracExtOpen}
        onClose={handlePracExtClose}
        title="Practice Extension Response"
        fields={pracExtResponseForm(api, pageData.therapy)}
        submitCall={(data) => api.pracExtResponse(pageData.id, data)}
        onComplete={() => {
          handlePracExtClose();
          refresh();
        }}
      />
      <StdFormDialog
        open={dateRecOpen}
        onClose={handleDateRecClose}
        title="Update Date Received"
        fields={[
          {
            label: "Date Received",
            name: "date_received",
            component: RPDateInput,
            width: 3,
          },
        ]}
        submitCall={(data) =>
          api.updateDateReceived(pageData.id, reformatDate(data.date_received))
        }
        onComplete={() => {
          handleDateRecClose();
          refresh();
        }}
      />
      <StdFormDialog
        open={extendFormOpen}
        onClose={handleCloseExtendForm}
        title="Extend Treatment"
        fields={[
          {
            label: "Date Received",
            name: "date_received",
            component: RPDateInput,
            width: 3,
          },
          {
            label: "Extension Duration (in days)",
            name: "duration",
            component: TextField,
            width: 3,
          },
        ]}
        initialVals={{
          date_received: nowDate(),
          duration: pageData.duration,
        }}
        submitCall={(data) => {
          const payload = {
            duration: parseInt(data.duration, 10),
            date_received: reformatDate(data.date_received),
          };
          return api.extendTreatment(pageData.treat_id, payload);
        }}
        onComplete={() => {
          handleCloseExtendForm();
          navigate(`/patients/${pageData.eoc_id}`);
        }}
      />
      <AttachScriptDialog
        open={rxDocOpen}
        onClose={handleRxDocClose}
        api={api}
        refresh={refresh}
        initialVals={{
          id: pageData.id,
          rxer_can_generate: pageData.rxer_can_generate,
        }}
      />
      <MediaShowDialog
        open={rxShowDocOpen}
        onClose={handleRxShowDocClose}
        api={api}
        attachment={curRxDoc}
      />
    </>
  );
};

export default PageRx;
