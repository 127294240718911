import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LinkEoc from "../../StandardComponents/Link/LinkEoc";
import { InternalLink } from "../../StandardComponents/InternalLink";

const DEFAULT_TITLE = "Serialized Items";

const NoItemsContent = () => (
  <Box>
    <Typography>No Items In Inventory</Typography>
  </Box>
);

const ItemsTableRow = ({ item }) => (
  <TableRow key={item.id}>
    <TableCell>
      <InternalLink to={`/units/${item.id}`}>{item.serial_num}</InternalLink>
    </TableCell>
    <TableCell>{item.item_name}</TableCell>
    <TableCell>{item.status}</TableCell>
    <TableCell>
      {item.eoc_id ? (
        <LinkEoc
          id={item.eoc_id}
          name_last={item.name_last}
          name_first={item.name_first}
          copyBtn={false}
        />
      ) : null}
    </TableCell>
  </TableRow>
);

const ItemsTable = ({ itemsList }) => (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Serial</TableCell>
          <TableCell>Item</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Patient</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {itemsList.map((i) => (
          <ItemsTableRow key={i.id} item={i} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const SerialListCard = ({ cardTitle, serialItemList, ...rest }) => {
  const resolvedCardTitle = cardTitle || DEFAULT_TITLE;

  const amtWithPatient = serialItemList.reduce(
    (acc, cur) => acc + +(cur.status === "with-patient"),
    0
  );

  const amtAvailable = serialItemList.reduce(
    (acc, cur) => acc + +(cur.status === "available"),
    0
  );

  const utilizationPerc =
    (amtWithPatient / (amtWithPatient + amtAvailable)) * 100;

  return (
    <Card {...rest}>
      <CardHeader
        title={resolvedCardTitle}
        subheader={`Utilization: ${utilizationPerc.toFixed(
          1
        )}% ( with-patient / (with-patient + available) )`}
      />
      <CardContent>
        {serialItemList.length > 0 ? (
          <ItemsTable itemsList={serialItemList} />
        ) : (
          <NoItemsContent />
        )}
      </CardContent>
    </Card>
  );
};

export default SerialListCard;
