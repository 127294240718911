import StandardDialog from "../StandardComponents/StandardDialog";
import AttachDownloadButton from "../StandardComponents/AttachDownloadButton";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";

const DownloadLabelsDialog = ({ initialVals, api, open, onClose, refresh }) => {
  const filename = `${initialVals.eoc.name_last.toLowerCase()}-${initialVals.eoc.name_first.toLowerCase()}.pdf`;
  return (
    <StandardDialog open={open} onClose={onClose}>
      <Grid2 container columnSpacing={2}>
        <Grid2 xs={6}>
          <AttachDownloadButton
            api={api}
            attachId={initialVals.outbound_attach_id}
            filename={`outbound-${filename}`}
            Button={Button}
            buttonProps={{
              children: "Download Outbound Label",
              fullWidth: true,
              variant: "outlined",
              sx: { height: "100px" },
            }}
          />
        </Grid2>
        <Grid2 xs={6}>
          <AttachDownloadButton
            api={api}
            attachId={initialVals.inbound_attach_id}
            filename={`inbound-${filename}`}
            Button={Button}
            buttonProps={{
              children: "Download Return Label",
              fullWidth: true,
              variant: "outlined",
              sx: { height: "100px" },
            }}
          />
        </Grid2>
      </Grid2>
    </StandardDialog>
  );
};

export default DownloadLabelsDialog;
