import { useEffect, useState } from "react";
import { Alert, alpha, CardMedia, CircularProgress } from "@mui/material";

const SignatureInline = ({ height, s3BucketStr, alt, api }) => {
  const [state, setState] = useState({
    requesting: false,
    error: "",
    url: null,
  });

  useEffect(() => {
    if (!s3BucketStr) return;
    if (
      state.requesting === false &&
      state.error === "" &&
      state.url === null
    ) {
      const bits = s3BucketStr.split("/");
      const key = bits[3];
      setState({ requesting: true, error: "", url: null });
      api
        .getSignature(key)
        .then((resp) => {
          const src = URL.createObjectURL(resp);
          setState({ requesting: false, error: "", url: src });
        })
        .catch((err) => {
          setState({ requesting: false, error: err.message, url: null });
        });
    }
  }, [state.requesting, state.error, state.url, api, s3BucketStr]);

  if (!s3BucketStr) return null;

  if (state.requesting === true && state.url === null) {
    return <CircularProgress />;
  }

  if (state.error) {
    return <Alert severity="error">{state.error}</Alert>;
  }

  return (
    <CardMedia
      sx={{
        height,
        backgroundSize: "contain",
        backgroundColor: (theme) => alpha(theme.palette.common.white, 0.25),
      }}
      image={state.url}
      title={alt}
    />
  );
};

export default SignatureInline;
