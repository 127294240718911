import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo } from "react";
import { prodCatSort, productionStringsFromScores } from "../../../utility";
import { InternalLink } from "../../StandardComponents/InternalLink";
import BleederGainerInlineStat from "../../StandardComponents/BleederGainerInlineStat";

const AgencyTabPanelPrescribers = ({ activeTab, reps, prescribers }) => {
  const orderedInfo = useMemo(() => {
    const sortedReps = [...reps].sort(prodCatSort);

    const finalReps = sortedReps.map((rep) => {
      const repsRxers = prescribers.filter((rxer) => rxer.rep_id === rep.id);
      repsRxers.sort(prodCatSort);
      rep.rxers = repsRxers;
      return rep;
    });

    return finalReps;
  }, [reps, prescribers]);

  if (activeTab !== "prescribers") return null;

  return (
    <Stack direction="column" spacing={2}>
      {orderedInfo
        .filter((r) => r.rxers.length > 0)
        .map((r) => {
          const [score, trend] = productionStringsFromScores(
            r.bg_current,
            r.bg_baseline,
            true
          );
          return (
            <Card key={r.id} variant="outlined">
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "transparent" }}>
                    <BleederGainerInlineStat
                      currentScore={r.bg_current}
                      baselineScore={r.bg_baseline}
                      age={r.bg_age}
                      size="large"
                    />
                  </Avatar>
                }
                title={`${r.name}'s Prescribers`}
                titleTypographyProps={{ variant: "h6" }}
                subheader={
                  <>
                    {score} ({trend})
                  </>
                }
              />
              <CardContent>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Prescriber</TableCell>
                      <TableCell sx={{ width: "160px" }}>Production</TableCell>
                      <TableCell sx={{ width: "100px" }}>Channel</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {r.rxers.map((p) => {
                      const [score, trend, gain] = productionStringsFromScores(
                        p.bg_current,
                        p.bg_baseline,
                        true
                      );

                      return (
                        <TableRow key={p.npi}>
                          <TableCell>
                            <Stack direction="row" spacing={1}>
                              <BleederGainerInlineStat
                                currentScore={p.bg_current}
                                baselineScore={p.bg_baseline}
                                age={p.bg_age}
                              />
                              <InternalLink to={`/prescribers/${p.npi}`}>
                                {p.name_first} {p.name_last} {p.credential}
                              </InternalLink>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {gain !== null ? (
                              <Stack direction="row" spacing={1}>
                                <Box sx={{ width: "100%" }}>{score}</Box>
                                <Box>{trend}</Box>
                              </Stack>
                            ) : null}
                          </TableCell>
                          <TableCell>{p.service_channel}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          );
        })}
    </Stack>
  );
};

export default AgencyTabPanelPrescribers;
