import { useState } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import ValidateTargetsButton from "./PageMassTexter/ValidateTargetsButton";
import CurrentTargetsList from "./PageMassTexter/CurrentTargetsList";
import SubmitAndProgress from "./PageMassTexter/SubmitAndProgress";

const PageMassTexter = ({ api }) => {
  const [submitState, setSubmitState] = useState("idle");
  const [currentMessage, setCurrentMessage] = useState("");
  const [targetsState, setTargetsState] = useState([]);
  const [targetsClean, setTargetsClean] = useState("");
  const [targetsCurrent, setTargetsCurrent] = useState("");

  const handleTargetChange = (e) => {
    setTargetsCurrent(e.target.value);
  };
  const handleCurrentMessageChange = (e) => {
    setCurrentMessage(e.target.value);
  };
  const handleTargetChangeState = (phone, newState, errMsg) => {
    console.log("state change", phone, newState);
    setTargetsState((prev) => {
      const target = prev.find((t) => t.phone === phone);
      if (!target) throw new Error("Finished target not found in list!");
      target.status = newState;
      target.errMsg = errMsg;
      if (newState === "success" || newState === "error") {
        const newTarget = prev.find((t) => t.status === "idle");
        if (!newTarget) {
          setSubmitState("finished");
        } else {
          newTarget.status = "progress";
        }
      }
      return [...prev];
    });
  };

  const isDirty = targetsCurrent !== targetsClean;
  const gtg =
    !isDirty &&
    currentMessage.length > 0 &&
    currentMessage.length < 1024 &&
    targetsState.length > 0;

  return (
    <Stack spacing={2}>
      <Typography variant="h4">Mass Text Tool</Typography>
      <SubmitAndProgress
        onSubmit={() => {
          setSubmitState("progress");
          setTargetsState((prev) => {
            const newState = [...prev];
            newState[0].status = "progress";
            return newState;
          });
        }}
        targetsState={targetsState}
        goodToGo={gtg}
        msgLength={currentMessage.length}
        isSubmitting={submitState === "progress"}
        isDone={submitState === "finished"}
        onReset={() => {
          setSubmitState("idle");
          setCurrentMessage("");
          setTargetsState([]);
          setTargetsClean("");
          setTargetsCurrent("");
        }}
      />{" "}
      <Stack spacing={2} direction="row">
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <TextField
              multiline
              disabled={submitState !== "idle"}
              value={currentMessage}
              onChange={handleCurrentMessageChange}
              placeholder="Enter message body..."
              minRows={2}
              maxRows={5}
              InputProps={{
                sx: {
                  fontFamily: "monospace",
                  fontSize: "smaller",
                  p: 1,
                },
              }}
              helperText={`Characters: ${currentMessage.length}`}
            />
            <ValidateTargetsButton
              api={api}
              disabled={!isDirty}
              onNewVerified={(newText, reps) => {
                setTargetsClean(newText);
                setTargetsCurrent(newText);
                const parsed = newText
                  .split("\n")
                  .filter((l) => !!l)
                  .map((l) => {
                    const phone = l.substring(0, 12).replaceAll("-", "");
                    return {
                      phone,
                      rep: reps.find((r) => r.phone === phone),
                      status: "idle",
                      errMsg: null,
                    };
                  });
                setTargetsState(parsed);
              }}
              targetsCurrent={targetsCurrent}
            />
            <TextField
              multiline
              disabled={submitState !== "idle"}
              value={targetsCurrent}
              onChange={handleTargetChange}
              placeholder="Enter target phone numbers here..."
              minRows={4}
              maxRows={25}
              error={isDirty}
              InputProps={{
                sx: {
                  fontFamily: "monospace",
                  fontSize: "smaller",
                  p: 1,
                },
              }}
            />
          </Stack>
        </Box>
        <Box sx={{ width: "425px" }}>
          <CurrentTargetsList
            targetsParsed={targetsState}
            onTargetChangeState={handleTargetChangeState}
            api={api}
            message={currentMessage}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default PageMassTexter;
