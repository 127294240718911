import StdFormDialog from "../PagePartials/StdFormDialog";
import RPDateInput from "../FormControls/RPDateInput";
import { nowDate, reformatDate } from "../../utility";

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  const FIELDS = [
    {
      name: "service_start_on",
      label: "Start of Service",
      component: RPDateInput,
      width: 6,
    },
  ];

  const initialVal = initialVals.service_start_on || nowDate();

  return (
    <StdFormDialog
      open={open}
      onClose={onClose}
      title="Set Service Start"
      onComplete={() => {
        onClose();
        refresh();
      }}
      fields={FIELDS}
      submitCall={(data) => {
        const payload = {
          service_start_on: reformatDate(data.service_start_on),
        };
        return api.srvcSetStart(initialVals.id, payload);
      }}
      initialVals={{ service_start_on: initialVal }}
    />
  );
};
