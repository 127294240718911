import { useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { demoSubheader } from "../../utility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinkEoc from "../StandardComponents/Link/LinkEoc";
import LinkRx from "../StandardComponents/Link/LinkRx";
import LinkOrd from "../StandardComponents/Link/LinkOrd";
import LinkSrvc from "../StandardComponents/Link/LinkSrvc";
import LinkRxer from "../StandardComponents/Link/LinkRxer";
import { useNavigate } from "react-router-dom";
import GuideChip from "../StandardComponents/GuideChip";
import TicketContextMenu from "./Tickets/TicketContextMenu";

const LINK_MAP = {
  eoc: LinkEoc,
  rx: LinkRx,
  ord: LinkOrd,
  srvc: LinkSrvc,
  rxer: LinkRxer,
};

const NavContext = ({ navCtx }) => {
  const [key, idStr] = navCtx.target.split("-");
  const id = parseInt(idStr, 10);
  const onEoc = key === "eoc";
  const ctx = onEoc
    ? { ...navCtx[key], alt: true }
    : navCtx[key].find((x) => x.id === id);

  const LinkComp = LINK_MAP[key];

  return (
    <Box>
      <Typography variant="h6">
        <LinkComp {...ctx} />
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
          fontWeight: "bold",
        }}
      >
        {LinkComp.subheader(ctx)}
      </Typography>
    </Box>
  );
};

const navCtxToMenuItemData = (navCtx) => {
  const items = [];

  items.push({ ...navCtx.eoc, type: "eoc" });
  navCtx.rx.forEach((rx) => items.push({ ...rx, type: "rx" }));
  navCtx.ord.forEach((ord) => items.push({ ...ord, type: "ord" }));
  navCtx.srvc.forEach((srvc) => items.push({ ...srvc, type: "srvc" }));
  navCtx.rxer.forEach((rxer) => items.push({ ...rxer, type: "rxer" }));

  const navItems = items.map((i) => {
    const url = LINK_MAP[i.type].url(i.id);
    const baseLabel = LINK_MAP[i.type].lblPriByCtx({ ...i });
    const subhead = LINK_MAP[i.type].subheader({ ...i });
    return [url, baseLabel, LINK_MAP[i.type].icon, subhead];
  });

  return navItems;
};

const PatientSummaryCard = ({
  navCtx,
  actionArea,
  api,
  taskContext = null,
}) => {
  const navigate = useNavigate();

  const [menuOpenEl, setMenuOpenEl] = useState(null);
  const menuOpen = !!menuOpenEl;

  const subheader = demoSubheader(navCtx.eoc.date_of_birth, navCtx.eoc.gender);
  const birthYear = navCtx.eoc.date_of_birth.substring(6);

  const menuData = navCtxToMenuItemData(navCtx);

  return (
    <Paper variant="outlined" sx={{ py: 2, pl: 2, pr: 1 }}>
      <Grid2 container columns={24} rowSpacing={1}>
        <Grid2 xs={11}>
          <Typography variant="h5">
            <LinkEoc
              id={navCtx.eoc.id}
              name_first={navCtx.eoc.name_first}
              name_last={navCtx.eoc.name_last}
            />
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            <Tooltip
              title={navCtx.eoc.date_of_birth}
              placement="left-start"
              arrow
            >
              <Box
                component="span"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    `https://www.wikipedia.org/wiki/${birthYear}`,
                    "_blank"
                  );
                }}
              >
                {subheader}
              </Box>
            </Tooltip>
          </Typography>
        </Grid2>

        <Grid2 xs={13}>
          <Stack
            direction="row-reverse"
            spacing={0}
            sx={{ alignItems: "flex-start" }}
          >
            <IconButton onClick={(e) => setMenuOpenEl(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
            {!!taskContext && (
              <TicketContextMenu api={api} context={taskContext} />
            )}
            <Box sx={{ mr: 1 }} />
            {actionArea}
            <Box sx={{ mr: 2 }} />
            <NavContext navCtx={navCtx} />
            <Menu
              open={menuOpen}
              anchorEl={menuOpenEl}
              onClose={() => setMenuOpenEl(null)}
              sx={{ minWidth: "300px" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {menuData.map(([url, label, Icon, secondary]) => (
                <MenuItem
                  onClick={() => navigate(url)}
                  key={url}
                  sx={{ minWidth: "300px" }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Icon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={label} secondary={secondary} />
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Grid2>
        <Grid2 xs={24}>
          <Divider orientation="horizontal" sx={{ cursor: "default" }}>
            <Tooltip title="Spirit Guides" placement="top" arrow>
              <span>Guides</span>
            </Tooltip>
          </Divider>
        </Grid2>
        <Grid2 xs={6} sx={{ textAlign: "center" }}>
          <GuideChip guide={navCtx.guides.patient} type="Patient Guide" />
        </Grid2>
        <Grid2 xs={6} sx={{ textAlign: "center" }}>
          <GuideChip guide={navCtx.guides.practice} type="Practice Guide" />
        </Grid2>
        <Grid2 xs={6} sx={{ textAlign: "center" }}>
          <GuideChip guide={navCtx.guides.biller} type="Billing Guide" />
        </Grid2>
        <Grid2 xs={6} sx={{ textAlign: "center" }}>
          <GuideChip guide={navCtx.guides.sales} type="Sales Guide" />
        </Grid2>
      </Grid2>
    </Paper>
  );
};

export default PatientSummaryCard;
