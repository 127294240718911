import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { icd10Fmt } from "../../../formatters";

const Icd10PickerListEmpty = () => (
  <Paper sx={{ p: 2, mb: 1, minHeight: 77 }}>
    <Typography>
      <ArrowUpwardIcon
        sx={{
          verticalAlign: "middle",
          fontSize: 42,
          color: "grey.500",
          mr: 3,
        }}
      />{" "}
      Add ICD-10 codes using the control above.
    </Typography>
  </Paper>
);

const Icd10PickerList = ({ codes, remCodeFn, selCodeFn }) => {
  const len = codes.size === undefined ? codes.length : codes.size;
  if (len === 0) return <Icd10PickerListEmpty />;

  return (
    <List dense disablePadding sx={{ minHeight: 85 }}>
      {codes.map((c) => {
        return (
          <ListItem
            disablePadding
            sx={{ pr: 8 }}
            dense
            key={c.code}
            secondaryAction={
              <IconButton onClick={() => remCodeFn(c)}>
                <RemoveCircleIcon />
              </IconButton>
            }
          >
            <ListItemButton disableRipple onClick={() => selCodeFn(c)}>
              <ListItemIcon>
                <Checkbox edge="start" checked={c.diag} disableRipple />
              </ListItemIcon>
              <ListItemText
                primary={icd10Fmt(c.code)}
                secondary={c.description}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default Icd10PickerList;
