import { useForm } from "react-hook-form";
import { useEffect } from "react";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import StandardDialog from "../../StandardComponents/StandardDialog";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import { Button } from "@mui/material";
import { TREAT_SITES } from "../../../utility";
import { fmtInjurySite } from "../../../formatters";
import Grid2 from "@mui/material/Unstable_Grid2";
import RHFCheckboxSimple from "../../FormControlsRHF/RHFCheckboxSimple";

const genDefaultValues = (treatSites) => {
  const siteOpts = TREAT_SITES.map((ts) => [
    ts.replace("-", "_"),
    treatSites.includes(ts),
  ]);
  return Object.fromEntries(siteOpts);
};

const TreatSiteFormLayout = ({ control }) => {
  const gridList = [];

  TREAT_SITES.forEach((ts, i) => {
    const col = gridList.length % 3;
    if (col === 2 && (ts.includes("left") || ts.includes("right"))) {
      gridList.push(<Grid2 xs={4} key={i} />);
    }

    gridList.push(
      <Grid2 xs={4} key={ts}>
        <RHFCheckboxSimple
          control={control}
          label={fmtInjurySite(ts)}
          name={ts.replace("-", "_")}
        />
      </Grid2>
    );
  });

  return gridList;
};

const TreatSiteUpdateFormDialog = ({
  open,
  onClose,
  api,
  refresh,
  eocId,
  treatSites,
}) => {
  const defaultValues = genDefaultValues(treatSites);

  const { handleSubmit, control, setError, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(genDefaultValues(treatSites));
  }, [reset, treatSites]);

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      refresh();
      onClose();
    }
  );

  const onSubmit = (data) => {
    const payload = Object.entries(data).reduce((acc, [ts, active]) => {
      if (active) acc.push(ts.replace("_", "-"));
      return acc;
    }, []);
    apiWrapper(api.updateTreatmentSites(eocId, { treatment_sites: payload }));
  };

  const handleOnClose = () => {
    onClose();
    reset();
    clearFormError();
  };

  return (
    <StandardDialog
      open={open}
      onClose={handleOnClose}
      title="Update Treatment Sites"
    >
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container>
          <TreatSiteFormLayout control={control} />
          <Grid2 xs={12} sx={{ pt: 3, textAlign: "right" }}>
            <Button
              type="submit"
              variant="contained"
              disabled={formErrState.submitting}
            >
              Update Treatment Sites
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default TreatSiteUpdateFormDialog;
