import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPLmnPicker = forwardRef(({ therapy, api, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acLmns(therapy)}
    />
  );
});

RPLmnPicker.defaultValue = null;

export default RPLmnPicker;
