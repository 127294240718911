import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const dateTimeStrToHumanDate = (dateTimeStr) => {
  const dateStr = dateTimeStr.substring(0, 10);
  const [year, month, day] = dateStr.split("-");
  return `${month}/${day}/${year}`;
};

const GeneratedBillList = ({ billList, showBillFn }) => {
  if (billList.length === 0) {
    return null;
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title="Generated Bills"
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell sx={{ width: "100px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billList.map((b) => (
              <TableRow key={b.id}>
                <TableCell>{`INV-${b.id
                  .toString()
                  .padStart(4, "0")}`}</TableCell>
                <TableCell>{b.format.toUpperCase()}</TableCell>
                <TableCell>{dateTimeStrToHumanDate(b.created_on)}</TableCell>
                <TableCell>
                  <Button size="small" onClick={() => showBillFn(b.id)}>
                    Show
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default GeneratedBillList;
