import { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import SplitButton from "../StandardComponents/SplitButton";
import StdFormDialog from "../PagePartials/StdFormDialog";
import LocationCard from "./PagePractice/LocationCard";
import PrescriberCard from "./PagePractice/PrescriberCard";
import ContactCard from "./PagePractice/ContactCard";
import RPLocationPicker from "../FormControls/RPLocationPicker";
import RPRepPicker from "../FormControls/RPRepPicker";
import RPContactPicker from "../FormControls/RPContactPicker";
import RPPrefContMethodPicker from "../FormControls/RPPrefContMethodPicker";
import RPContactTypePicker from "../FormControls/RPContactTypePicker";
import RPPhoneNumberInput from "../FormControls/RPPhoneNumberInput";
import RPStatePicker from "../FormControls/RPStatePicker";
import { npiDataMeddle } from "../../utility";
import StdConfirmSubmitDialog from "../PagePartials/StdConfirmSubmitDialog";
import TicketContextMenu from "../PagePartials/Tickets/TicketContextMenu";

const npiSearchForm = [{ name: "npi", component: TextField, label: "NPI" }];

const pracUpdateForm = [
  { name: "name", component: TextField, label: "Practice Name" },
];

const locUpdateForm = [
  { name: "name", component: TextField, label: "Location Name" },
  { name: "addr_street1", component: TextField, label: "Street 1", width: 8 },
  { name: "addr_street2", component: TextField, label: "Street 2", width: 4 },
  { name: "addr_city", component: TextField, label: "City", width: 5 },
  { name: "addr_state", component: RPStatePicker, label: "State", width: 4 },
  { name: "addr_zip", component: TextField, label: "Zip", width: 3 },
  {
    name: "main_phone",
    component: RPPhoneNumberInput,
    label: "Phone",
    width: 6,
  },
  { name: "main_fax", component: RPPhoneNumberInput, label: "Fax", width: 6 },
];

const contactForm = [
  { name: "name_first", component: TextField, label: "First Name", width: 6 },
  { name: "name_last", component: TextField, label: "Last Name", width: 6 },
  {
    name: "type",
    component: RPContactTypePicker,
    label: "Contact Type",
    width: 6,
  },
  {
    name: "pref_cont_method",
    component: RPPrefContMethodPicker,
    label: "Preferred Contact Method",
    width: 6,
  },
  { name: "phone", component: RPPhoneNumberInput, label: "Phone", width: 6 },
  { name: "fax", component: RPPhoneNumberInput, label: "Fax", width: 6 },
  { name: "email", component: TextField, label: "Email", width: 8 },
];

const PagePractice = ({ pageData, refresh, api }) => {
  const [locToEdit, setLocToEdit] = useState(null);
  const handleLocEditReqOpen = (loc) => setLocToEdit({ ...loc });
  const handleLocEditReqClose = () => setLocToEdit(null);

  const [newContactFormOpen, setNewContactFormOpen] = useState(false);
  const handleNewContactFormOpen = () => setNewContactFormOpen(true);
  const handleNewContactFormClose = () => setNewContactFormOpen(false);

  const [contactToUpdate, setContactUpdateFormOpen] = useState(null);
  const handleContactUpdateFormOpen = (cont) => setContactUpdateFormOpen(cont);
  const handleContactUpdateFormClose = () => setContactUpdateFormOpen(null);

  const [newLocFormOpen, setNewFormLocOpen] = useState(false);
  const handleNewLocFormOpen = () => setNewFormLocOpen(true);
  const handleNewLocFormClose = () => setNewFormLocOpen(false);

  const [pracUpdateFormOpen, setPracUpdateFormOpen] = useState(false);
  const handlePracUpdateFormOpen = () => setPracUpdateFormOpen(true);
  const handlePracUpdateFormClose = () => setPracUpdateFormOpen(false);

  const [npiSearchFormOpen, setNpiSearchFormOpen] = useState(false);
  const handleNpiSearchFormOpen = () => setNpiSearchFormOpen(true);
  const handleNpiSearchFormClose = () => setNpiSearchFormOpen(false);

  const [npiSearchResult, setNpiSearchResult] = useState(null);

  const [prescToEdit, setPrescToEdit] = useState(null);
  const handlePrescToEditReqOpen = (presc) => setPrescToEdit({ ...presc });
  const handlePrescToEditReqClose = () => setPrescToEdit(null);

  const [makePrimaryOpen, setMakePrimaryOpen] = useState(null);
  const handleMakePrimaryOpen = (loc) => setMakePrimaryOpen(loc);
  const handleMakePrimaryClose = () => setMakePrimaryOpen(null);

  const prescForm = [
    { name: "name_first", component: TextField, label: "First Name", width: 4 },
    { name: "name_last", component: TextField, label: "Last Name", width: 4 },
    { name: "credential", component: TextField, label: "Credential", width: 4 },
    {
      name: "prac_loc_id",
      component: RPLocationPicker,
      label: "Location",
      width: 6,
      extraProps: { practiceId: pageData.id, api },
    },
    {
      name: "rep_id",
      component: RPRepPicker,
      label: "Assigned Rep",
      width: 6,
      extraProps: { api },
    },
  ];

  const prescEditForm = [
    { name: "name_first", component: TextField, label: "First Name", width: 4 },
    { name: "name_last", component: TextField, label: "Last Name", width: 4 },
    { name: "credential", component: TextField, label: "Credential", width: 4 },
    {
      name: "prac_loc_id",
      component: RPLocationPicker,
      label: "Location",
      width: 6,
      extraProps: { practiceId: pageData.id, api },
    },
    {
      name: "contact_id",
      component: RPContactPicker,
      label: "Primary Contact",
      width: 6,
      extraProps: { practiceId: pageData.id, api },
    },
  ];

  const splitBtnOpts = {
    "Add Contact": () => handleNewContactFormOpen(),
    "Add Location": () => handleNewLocFormOpen(),
    "Add Prescriber": () => handleNpiSearchFormOpen(),
  };

  const practTitle =
    pageData.name === "NOMS" ? `${pageData.name} 🍪` : pageData.name;

  return (
    <>
      <BackLinkHeader title="Practice List" to="../" />
      <Box sx={{ display: "flex", mb: 4 }}>
        <Typography variant="h5">{practTitle}</Typography>
        <Box>
          <IconButton
            size="small"
            sx={{ ml: 1 }}
            onClick={handlePracUpdateFormOpen}
          >
            <ModeEditIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={1}>
          <SplitButton optsAndClicks={splitBtnOpts} />
          <TicketContextMenu
            api={api}
            context={{
              type: "practices",
              ident: pageData.id,
              label: pageData.name,
            }}
          />
        </Stack>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
            Prescribers
          </Typography>
          <Stack>
            {pageData.prescribers.map((p) => (
              <PrescriberCard
                key={p.npi}
                presc={p}
                conts={pageData.contacts}
                locs={pageData.locations}
                onEditRequest={handlePrescToEditReqOpen}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
            Locations
          </Typography>
          {pageData.locations.map((l) => (
            <LocationCard
              key={l.id}
              loc={l}
              onRequestEdit={handleLocEditReqOpen}
              onMakePrimary={handleMakePrimaryOpen}
            />
          ))}
          <Typography component="h2" variant="h6" sx={{ mb: 1 }}>
            Contacts
          </Typography>
          {pageData.contacts.map((c) => (
            <ContactCard
              key={c.id}
              contact={c}
              onRequestEdit={handleContactUpdateFormOpen}
            />
          ))}
        </Grid>
      </Grid>
      <StdFormDialog
        title="Update Practice Name"
        fields={pracUpdateForm}
        onClose={handlePracUpdateFormClose}
        open={pracUpdateFormOpen}
        initialVals={{ name: pageData.name }}
        submitCall={(data) => {
          return api.updatePractice(pageData.id, data);
        }}
        onComplete={() => {
          refresh();
          handlePracUpdateFormClose();
        }}
      />
      <StdFormDialog
        title="Add Location"
        fields={locUpdateForm}
        onClose={handleNewLocFormClose}
        open={newLocFormOpen}
        submitCall={(data) => {
          return api.addLocation(pageData.id, data);
        }}
        onComplete={() => {
          refresh();
          handleNewLocFormClose();
        }}
      />
      <StdFormDialog
        title="Update Location"
        fields={locUpdateForm}
        onClose={handleLocEditReqClose}
        open={locToEdit !== null}
        initialVals={locToEdit}
        submitCall={(data) => {
          return api.updateLocation(locToEdit.id, data);
        }}
        onComplete={() => {
          refresh();
          handleLocEditReqClose();
        }}
      />
      <StdFormDialog
        title="Add Contact"
        fields={contactForm}
        onClose={handleNewContactFormClose}
        open={newContactFormOpen}
        submitCall={(data) => {
          return api.newContact(pageData.id, data);
        }}
        onComplete={() => {
          refresh();
          handleNewContactFormClose();
        }}
      />
      <StdFormDialog
        title="Edit Contact"
        fields={contactForm}
        onClose={handleContactUpdateFormClose}
        open={!!contactToUpdate}
        initialVals={contactToUpdate}
        submitCall={(data) => api.updateContact(contactToUpdate.id, data)}
        onComplete={() => {
          refresh();
          handleContactUpdateFormClose();
        }}
      />
      <StdFormDialog
        title="Add Prescriber"
        fields={npiSearchForm}
        onClose={handleNpiSearchFormClose}
        open={npiSearchFormOpen}
        submitCall={(data) => {
          return api.npiSearch(data.npi);
        }}
        onComplete={(result) => {
          setNpiSearchResult(npiDataMeddle(result.data));
          handleNpiSearchFormClose();
        }}
      />
      <StdFormDialog
        title="Add Prescriber"
        fields={prescForm}
        onClose={() => setNpiSearchResult(null)}
        open={!!npiSearchResult}
        initialVals={npiSearchResult}
        submitCall={(data) => {
          const s = npiSearchResult;
          const sdata = { ...data, npi: s.npi, gender: s.gender };
          return api.addPrescriber(sdata);
        }}
        onComplete={() => {
          refresh();
          setNpiSearchResult(null);
        }}
      />
      <StdFormDialog
        title="Edit Prescriber"
        fields={prescEditForm}
        onClose={handlePrescToEditReqClose}
        open={!!prescToEdit}
        initialVals={prescToEdit}
        submitCall={(data) => {
          return api.updatePrescriber(prescToEdit.npi, data);
        }}
        onComplete={() => {
          refresh();
          handlePrescToEditReqClose();
        }}
      />
      <StdConfirmSubmitDialog
        handleClose={handleMakePrimaryClose}
        title="Make location primary?"
        open={!!makePrimaryOpen}
        onSubmit={() => {
          return api.setLocationPrimary(makePrimaryOpen);
        }}
        onComplete={() => {
          refresh();
          handleMakePrimaryClose();
        }}
      >
        This will make the clicked-on location the primary location for this
        practice.
      </StdConfirmSubmitDialog>
    </>
  );
};

export default PagePractice;
