import useSWR from "swr";
import { BUCKET_LIST, sectionByKey } from "../../../buckets";
import { useEffect, useState } from "react";
import { Alert, Typography } from "@mui/material";
import BucketCard from "./BucketCard";

const BucketTabLoader = ({
  api,
  selectedKey,
  sectionKey,
  bucketKey,
  tabStateChangeFn,
  bucketSpec,
  filterState,
}) => {
  const bucket = BUCKET_LIST[bucketKey];
  const [filterCol, setFilterCol] = useState(null);

  const { data, error, isLoading } = useSWR(
    `buckets-${bucketKey}`,
    async (key) => {
      const bits = key.split("-");
      bits.shift();
      const res = await api.getBucketData(bits.join("-"));
      if (res.status !== "ok") {
        throw new Error(res.message);
      }
      return res.data;
    }
  );

  useEffect(() => {
    const section = sectionByKey(sectionKey);
    const filterOpts = section?.filterBuilderFn
      ? section.filterBuilderFn(data)
      : null;

    setFilterCol(filterOpts?.matchingCol || null);

    tabStateChangeFn(bucketKey, error, isLoading, data?.length, filterOpts);
  }, [
    sectionKey,
    tabStateChangeFn,
    bucketKey,
    error,
    isLoading,
    data?.length,
    data,
  ]);

  if (selectedKey !== bucketKey) {
    return null;
  }

  if (isLoading && !data) return <Typography>Loading...</Typography>;
  if (error && !data) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  let filteredData = data;
  if (filterState && filterCol) {
    filteredData = data.filter((r) => {
      return filterState === r[filterCol].toString();
    });
  }

  return (
    <BucketCard title={bucket.title} spec={bucketSpec} data={filteredData} />
  );
};

export default BucketTabLoader;
