import SectionHeading from "../PagePartials/SectionHeading";
import IntakeAttributionInsights from "./PageHome/IntakeAttributionInsights";
import { Stack } from "@mui/material";
import BleederGainerCard from "../StandardComponents/BleederGainerCard";

const PageHome = ({ api }) => {
  return (
    <>
      <SectionHeading headingLabel="Dashboard" />
      <Stack direction="column" spacing={3}>
        <BleederGainerCard
          api={api}
          entity="users"
          ident={0}
          title="WRS Production"
        />
        <IntakeAttributionInsights api={api} />
      </Stack>
    </>
  );
};

export default PageHome;
