import { useMemo, useState } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useDialogState from "../../HOC/useDialogState";
import SectionHeading from "../PagePartials/SectionHeading";
import ReportFilterDialog from "./PageReports/ReportFilterDialog";
import FolderIcon from "@mui/icons-material/Folder";
import TableIcon from "../StandardComponents/Icons/TableIcon";

const PageReports = ({ pageData, api, refresh }) => {
  const categories = useMemo(() => {
    const c = new Set(pageData.map((r) => r.category));
    return [...c.values()];
  }, [pageData]);
  const defaultTab = categories[0];

  const [curTab, setCurTab] = useState(defaultTab);
  const handleTabChange = (e, newVal) => setCurTab(newVal);

  const {
    open: filterModalData,
    openFn: filterModalHandleOpen,
    closeFn: filterModalHandleClose,
  } = useDialogState(false);
  const filterModalOpen = !!filterModalData;

  const reportsInCurrentCategory = pageData.filter(
    (r) => r.category.toLowerCase() === curTab.toLowerCase()
  );

  return (
    <>
      <Grid2 container>
        <Grid2 xs={12}>
          <SectionHeading headingLabel="Reports" />
        </Grid2>
        <Grid2 xs={2}>
          <Tabs
            orientation="vertical"
            sx={{ height: "100%", borderRight: 1, borderColor: "divider" }}
            value={curTab}
            onChange={handleTabChange}
          >
            {categories.map((c, i) => (
              <Tab
                key={c}
                index={i}
                value={c}
                label={c}
                icon={<FolderIcon />}
                iconPosition="start"
                sx={{ justifyContent: "left" }}
              />
            ))}
          </Tabs>
        </Grid2>
        <Grid2 xs={10}>
          <List dense>
            {reportsInCurrentCategory.map((r) => {
              return (
                <ListItem key={r.name}>
                  <ListItemButton onClick={() => filterModalHandleOpen(r)}>
                    <ListItemAvatar>
                      <Avatar>
                        <TableIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={r.label} secondary={r.desc} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid2>
      </Grid2>
      {filterModalOpen && (
        <ReportFilterDialog
          open={filterModalOpen}
          onClose={filterModalHandleClose}
          reportConfig={filterModalData}
          api={api}
          refresh={refresh}
        />
      )}
    </>
  );
};

export default PageReports;
