import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  dateObjFormatToAnnArborDateTime,
  genNumberToHumanReadable,
} from "../../../utility";
import { InternalLink } from "../../StandardComponents/InternalLink";

const PrescriberTabPanelPatients = ({ activeTab, patients }) => {
  if (activeTab !== "patients") return null;

  if (patients.length === 0) {
    return <Alert severity="info">No scripts for this prescriber.</Alert>;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Therapy</TableCell>
          <TableCell>Generation</TableCell>
          <TableCell>Accepted On</TableCell>
          <TableCell>Rep</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {patients.map((p) => {
          const patientName = (
            <InternalLink to={`/patients/${p.eoc_id}`}>
              {[p.name_first, p.name_last].filter(Boolean).join(" ")}
            </InternalLink>
          );

          const therapy = `${p.therapy.toUpperCase()} - ${
            p.surgical ? "Surgical" : "Non-Op"
          }`;

          const gen = genNumberToHumanReadable(p.generation);
          const createdOn = dateObjFormatToAnnArborDateTime(
            new Date(p.created_on),
            false
          );

          const rep = (
            <>
              <InternalLink to={`/reps/${p.rep_id}`}>{p.rep_name}</InternalLink>{" "}
              from{" "}
              <InternalLink to={`/agencies/${p.agency_id}`}>
                {p.agency_name}
              </InternalLink>
            </>
          );

          return (
            <TableRow key={p.id}>
              <TableCell>{patientName}</TableCell>
              <TableCell>{therapy}</TableCell>
              <TableCell>{gen}</TableCell>
              <TableCell>{createdOn}</TableCell>
              <TableCell>{rep}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default PrescriberTabPanelPatients;
