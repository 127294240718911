import { Stack } from "@mui/material";
import OrderForm from "./OrderForm";
import GeneratedBillList from "./GeneratedBillList";

const OrderTabLines = ({ activeTab, orderId, api, billList, showBillFn }) => {
  if (activeTab !== "lines") return null;

  return (
    <Stack spacing={2}>
      <OrderForm
        api={api}
        orderId={orderId}
        hasBeenBilled={!!billList.length}
      />
      <GeneratedBillList
        billList={billList}
        showBillFn={(billId) => showBillFn(billId)}
      />
    </Stack>
  );
};

export default OrderTabLines;
