import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { InternalLink } from "../../../StandardComponents/InternalLink";

/**
 * @param {Array<{
 *   mfgr_id: number,
 *   mfgr_name: string,
 *   item_id: number,
 *   item_name: string,
 *   item_model_num: string,
 *   qty: number,
 * }>} consumables
 * @param {number} agencyId
 */
const ConsumablesSummaryTable = ({ agencyId, consumables }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Consumable</TableCell>
          <TableCell>Model Number</TableCell>
          <TableCell>Manufacturer</TableCell>
          <TableCell>In Stock</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {consumables.map((c) => {
          return (
            <TableRow key={c.item_id}>
              <TableCell>{c.item_name}</TableCell>
              <TableCell>{c.item_model_num}</TableCell>
              <TableCell>{c.mfgr_name}</TableCell>
              <TableCell>{c.qty}</TableCell>
              <TableCell>
                <InternalLink to={`/agencies/${agencyId}/items/${c.item_id}`}>
                  History
                </InternalLink>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ConsumablesSummaryTable;
