import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor } from "@fortawesome/pro-regular-svg-icons";
import { SvgIcon } from "@mui/material";

const RxerIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <FontAwesomeIcon icon={faUserDoctor} />
    </SvgIcon>
  );
};

export default RxerIcon;
