import { Paper, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const DiagnosisContent = ({ Icon, success, text }) => {
  const sx = {
    verticalAlign: "middle",
    fontSize: 42,
    mr: 3,
  };

  if (!success) {
    sx.color = "grey.500";
  }

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center",
        minHeight: 80,
      }}
    >
      <Icon color={success ? "success" : undefined} sx={sx} />
      <Typography>{text}</Typography>
    </Paper>
  );
};

const Icd10Diagnosis = ({ codes }) => {
  const diagnosisPick = codes.find((c) => c.diag === true);
  const codesLen = codes.length === undefined ? codes.size : codes.length;

  if (!diagnosisPick || codesLen === 0) {
    const helpText =
      codesLen === 0
        ? "Add ICD-10 codes using the control above."
        : "Pick ICD-10 code from list to indicate primary Diagnosis.";
    return <DiagnosisContent Icon={ArrowUpwardIcon} text={helpText} />;
  }

  return (
    <DiagnosisContent
      Icon={DoneIcon}
      text={diagnosisPick.description}
      success
    />
  );
};

export default Icd10Diagnosis;
