import { useController } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@mui/material";

const RHFSwitchField = ({
  name,
  label,
  control,
  ignoreError = false,
  rules = undefined,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });

  const helperText = ignoreError ? "" : error?.message ?? " ";

  return (
    <FormControl margin="dense" size="small">
      <FormControlLabel
        control={
          <Switch
            name={field.name}
            onBlur={field.onBlur}
            inputRef={field.ref}
            onChange={field.onChange}
            checked={field.value}
            {...rest}
          />
        }
        label={label}
      />
      <FormHelperText variant="outlined" error={!!error}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default RHFSwitchField;
