import SkullIcon from "./Icons/SkullIcon";
import FireIcon from "./Icons/FireIcon";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Box, Tooltip } from "@mui/material";
import BabyIcon from "./Icons/BabyIcon";
import {
  productionCategoryFromScores,
  productionStringsFromScores,
} from "../../utility";

const BleederGainerInlineStat = ({
  currentScore,
  baselineScore,
  age,
  size = "small",
}) => {
  const productionCat = productionCategoryFromScores(
    currentScore,
    baselineScore,
    age
  );

  if (productionCat === "baby") {
    return (
      <Tooltip title="No scripts yet!" arrow placement="left">
        <BabyIcon fontSize={size} sx={{ p: "2px" }} color="info" />
      </Tooltip>
    );
  }

  if (productionCat === "dead") {
    return (
      <Tooltip title="No scripts in last 90 days" arrow placement="left">
        <SkullIcon fontSize={size} sx={{ p: "2px", color: "text.secondary" }} />
      </Tooltip>
    );
  }

  if (productionCat === "unknown") {
    return (
      <Tooltip title="No scripts in the system" arrow placement="left">
        <QuestionMarkIcon fontSize={size} color="secondary" />
      </Tooltip>
    );
  }

  const [scoreStr, gainFormatted] = productionStringsFromScores(
    currentScore,
    baselineScore
  );

  if (productionCat === "fire") {
    return (
      <Tooltip
        title={
          <Box sx={{ whiteSpace: "pre-line" }}>
            Production: {scoreStr}
            {"\n"}
            Trend: {gainFormatted}
            {"\n"}
            ON FIRE!!
          </Box>
        }
        arrow
        placement="left"
      >
        <FireIcon fontSize={size} color="warning" sx={{ p: "2px" }} />
      </Tooltip>
    );
  }

  if (productionCat === "rising") {
    return (
      <Tooltip
        title={
          <Box sx={{ whiteSpace: "pre-line" }}>
            Production: {scoreStr}
            {"\n"}
            Trend: {gainFormatted}
          </Box>
        }
        arrow
        placement="left"
      >
        <TrendingUpIcon fontSize={size} color="success" />
      </Tooltip>
    );
  }

  if (productionCat === "falling") {
    return (
      <Tooltip
        title={
          <Box sx={{ whiteSpace: "pre-line" }}>
            Production: {scoreStr}
            {"\n"}
            Trend: {gainFormatted}
          </Box>
        }
        arrow
        placement="left"
      >
        <TrendingDownIcon fontSize={size} color="error" />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        <Box sx={{ whiteSpace: "pre-line" }}>
          Production: {scoreStr}
          {"\n"}
          Trend: {gainFormatted}
        </Box>
      }
      arrow
      placement="left"
    >
      <TrendingFlatIcon fontSize={size} color="warning" />
    </Tooltip>
  );
};

export default BleederGainerInlineStat;
