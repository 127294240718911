import { forwardRef } from "react";
import RPStatePicker from "../FormControls/RPStatePicker";

const NStateField = forwardRef(({ fieldRecord, updater }, ref) => {
  return (
    <RPStatePicker
      name={fieldRecord.name}
      label={fieldRecord.label}
      helperText={fieldRecord.errstr}
      error={fieldRecord.error}
      onChange={(newVal) => updater(fieldRecord.name, newVal)}
      value={fieldRecord.value || null}
      ref={ref}
      size="small"
      fullWidth
    />
  );
});

export default NStateField;
