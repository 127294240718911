import { InternalLink } from "../Components/StandardComponents/InternalLink";

export const achievementSection = {
  title: "Achievements",
  key: "sec-achievements",
  buckets: ["achv-log"],
};

export const achievementLog = [
  "achv-log",
  {
    title: "Achievement Log",
    description: "",
    columns: [
      {
        label: "Who",
        cell: (r) => (
          <InternalLink to={`/${r.entity}/${r.entity_id}`}>
            {r.entity_label}
          </InternalLink>
        ),
      },
      {
        label: "What",
        cell: (r) => r.what,
      },
      {
        label: "When",
        cell: (r) => r.when,
      },
      {
        label: "Context",
        cell: (r) => (
          <InternalLink to={`/${r.context_entity}/${r.context_id}`}>
            {r.context_label}
          </InternalLink>
        ),
      },
    ],
  },
];
