import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPAllPayorPicker = forwardRef(({ api, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acAllPayors()}
    />
  );
});

RPAllPayorPicker.defaultValue = null;

export default RPAllPayorPicker;
