import { useController } from "react-hook-form";
import { getCurrencyMaskGenerator, useWebMask } from "react-hook-mask";
import { TextField } from "@mui/material";
import { basicTextFieldProps } from "./_defaults";
import { forwardRef } from "react";
import composeRefs from "@seznam/compose-react-refs/composeRefs";

const maskGenerator = getCurrencyMaskGenerator({
  prefix: "$",
  thousandSeparator: ",",
  centsSeparator: ".",
});

const RHFMoneyField = forwardRef(
  ({ name, control, rules = undefined, ...rest }, outerRef) => {
    const {
      field,
      fieldState: { error },
    } = useController({ name, control, rules });

    field.value = field.value.toString();

    const {
      value,
      onChange,
      ref: maskRef,
    } = useWebMask({
      maskGenerator,
      value: field.value,
      onChange: field.onChange,
      keepMask: rest.keepMask,
    });

    return (
      <TextField
        name={field.name}
        value={value}
        inputRef={composeRefs(outerRef, field.ref, maskRef)}
        onBlur={field.onBlur}
        onChange={onChange}
        error={!!error}
        helperText={error?.message ?? " "}
        {...basicTextFieldProps}
        {...rest}
      />
    );
  }
);

export default RHFMoneyField;
