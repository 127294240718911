import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";

const IntakeAttributionTable = ({ api }) => {
  const [currentPath, setCurrentPath] = useState([]);
  const [computed, setComputed] = useState(false);
  const [salesData, setSalesData] = useState(["init", {}]);
  const [status, data] = salesData;
  const [date, setDate] = useState(new Date());
  const [prevMonthDisabled, setPrevMonthDisabled] = useState(false);
  const [nextMonthDisabled, setNextMonthDisabled] = useState(true);

  useEffect(() => {
    let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    if (date.getMonth() === 5 && date.getFullYear() === 2023)
      setPrevMonthDisabled(true);
    else if (prevMonthDisabled) setPrevMonthDisabled(false);

    if (
      date.getMonth() === new Date().getMonth() &&
      date.getFullYear() === new Date().getFullYear()
    )
      setNextMonthDisabled(true);
    else if (nextMonthDisabled) setNextMonthDisabled(false);

    // format dates to 'YYYY-MM-DD'
    startDate = `${startDate.getFullYear()}-${(
      "0" +
      (startDate.getMonth() + 1)
    ).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;
    endDate = `${endDate.getFullYear()}-${(
      "0" +
      (endDate.getMonth() + 1)
    ).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;

    if (status === "init") {
      api
        .getSalesAttributionInsights(startDate, endDate)
        .then((d) => {
          setSalesData(["loaded", d]);
        })
        .catch((error) => {
          setSalesData(["error", error]);
        });

      setSalesData(["loading", {}]);
    }
  }, [
    salesData,
    api,
    setSalesData,
    status,
    date,
    prevMonthDisabled,
    nextMonthDisabled,
  ]);

  // Define an array for the months
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const reset = () => {
    setSalesData(["init", {}]);
    setComputed(false);
  };

  const handlePrevMonth = () => {
    setDate(
      (prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1)
    );
    reset();
  };

  const handleNextMonth = () => {
    setDate(
      (prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1)
    );
    reset();
  };

  const getFormattedDate = () => {
    let currentYear = date.getFullYear();
    let currentMonth = months[date.getMonth()];
    return `${currentMonth} ${currentYear}`;
  };

  const computeAndCacheTotals = useCallback((node) => {
    if (!node || typeof node !== "object")
      return { accepted: 0, rejected: 0, incomplete: 0, total: 0 };

    if (
      node.hasOwnProperty("accepted") &&
      node.hasOwnProperty("rejected") &&
      node.hasOwnProperty("incomplete")
    ) {
      node.total = node.accepted + node.rejected + node.incomplete;
      return {
        accepted: node.accepted,
        rejected: node.rejected,
        incomplete: node.incomplete,
        total: node.total,
      };
    }

    let accepted = 0;
    let rejected = 0;
    let incomplete = 0;

    for (const key in node) {
      const childTotals = computeAndCacheTotals(node[key]);
      accepted += childTotals.accepted;
      rejected += childTotals.rejected;
      incomplete += childTotals.incomplete;
    }

    node.total = {
      accepted,
      rejected,
      incomplete,
      total: accepted + rejected + incomplete,
    };

    return node.total;
  }, []);

  useEffect(() => {
    if (status === "loaded") {
      computeAndCacheTotals(data.data);
      setComputed(true);
    }
  }, [data, status, setComputed, computeAndCacheTotals]);

  if (!computed) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const navigateTo = (path) => {
    setCurrentPath(path);
  };

  const getNestedData = (data, path) => {
    return path.reduce(
      (obj, key) => (obj && obj[key] && key !== "status" ? obj[key] : null),
      data
    );
  };

  const getDataToDisplay = () => {
    const nestedData = getNestedData(data.data, currentPath);
    if (!nestedData) return [];
    return Object.entries(nestedData);
  };

  const handleRowClick = (name) => {
    setCurrentPath([...currentPath, name]);
  };

  const handleBreadcrumbClick = (index) => {
    setCurrentPath(currentPath.slice(0, index + 1));
  };

  const prescriberLink = (name, row) => {
    return <InternalLink to={`/prescribers/${row.npi}`}>{name}</InternalLink>;
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={2}
      >
        <Button
          variant="outlined"
          size="small"
          disabled={prevMonthDisabled}
          onClick={handlePrevMonth}
        >
          &lt;
        </Button>
        <Typography variant="h6" component="div" marginLeft={2} marginRight={2}>
          {getFormattedDate()}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          disabled={nextMonthDisabled}
          onClick={handleNextMonth}
        >
          &gt;
        </Button>
      </Box>
      <div>
        {currentPath.length === 0 ? (
          <Button disabled>National</Button>
        ) : (
          <Button onClick={() => navigateTo([])}>National</Button>
        )}
        {currentPath.map((part, index) => (
          <Button
            disabled={currentPath.length === index + 1}
            key={index}
            onClick={() => handleBreadcrumbClick(index)}
          >
            {part}
          </Button>
        ))}
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Rejected</TableCell>
              <TableCell align="right">Incomplete</TableCell>
              <TableCell align="right">Accepted</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getDataToDisplay().map(([name, data]) => {
              const totalRow = name === "total" ? true : false;
              const npi = data?.npi ? data.npi : undefined;

              const leaf = !Object.values(data).some(
                (value) => typeof value === "object" && value !== null
              );

              const rejected =
                totalRow || typeof data.rejected === "number"
                  ? data.rejected
                  : data.total.rejected;
              const incomplete =
                totalRow || typeof data.incomplete === "number"
                  ? data.incomplete
                  : data.total.incomplete;
              const accepted =
                totalRow || typeof data.accepted === "number"
                  ? data.accepted
                  : data.total.accepted;

              const style = !(totalRow || leaf) ? { cursor: "pointer" } : {};

              let label = name === "total" ? "Total" : name;
              if (npi) label = prescriberLink(name, data);

              return (
                <TableRow
                  key={name}
                  hover={!(totalRow || leaf)}
                  onClick={() => !(totalRow || leaf) && handleRowClick(name)}
                  style={style}
                >
                  <TableCell component="th" scope="row">
                    {label}
                  </TableCell>
                  <TableCell align="right">
                    {typeof data === "number" ? data : rejected}
                  </TableCell>
                  <TableCell align="right">
                    {typeof data === "number" ? data : incomplete}
                  </TableCell>
                  <TableCell align="right">
                    {typeof data === "number" ? data : accepted}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default IntakeAttributionTable;
