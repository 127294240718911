import { useState } from "react";
import {
  Alert,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import SectionHeading from "../PagePartials/SectionHeading";
import KeyValueList from "../PagePartials/KeyValueList";
import TransferOrderStepper from "./PageTransferOrder/TransferOrderStepper";
import SplitButton from "../StandardComponents/SplitButton";
import StdConfirmSubmitDialog from "../PagePartials/StdConfirmSubmitDialog";
import { dateObjFormatToSmallest } from "../../utility";
import ItemNameWithIcon from "./PageTransferOrders/ItemNameWithIcon";
import TableCellEditLineItem from "./PageTransferOrder/TableCellEditLineItem";
import TableCellLineItemField from "./PageTransferOrder/TableCellLineItemField";

const PageTransferOrder = ({ pageData, api, refresh }) => {
  const navigate = useNavigate();

  const [associatedSerial, setAssociatedSerial] = useState(null);

  const [submitErrorMsg, setSubmitErrorMsg] = useState("");

  const [markApprovedDialogOpen, setMarkApprovedDialogOpen] = useState(false);
  const handleOpenApprovedDialog = () => setMarkApprovedDialogOpen(true);
  const handleCloseApprovedDialog = () => setMarkApprovedDialogOpen(false);

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const handleOpenCancelDialog = () => setCancelDialogOpen(true);
  const handleCloseCancelDialog = () => setCancelDialogOpen(false);

  const [completeDialogOpen, setCompleteDialogOpen] = useState(false);
  const handleOpenCompleteDialog = () => setCompleteDialogOpen(true);
  const handleCloseCompleteDialog = () => setCompleteDialogOpen(false);

  const [toLineEditable, setToLineEditable] = useState(null);
  const handleSetToLineEditable = (toLine) => {
    setToLineEditable(toLine);
  };
  const handleClearToLineEditable = () => {
    setToLineEditable(null);
    setAssociatedSerial(null);
  };

  const toLabel = `TO-${pageData.id.toString().padStart(4, "0")}`;

  const toData = {
    Origin: pageData.from_agnc_name,
    Recipient: pageData.to_agnc_name,
    Status: pageData.status,
    "Created On": dateObjFormatToSmallest(pageData.created_on),
    "Received On": dateObjFormatToSmallest(pageData.received_on),
  };

  const buttonOptsByStatus = (status) => {
    const splitBtnOpts = {
      "Approve Transfer": handleOpenApprovedDialog,
      "Complete Transfer": handleOpenCompleteDialog,
      "Cancel Transfer": handleOpenCancelDialog,
    };

    if (status === "open") {
      delete splitBtnOpts["Complete Transfer"];
    }

    if (status === "in-progress") {
      delete splitBtnOpts["Approve Transfer"];
    }

    if (status === "closed") {
      return {};
    }

    return splitBtnOpts;
  };

  const handleSetToLineUpdate = () => {
    api
      .assocSerialWithToLine(pageData.id, toLineEditable.id, associatedSerial)
      .then((resp) => {
        if (resp.status === "ok") {
          handleClearToLineEditable();
          setSubmitErrorMsg("");
          refresh();
          return;
        }
        setSubmitErrorMsg(resp.message);
      })
      .catch((err) => {
        setSubmitErrorMsg(err.message);
      });
  };

  return (
    <>
      <BackLinkHeader title="Back to Transfers List" to="/transfer-orders" />
      <SectionHeading
        headingLabel={`Transfer Order ${toLabel}`}
        buttonEl={
          <SplitButton optsAndClicks={buttonOptsByStatus(pageData.status)} />
        }
      />

      <Grid container columnSpacing={4} rowSpacing={4}>
        <Grid item xs={12}>
          <TransferOrderStepper status={pageData.status} />
        </Grid>
        <Grid item xs={6}>
          <KeyValueList kvData={toData} boldedKeys />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          {submitErrorMsg && (
            <Alert sx={{ mb: 2 }} color="error">
              {submitErrorMsg}
            </Alert>
          )}
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Part</TableCell>
                  <TableCell sx={{ width: "250px" }}>Serial / Qty</TableCell>
                  <TableCell sx={{ width: "100px" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageData.lines.map((l) => (
                  <TableRow key={l.id}>
                    <TableCell>
                      <ItemNameWithIcon
                        name={l.item_name}
                        item_type={l.item_type}
                      />
                    </TableCell>
                    <TableCell>{l.item_model_num}</TableCell>
                    <TableCellLineItemField
                      li={l}
                      liEditable={toLineEditable}
                      api={api}
                      agencyId={pageData.from_agnc_id}
                      value={associatedSerial}
                      onChange={(newVal) => setAssociatedSerial(newVal)}
                    />
                    <TableCellEditLineItem
                      li={l}
                      liEditable={toLineEditable}
                      onMakeLineEditable={handleSetToLineEditable}
                      onClearLineEditable={handleClearToLineEditable}
                      onAcceptChange={handleSetToLineUpdate}
                      hidden={pageData.status === "closed"}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <StdConfirmSubmitDialog
        open={markApprovedDialogOpen}
        handleClose={handleCloseApprovedDialog}
        title="Approve Transfer"
        onSubmit={() => api.approveTo(pageData.id)}
        onComplete={() => {
          refresh();
          handleCloseApprovedDialog();
        }}
      >
        Approving a transfer will set it's status to "In Progress". This will
        also cause the inventory in the transfer to be shown as "incoming" in
        the recipient's inventory screen.
      </StdConfirmSubmitDialog>
      <StdConfirmSubmitDialog
        open={cancelDialogOpen}
        handleClose={handleCloseCancelDialog}
        title="Cancel Transfer"
        onSubmit={() => api.cancelTo(pageData.id)}
        onComplete={() => {
          navigate("/transfer-orders");
        }}
      >
        Throw this transfer away completely? Everything done so far will be
        reversed.
      </StdConfirmSubmitDialog>
      <StdConfirmSubmitDialog
        open={completeDialogOpen}
        handleClose={handleCloseCompleteDialog}
        title="Complete Transfer"
        onSubmit={() => api.completeTo(pageData.id)}
        onComplete={() => {
          refresh();
          handleCloseCompleteDialog();
        }}
      >
        Completing this transfer will move the inventory from the origin to the
        recipient.
      </StdConfirmSubmitDialog>
    </>
  );
};

export default PageTransferOrder;
