import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../picCrop";
import { apiCallSimplify } from "../../utility";
import ApiDataContext from "../../ApiDataContext";

const PageSettings = ({ api }) => {
  const { fullReload, me } = useContext(ApiDataContext);
  const [localUrl, setLocalUrl] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedPixels, setCroppedPixels] = useState(null);
  const [zoom, setZoom] = useState(1);

  const onImageSelect = (ev) => {
    const fileObj = ev.target.files[0];
    if (fileObj) {
      const url = URL.createObjectURL(fileObj);
      setLocalUrl(url);
    } else {
      setLocalUrl(null);
    }
  };

  const onCancelImage = () => {
    URL.revokeObjectURL(localUrl);
    setLocalUrl(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const onCropComplete = (_, c) => setCroppedPixels(c);

  const onAccept = async () => {
    try {
      let data = await getCroppedImg(localUrl, croppedPixels);
      data = data.substring(23);
      await apiCallSimplify(api.updateUserAvatar(data));
      fullReload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: 2 }}>
        User Settings
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <Avatar sx={{ height: "128px", width: "128px" }} src={me.picture} />
        <Button component="label" variant="contained">
          Change Profile Image
          <Input
            type="file"
            slotProps={{ input: { accept: "image/*" } }}
            onChange={onImageSelect}
            name="attachment"
            // styles taken from stack overflow
            sx={{
              clip: "rect(0 0 0 0)",
              clipPath: "inset(50%)",
              height: 1,
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
              left: 0,
              whiteSpace: "nowrap",
              width: 1,
            }}
          />
        </Button>
      </Stack>
      {localUrl && (
        <Dialog open={!!localUrl} maxWidth="md" fullWidth>
          <DialogContent sx={{ height: "600px", position: "relative" }}>
            <Cropper
              aspect={1}
              showGrid={false}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              crop={crop}
              zoom={zoom}
              onZoomChange={setZoom}
              image={localUrl}
            />
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => onCancelImage()}>
              Cancel
            </Button>
            <Box sx={{ flexGrow: 1 }}>
              <Slider
                onChange={(e, newVal) => setZoom(newVal)}
                min={1}
                max={3}
                step={0.1}
              />
            </Box>
            <Button onClick={onAccept}>Accept</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PageSettings;
