import { useForm } from "react-hook-form";
import { useEffect } from "react";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import StandardDialog from "../../StandardComponents/StandardDialog";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import { Button } from "@mui/material";
import RHFDateTimePicker from "../../FormControlsRHF/RHFDateTimePicker";
import { addDays } from "date-fns";

const genDefaultValues = (followUp) => {
  return { follow_up: followUp };
};

const TicketFollowUpFormDialog = ({
  open,
  onClose,
  onSuccess,
  api,
  followUp,
  ticketId,
}) => {
  const defaultValues = genDefaultValues(followUp);

  const { handleSubmit, control, setError, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(genDefaultValues(followUp));
  }, [reset, followUp]);

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      onSuccess();
      onClose();
    }
  );

  const onSubmit = (data) => {
    apiWrapper(api.updateTicketFollowUp(ticketId, data));
  };

  return (
    <StandardDialog
      open={open}
      onClose={onClose}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      title="Update Follow-Up Date"
      actions={
        <Button
          disabled={formErrState.submitting}
          type="submit"
          variant="contained"
        >
          Save Change
        </Button>
      }
    >
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <RHFDateTimePicker
        control={control}
        name="follow_up"
        pickerProps={{
          disablePast: true,
          maxDateTime: addDays(new Date(), 30),
        }}
      />
    </StandardDialog>
  );
};

export default TicketFollowUpFormDialog;
