import { useContext, useState } from "react";
import { withAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import SearchControl from "./SearchControl";
import NotificationPreviewer from "../../PagePartials/NotificationPreviewer";
import ApiDataContext from "../../../ApiDataContext";

const TopBar = ({ auth0, toggleThemeMode, api }) => {
  const { me } = useContext(ApiDataContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [acctMenuAnchorEl, setAcctMenuAnchorEl] = useState(null);

  const isAcctMenuOpen = Boolean(acctMenuAnchorEl);

  const handleAcctMenuOpen = (ev) => setAcctMenuAnchorEl(ev.currentTarget);
  const handleAcctMenuClose = () => setAcctMenuAnchorEl(null);

  const handleLogout = () => {
    auth0.logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL });
    handleAcctMenuClose();
  };

  const handleNavToSettings = () => {
    handleAcctMenuClose();
    navigate("/settings");
  };

  const appBarColor =
    process.env.NODE_ENV === "production" ? "primary" : "warning";

  const appBarTitle =
    process.env.NODE_ENV === "production"
      ? "Red Panda"
      : `Red Panda - ${process.env.REACT_APP_SENTRY_ENV}`;

  return (
    <>
      <AppBar
        position="fixed"
        color={appBarColor}
        sx={{ width: 1180, right: "auto" }}
      >
        <Toolbar>
          <Avatar
            alt="Logo"
            src="/red-panda.png"
            variant="square"
            sx={{ width: 32, height: 32, mr: 2 }}
          />
          <Typography
            variant="h6"
            color="inherit"
            component={RouterLink}
            to="/"
            sx={{ textDecoration: "none" }}
          >
            {appBarTitle}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <SearchControl api={api} />
          <NotificationPreviewer api={api} />
          <IconButton onClick={toggleThemeMode} color="inherit">
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
          <IconButton
            size="large"
            color="inherit"
            edge="end"
            disableRipple
            onClick={handleAcctMenuOpen}
          >
            <Avatar alt={me.name} src={me.picture} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Menu
        anchorEl={acctMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={isAcctMenuOpen}
        onClose={handleAcctMenuClose}
      >
        <MenuItem disabled>{auth0.user.name}</MenuItem>
        <MenuItem onClick={handleNavToSettings}>Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default withAuth0(TopBar);
