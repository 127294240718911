import { forwardRef, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

const NCheckboxField = forwardRef(({ fieldRecord, updater }, ref) => {
  const [checked, setChecked] = useState(fieldRecord.value);

  return (
    <FormControlLabel
      control={<Checkbox />}
      checked={checked}
      onChange={(e) => {
        setChecked(e.target.checked);
        updater(fieldRecord.name, e.target.checked);
      }}
      label={fieldRecord.label}
      inputRef={ref}
    />
  );
});

export default NCheckboxField;
