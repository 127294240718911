import useSWR from "swr";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/material";
import PurchaseOrderTable from "./PurchaseOrderTable";
import { useWatch } from "react-hook-form";
import { dateObjToDateStr } from "../../../utility";

const handleDateConvert = (val, key, list) => {
  if (!val) return;
  if (val.toString() === "Invalid Date") return;
  list.push(`${key}:${dateObjToDateStr(val)}`);
};

/**
 * @param {{
 *   agency_id: { value: number }|null,
 *   mfgr_id: { value: number }|null,
 *   status: {
 *     open: boolean,
 *     "in-progress": boolean,
 *     partial: boolean,
 *     cancelled: boolean,
 *     closed: boolean
 *   },
 *   approved_on: [Date|null, Date|null],
 *   created_on: [Date|null, Date|null]
 * }} filterState
 */
const filterStateToKey = (filterState) => {
  const statuses = [];
  Object.entries(filterState.status).forEach(
    ([n, v]) => !!v && statuses.push(n)
  );
  statuses.sort();
  const filters = [];
  if (filterState.agency_id)
    filters.push(`agency_id:${filterState.agency_id.value}`);
  if (filterState.mfgr_id) filters.push(`mfgr_id:${filterState.mfgr_id.value}`);
  handleDateConvert(filterState.received_on[0], "received_on_start", filters);
  handleDateConvert(filterState.received_on[1], "received_on_end", filters);
  handleDateConvert(filterState.created_on[0], "created_on_start", filters);
  handleDateConvert(filterState.created_on[1], "created_on_end", filters);
  if (statuses.length > 0) filters.push(`statuses:${statuses.join(",")}`);
  return "POLIST|" + filters.join("|");
};

const mkFetcher = (api) => async (key) => {
  const params = {};
  const parts = key.split("|");
  for (const part of parts) {
    if (part === "POLIST") continue;
    const [k, v] = part.split(":");
    if (k === "statuses") {
      params[k] = v.split(",");
    } else {
      params[k] = v;
    }
  }
  const response = await api.getPos(params);
  if (response.status === "ok") return response.data;
  throw new Error(response.message);
};

const PurchaseOrderLoader = ({ api, control }) => {
  const filterState = useWatch({ control });
  const key = filterStateToKey(filterState);

  const { isLoading, data, error } = useSWR(key, mkFetcher(api));

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (data.length === 0) {
    return <Alert severity="info">No results for the current filters.</Alert>;
  }

  return (
    <>
      {data.length >= 500 && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          Results limited to 500 entries.
        </Alert>
      )}
      <PurchaseOrderTable poList={data} />
    </>
  );
};

export default PurchaseOrderLoader;
