import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { surgeryStr } from "../../../utility";
import { fmtInjurySite, icd10Fmt } from "../../../formatters";
import HorizIconToolTip from "../../StandardComponents/HorizIconToolTip";

const SListItem = ({ label, val }) => {
  return (
    <ListItem sx={{ py: "2px" }}>
      <ListItemText primary={label} />
      <ListItemSecondaryAction>{val}</ListItemSecondaryAction>
    </ListItem>
  );
};

const DemoInjuryCard = ({
  date_of_surgery,
  surgical,
  date_of_injury,
  icd10s,
  treatment_sites,
  diagnosis,
}) => {
  return (
    <Paper sx={{ p: 2 }} variant="outlined">
      <Typography sx={{ fontSize: "larger", mb: 1 }}>Injury</Typography>
      <Divider />
      <List>
        <SListItem
          label="Surgery"
          val={surgeryStr(surgical, date_of_surgery)}
        />
        <SListItem
          label="Date of Injury"
          val={date_of_injury || "[NOT ENTERED]"}
        />
        <SListItem
          label="ICD-10(s)"
          val={
            <Box sx={{ width: "350px", textAlign: "right" }}>
              {icd10s.map((c) => (
                <HorizIconToolTip key={c.code} title={c.description}>
                  <Chip
                    size="small"
                    label={icd10Fmt(c.code)}
                    sx={{ ml: "2px" }}
                  />
                </HorizIconToolTip>
              ))}
            </Box>
          }
        />
        <SListItem
          label="Injury Site(s)"
          val={treatment_sites.map((i) => (
            <Chip
              sx={{ ml: 1 }}
              key={i}
              size="small"
              label={fmtInjurySite(i)}
            />
          ))}
        />
      </List>
      <Box sx={{ px: 2, pb: 1 }}>
        <Typography>Diagnosis</Typography>
        <Typography variant="body2" color="text.secondary">
          {diagnosis}
        </Typography>
      </Box>
    </Paper>
  );
};

export default DemoInjuryCard;
