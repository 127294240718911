import SectionHeading from "../PagePartials/SectionHeading";
import OnboardingsListCard from "./PagePrescriberOnboardings/OnboardingsListCard";
import SignaturesNeedingReviewCard from "./PagePrescriberOnboardings/SignaturesNeedingReviewCard";
import { Stack } from "@mui/material";

const PagePrescriberOnboardings = ({ pageData }) => {
  const onboardings = pageData.onboardings;
  const sigsReview = pageData.sigs_unacceptable.filter(
    (s) => s.erl_status === "needs-review"
  );
  const sigsSuspect = pageData.sigs_unacceptable.filter(
    (s) => s.erl_status === "suspect"
  );

  const reviewCount = sigsReview.length ? ` (${sigsReview.length})` : "";
  const verifyCount = sigsSuspect.length ? ` (${sigsSuspect.length})` : "";

  return (
    <>
      <SectionHeading headingLabel="Prescriber Onboarding" />
      <Stack direction="column" spacing={3}>
        <OnboardingsListCard onboardings={onboardings} />
        <SignaturesNeedingReviewCard
          title={`Signatures Needing Review${reviewCount}`}
          subheader="Signatures that need to be reviewed by a WRS team member."
          sigs={sigsReview}
        />
        <SignaturesNeedingReviewCard
          title={`Signatures Needing Additional Verification${verifyCount}`}
          subheader="Signatures that we need to verify with the submitting sales rep."
          sigs={sigsSuspect}
        />
      </Stack>
    </>
  );
};

export default PagePrescriberOnboardings;
