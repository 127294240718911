import { Avatar, Chip, Tooltip } from "@mui/material";
import { dateObjFormatToAnnArborDateTime } from "../../utility";

/**
 * @param {string} user_name
 * @param {string} user_pic
 * @param {Date} date
 */
const UserActionChip = ({ user_name, user_pic, date }) => {
  const dateFmt = dateObjFormatToAnnArborDateTime(date, false);
  return (
    <Chip
      variant="outlined"
      avatar={
        <Tooltip title={user_name} arrow placement="left-end">
          <Avatar alt={user_name} src={user_pic} />
        </Tooltip>
      }
      label={dateFmt}
    />
  );
};

export default UserActionChip;
