import { useEffect, useState } from "react";
import { List, Record } from "immutable";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import OrderFormEditRow from "./OrderFormEditRow";
import OrderFormViewRow from "./OrderFormViewRow";
import { reformatDate } from "../../../utility";
import { moneyFmt } from "../../../formatters";
import OrderCollectionsTable from "./OrderForm/OrderCollectionsTable";
import StdConfirmSubmitDialog from "../../PagePartials/StdConfirmSubmitDialog";
import useDialogState from "../../../HOC/useDialogState";

const PTN_NONDIG = new RegExp("[^0-9]", "g");

const LOADSTATE = {
  INIT: "init",
  LOADING: "loading",
  LOADED: "loaded",
  ERROR: "error",
};

const SUBMITSTATE = {
  IDLE: "idle",
  SUBMITTING: "submitting",
  ERROR: "error",
};

const CompData = Record({
  loadState: LOADSTATE.INIT,
  submitState: SUBMITSTATE.IDLE,
  srvData: null,
  srvError: "",
  orderDat: null,
  editInit: null,
});

const OrderLine = Record({
  id: null,
  description: "",
  dos_start: null,
  dos_end: null,
  hcpc: "",
  modifier: "",
  charge: 0,
  qty: 1,
  balance: 0,
  ar_ignored_on: null,
  ar_ignored_by: null,
  ar_ignored_user_name: null,
  ar_ignored_user_pic: null,
  place_of_service: "",
  icd10: "",
  commissionable: false,
  edit: false,
});

const initState = () => {
  const orderDat = List();
  return CompData({ orderDat });
};

const apiCallToCurState = (data) => {
  const list = new List();
  return list.withMutations((l) => {
    data.forEach((srvl) => {
      const olr = OrderLine(srvl);
      l.push(olr);
    });
  });
};

const onLoadSuccess = (prevState, srvData) => {
  return prevState.withMutations((s) => {
    const newState = s
      .set("loadState", LOADSTATE.LOADED)
      .set("srvError", "")
      .set("srvData", srvData)
      .set("orderDat", apiCallToCurState(srvData));
    return newState;
  });
};

const onLoadError = (prevState, errMsg) => {
  return prevState.withMutations((s) => {
    s.set("loadState", LOADSTATE.ERROR).set("srvError", errMsg);
  });
};

const getOrderDataFromServer = (api, orderId, onSuccessFn, onFailFn) => {
  api
    .getOrderLinesById(orderId)
    .then((resp) => {
      if (resp.status === "ok") {
        onSuccessFn(resp.data);
        return;
      }
      onFailFn(resp.message);
    })
    .catch((err) => onFailFn(err.message));
};

const LoadError = ({ state }) => {
  if (!state.srvError) return null;
  return (
    <CardContent>
      <Alert severity="error">{state.srvError}</Alert>
    </CardContent>
  );
};

/**
 * Parses the value from an input box
 *
 * @param {string} rawVal
 * @returns {number|null}
 */
const qtyIptFilter = (rawVal) => {
  const justDig = rawVal.replaceAll(PTN_NONDIG, "");
  if (justDig === "") return null;
  return parseInt(justDig, 10);
};

/**
 * Parses value from input box
 *
 * For the "charge" value (which is currency) the approach is to take the value coming from the
 * input box as a string into this function. This function then interprets an integer from that
 * and stores the integer value in our main model.
 *
 * It would be up to the controlled input to take that integer value and re-display it correctly
 * for the user after an onChange event.
 *
 * Null is allowed here to show that no value is in the input box.
 *
 * @param {string} rawVal
 * @returns {number|null}
 */
const priceIptFilter = (rawVal) => {
  const justDig = rawVal.replaceAll(PTN_NONDIG, "");
  if (justDig === "") return null;
  return parseInt(justDig, 10);
};

const iptFilters = {
  qty: qtyIptFilter,
  charge: priceIptFilter,
};

const cleanCommitPayload = (rawPayload) => {
  const payload = { ...rawPayload };
  payload.dos_start = reformatDate(payload.dos_start);
  payload.dos_end = reformatDate(payload.dos_end);
  return payload;
};

const commitLine = (api, orderId, onSuccessFn, onFailFn, state) => {
  const editOlIdx = state.orderDat.findIndex((ol) => ol.edit);
  if (editOlIdx === -1) return state;
  const ol = state.orderDat.get(editOlIdx);
  const payload = cleanCommitPayload(ol.toJS());
  api
    .commitOrderLine(orderId, payload)
    .then((resp) => {
      if (resp.status === "ok") {
        onSuccessFn(resp.data);
        return;
      }
      onFailFn(resp.message);
    })
    .catch((err) => onFailFn(err.message));

  return state.set("submitState", SUBMITSTATE.SUBMITTING);
};

const cancelLine = (state) => {
  const editOlIdx = state.orderDat.findIndex((ol) => ol.edit);
  if (editOlIdx === -1) return state;
  const resetState = state.set("editInit", null).set("srvError", "");
  if (state.editInit === false) {
    return resetState.deleteIn(["orderDat", editOlIdx]);
  }
  const newLines = resetState.orderDat.splice(editOlIdx, 1, state.editInit);
  return resetState.set("orderDat", newLines);
};

const hasCommLine = (state) =>
  state.orderDat.reduce((reduction, value) => {
    if (reduction) return true;
    return value.commissionable;
  }, false);

const onCommitSuccess = (prev) =>
  prev.set("submitState", SUBMITSTATE.IDLE).set("loadState", LOADSTATE.INIT);

const onCommitError = (prev, errMsg) =>
  prev.set("submitState", SUBMITSTATE.ERROR).set("srvError", errMsg);

const onDeleteSuccess = (prev) =>
  prev.set("submitState", SUBMITSTATE.IDLE).set("loadState", LOADSTATE.INIT);

const onDeleteError = (prev, errMsg) =>
  prev.set("submitState", SUBMITSTATE.ERROR).set("srvError", errMsg);

const deleteLine = (api, lineToDelete, onSuccessFn, onFailFn) => {
  api
    .delOrderLine(lineToDelete.id)
    .then((resp) => {
      if (resp.status === "ok") {
        onSuccessFn(resp.data);
        return;
      }
      onFailFn(resp.message);
    })
    .catch((err) => onFailFn(err.message));
};

const OrderForm = ({ api, orderId, hasBeenBilled = false }) => {
  const [state, setState] = useState(initState());
  const [collectionMode, setCollectionMode] = useState(hasBeenBilled);

  const { open, openFn, closeFn } = useDialogState(false);

  useEffect(() => {
    if (state.loadState === LOADSTATE.INIT) {
      getOrderDataFromServer(
        api,
        orderId,
        (data) => setState((p) => onLoadSuccess(p, data)),
        (errmsg) => setState((p) => onLoadError(p, errmsg))
      );
      setState(state.set("loadState", LOADSTATE.LOADING));
    }
  }, [api, state, orderId]);

  const hasEditRow = state.orderDat.reduce(
    (acc, val) => acc || val.edit,
    false
  );

  const orderTotal = state.orderDat.reduce(
    (acc, val) => acc + val.charge * val.qty,
    0
  );

  const handleDataChange = (line, field, value) => {
    setState((prev) => {
      const targetIdx = prev.orderDat.indexOf(line);
      const realVal = iptFilters[field] ? iptFilters[field](value) : value;
      return prev.updateIn(["orderDat", targetIdx, field], () => realVal);
    });
  };

  const showCommissionAlert = state.orderDat.size > 0 && !hasCommLine(state);

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title="Order Lines"
          titleTypographyProps={{ variant: "h6" }}
          action={
            <FormControlLabel
              control={
                <Switch
                  checked={collectionMode}
                  onChange={(e) => {
                    setCollectionMode(e.target.checked);
                  }}
                />
              }
              label="Collection View"
            />
          }
        />
        <LoadError state={state} />
        <CardContent>
          {showCommissionAlert && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <AlertTitle>Check Line Items!</AlertTitle> No line items on the
              order are marked as commissionable. You will not be allowed to
              generate a bill without one!
            </Alert>
          )}
          {!collectionMode ? (
            <Table size="small" sx={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow sx={{ "& th": { px: "1px" } }}>
                  <Tooltip title="Commissionable" placement="top" arrow>
                    <TableCell sx={{ width: "20px" }}>C</TableCell>
                  </Tooltip>
                  <TableCell sx={{ width: "87px" }}>Srv Start</TableCell>
                  <TableCell sx={{ width: "87px" }}>Srv End</TableCell>
                  <TableCell sx={{ width: "50px" }}>HCPC</TableCell>
                  <TableCell sx={{ width: "60px" }}>Mods</TableCell>
                  <Tooltip title="Place of Service" placement="top" arrow>
                    <TableCell sx={{ width: "45px" }}>POS</TableCell>
                  </Tooltip>
                  <TableCell>Description</TableCell>
                  <TableCell sx={{ width: "40px" }}>Qty</TableCell>
                  <TableCell sx={{ width: "75px" }}>Charge</TableCell>
                  <TableCell sx={{ width: "80px" }}>Total</TableCell>
                  <TableCell sx={{ width: "70px" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.orderDat.toJS().map((l, i) => {
                  if (l.edit) {
                    return (
                      <OrderFormEditRow
                        key={`edit`}
                        line={l}
                        onChange={(fld, val) =>
                          handleDataChange(state.orderDat.get(i), fld, val)
                        }
                        onCancel={() => setState((prev) => cancelLine(prev))}
                        onCommit={() =>
                          commitLine(
                            api,
                            orderId,
                            () => setState((prev) => onCommitSuccess(prev)),
                            (errMsg) =>
                              setState((prev) => onCommitError(prev, errMsg)),
                            state
                          )
                        }
                      />
                    );
                  }
                  return (
                    <OrderFormViewRow
                      key={l.id}
                      line={l}
                      hasEditRow={hasEditRow}
                      onEdit={() => {
                        setState((prev) => {
                          const editIdx = prev.orderDat.indexOf(
                            state.orderDat.get(i)
                          );
                          return prev
                            .updateIn(["orderDat", editIdx, "edit"], () => true)
                            .set("editInit", state.orderDat.get(i));
                        });
                      }}
                      onDelete={(line) =>
                        deleteLine(
                          api,
                          line,
                          () => setState((prev) => onDeleteSuccess(prev)),
                          (errMsg) =>
                            setState((prev) => onDeleteError(prev, errMsg))
                        )
                      }
                    />
                  );
                })}
                <TableRow>
                  <TableCell
                    sx={{
                      pl: "5px",
                      pr: "5px",
                      py: "8px",
                    }}
                    colSpan={9}
                  ></TableCell>
                  <TableCell
                    sx={{
                      pl: "5px",
                      pr: "5px",
                      py: "8px",
                      fontWeight: "bold",
                    }}
                    colSpan={2}
                  >
                    {moneyFmt(orderTotal)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <OrderCollectionsTable
              lines={state.orderDat.toJS()}
              initiateARIgnoreFn={openFn}
            />
          )}
        </CardContent>
        {!collectionMode && (
          <CardActions sx={{ flexDirection: "row-reverse" }}>
            <Button
              disabled={hasEditRow}
              onClick={() => {
                setState((prev) =>
                  prev.withMutations((p) => {
                    const line = OrderLine({ edit: true });
                    p.set("orderDat", p.orderDat.push(line)).set(
                      "editInit",
                      false
                    );
                  })
                );
              }}
            >
              Add Order Line
            </Button>
          </CardActions>
        )}
      </Card>
      <StdConfirmSubmitDialog
        open={!!open}
        handleClose={closeFn}
        title="Write off this charge?"
        onComplete={() => {
          closeFn();
          setState((prev) => {
            return prev.withMutations((s) => {
              s.set("loadState", LOADSTATE.INIT);
            });
          });
        }}
        onSubmit={() => {
          return api.orderLineARIgnore(open);
        }}
      >
        Mark this charge as "written off"? The remaining balance will not show
        up in AR reporting.
      </StdConfirmSubmitDialog>
    </>
  );
};

export default OrderForm;
