import SectionHeading from "../PagePartials/SectionHeading";
import { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import KeyValueList from "../PagePartials/KeyValueList";
import { moneyFmt, phoneFmt } from "../../formatters";
import StdFormDialog from "../PagePartials/StdFormDialog";
import RPInvoiceFormatPicker from "../FormControls/RPInvoiceFormatPicker";
import RPPayorTypePicker from "../FormControls/RPPayorTypePicker";
import RPStatePicker from "../FormControls/RPStatePicker";
import RPZipInput from "../FormControls/RPZipInput";
import { useParams } from "react-router-dom";
import SplitButton from "../StandardComponents/SplitButton";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import useDialogState from "../../HOC/useDialogState";
import UpdateOrgDialog from "./PageOrg/UpdateOrgDialog";
import TicketContextMenu from "../PagePartials/Tickets/TicketContextMenu";

const payorAddForm = [
  { name: "name", label: "Location Name", component: TextField, width: 12 },
  { type: "plain", component: Divider, extraProps: { sx: { mb: 2 } } },
  {
    name: "payment_term",
    label: "Payment Term",
    component: TextField,
    width: 3,
  },
  {
    name: "invoice_format",
    label: "Default Invoice Format",
    component: RPInvoiceFormatPicker,
    width: 4,
  },
  {
    name: "company_type",
    label: "Type",
    component: RPPayorTypePicker,
    width: 3,
  },
  { type: "section", label: "Billing Address" },
  {
    name: "addr_recipient",
    label: "Billing Recipient",
    component: TextField,
    width: 12,
  },
  {
    name: "addr_street1",
    label: "Street Address",
    component: TextField,
    width: 8,
  },
  {
    name: "addr_street2",
    label: "Suite / Apt",
    component: TextField,
    width: 4,
  },
  { name: "addr_city", label: "City", component: TextField, width: 5 },
  { name: "addr_state", label: "State", component: RPStatePicker, width: 4 },
  { name: "addr_zip", label: "Zip", component: RPZipInput, width: 3 },
];

const PageOrg = ({ pageData, api, refresh }) => {
  const { orgId } = useParams();

  const {
    open: updatePayorOpen,
    openFn: handleOpenUpdatePayor,
    closeFn: handleCloseUpdatePayor,
  } = useDialogState();

  const [addPayorOpen, setAddPayorOpen] = useState(false);
  const handleOpenPayorForm = () => setAddPayorOpen(true);
  const handleClosePayorForm = () => setAddPayorOpen(false);

  const splitBtn = (
    <SplitButton
      optsAndClicks={{
        "Update Payor": handleOpenUpdatePayor,
        "Add Billing Location": handleOpenPayorForm,
      }}
    />
  );

  return (
    <>
      <BackLinkHeader title="Back to Organizations" to="/orgs" />
      <SectionHeading
        headingLabel={pageData.name}
        buttonEl={
          <Stack spacing={1} direction="row">
            {splitBtn}
            <TicketContextMenu
              api={api}
              context={{
                type: "payor_orgs",
                ident: pageData.id,
                label: `${pageData.name}`,
              }}
            />
          </Stack>
        }
      />
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item xs={5}>
          <Card variant="outlined">
            <CardContent>
              <KeyValueList
                noItemYPadding
                noItemXPadding
                kvData={{
                  "Total Orders": pageData.billed_order_count,
                  "Total Billed": moneyFmt(pageData.billed_total),
                  "Total Collected": moneyFmt(pageData.collected_total),
                  "Total Allocated": moneyFmt(pageData.alloced_total),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7}>
          <Card variant="outlined">
            <CardContent>
              <KeyValueList
                kvData={{
                  "Main Phone Number": phoneFmt(pageData.main_phone),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader
              title="Billing Locations"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              {pageData.payors.length ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell colSpan="4">Address</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageData.payors.map((p) => (
                      <TableRow key={p.id}>
                        <TableCell>{p.name}</TableCell>
                        <TableCell>{p.company_type}</TableCell>
                        <TableCell>
                          {[p.addr_street1, p.addr_street2]
                            .filter((x) => !!x)
                            .join(", ")}
                        </TableCell>
                        <TableCell>{p.addr_city}</TableCell>
                        <TableCell>{p.addr_state}</TableCell>
                        <TableCell>{p.addr_zip}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  sx={{ color: "text.secondary", textAlign: "center" }}
                >
                  No records...
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <UpdateOrgDialog
        open={updatePayorOpen}
        onClose={handleCloseUpdatePayor}
        api={api}
        refresh={refresh}
        orgId={pageData.id}
        initialVals={{
          name: pageData.name,
          main_phone: pageData.main_phone,
        }}
      />
      <StdFormDialog
        title="Add Billing Location"
        fields={payorAddForm}
        onClose={handleClosePayorForm}
        open={!!addPayorOpen}
        submitCall={(data) => {
          let expects_po = null;
          if (data.expects_po === "yes") expects_po = true;
          if (data.expects_po === "no") expects_po = false;
          const payload = { ...data, expects_po };
          return api.addPayor(orgId, payload);
        }}
        initialVals={{ payment_term: 30 }}
        onComplete={() => {
          refresh();
          handleClosePayorForm();
        }}
      />
    </>
  );
};

export default PageOrg;
