import { Chip, Tooltip } from "@mui/material";
import { format } from "date-fns";
import { ucWord } from "../../../utility";

/**
 * @param {{
 *   status: string,
 *   follow_up_on: Date,
 *   finished_on: Date | null
 * }} ticket
 * @param {"small"|"medium"} [size="small"]
 * @param {boolean} [caps=false]
 * @constructor
 */
const TicketStatusPill = ({ ticket, size = "small", caps = false }) => {
  const now = new Date();

  if (ticket.status === "closed" || ticket.status === "cancelled") {
    const title = `${ucWord(ticket.status)} on ${format(
      ticket.finished_on,
      "MM/dd/yyyy"
    )}`;
    return (
      <Tooltip title={title} placement="left-start" arrow>
        <Chip
          label={
            caps ? ticket.status.toUpperCase() : ticket.status.toLowerCase()
          }
          color="default"
          size={size}
          sx={{ cursor: "default" }}
        />
      </Tooltip>
    );
  }

  if (now > ticket.follow_up_on) {
    const title = `Due ${format(ticket.follow_up_on, "EEE MMM d")}`;
    const label = caps ? "PAST DUE" : "past due";
    return (
      <Tooltip title={title} placement="left-start" arrow>
        <Chip
          label={label}
          color="error"
          size={size}
          sx={{ cursor: "default" }}
        />
      </Tooltip>
    );
  }

  const title = `Follow up on ${format(ticket.follow_up_on, "EEE MMM d")}`;
  return (
    <Tooltip title={title} placement="left-start" arrow>
      <Chip
        label={
          caps
            ? ticket.status.toUpperCase().replace("_", " ")
            : ticket.status.toLowerCase().replace("_", " ")
        }
        size={size}
        sx={{ cursor: "default" }}
        color={ticket.status === "blocked" ? "warning" : "success"}
      />
    </Tooltip>
  );
};

export default TicketStatusPill;
