import { WSPatientAndSrvcLink, WSUserAvatar } from "./ws-shared";
import { InternalLink } from "../Components/StandardComponents/InternalLink";
import { surgeryStr } from "../utility";

export const psrSection = {
  title: "Field Management",
  key: "sec-psr",
  buckets: ["agnc-fitting-needed", "agnc-pickup-needed"],
};

export const psrFittings = [
  "agnc-fitting-needed",
  {
    title: "Fittings",
    description:
      "This is the list of all patients that will appear in the pickup area of central.",
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientAndSrvcLink
            srvc_id={r.id}
            eoc_id={r.eoc_id}
            name_last={r.name_last}
            name_first={r.name_first}
          />
        ),
      },
      {
        label: "Status",
        cell: (r) => r.status,
      },
      {
        label: "Surgery",
        cell: (r) => surgeryStr(r.surgical, r.date_of_surgery),
        width: "220px",
      },
      {
        label: "Unit",
        cell: (r) => [r.therapy, r.item_code].join("-").toUpperCase(),
        width: "95px",
      },
      {
        label: "PSR",
        cell: (r) => (
          <InternalLink to={`/reps/${r.psr_id}`}>{r.psr_name}</InternalLink>
        ),
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
        width: "70px",
      },
    ],
  },
];

export const psrPickups = [
  "agnc-pickup-needed",
  {
    title: "Pickups",
    description:
      "This is the list of all patients that will appear in the pickup area of central.",
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientAndSrvcLink
            srvc_id={r.id}
            eoc_id={r.eoc_id}
            name_last={r.name_last}
            name_first={r.name_first}
          />
        ),
      },
      {
        label: "Service Ended",
        cell: (r) => r.service_end_date,
      },
      {
        label: "Expired For",
        cell: (r) => `${r.remaining_days} days`,
      },
      {
        label: "Serial",
        cell: (r) => `${r.serial_num}`,
      },
      {
        label: "PSR",
        cell: (r) => (
          <InternalLink to={`/reps/${r.psr_id}`}>{r.psr_name}</InternalLink>
        ),
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];
