import { useContext, useState } from "react";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import SectionHeading from "../PagePartials/SectionHeading";
import ApiDataContext from "../../ApiDataContext";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import ItemHistoryTable from "./PageAgencyItemHistory/ItemHistoryTable";
import TrueUpDialogForm from "./PageAgencyItemHistory/TrueUpDialogForm";

const PageAgencyItemHistory = ({ pageData, api, refresh }) => {
  const { items } = useContext(ApiDataContext);
  const { itemId } = useParams();
  const [tuFormOpen, setTuFormOpen] = useState(false);

  const itemDef = items.find((i) => i.id === parseInt(itemId, 10));

  const backLinkTitle = `Back to ${pageData.name}`;
  const backLinkUrl = `/agencies/${pageData.id}`;
  const headingLabel = `${itemDef.hlabel} Log`;
  const headingSub = `Agency: ${pageData.name}`;

  const log = pageData.log.toReversed().reduce((acc, cur) => {
    const prev = acc[acc.length - 1] || {
      balance: 0,
    };
    const balance = prev.balance;
    const change =
      cur.op === "sub"
        ? cur.qty * -1
        : cur.op === "set"
        ? cur.qty - balance
        : cur.qty;
    const newBalance = balance + change;
    const dateFormatted = format(new Date(cur.ts), "MM/dd/yyyy");
    acc.push({
      date: dateFormatted,
      ctx: cur.ctx,
      change,
      balance: newBalance,
    });
    return acc;
  }, []);

  return (
    <>
      <BackLinkHeader title={backLinkTitle} to={backLinkUrl} />
      <SectionHeading
        headingLabel={headingLabel}
        buttonLabel="Enter True-Up"
        buttonOnClick={() => {
          setTuFormOpen(true);
        }}
      />
      <Typography
        sx={{ color: "text.secondary", mt: -4, mb: 1 }}
        variant="body2"
      >
        {headingSub}
      </Typography>
      <ItemHistoryTable tableData={log.toReversed()} />
      <TrueUpDialogForm
        open={tuFormOpen}
        onClose={() => setTuFormOpen(false)}
        agencyId={pageData.id}
        itemId={itemDef.id}
        agencyName={pageData.name}
        itemName={itemDef.hlabel}
        api={api}
        refresh={refresh}
      />
    </>
  );
};

export default PageAgencyItemHistory;
