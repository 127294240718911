import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPUserPicker = forwardRef(({ api, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acUsers()}
    />
  );
});

RPUserPicker.defaultValue = null;

export default RPUserPicker;
