import { Box, Button, Card, CardMedia, Paper, Typography } from "@mui/material";

const boxWidth = 480;

const LayoutLogin = ({ handleLogin }) => {
  return (
    <Paper
      sx={{
        p: 4,
        margin: "10vh auto",
        width: boxWidth,
        maxWidth: boxWidth,
        minWidth: boxWidth,
        minHeight: 250,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{ mb: 4, textAlign: "center" }}
        variant="h4"
        component="h1"
      >
        Welcome to Red Panda!
      </Typography>
      <Card elevation={0}>
        <CardMedia
          sx={{ width: 320, margin: "auto", mb: 4 }}
          component="img"
          height="300"
          image="/red-panda-02.jpg"
          alt="Red Panda"
        />
      </Card>
      <Box sx={{ flexGrow: 1 }} />
      <Button size="large" variant="contained" onClick={handleLogin} fullWidth>
        Login
      </Button>
    </Paper>
  );
};

export default LayoutLogin;
