import {
  Button,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

const SubmitAndProgress = ({
  msgLength,
  targetsState,
  onSubmit,
  goodToGo,
  onReset,
  isSubmitting = false,
  isDone = false,
}) => {
  const num = targetsState.filter(
    (t) => t.status === "success" || t.status === "error"
  ).length;
  const denom = targetsState.length;

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
        {isSubmitting ? (
          <LinearProgress
            sx={{ width: "100%" }}
            variant="determinate"
            value={(num / denom) * 100}
          />
        ) : (
          <Typography sx={{ flexGrow: 1 }}>
            {isDone ? (
              "Mass text complete!"
            ) : (
              <>
                Texting a <strong>{msgLength}</strong> character message to{" "}
                <strong>{targetsState.length}</strong> numbers.
              </>
            )}
          </Typography>
        )}
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={onReset}
          disabled={isSubmitting}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          color="success"
          size="small"
          disabled={!goodToGo || isSubmitting || isDone}
          onClick={onSubmit}
        >
          Go!
        </Button>
      </Stack>
    </Paper>
  );
};

export default SubmitAndProgress;
