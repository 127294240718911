import { useState } from "react";
import { getByPath } from "../utility";

const useStdFormErrors = (setFieldError, defaultFormValues, onSuccess) => {
  const [formErrState, setFormErrState] = useState({
    submitting: false,
    hasErrors: false,
    globalErrMsg: "",
  });

  const setSubmitting = () =>
    setFormErrState((prev) => ({
      ...prev,
      submitting: true,
    }));
  const setFormError = (msg) =>
    setFormErrState({
      submitting: false,
      hasErrors: true,
      globalErrMsg: msg,
    });
  const clearFormError = () =>
    setFormErrState({ submitting: false, hasErrors: false, globalErrMsg: "" });

  const setFormSuccess = () =>
    setFormErrState({ submitting: false, hasErrors: false, globalErrMsg: "" });

  const apiWrapper = (callPromise) => {
    setSubmitting();
    callPromise
      .then((resp) => {
        if (resp.statusCode === 404) {
          setFormError(resp.message);
          return;
        }

        if (resp.status === "error") {
          setFormError(resp.message);
          return;
        }

        // if validation, add errors back in
        if (resp.status === "validation") {
          const unmatched = [];
          Object.entries(resp.errors).forEach(([k, m]) => {
            if (getByPath(defaultFormValues, k) !== undefined) {
              setFieldError(k, { type: "server", message: m });
            } else {
              unmatched.push(m);
            }
          });
          if (unmatched.length > 0) {
            setFormError(unmatched);
          } else {
            clearFormError();
          }
          return;
        }

        setFormSuccess();
        onSuccess(resp.data);
      })
      .catch((err) => {
        setFormError(err.message);
      });
    return callPromise;
  };

  return { apiWrapper, formErrState, clearFormError };
};

export default useStdFormErrors;
