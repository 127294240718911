import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import RHFDateField from "../../FormControlsRHF/RHFDateField";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import ClearIcon from "@mui/icons-material/Clear";
import { moneyFmt } from "../../../formatters";
import { reformatDate } from "../../../utility";

const OrderSearchForm = ({ api, onSearchResults }) => {
  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      name_first: "",
      name_last: "",
      date_of_birth: "",
      invoice_num: "",
    },
  });

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    {},
    (data) => {
      clearFormError();
      onSearchResults(data);
    }
  );

  const onSubmit = (data) => {
    const payload = {
      ...data,
      date_of_birth: reformatDate(data.date_of_birth) || "",
    };
    apiWrapper(api.searchOrders(payload));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container columnSpacing={2}>
        {formErrState.hasErrors && (
          <Grid2 xs={12}>
            <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mt: 2 }} />
          </Grid2>
        )}
        <Grid2 xs={3}>
          <RHFTextField
            control={control}
            name="name_first"
            label="First Name"
          />
        </Grid2>
        <Grid2 xs={3}>
          <RHFTextField control={control} name="name_last" label="Last Name" />
        </Grid2>
        <Grid2 xs={3}>
          <RHFDateField
            control={control}
            name="date_of_birth"
            label="Date of Birth"
          />
        </Grid2>
        <Grid2 xs={3}>
          <RHFTextField
            control={control}
            name="invoice_num"
            label="Invoice Number"
          />
        </Grid2>
      </Grid2>
      <Grid2 xs={12} sx={{ textAlign: "right" }}>
        <Button variant="contained" color="secondary" type="submit">
          Search
        </Button>
      </Grid2>
    </form>
  );
};

const OrderResults = ({ searchResults, onOrderSelect }) => {
  const res = Object.entries(searchResults);
  if (res.length === 0) {
    return <Typography>No Results</Typography>;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Patient</TableCell>
          <TableCell>Payor</TableCell>
          <TableCell sx={{ width: "80px" }}>DOS</TableCell>
          <TableCell sx={{ width: "80px" }}>Therapy</TableCell>
          <TableCell sx={{ width: "120px" }}>Generation</TableCell>
          <TableCell sx={{ width: "120px" }}>Charges</TableCell>
          <TableCell sx={{ width: "120px" }}>Balance</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {res.map(([orderId, o]) => {
          const name = [o.name_first, o.name_last].filter(Boolean).join(" ");
          const gen =
            o.generation === 0 ? "Initial" : `Extension ${o.generation}`;
          const therapy = o.therapy.toUpperCase();
          const balance = o.lines.reduce((t, l) => t + l.balance, 0);
          const charge = o.lines.reduce((t, l) => t + l.charge * l.qty, 0);
          // TODO: Fix the bgcolor to respect dark mode
          return (
            <TableRow
              key={orderId}
              sx={{ cursor: "pointer", "&:hover": { bgcolor: "#eee" } }}
              onClick={() => {
                onOrderSelect(orderId, o);
              }}
            >
              <TableCell>{name}</TableCell>
              <TableCell>{o.payor_org}</TableCell>
              <TableCell>{o.dos_start}</TableCell>
              <TableCell>{therapy}</TableCell>
              <TableCell>{gen}</TableCell>
              <TableCell>{moneyFmt(charge)}</TableCell>
              <TableCell>{moneyFmt(balance)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const OrderSearchCard = ({ api, onOrderSelect }) => {
  const [searchResults, setSearchResults] = useState(null);

  return (
    <Card variant="outlined" sx={{ mt: 2 }}>
      <CardHeader
        title={searchResults === null ? "Add Order" : "Search Results"}
        action={
          searchResults === null ? null : (
            <IconButton onClick={() => setSearchResults(null)}>
              <ClearIcon />
            </IconButton>
          )
        }
      />
      <CardContent>
        {null === searchResults && (
          <OrderSearchForm
            api={api}
            onSearchResults={(d) => setSearchResults(d)}
          />
        )}
        {null !== searchResults && (
          <OrderResults
            searchResults={searchResults}
            onOrderSelect={(orderId, order) => {
              onOrderSelect(orderId, order);
              setSearchResults(null);
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default OrderSearchCard;
