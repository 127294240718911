import { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const StdConfirmSubmitDialog = ({
  children,
  open,
  title,
  onSubmit,
  handleClose,
  onComplete,
  ...rest
}) => {
  const [submitError, setSubmitError] = useState(null);

  if (!rest.PaperProps) {
    rest.PaperProps = { sx: { width: 700, minWidth: 700 } };
  }

  return (
    <Dialog open={open} disableEscapeKeyDown disableScrollLock {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {submitError ? (
          <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        ) : null}
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSubmitError(null);
            handleClose();
          }}
          variant="outlined"
          color="primary"
        >
          Kidding...
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          onClick={() => {
            setSubmitError(null);
            onSubmit()
              .then((resp) => {
                if (resp.status !== "ok") {
                  setSubmitError(resp.message);
                  return;
                }
                onComplete(resp);
              })
              .catch((err) => {
                setSubmitError(err.message);
              });
          }}
        >
          Absolutely!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StdConfirmSubmitDialog;
