import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const CONTACT_METHODS = [
  { value: "email", label: "Email" },
  { value: "phone", label: "Phone" },
  { value: "fax", label: "Fax" },
];

const RPPrefContMethodPicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={CONTACT_METHODS} />;
});

RPPrefContMethodPicker.defaultValue = null;

export default RPPrefContMethodPicker;
