import { IconButton, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { dateObjFormatToAnnArborDate } from "../../../utility";
import SackXmark from "../../StandardComponents/Icons/SackXmark";
import { useFormState } from "react-hook-form";

const ARIgnoreIcon = ({
  control,
  balance,
  ar_ignored_on,
  ar_ignored_user_name,
  ignoreARFn,
}) => {
  const { isDirty } = useFormState({ control });

  if (balance === 0) {
    return (
      <Tooltip title={"Balance is zero!!"} arrow placement="top">
        <CheckCircleIcon
          fontSize="small"
          color="success"
          sx={{ alignItems: "center", ml: "4px" }}
        />
      </Tooltip>
    );
  }

  if (ar_ignored_on) {
    const date = dateObjFormatToAnnArborDate(new Date(ar_ignored_on));
    const tt = `AR Ignored On ${date} by ${ar_ignored_user_name}`;
    return (
      <Tooltip title={tt} arrow placement="top">
        <span>
          <IconButton disabled size="small">
            <SackXmark fontSize="small" sx={{ p: "2px" }} />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        isDirty
          ? "Either post or reset these allocations before taking this action!"
          : "Write off this charge"
      }
      arrow
      placement="top"
    >
      <span>
        <IconButton disabled={!!isDirty} onClick={ignoreARFn} size="small">
          <SackXmark
            color={!!isDirty ? undefined : "error"}
            fontSize="small"
            sx={{ p: "2px" }}
          />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ARIgnoreIcon;
