import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";

const resolveVal = (v) => {
  if (typeof v === "function") {
    return v();
  }
  return v;
};

const KeyValueList = ({
  kvData,
  boldedKeys = false,
  noItemXPadding = false,
  noItemYPadding = false,
  ignoreTypos = [],
}) => {
  const primaryTypographyProps = {
    variant: "body1",
  };

  if (boldedKeys) {
    primaryTypographyProps.sx = { fontWeight: "bold" };
  }

  const liSx = {};
  if (noItemYPadding) {
    liSx.py = 0;
  }
  if (noItemXPadding) {
    liSx.px = 0;
  }

  return (
    <List dense sx={{ width: "100%" }}>
      {Object.entries(kvData).map(([k, v]) => {
        const includeTypo = !ignoreTypos.includes(k);

        return (
          <ListItem key={k} sx={liSx}>
            <ListItemText
              primary={k}
              primaryTypographyProps={primaryTypographyProps}
            />
            <ListItemSecondaryAction sx={{ whiteSpace: "pre-line" }}>
              {!!includeTypo ? (
                <Typography variant="body2">{resolveVal(v)}</Typography>
              ) : (
                resolveVal(v)
              )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default KeyValueList;
