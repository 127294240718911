import { forwardRef } from "react";
import { createDefaultMaskGenerator, useWebMask } from "react-hook-mask";
import { TextField } from "@mui/material";
import { ssnFmt } from "../../formatters";

const PTN_SSN_RAW = new RegExp("^\\d{9}$");

const maskGenerator = createDefaultMaskGenerator("999-99-9999");

const RPSsnInput = forwardRef((props, ref) => {
  const {
    value,
    onChange,
    ref: maskRef,
  } = useWebMask({
    maskGenerator,
    value: props.value,
    onChange: props.onChange,
    keepMask: props.keepMask,
    ref,
  });

  // hack for displaying a passed in raw value
  let override = null;
  if (PTN_SSN_RAW.test(value)) {
    override = ssnFmt(value);
  }

  return (
    <TextField
      {...props}
      value={override || value}
      onChange={onChange}
      inputRef={maskRef}
    />
  );
});

export default RPSsnInput;
