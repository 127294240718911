import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import ApiDataContext from "../../ApiDataContext";
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useStdFormErrors from "../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../StandardComponents/FormLevelErrorAlert";
import StandardDialog from "../StandardComponents/StandardDialog";
import JointSettingsList from "../StandardComponents/JointSettingsList";
import RHFDateField from "../FormControlsRHF/RHFDateField";
import RHFAvailableWarehouseSerialPicker from "../FormControlsRHF/RHFAvailableWarehouseSerialPicker";
import RPPackableItems from "../FormControls/RPPackableItems";
import { nowDate, reformatDate } from "../../utility";
import CancelIcon from "@mui/icons-material/Cancel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandFist } from "@fortawesome/free-solid-svg-icons";
import { fmtItemCodeLabel } from "../../formatters";

const genDefaultValues = (initialVals) => ({
  inv_serial_id: initialVals.inv_serial_id,
  packed_on: nowDate(),
  inv_additional: [],
});

const NoConsumablesAlert = ({ overridden, setOverrideFn }) => {
  if (overridden) {
    return <Alert severity="warning">You have chosen no consumables.</Alert>;
  }

  return (
    <Alert
      severity="error"
      action={
        <Tooltip title="No machine is going to tell me what to do!" arrow>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setOverrideFn(true)}
          >
            <SvgIcon fontSize="small">
              <FontAwesomeIcon icon={faHandFist} />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      }
    >
      You currently have no consumables selected for packing! This is likely a
      mistake!
    </Alert>
  );
};

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  // labels for the consumables dropdown
  const { items } = useContext(ApiDataContext);

  // consumable override
  const [overridden, setOverride] = useState(false);

  // when we close the dialog
  const handleClose = () => {
    onClose();
    reset();
    clearFormError();
    setOverride(false);
  };

  // form management
  const defaultValues = genDefaultValues(initialVals);
  const { control, handleSubmit, reset, setError } = useForm({ defaultValues });
  const { append, remove, fields } = useFieldArray({
    control,
    name: "inv_additional",
  });
  const onSuccess = () => {
    refresh();
    handleClose();
  };
  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    defaultValues,
    onSuccess
  );
  const onSubmit = (data) => {
    clearFormError();
    const payload = {
      inv_serial_id: data.inv_serial_id?.value,
      packed_on: reformatDate(data.packed_on),
      inv_additional: data.inv_additional.map((i) => i.itemId),
    };
    return apiWrapper(api.srvcSetPacked(initialVals.id, payload));
  };

  // consumable management
  const [curConsume, setCurConsume] = useState(null);
  const onConsumeChange = (newVal) => {
    if (newVal) {
      append({ itemId: newVal });
      setCurConsume(newVal);
    }
  };
  useEffect(() => setCurConsume(null), [curConsume]);

  // UI helpers
  const hasSomeConsumables = !!fields.length;
  const disableSubmit =
    !formErrState.submitting && !hasSomeConsumables && !overridden;
  const serialPickerLabel = `${fmtItemCodeLabel(
    initialVals.latest_item_code
  )} Serial Number`;

  return (
    <StandardDialog
      title="Pack Unit for Shipment"
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2} rowSpacing={2}>
          {formErrState.hasErrors && (
            <Grid2 xs={12}>
              <FormLevelErrorAlert
                formErrStruct={formErrState}
                sx={{ my: 2 }}
              />
            </Grid2>
          )}
          <Grid2 xs={6} sx={{ pt: 1 }}>
            <Stack direction="column" spacing={2}>
              <RHFAvailableWarehouseSerialPicker
                control={control}
                name="inv_serial_id"
                label={serialPickerLabel}
                api={api}
                itemId={initialVals.item_id}
              />
              <RHFDateField
                control={control}
                name="packed_on"
                label="Packed On"
              />
              <Box>
                <Typography>
                  Add consumables by selecting items from this list:
                </Typography>
              </Box>
              <RPPackableItems
                api={api}
                treatId={initialVals.treat_id}
                onChange={onConsumeChange}
                label="Available Consumables"
                size="small"
                value={curConsume}
                helperText=""
              />
            </Stack>
          </Grid2>
          <Grid2 xs={6}>
            <Paper variant="outlined" sx={{ m: 1, p: 1 }}>
              <Typography fontSize="larger" sx={{ mb: 1 }}>
                Settings
              </Typography>
              <Divider />
              {initialVals.settings ? (
                <>
                  <JointSettingsList joint={initialVals.settings} />
                </>
              ) : (
                <Alert severity="warning">
                  Settings undefined or unneeded!
                </Alert>
              )}
            </Paper>
          </Grid2>
          <Grid2 xs={12}>
            <Paper variant="outlined" sx={{ p: 1, mr: 1 }}>
              <Typography fontSize="larger">Consumables Packed</Typography>
              <Divider sx={{ mb: 1 }} />
              <List>
                {hasSomeConsumables ? (
                  fields.map((f, idx) => {
                    return (
                      <ListItem key={f.id}>
                        <ListItemText>
                          {items.find((i) => i.id === f.itemId).hlabel}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => remove(idx)} color="error">
                            <CancelIcon color="inherit" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })
                ) : (
                  <NoConsumablesAlert
                    overridden={overridden}
                    setOverrideFn={setOverride}
                  />
                )}
              </List>
            </Paper>
          </Grid2>
          <Grid2 xs={12} sx={{ textAlign: "right" }}>
            <Button type="submit" variant="contained" disabled={disableSubmit}>
              Pack Items
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </StandardDialog>
  );
};
