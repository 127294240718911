import { Box, Button, Typography } from "@mui/material";

const LoadingPageError = ({ msg, retry }) => {
  return (
    <Box>
      <Typography variant="h6">Error!</Typography>
      <Typography>{msg}</Typography>
      <Button onClick={retry}>Retry</Button>
    </Box>
  );
};

export default LoadingPageError;
