import { InternalLink } from "../Components/StandardComponents/InternalLink";
import { surgeryStr } from "../utility";
import { WSPatientLink } from "./ws-shared";

export const criticalSection = {
  title: "Critical",
  key: "sec-crit",
  buckets: ["rx-needs-doc"],
};

export const rxNeedsDoc = [
  "rx-needs-doc",
  {
    title: "Missing Prescription Document",
    description: "",
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientLink
            eoc_id={r.id}
            name_last={r.name_last}
            name_first={r.name_first}
          />
        ),
      },
      {
        label: "Prescriber",
        cell: (r) => (
          <InternalLink to={`/prescribers/${r.npi}`}>
            {[r.rxer_name_first, r.rxer_name_last, r.credential]
              .filter(Boolean)
              .join(" ")}
          </InternalLink>
        ),
      },
      {
        label: "Rep",
        cell: (r) => (
          <InternalLink to={`/reps/${r.rep_id}`}>{r.rep_name}</InternalLink>
        ),
      },
      {
        label: "Surgery",
        cell: (r) => surgeryStr(r.surgical, r.date_of_surgery),
      },
    ],
  },
];
