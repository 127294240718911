import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import AttachmentList from "./AttachmentListCard/AttachmentList";
import AddAttachmentButton from "./AttachmentListCard/AddAttachmentButton";
import RPAttachTagsPicker from "../FormControls/RPAttachTagsPicker";
import MediaShowDialog from "./MediaShowDialog";
import StdFormDialog from "./StdFormDialog";
import StdConfirmSubmitDialog from "./StdConfirmSubmitDialog";
import GenericFileUploadDialog from "./GenericFileUploadDialog";

const FORM_EDIT_ATTACH = [
  { name: "title", component: TextField, label: "Title" },
  {
    name: "tags",
    component: RPAttachTagsPicker,
    label: "Tags",
    extraProps: { namespace: "agencies" },
  },
];

const attachmentById = (data, id) => {
  const found = data.find((a) => a.id === id);
  if (!found) return null;
  return found;
};

const AttachmentListCard = ({
  attachmentData,
  targetEntity,
  targetId,
  editable,
  refresh,
  api,
}) => {
  const [addAttachmentOpen, setAddAttachmentOpen] = useState(false);
  const handleAddAttachmentOpen = () => setAddAttachmentOpen(true);
  const handleAddAttachmentClose = () => setAddAttachmentOpen(false);

  const [editAttachmentOpen, setEditAttachmentOpen] = useState(null);
  const handleEditAttachmentOpen = (id) =>
    setEditAttachmentOpen(attachmentById(attachmentData, id));
  const handleEditAttachmentClose = () => setEditAttachmentOpen(null);

  const [viewAttachmentOpen, setViewAttachmentOpen] = useState(null);
  const handleViewAttachmentOpen = (id) =>
    setViewAttachmentOpen(attachmentById(attachmentData, id));
  const handleViewAttachmentClose = () => setViewAttachmentOpen(null);

  const [deleteAttachmentOpen, setDeleteAttachmentOpen] = useState(null);
  const handleDeleteAttachmentOpen = (id) =>
    setDeleteAttachmentOpen(attachmentById(attachmentData, id));
  const handleDeleteAttachmentClose = () => setDeleteAttachmentOpen(null);

  return (
    <>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          sx={{ mb: 0, pb: 0 }}
          title="Attachments"
          titleTypographyProps={{ variant: "h6", component: "h3" }}
          action={
            <AddAttachmentButton
              editable={editable}
              onClick={handleAddAttachmentOpen}
            />
          }
        />
        <CardContent sx={{ p: 0, m: 0, "&:last-child": { p: 0 } }}>
          {attachmentData.length === 0 ? (
            <Box sx={{ m: 3 }}>
              <Typography>None</Typography>
            </Box>
          ) : (
            <AttachmentList
              items={attachmentData}
              onClick={handleEditAttachmentOpen}
              onMainClick={handleViewAttachmentOpen}
              onDelete={handleDeleteAttachmentOpen}
              editable={editable}
            />
          )}
        </CardContent>
      </Card>
      <GenericFileUploadDialog
        open={addAttachmentOpen}
        onClose={handleAddAttachmentClose}
        api={api}
        targetEntity={targetEntity}
        targetId={targetId}
        refresh={refresh}
      />
      <MediaShowDialog
        open={!!viewAttachmentOpen}
        onClose={handleViewAttachmentClose}
        attachment={viewAttachmentOpen}
        api={api}
      />
      <StdFormDialog
        open={!!editAttachmentOpen}
        title="Edit Attachment"
        onClose={handleEditAttachmentClose}
        fields={FORM_EDIT_ATTACH}
        onComplete={() => {
          handleEditAttachmentClose();
          refresh();
        }}
        submitCall={(data) => api.updateAttachment(editAttachmentOpen.id, data)}
        initialVals={editAttachmentOpen}
      />
      <StdConfirmSubmitDialog
        open={!!deleteAttachmentOpen}
        title={`Delete Attachment`}
        PaperProps={{ sx: { width: 600, minWidth: 600 } }}
        onSubmit={() => api.deleteAttachment(deleteAttachmentOpen.id)}
        onComplete={() => {
          handleDeleteAttachmentClose();
          refresh();
        }}
        handleClose={handleDeleteAttachmentClose}
      >
        Delete the attachment permanently? This cannot be undone!
      </StdConfirmSubmitDialog>
    </>
  );
};

export default AttachmentListCard;
