import { Autocomplete, TextField } from "@mui/material";
import { forwardRef } from "react";

const OPTIONS = {
  agencies: ["Contract"],
  reps: ["Contract"],
  prescribers: [],
  prescriptions: ["RX", "LMN"],
};

const RPAttachTagsPicker = forwardRef(
  ({ namespace, onChange, label, error, helperText, size, ...rest }, ref) => {
    const opts = OPTIONS[namespace];
    if (!opts) {
      return null;
    }
    return (
      <Autocomplete
        {...rest}
        options={opts}
        size={size}
        multiple
        filterSelectedOptions
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            label={label}
            inputRef={ref}
          />
        )}
        onChange={(_, newVal) => {
          onChange(newVal);
        }}
      />
    );
  }
);

RPAttachTagsPicker.defaultValue = [];

export default RPAttachTagsPicker;
