import { useForm } from "react-hook-form";
import { nowDate, reformatDate } from "../../../utility";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import StandardDialog from "../../StandardComponents/StandardDialog";
import { Button } from "@mui/material";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import Grid2 from "@mui/material/Unstable_Grid2";
import RHFDateField from "../../FormControlsRHF/RHFDateField";

const genDefaults = () => {
  return {
    completed_on: nowDate(),
  };
};

const SevenDayCallDialog = ({ open, onClose, api, refresh, treatId }) => {
  const defaultValues = genDefaults();

  const { handleSubmit, control, setError, reset } = useForm({ defaultValues });
  const handleClose = () => {
    onClose();
    reset();
  };
  const onSuccess = () => {
    handleClose();
    refresh();
  };
  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    onSuccess
  );

  const onSubmit = (data) => {
    const payload = {
      completed_on: reformatDate(data.completed_on),
    };
    apiWrapper(api.completeSevenDayCall(treatId, payload));
  };

  return (
    <StandardDialog title="7-Day Call" open={open} onClose={handleClose}>
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2}>
          <RHFDateField
            name="completed_on"
            control={control}
            label="7-Day Call Completed On"
          />
        </Grid2>
        <Grid2 xs={12} sx={{ textAlign: "right" }}>
          <Button type="submit" variant="contained">
            Mark Complete
          </Button>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default SevenDayCallDialog;
