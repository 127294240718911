import { useState } from "react";
import { Dialog as UpdateDemos } from "./UpdateDemos";
import { Dialog as AttachScript } from "./AttachScript";
import { Dialog as SrvcInitialContact } from "./SrvcInitialContact";
import { Dialog as SrvcPrefShip } from "./SrvcPrefShip";
import { Dialog as SrvcPack } from "./SrvcPack";
import { Dialog as SrvcShip } from "./SrvcShip";
import { Dialog as SrvcArrive } from "./SrvcArrive";
import { Dialog as SrvcFit } from "./SrvcFit";
import { Dialog as SrvcStart } from "./SrvcStart";
import { Dialog as SrvcEnd } from "./SrvcEnd";
import { Dialog as SrvcPrefPickup } from "./SrvcPrefPickup";
import { Dialog as SrvcPickup } from "./SrvcPickup";
import { Dialog as SrvcReturned } from "./SrvcReturned";
import { Dialog as SrvcReprocess } from "./SrvcReprocess";
import GenerateLabelFormDialog from "../Pages/PageServiceCycle/GenerateLabelFormDialog";
import DownloadLabelsDialog from "./DownloadLabelsDialog";

const BMAP = {
  "update-demos": { action: UpdateDemos, label: "Fix" },
  "upload-hard-copy": { action: AttachScript, label: "Fix" },
  "set-acked-on": { action: SrvcInitialContact, label: "Fix" },
  "set-pref-ship": { action: SrvcPrefShip, label: "Fix" },
  "set-packed": { action: SrvcPack, label: "Fix" },
  "set-shipped": { action: SrvcShip, label: "Fix" },
  "set-delivery": { action: SrvcArrive, label: "Fix" },
  "set-fit": { action: SrvcFit, label: "Fix" },
  "set-service-start": { action: SrvcStart, label: "Fix" },
  "set-service-end": { action: SrvcEnd, label: "Fix" },
  "set-pref-pickup": { action: SrvcPrefPickup, label: "Fix" },
  "set-pickup": { action: SrvcPickup, label: "Fix" },
  "set-returned": { action: SrvcReturned, label: "Fix" },
  "set-reprocessed": { action: SrvcReprocess, label: "Fix" },
  "gen-ups-label": { action: GenerateLabelFormDialog, label: "Fix" },
  "dl-ups-label": { action: DownloadLabelsDialog, label: "Show" },
};

export const labelForRule = (rule) => {
  const r = BMAP[rule];
  if (!r) return "??";
  return r.label;
};

export const useDialogCtrl = (actionList) => {
  const dedupedList = Array.from(new Set(actionList).values());
  const initialOpenStates = dedupedList.map((act) => [act, false]);
  const [openStates, setOpenStates] = useState(initialOpenStates);

  const openDialog = (name) =>
    setOpenStates(
      dedupedList.map((act) => (act === name ? [act, true] : [act, false]))
    );
  const closeDialogs = () => {
    setOpenStates(initialOpenStates);
  };

  return {
    openStates: Object.fromEntries(openStates),
    openDialog,
    closeDialogs,
  };
};

export const BusinessActionManager = ({
  api,
  refresh,
  actionList,
  openStates,
  closeDialogs,
  pageData,
}) => {
  const dedupedList = Array.from(new Set(actionList).values());

  return dedupedList.map((actionName) => {
    const bmapEntry = BMAP[actionName];
    const Action = bmapEntry?.action;
    if (!Action) {
      return null;
    }
    return (
      <Action
        initialVals={pageData}
        key={actionName}
        refresh={refresh}
        api={api}
        onClose={closeDialogs}
        open={
          openStates[actionName] === undefined ? false : openStates[actionName]
        }
      />
    );
  });
};
