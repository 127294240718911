import StdFormDialog from "../PagePartials/StdFormDialog";
import RPDateInput from "../FormControls/RPDateInput";
import { reformatDate } from "../../utility";

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  const FIELDS = [
    {
      name: "service_end_on",
      label: "End of Service",
      component: RPDateInput,
      width: 6,
    },
  ];

  const defaultVal = initialVals.service_end_on || initialVals.default_end_date;

  return (
    <StdFormDialog
      open={open}
      onClose={onClose}
      title="Set Service End"
      onComplete={() => {
        onClose();
        refresh();
      }}
      fields={FIELDS}
      submitCall={(data) => {
        const payload = { service_end_on: reformatDate(data.service_end_on) };
        return api.srvcSetEnd(initialVals.id, payload);
      }}
      initialVals={{ service_end_on: defaultVal }}
    />
  );
};
