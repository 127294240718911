import SectionHeading from "../PagePartials/SectionHeading";
import SplitButton from "../StandardComponents/SplitButton";
import NewTicketFormDialog from "../PagePartials/Tickets/NewTicketFormDialog";
import useDialogState from "../../HOC/useDialogState";
import Grid2 from "@mui/material/Unstable_Grid2";
import TicketCategoryList from "../PagePartials/Tickets/TicketCategoryList";
import { DateRangeCalendar, DateRangePickerDay } from "@mui/x-date-pickers-pro";
import { useSWRConfig } from "swr";
import { useSearchParams } from "react-router-dom";
import { Alert, Badge, Box, Button, Paper, Stack } from "@mui/material";
import TicketDashboardListLoader from "../PagePartials/Tickets/TicketDashboardListLoader";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { dateStrHyphenToDateObj } from "../../utility";

const parseInitialDateRange = (searchParams) => {
  const start = searchParams.get("start");
  const end = searchParams.get("end");
  if (!start || !end) return [null, null];

  return [dateStrHyphenToDateObj(start), dateStrHyphenToDateObj(end)];
};

const DottedDay = ({ day, followUpDateSet, ...props }) => {
  const dateStr = format(day, "yyyy-MM-dd");
  const now = new Date();
  let color = "secondary";
  if (day < now) {
    color = "error";
  }
  if (followUpDateSet.has(dateStr)) {
    return (
      <>
        <Badge
          variant="dot"
          color={color}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiBadge-badge": { bottom: "7.5px", left: "12.25px" },
          }}
        >
          <Box component="span"></Box>
        </Badge>
        <DateRangePickerDay day={day} {...props} />
      </>
    );
  }

  return <DateRangePickerDay day={day} {...props} />;
};

const PageTickets = ({ api }) => {
  const { open, openFn, closeFn } = useDialogState();
  const { mutate } = useSWRConfig();
  const [followUpDateSet, setFollowUpDateSet] = useState(new Set());
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRangeVal, setDateRangeVal] = useState(
    parseInitialDateRange(searchParams)
  );

  useEffect(() => {
    if (dateRangeVal[0] && dateRangeVal[1]) {
      const start = format(dateRangeVal[0], "yyyy-MM-dd");
      const end = format(dateRangeVal[1], "yyyy-MM-dd");
      searchParams.set("start", start);
      searchParams.set("end", end);
    } else {
      searchParams.delete("start");
      searchParams.delete("end");
    }
  }, [dateRangeVal, searchParams, setSearchParams]);

  useEffect(() => {
    const requestedCat = searchParams.get("category");
    const defaultCat =
      localStorage.getItem("ticket-dashboard-category") || "assigned";
    const resolvedCat = requestedCat || defaultCat;
    if (resolvedCat !== defaultCat) {
      localStorage.setItem("ticket-dashboard-category", resolvedCat);
    }
    searchParams.set("category", resolvedCat);
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  const optsAndClicks = {
    "New Task": () => openFn(),
  };

  const effectiveCat = searchParams.get("category") ?? "assigned";
  const hasRange = !!(dateRangeVal[0] && dateRangeVal[1]);

  return (
    <>
      <Grid2 container columnSpacing={1} rowSpacing={3} columns={20}>
        <Grid2 xs={20}>
          <SectionHeading
            sx={{ mb: 0 }}
            headingLabel="Task Dashboard"
            buttonEl={<SplitButton optsAndClicks={optsAndClicks} />}
          />
        </Grid2>
        <Grid2 xs={13}>
          {hasRange && (
            <Alert severity="info">
              Showing only tasks with follow-up dates from{" "}
              <strong>{format(dateRangeVal[0], "MM/dd")}</strong> to{" "}
              <strong>{format(dateRangeVal[1], "MM/dd")}</strong>
            </Alert>
          )}
          <TicketDashboardListLoader
            api={api}
            category={effectiveCat}
            dateLimiter={dateRangeVal}
            m={dateRangeVal}
            setFollowUpDatesSetFn={setFollowUpDateSet}
          >
            <Alert severity="info">No tasks found!</Alert>
          </TicketDashboardListLoader>
        </Grid2>
        <Grid2 xs={7}>
          <Stack direction="column" spacing={3}>
            <Paper>
              <DateRangeCalendar
                calendars={1}
                fixedWeekNumber={6}
                value={dateRangeVal}
                onChange={(newValue) => setDateRangeVal(newValue)}
                showDaysOutsideCurrentMonth
                slotProps={{
                  day: { followUpDateSet },
                }}
                slots={{
                  day: DottedDay,
                }}
              />
              <Button
                size="small"
                fullWidth
                onClick={() => setDateRangeVal([null, null])}
              >
                Clear Range Filter
              </Button>
            </Paper>
            <Paper>
              <TicketCategoryList api={api} category={effectiveCat} />
            </Paper>
          </Stack>
        </Grid2>
      </Grid2>
      <NewTicketFormDialog
        api={api}
        open={open}
        onClose={closeFn}
        context={null}
        onSuccess={() => {
          closeFn();
          mutate("ticketSummaryCounts");
          mutate("ticketDashboard-requested");
          mutate("ticketDashboard-reminders");
        }}
      />
    </>
  );
};

export default PageTickets;
