import { ListItemIcon, ListItemText, MenuItem, Menu } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

/**
 * @param {HTMLElement|null} anchorEl
 * @param {function} onClose
 * @param {boolean} showZeros
 * @param {boolean} showDeprecated
 * @param {function} toggleShowZeros
 * @param {function} toggleShowDeprecated
 */
const ConsumablesSummaryFilterMenu = ({
  anchorEl,
  onClose,
  showZeros,
  showDeprecated,
  toggleShowZeros,
  toggleShowDeprecated,
}) => {
  const open = Boolean(anchorEl);

  return (
    <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
      <MenuItem onClick={toggleShowZeros}>
        {!!showZeros && (
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
        )}
        <ListItemText inset={!showZeros}>Show 0 Quantity Items</ListItemText>
      </MenuItem>
      <MenuItem onClick={toggleShowDeprecated}>
        {!!showDeprecated && (
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
        )}
        <ListItemText inset={!showDeprecated}>
          Show Deprecated Items
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default ConsumablesSummaryFilterMenu;
