import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import BackLinkHeader from "../PagePartials/BackLinkHeader";

const changeColor = blue[50];

const FormField = ({
  label,
  multi,
  ident,
  initialState,
  currentState,
  update,
  helperText,
}) => {
  return (
    <TextField
      label={label}
      fullWidth
      multiline={multi}
      rows={multi ? 6 : undefined}
      InputProps={{
        sx: {
          backgroundColor:
            initialState[ident] !== currentState[ident] ? changeColor : null,
        },
      }}
      value={currentState[ident]}
      onChange={(e) => {
        const s = { ...currentState };
        s[ident] = e.target.value;
        update(s);
      }}
      helperText={helperText}
    />
  );
};

const PagePracticeEngagementEmailGlobals = ({ pageData, api, refresh }) => {
  const [initials, setInitials] = useState(pageData);
  const [emailGlobals, setEmailGlobals] = useState(pageData);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setInitials(pageData);
    setEmailGlobals(pageData);
  }, [pageData]);

  const submitForm = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError("");
    try {
      const response = await api.practiceEngagementGlobalsUpdate(emailGlobals);
      console.log("response", response);
      if (response.status !== "ok") {
        setError(response.message);
      } else {
        refresh();
      }
    } catch (error) {
      setError(error.message);
      console.log("error", error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <BackLinkHeader title="Back to Practice List" to="/practices" />
      <form onSubmit={submitForm}>
        <Stack spacing={2}>
          <Typography variant="h4">
            Practice Engagement Email Global Placeholders
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <FormField
            currentState={emailGlobals}
            initialState={initials}
            update={setEmailGlobals}
            ident="subject"
            label="Email Subject Line"
          />
          <FormField
            currentState={emailGlobals}
            initialState={initials}
            update={setEmailGlobals}
            ident="preamblePatients"
            label="Preamble With Patients"
            multi
            helperText="Appears right under the greeting to the scheduler. Only shows if there are patients."
          />
          <FormField
            currentState={emailGlobals}
            initialState={initials}
            update={setEmailGlobals}
            ident="preambleNoPatients"
            label="Preamble No Patients"
            multi
            helperText="Appears right under the greeting to the scheduler. Only shows if there are NO patients."
          />
          <FormField
            currentState={emailGlobals}
            initialState={initials}
            update={setEmailGlobals}
            ident="calloutHeading"
            label="Callout Heading"
            helperText="This will be bolded in the email."
          />
          <FormField
            currentState={emailGlobals}
            initialState={initials}
            update={setEmailGlobals}
            ident="calloutText"
            label="Callout Body"
            multi
            helperText="Right under the Callout Heading."
          />
          <FormField
            currentState={emailGlobals}
            initialState={initials}
            update={setEmailGlobals}
            ident="footer"
            label="Footer"
            multi
            helperText="Shows up at the end of the email right before the signature."
          />
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setEmailGlobals(initials);
              }}
              disabled={submitting}
            >
              Reset
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitting}
            >
              Update
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
};

export default PagePracticeEngagementEmailGlobals;
