import { Button, Stack, TextField } from "@mui/material";
import RPItemPicker from "../../FormControls/RPItemPicker";
import { useState } from "react";
import { parseIntB } from "../../../utility";

const AddItemControl = ({ api, mfgrId, removeList, onItemAdd }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [qty, setQty] = useState(1);
  const onQtyChange = (e) => {
    const parsed = parseIntB(e.target.value);
    if (null === parsed || parsed < 1) {
      setQty(1);
      return;
    }
    setQty(parsed);
  };

  return (
    <Stack direction="row" spacing={2}>
      <RPItemPicker
        fullWidth
        label="Item To Add"
        api={api}
        mfgrId={mfgrId}
        removeItemIds={removeList}
        size="small"
        onChange={(val) => setSelectedItem(val)}
        value={selectedItem}
      />
      <TextField
        sx={{ minWidth: "100px" }}
        label="Quantity"
        size="small"
        type="number"
        value={qty}
        onChange={onQtyChange}
      />
      <Button
        variant="contained"
        sx={{ minWidth: "160px" }}
        onClick={() => {
          onItemAdd(selectedItem, qty);
          setSelectedItem(null);
          setQty(1);
        }}
      >
        Add Item to PO
      </Button>
    </Stack>
  );
};

export default AddItemControl;
