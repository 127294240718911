import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPLocationPicker = forwardRef(({ api, practiceId, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acPLocs(practiceId)}
    />
  );
});

RPLocationPicker.defaultValue = null;

export default RPLocationPicker;
