import RHFAutocomplete from "./RHFAutocomplete";

const OPTIONS = [
  { value: "unless-denied", label: "Fit Unless Denied" },
  { value: "without-approval", label: "Fit Without Approval" },
];

const RHFFitPrefPicker = (props) => {
  return <RHFAutocomplete options={OPTIONS} {...props} />;
};

RHFFitPrefPicker.byVal = (val) => OPTIONS.find((o) => o.value === val);

export default RHFFitPrefPicker;
