import { createDefaultMaskGenerator, useWebMask } from "react-hook-mask";
import { TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { basicTextFieldProps } from "./_defaults";

const maskGenerator = createDefaultMaskGenerator("99999");

const RHFZipCodeField = ({ name, control, rules = undefined, ...rest }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });

  const { value, onChange, ref } = useWebMask({
    maskGenerator,
    value: field.value,
    onChange: field.onChange,
    keepMask: rest.keepMask,
    ref: field.ref,
  });

  return (
    <TextField
      name={field.name}
      value={value}
      inputRef={ref}
      onBlur={field.onBlur}
      onChange={onChange}
      error={!!error}
      helperText={error?.message ?? " "}
      {...basicTextFieldProps}
      {...rest}
    />
  );
};

export default RHFZipCodeField;
