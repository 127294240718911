import { Alert, Box, Button, Stack } from "@mui/material";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import CommissionsToSweepTable from "./CommissionsToSweepTable";
import IncentivesToSweepTable from "./IncentivesToSweepTable";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { moneyFmt } from "../../../formatters";
import { arrayFindOrDie } from "../../../utility";
import Grid2 from "@mui/material/Unstable_Grid2";

const genDefaultValues = (itemList, incentivesList) => {
  return {
    items: itemList.map((i) => ({
      commlid: i.id,
      ignore: false,
      sweep: false,
    })),
    incentives: incentivesList.map((i) => ({
      incid: i.id,
      ignore: false,
      sweep: false,
    })),
  };
};

const SweepTotals = ({ control, items, incentives }) => {
  const [itemCtrl, incCtrl] = useWatch({
    control,
    name: ["items", "incentives"],
  });

  const [itemsTotal, itemsIgnored] = itemCtrl.reduce(
    ([itemsTotal, itemsIgnored], cur) => {
      const item = arrayFindOrDie(items, (i) => i.id === cur.commlid);
      if (cur.ignore && cur.sweep) return [itemsTotal, itemsIgnored];
      if (!cur.ignore && !cur.sweep) return [itemsTotal, itemsIgnored];
      if (cur.ignore) return [itemsTotal, itemsIgnored + item.amount];
      return [itemsTotal + item.amount, itemsIgnored];
    },
    [0, 0]
  );

  const [itemsFinal, ignoredFinal] = incCtrl.reduce(
    ([itemsTotal, itemsIgnored], cur) => {
      const item = arrayFindOrDie(incentives, (i) => i.id === cur.incid);
      if (cur.ignore && cur.sweep) return [itemsTotal, itemsIgnored];
      if (!cur.ignore && !cur.sweep) return [itemsTotal, itemsIgnored];
      if (cur.ignore) return [itemsTotal, itemsIgnored + item.amount];
      return [itemsTotal + item.amount, itemsIgnored];
    },
    [itemsTotal, itemsIgnored]
  );

  return (
    <Box sx={{ textAlign: "right" }}>
      <Box>
        Total: <strong>{moneyFmt(itemsFinal)}</strong>
      </Box>
      <Box>
        Ignored: <strong>{moneyFmt(ignoredFinal)}</strong>
      </Box>
    </Box>
  );
};

const AgencySweepForm = ({
  payoutDate,
  agencyInfo,
  api,
  refresh,
  disabled,
}) => {
  const navigate = useNavigate();

  const defaultValues = genDefaultValues(
    agencyInfo.items,
    agencyInfo.incentives
  );
  const { control, handleSubmit, setValue, setError, reset } = useForm({
    defaultValues,
  });

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    {},
    () => {
      clearFormError();
      refresh();
    }
  );

  useEffect(() => {
    reset(genDefaultValues(agencyInfo.items, agencyInfo.incentives));
  }, [agencyInfo, reset]);

  const { fields } = useFieldArray({ control, name: "items" });
  const { fields: incFields } = useFieldArray({ control, name: "incentives" });

  const onSubmit = (data) => {
    const payload = {
      agency_id: agencyInfo.id,
      payout_date: payoutDate,
      items: data.items.filter((i) => i.ignore !== false || i.sweep !== false),
      incentives: data.incentives.filter(
        (i) => i.ignore !== false || i.sweep !== false
      ),
    };
    apiWrapper(api.sweepCommissions(payload));
  };

  return (
    <>
      {!agencyInfo.aps ? (
        <Alert
          sx={{ mb: 2 }}
          severity="error"
          action={
            <Button
              size="small"
              color="inherit"
              onClick={() => navigate(`/agencies/${agencyInfo.id}`)}
            >
              Fix
            </Button>
          }
        >
          APS info has not been entered for this agency. We cannot pay them
          without this!
        </Alert>
      ) : (
        <Stack direction="row-reverse" spacing={2}>
          <Button
            size="small"
            onClick={() => {
              fields.forEach((f, i) => {
                setValue(`items.${i}.ignore`, false);
                setValue(`items.${i}.sweep`, true);
              });
              incFields.forEach((f, i) => {
                setValue(`incentives.${i}.ignore`, false);
                setValue(`incentives.${i}.sweep`, true);
              });
            }}
          >
            Accept All
          </Button>
          <Button
            size="small"
            onClick={() => {
              fields.forEach((f, i) => {
                setValue(`items.${i}.ignore`, true);
                setValue(`items.${i}.sweep`, false);
              });
              incFields.forEach((f, i) => {
                setValue(`incentives.${i}.ignore`, true);
                setValue(`incentives.${i}.sweep`, false);
              });
            }}
          >
            Ignore All
          </Button>
          <Button
            size="small"
            onClick={() => {
              fields.forEach((f, i) => {
                setValue(`items.${i}.ignore`, false);
                setValue(`items.${i}.sweep`, false);
              });
              incFields.forEach((f, i) => {
                setValue(`incentives.${i}.ignore`, false);
                setValue(`incentives.${i}.sweep`, false);
              });
            }}
          >
            Clear All
          </Button>
        </Stack>
      )}

      {formErrState.hasErrors && (
        <FormLevelErrorAlert formErrStruct={formErrState} sx={{ my: 2 }} />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <CommissionsToSweepTable
          items={agencyInfo.items}
          control={control}
          fields={fields}
          setValue={setValue}
          disabled={disabled}
        />
        <Box sx={{ my: 4 }} />
        <IncentivesToSweepTable
          incentives={agencyInfo.incentives}
          control={control}
          fields={incFields}
          setValue={setValue}
          disabled={disabled}
        />
        <Box sx={{ my: 4 }} />
        <Grid2 container>
          <Grid2 xs={7} />
          <Grid2 xs={3}>
            <SweepTotals
              control={control}
              items={agencyInfo.items}
              incentives={agencyInfo.incentives}
            />
          </Grid2>
          <Grid2 xs={2}>
            <Box sx={{ mt: 2, textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="secondary"
                disabled={disabled}
              >
                Commit
              </Button>
            </Box>
          </Grid2>
        </Grid2>
      </form>
    </>
  );
};

export default AgencySweepForm;
