import { Chip } from "@mui/material";

const RoleChip = ({ variant, size, color, label, tooltip }) => (
  <Chip
    variant={variant}
    size={size}
    sx={{ marginRight: "2px" }}
    color={color}
    label={label}
  />
);

const SalesRoleChip = ({ variant, size }) => (
  <RoleChip
    variant={variant}
    size={size}
    color="info"
    label="SA"
    tooltip="Sales"
  />
);

const ServiceRoleChip = ({ variant, size }) => (
  <RoleChip
    variant={variant}
    size={size}
    color="secondary"
    label="SE"
    tooltip="Service"
  />
);

const AgentRoleChip = ({ variant, size }) => (
  <RoleChip
    variant={variant}
    size={size}
    color="warning"
    label="AG"
    tooltip="Agent"
  />
);

const PrincipalRoleChip = ({ variant, size }) => (
  <RoleChip
    variant={variant}
    size={size}
    color="success"
    label="PR"
    tooltip="Principal"
  />
);

const RepRoleChipList = ({ roleList, variant, size }) => {
  if (!variant) {
    variant = "outlined";
  }

  if (!size) {
    size = "small";
  }

  if (!Array.isArray(roleList) || roleList.length === 0) {
    return null;
  }

  return (
    <>
      {roleList.includes("principal") ? (
        <PrincipalRoleChip variant={variant} size={size} />
      ) : null}
      {roleList.includes("agent") ? (
        <AgentRoleChip variant={variant} size={size} />
      ) : null}
      {roleList.includes("service") ? (
        <ServiceRoleChip variant={variant} size={size} />
      ) : null}
      {roleList.includes("sales") ? (
        <SalesRoleChip variant={variant} size={size} />
      ) : null}
    </>
  );
};

export default RepRoleChipList;
