import { forwardRef, useContext } from "react";
import RHFAutocomplete from "./RHFAutocomplete";
import ApiDataContext from "../../ApiDataContext";

const RHFManufacturerPicker = forwardRef(
  ({ control, name, rules = undefined, ignoreErrors, ...rest }, ref) => {
    const { mfgrs } = useContext(ApiDataContext);
    const options = mfgrs.map((m) => ({ value: m.value, label: m.label }));

    return (
      <RHFAutocomplete
        control={control}
        ref={ref}
        name={name}
        rules={rules}
        options={options}
        ignoreErrors
        {...rest}
      />
    );
  }
);

export default RHFManufacturerPicker;
