import { useForm } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CommTableRow from "./CommTableRow";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import { useEffect, useMemo } from "react";
import CommissionIncentivesCard from "./CommissionIncentivesCard";
import { dateObjFormatToAnnArborDateTime } from "../../../utility";

const MATRIX_DEFAULTS = {
  agency_cct_initial: "",
  agency_cct_extension: "",
  agency_sam_initial: "", // these two aren't used, but just in case...
  agency_sam_extension: "",
  "drop-ship_cct_initial": "",
  "drop-ship_cct_extension": "",
  "drop-ship_sam_initial": "",
  "drop-ship_sam_extension": "",
};

const genDefaultValues = (commMatrix, commissionModel) => {
  const m = Object.fromEntries(
    Object.entries(commMatrix).map(([k, v]) => [k, v.amount / 100])
  );
  return { ...MATRIX_DEFAULTS, ...m };
};

const commModelEnumToStr = (commModelEnum) => {
  switch (commModelEnum) {
    case "receivables-v01":
      return "On Receivables";
    case "billables-v01":
      return "On Billables";
    default:
      return "Unknown!";
  }
};

const translateFormToPayload = (formData) =>
  Object.fromEntries(
    Object.entries(formData).map(([k, v]) => [
      k,
      v === "" ? undefined : Math.round(parseFloat(v) * 100),
    ])
  );

const ApsInfoCard = ({ apsInfo }) => {
  const infoEntered = !!apsInfo.entered_on;

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography sx={{ mb: 1 }}>APS Info</Typography>
      {infoEntered ? (
        <>
          <Typography variant="body2">
            Employee Name: <strong>{apsInfo.employee_name}</strong>
          </Typography>
          <Typography variant="body2">
            Employee Number: <strong>{apsInfo.employee_num}</strong>
          </Typography>
          <Typography variant="body2">
            Entered On:{" "}
            <strong>
              {dateObjFormatToAnnArborDateTime(apsInfo.entered_on, false)}
            </strong>
          </Typography>
        </>
      ) : (
        <Alert
          severity="warning"
          action={
            <Button color="inherit" onClick={apsInfo.fix_error_fn}>
              Fix
            </Button>
          }
        >
          No Info Entered
        </Alert>
      )}
    </Paper>
  );
};

const AgencyTabPanelCommissions = ({
  activeTab,
  commissionModel,
  commMatrix,
  agencyId,
  incentives,
  apsInfo,
  agencyRetired,
  api,
  refresh,
}) => {
  const defaultValues = useMemo(
    () => genDefaultValues(commMatrix, commissionModel),
    [commMatrix, commissionModel]
  );

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    mode: "onBlur",
  });

  const onSuccess = () => {
    refresh();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    onSuccess
  );

  const onSubmit = (data) => {
    const parsed = translateFormToPayload(data);
    apiWrapper(api.agencySetCommissionPlans(agencyId, parsed));
  };

  if (activeTab !== "commissions") return null;

  return (
    <Stack spacing={2}>
      <Alert severity="info">
        Current Commission Model:{" "}
        <strong>{commModelEnumToStr(commissionModel)}</strong>
      </Alert>
      <ApsInfoCard apsInfo={apsInfo} />
      <Card variant="outlined">
        <CardHeader title="Commission Matrix" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <FormLevelErrorAlert formErrStruct={formErrState} />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "145px" }}>Service Channel</TableCell>
                  <TableCell sx={{ width: "100px" }}>Therapy</TableCell>
                  <TableCell sx={{ width: "130px" }}>Generation</TableCell>
                  <TableCell>Effective Since</TableCell>
                  <TableCell>Set By</TableCell>
                  <TableCell sx={{ width: "100px" }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <CommTableRow
                  commModel={commissionModel}
                  name="agency_cct_initial"
                  control={control}
                  serviceChannel="Agency"
                  therapy="CCT"
                  generation="Initial"
                  commMatrix={commMatrix}
                />
                <CommTableRow
                  commModel={commissionModel}
                  name="agency_cct_extension"
                  control={control}
                  serviceChannel="Agency"
                  therapy="CCT"
                  generation="Extension"
                  commMatrix={commMatrix}
                />
                <CommTableRow
                  commModel={commissionModel}
                  name="drop-ship_cct_initial"
                  control={control}
                  serviceChannel="Drop Ship"
                  therapy="CCT"
                  generation="Initial"
                  commMatrix={commMatrix}
                />
                <CommTableRow
                  commModel={commissionModel}
                  name="drop-ship_cct_extension"
                  control={control}
                  serviceChannel="Drop Ship"
                  therapy="CCT"
                  generation="Extension"
                  commMatrix={commMatrix}
                />
                <CommTableRow
                  commModel={commissionModel}
                  name="drop-ship_sam_initial"
                  control={control}
                  serviceChannel="Drop Ship"
                  therapy="SAM"
                  generation="Initial"
                  commMatrix={commMatrix}
                />
                <CommTableRow
                  commModel={commissionModel}
                  name="drop-ship_sam_extension"
                  control={control}
                  serviceChannel="Drop Ship"
                  therapy="SAM"
                  generation="Extension"
                  commMatrix={commMatrix}
                />
              </TableBody>
            </Table>
          </CardContent>
          <CardActions sx={{ flexDirection: "row-reverse" }}>
            <Button type="submit" variant="contained" disabled={!isDirty}>
              Set
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              disabled={!isDirty}
              type="button"
              variant="outlined"
              color="error"
              onClick={() => reset()}
            >
              Reset
            </Button>
          </CardActions>
        </form>
      </Card>
      <CommissionIncentivesCard
        agencyId={agencyId}
        incentives={incentives}
        disabled={agencyRetired}
        api={api}
        refresh={refresh}
        commBonuses={[]}
      />
    </Stack>
  );
};

export default AgencyTabPanelCommissions;
