import { Fragment, useContext, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  apiCallSimplify,
  dateObjFormatToAnnArborDateTime,
  secondsToHumanFmt,
} from "../../../utility";
import PushPinIcon from "@mui/icons-material/PushPin";
import PublicIcon from "@mui/icons-material/Public";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import useSWRMutation from "swr/mutation";
import useSWR, { useSWRConfig } from "swr";
import PhoneIcon from "@mui/icons-material/Phone";
import { lightBlue, lightGreen, red } from "@mui/material/colors";
import ApiDataContext from "../../../ApiDataContext";
import { addHours } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const COMMENT_EDIT_WINDOW_HOURS = 1;

const updatePinState = async (api, commentId, targetState) => {
  const apiCall = targetState ? api.commentPin : api.commentUnpin;
  const result = await apiCall(commentId);
  if (result.status !== "ok") throw new Error(result.message);
};

const IsPublic = ({ is_public }) => {
  if (!is_public) return null;
  return (
    <Tooltip title="Public Comment" arrow>
      <span>
        <IconButton size="small" disabled>
          <PublicIcon fontSize="small" color="primary" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const Severity = ({ severity }) => {
  if (severity === "normal") return null;

  if (severity === "caution")
    return (
      <IconButton size="small" disabled>
        <WarningIcon fontSize="small" color="warning" />
      </IconButton>
    );

  return (
    <IconButton size="small" disabled>
      <ErrorIcon fontSize="small" color="error" />
    </IconButton>
  );
};

const Comment = ({ comment, name, editMode, onChange, curVal, inProgress }) => {
  if (editMode) {
    return (
      <>
        <Box sx={{ fontWeight: "bold", color: "text.secondary" }}>{name}</Box>
        <Box sx={{ whiteSpace: "pre-line" }}>
          <TextField
            disabled={inProgress}
            value={curVal}
            onChange={onChange}
            sx={{ pr: 16 }}
            InputProps={{ sx: { p: "3px" } }}
            multiline
            fullWidth
          />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ fontWeight: "bold", color: "text.secondary" }}>{name}</Box>
      <Box sx={{ whiteSpace: "pre-line" }}>{comment}</Box>
    </>
  );
};

const EditButton = ({ onClick, editMode, canEdit, onSubmit, inProgress }) => {
  if (!canEdit) return null;

  if (editMode) {
    return (
      <>
        <IconButton size="small" onClick={onSubmit} disabled={inProgress}>
          <CheckCircleIcon fontSize="small" />
        </IconButton>

        <IconButton size="small" onClick={onClick} disabled={inProgress}>
          <CancelIcon fontSize="small" />
        </IconButton>
      </>
    );
  }

  return (
    <IconButton size="small" onClick={onClick}>
      <EditIcon fontSize="small" />
    </IconButton>
  );
};

const CommentListItem = ({ api, c, liSx, listKey, readOnly }) => {
  const { mutate } = useSWRConfig();
  const { me } = useContext(ApiDataContext);
  const [editMode, setEditMode] = useState(false);
  const [newComment, setNewComment] = useState(c.comment);
  const [submitState, setSubmitState] = useState({
    in_progress: false,
    error: "",
  });

  const myComment = c.user_id === me.id;
  const created = new Date(c.created_on);
  const editCutoff = addHours(created, COMMENT_EDIT_WINDOW_HOURS);
  const canEdit = myComment && editCutoff > new Date();

  const { trigger } = useSWRMutation(
    `comment-pin-${c.id}`,
    async (key, { arg }) => {
      const { commentId, targetState } = arg;
      await updatePinState(api, commentId, targetState);
    },
    {
      onSuccess: () => mutate(listKey),
    }
  );

  const handleStartEdit = () => {
    setEditMode(true);
    setNewComment(c.comment);
  };

  const handleStopEdit = () => {
    setEditMode(false);
  };

  const handleEditSubmit = async () => {
    try {
      setSubmitState({
        in_progress: true,
        error: "",
      });
      await apiCallSimplify(api.updateComment(c.id, newComment));
      setSubmitState({
        in_progress: false,
        error: "",
      });
      mutate(listKey);
      handleStopEdit();
    } catch (err) {
      setSubmitState({
        in_progress: false,
        error: err.message,
      });
    }
  };

  return (
    <ListItem
      alignItems="flex-start"
      sx={liSx}
      secondaryAction={
        <Stack direction="row" spacing="2px">
          <IsPublic is_public={c.is_public} />
          <Severity severity={c.severity} />
          <IconButton
            size="small"
            color={c.is_pinned ? "error" : undefined}
            onClick={() =>
              trigger({ commentId: c.id, targetState: !c.is_pinned })
            }
            disabled={readOnly}
          >
            <PushPinIcon fontSize="small" />
          </IconButton>
          <EditButton
            inProgress={submitState.in_progress}
            canEdit={canEdit}
            editMode={editMode}
            onClick={() => {
              if (editMode) {
                handleStopEdit();
                return;
              }
              handleStartEdit();
            }}
            onSubmit={handleEditSubmit}
          />
        </Stack>
      }
    >
      <ListItemAvatar sx={{ pr: 2 }}>
        <Avatar
          alt={c.user_name}
          src={c.user_pic}
          sx={{ width: 48, height: 48 }}
          variant="rounded"
        />
      </ListItemAvatar>
      {editMode ? (
        <ListItemText
          primary={
            <Comment
              inProgress={submitState.in_progress}
              comment={c.comment}
              name={c.user_name}
              editMode={editMode}
              curVal={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
          }
          secondary={dateObjFormatToAnnArborDateTime(c.created_on, false)}
          secondaryTypographyProps={{ pt: 1 }}
        />
      ) : (
        <ListItemText
          primary={<Comment comment={c.comment} name={c.user_name} />}
          secondary={dateObjFormatToAnnArborDateTime(c.created_on, false)}
          secondaryTypographyProps={{ pt: 1 }}
        />
      )}
    </ListItem>
  );
};

const PhoneLogListItem = ({ c, patientName }) => {
  const from = c.direction === "inbound" ? patientName : c.caller_name;
  const to = c.direction === "outbound" ? patientName : c.callee_name;
  const name = `${from} -> ${to}`;

  const mainDesc = `${c.result}${
    c.duration > 0 ? ` - talked for ${secondsToHumanFmt(c.duration)}` : ""
  }`;

  let iconColor = red[400];
  if (c.direction === "inbound" && c.result === "Call connected")
    iconColor = lightGreen[400];
  if (c.direction === "outbound" && c.result === "Call connected")
    iconColor = lightBlue[400];

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar sx={{ pr: 2 }}>
        <Avatar
          alt={"Phone Call"}
          sx={{ width: 48, height: 48, bgcolor: iconColor }}
          variant="rounded"
        >
          <PhoneIcon fontSize="large" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Comment comment={mainDesc} name={name} />}
        secondary={dateObjFormatToAnnArborDateTime(c.created_on, false)}
        secondaryTypographyProps={{ pt: 1 }}
      />
    </ListItem>
  );
};

const PER_PAGE_COUNT = 10;

const CommentList = ({
  comments,
  api,
  listKey,
  readOnly,
  embedPhone,
  embedPatientName,
}) => {
  const [visibleCount, setVisibleCount] = useState(PER_PAGE_COUNT);

  const plogFetchKey = embedPhone ? `calllog-${embedPhone}` : null;
  const { data: plogData } = useSWR(plogFetchKey, async (key) => {
    const [, phone] = key.split("-");
    const res = await api.phoneLog(phone);
    if (res.status === "ok") return res.data;
    throw new Error(res.message);
  });

  const plogDataList = (plogData || []).map((l) => {
    l.created_on = l.date_time;
    return l;
  });
  const masterDataList = [...comments, ...plogDataList];
  masterDataList.sort((a, b) => {
    if (a.is_pinned === true && b.is_pinned === true) {
      return new Date(b.created_on) - new Date(a.created_on);
    }
    if (!a.is_pinned && !b.is_pinned) {
      return new Date(b.created_on) - new Date(a.created_on);
    }
    if (a.is_pinned) return -1;
    return 1;
  });

  const showMoreButton = masterDataList.length > visibleCount;
  const shownData = masterDataList.slice(0, visibleCount);

  return (
    <List>
      {shownData.map((c, i) => {
        let liSx = { "& .MuiListItemSecondaryAction-root": { top: "24px" } };
        if (c.severity === "caution") liSx.bgcolor = "bgCaution";
        if (c.severity === "serious") liSx.bgcolor = "bgError";

        return (
          <Fragment key={`comment-${c.id}`}>
            {i === 0 ? null : <Divider variant="inset" component="li" />}
            {"call_type" in c ? (
              <PhoneLogListItem c={c} patientName={embedPatientName} />
            ) : (
              <CommentListItem
                api={api}
                c={c}
                liSx={liSx}
                listKey={listKey}
                readOnly={readOnly}
              />
            )}
          </Fragment>
        );
      })}
      {showMoreButton && (
        <ListItem>
          <Box sx={{ width: "100%" }}>
            <Button
              fullWidth
              onClick={() => setVisibleCount(visibleCount + PER_PAGE_COUNT)}
            >
              Show More
            </Button>
          </Box>
        </ListItem>
      )}
    </List>
  );
};

export default CommentList;
