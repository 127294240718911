import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const BucketFilterControl = ({ opts, value, onChange }) => {
  if (!opts) {
    return null;
  }

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="filter-input-label">Filter</InputLabel>
      <Select
        value={value.toString()}
        onChange={onChange}
        label="Filter"
        fullWidth
      >
        <MenuItem value="">(No Filter)</MenuItem>
        {Object.entries(opts).map(([val, label]) => (
          <MenuItem key={val} value={val.toString()}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BucketFilterControl;
