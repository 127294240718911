import EventNoteIcon from "@mui/icons-material/EventNote";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Tooltip } from "@mui/material";
import { ucWord } from "../../../utility";

const iconFromType = (type) => {
  switch (type) {
    case "scheduler":
      return EventNoteIcon;
    case "office":
      return SupportAgentIcon;
    default:
      return QuestionMarkIcon;
  }
};

const ContactTypeIcon = ({ contactType }) => {
  const Icon = iconFromType(contactType);
  return (
    <Tooltip title={`Contact Type: ${ucWord(contactType)}`}>
      <Icon
        fontSize="small"
        sx={{ color: "text.secondary", width: "18px", my: "4px", mr: "8px" }}
      />
    </Tooltip>
  );
};

export default ContactTypeIcon;
