import WarningIcon from "@mui/icons-material/Warning";
import { Badge, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const WarningBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const WarningIconButton = ({ onClick, amt }) => {
  if (amt === 0) {
    return null;
  }

  return (
    <IconButton size="small" color="warning" onClick={onClick}>
      <WarningBadge badgeContent={amt} color="warning">
        <WarningIcon />
      </WarningBadge>
    </IconButton>
  );
};

export default WarningIconButton;
