import ClipboardListCheckIcon from "../../StandardComponents/Icons/ClipboardListCheckIcon";
import { Badge, Box, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import TicketContextDialog from "./TicketContextDialog";
import useSWR from "swr";
import { genericSWRFetcher, hydrateDateObj } from "../../../utility";

/**
 * @param {{
 *   type: TicketContext,
 *   ident: number,
 *   label: string,
 * }} context
 * @param {object} api
 * @constructor
 */
const TicketContextMenu = ({ api, context }) => {
  const fetcher = genericSWRFetcher(api.ticketContextList, (key) => {
    const [, context, ident] = key.split("-");
    return [context, ident];
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const { data, error, mutate } = useSWR(
    `ticketDashboard-${context.type}-${context.ident}`,
    fetcher
  );

  const handleIconClick = () => openDialog();
  const refreshData = () => mutate();

  if (error) {
    return (
      <Tooltip title={error.message} arrow>
        <Box component="span">
          <IconButton disabled>
            <Badge color="error" badgeContent="!!">
              <ClipboardListCheckIcon sx={{ p: "1px" }} />
            </Badge>
          </IconButton>
        </Box>
      </Tooltip>
    );
  }

  if (!data) {
    return (
      <IconButton disabled>
        <Badge color="default" badgeContent="?">
          <ClipboardListCheckIcon sx={{ p: "1px" }} />
        </Badge>
      </IconButton>
    );
  }

  const xData = data
    .map((t) => ({
      ...t,
      follow_up_on: hydrateDateObj(t.follow_up_on),
      finished_on: hydrateDateObj(t.finished_on),
      snooze_until: hydrateDateObj(t.snooze_until),
      created_on: hydrateDateObj(t.created_on),
      last_activity_on: hydrateDateObj(t.last_activity_on),
      last_viewed: hydrateDateObj(t.last_viewed),
    }))
    .map((t) => ({
      ...t,
      has_activity: t.last_activity_on > t.last_viewed,
      past_due: new Date() > t.follow_up_on,
    }));

  const onlyActive = xData.filter((t) => !t.finished_on);

  const hasActivity = xData.reduce(
    (acc, cur) => acc || cur.has_activity,
    false
  );
  const pastDue = onlyActive.reduce((acc, cur) => acc || cur.past_due, false);
  const allClosed = xData.reduce((acc, cur) => acc && !!cur.finished_on, true);

  const badgeCount = data.length;
  const badgeColor = pastDue
    ? "error"
    : hasActivity
    ? "info"
    : allClosed
    ? "default"
    : "secondary";

  return (
    <>
      <IconButton onClick={handleIconClick}>
        <Badge color={badgeColor} badgeContent={badgeCount}>
          <ClipboardListCheckIcon sx={{ p: "1px" }} />
        </Badge>
      </IconButton>

      <TicketContextDialog
        open={dialogOpen}
        onClose={() => closeDialog()}
        api={api}
        context={context}
        data={xData}
        refreshData={refreshData}
      />
    </>
  );
};

export default TicketContextMenu;
