import { useNavigate } from "react-router-dom";
import SectionHeading from "../PagePartials/SectionHeading";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { InternalLink } from "../StandardComponents/InternalLink";
import { dateObjFormatToSmallest } from "../../utility";

const summaryDate = (to) => {
  const dateStr = to.received_on || to.created_on;
  return dateObjFormatToSmallest(dateStr);
};

const PageTransferOrders = ({ pageData }) => {
  const navigate = useNavigate();

  return (
    <>
      <SectionHeading
        headingLabel="Transfer Orders"
        buttonLabel="New Transfer"
        buttonOnClick={() => navigate("/transfer-orders/new")}
      />

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>TO Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Origin</TableCell>
              <TableCell>Recipient</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.map((to) => (
              <TableRow key={to.id}>
                <TableCell>
                  <InternalLink to={`/transfer-orders/${to.id}`}>
                    {`TO-${to.id.toString().padStart(4, "0")}`}
                  </InternalLink>
                </TableCell>
                <TableCell>{to.status}</TableCell>
                <TableCell>
                  <InternalLink to={`/agencies/${to.from_agnc_id}`}>
                    {to.from_agnc_name}
                  </InternalLink>
                </TableCell>
                <TableCell>
                  <InternalLink to={`/agencies/${to.to_agnc_id}`}>
                    {to.to_agnc_name}
                  </InternalLink>
                </TableCell>
                <TableCell>{summaryDate(to)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PageTransferOrders;
