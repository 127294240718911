import {
  Checkbox,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import RPDateInput from "../../FormControls/RPDateInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { moneyFmt } from "../../../formatters";

const IPT_SX = {
  "& .MuiInputBase-input": { px: "4px", py: "6px", typography: "body2" },
};

const TR_SX = {
  pl: "1px",
  pr: "1px",
};

/**
 * Translates the model value into something that can be shown in the input box
 *
 * @param {number|null} modelVal
 * @return {string}
 */
const formatChargeForInput = (modelVal) => {
  let val = "";
  if (modelVal === null) return val;
  val = modelVal.toString(10).padStart(3, "0");
  return `${val.slice(0, -2)}.${val.slice(-2)}`;
};

const OrderFormEditRow = ({ line, onChange, onCancel, onCommit }) => {
  let charge = formatChargeForInput(line.charge);
  let qty = line.qty === null ? "" : line.qty.toString(10);
  return (
    <TableRow>
      <TableCell sx={TR_SX}>
        <Checkbox
          disableRipple
          type="checkbox"
          inputProps={{ m: 0, p: 0 }}
          sx={{ m: 0, p: 0, ml: "-3px" }}
          size="small"
          checked={line.commissionable}
          onChange={(e) => onChange("commissionable", e.target.checked)}
        />
      </TableCell>
      <TableCell sx={TR_SX}>
        <RPDateInput
          size="small"
          sx={IPT_SX}
          name="dos_start"
          value={line.dos_start}
          onChange={(val) => {
            onChange("dos_start", val);
          }}
        />
      </TableCell>
      <TableCell sx={TR_SX}>
        <RPDateInput
          size="small"
          sx={IPT_SX}
          name="dos_end"
          value={line.dos_end}
          onChange={(val) => {
            onChange("dos_end", val);
          }}
        />
      </TableCell>
      <TableCell sx={TR_SX}>
        <TextField
          size="small"
          inputProps={{ maxLength: 5 }}
          sx={IPT_SX}
          name="hcpc"
          value={line.hcpc}
          onChange={(e) => onChange("hcpc", e.target.value)}
        />
      </TableCell>
      <TableCell sx={TR_SX}>
        <TextField
          size="small"
          inputProps={{ maxLength: 5 }}
          sx={IPT_SX}
          name="modifier"
          value={line.modifier}
          onChange={(e) => onChange("modifier", e.target.value)}
        />
      </TableCell>
      <TableCell sx={TR_SX}>
        <TextField
          size="small"
          inputProps={{ maxLength: 2 }}
          sx={IPT_SX}
          name="place_of_service"
          value={line.place_of_service}
          onChange={(e) => onChange("place_of_service", e.target.value)}
        />
      </TableCell>
      <TableCell sx={TR_SX}>
        <TextField
          size="small"
          fullWidth
          sx={IPT_SX}
          name="description"
          value={line.description}
          onChange={(e) => onChange("description", e.target.value)}
        />
      </TableCell>
      <TableCell sx={TR_SX}>
        <TextField
          size="small"
          sx={IPT_SX}
          name="qty"
          value={qty}
          onChange={(e) => onChange("qty", e.target.value)}
        />
      </TableCell>
      <TableCell sx={TR_SX}>
        <TextField
          size="small"
          sx={IPT_SX}
          name="charge"
          value={charge}
          onChange={(e) => onChange("charge", e.target.value)}
        />
      </TableCell>
      <TableCell sx={{ pr: "2px", pl: "6px" }}>
        {moneyFmt(line.qty * line.charge)}
      </TableCell>
      <TableCell sx={TR_SX}>
        <Stack direction="row">
          <IconButton size="small" onClick={onCommit}>
            <CheckCircleIcon fontSize="smaller" />
          </IconButton>
          <IconButton size="small" onClick={onCancel}>
            <CancelIcon fontSize="smaller" />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default OrderFormEditRow;
