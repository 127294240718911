import StdFormDialog from "../PagePartials/StdFormDialog";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import RPZipInput from "../FormControls/RPZipInput";
import RPStatePicker from "../FormControls/RPStatePicker";
import RPPhoneNumberInput from "../FormControls/RPPhoneNumberInput";
import RPDateInput from "../FormControls/RPDateInput";
import { dateObjToDateStr, nowDate, reformatDate } from "../../utility";
import RPDatePickerInput from "../FormControls/RPDatePickerInput";
import { forwardRef } from "react";

const ShipWithoutContactField = forwardRef(
  ({ label, helperText, error, fullWidth, ...props }, ref) => {
    const helpText =
      "Checking this will indicate that you wish a unit to be shipped to this patient even though the patient has not necessarily been contacted. The logistics team will get this information in their own work queues so they are aware of this decision.";

    return (
      <Tooltip title={helpText} arrow placement="top-start">
        <FormControlLabel
          control={<Checkbox {...props} inputRef={ref} />}
          label={label}
        />
      </Tooltip>
    );
  }
);

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  const FIELDS = [
    {
      type: "section",
      label: "Initial Contact Info",
      subheader: "Changing these fields will update the Patient record.",
    },
    {
      name: "acked_on",
      label: "Initial Contact Date",
      component: RPDateInput,
      width: 3,
    },
    {
      name: "phone",
      label: "Confirm Phone Number",
      component: RPPhoneNumberInput,
      width: 3,
    },
    {
      name: "email",
      label: "Confirm Email Address",
      component: TextField,
      width: 5,
    },
    {
      type: "section",
      label: "Fulfillment Instructions",
      subheader: "What does the logistics team need to know?",
    },
    {
      name: "pref_arrive_on",
      label: "Promised By Date",
      component: RPDatePickerInput,
      width: 3,
    },
    {
      type: "plain",
      component: Box,
      width: 1,
    },
    {
      name: "ship_without_contact",
      label: "Ship Without Contact?",
      component: ShipWithoutContactField,
      width: 8,
    },
    {
      type: "section",
      label: "Shipping Address",
      subheader: "Changing these fields will NOT change the Patient record.",
    },
    {
      name: "addr_street1",
      label: "Street Address",
      component: TextField,
      width: 8,
    },
    {
      name: "addr_street2",
      label: "Apt / Suite",
      component: TextField,
      width: 4,
    },
    { name: "addr_city", label: "City", component: TextField, width: 5 },
    {
      name: "addr_state",
      label: "State",
      component: RPStatePicker,
      width: 4,
    },
    { name: "addr_zip", label: "Zip Code", component: RPZipInput, width: 3 },
  ];

  const initialFormVals = {
    acked_on: nowDate(),
    phone: initialVals.phone,
    email: initialVals.email,
    pref_arrive_on: null,
    addr_street1: initialVals.eoc.addr_street1,
    addr_street2: initialVals.eoc.addr_street2,
    addr_city: initialVals.eoc.addr_city,
    addr_state: initialVals.eoc.addr_state,
    addr_zip: initialVals.eoc.addr_zip,
  };

  return (
    <StdFormDialog
      open={open}
      onClose={onClose}
      title="Initial Patient Call"
      onComplete={() => {
        onClose();
        refresh();
      }}
      fields={FIELDS}
      submitCall={(data) => {
        const payload = {
          phone: data.phone,
          email: data.email,
          acked_on: reformatDate(data.acked_on),
          pref_arrive_on: dateObjToDateStr(data.pref_arrive_on),
          ship_without_contact: !!data.ship_without_contact,
          addr_street1: data.addr_street1,
          addr_street2: data.addr_street2,
          addr_city: data.addr_city,
          addr_state: data.addr_state,
          addr_zip: data.addr_zip,
        };

        return api.srvcSetAckedOn(initialVals.id, payload);
      }}
      initialVals={initialFormVals}
    />
  );
};
