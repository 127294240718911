import RHFAutocomplete from "./RHFAutocomplete";

const OPTIONS = [
  { value: "standard", label: "Standard Process" },
  { value: "inverse", label: "Inverse Process" },
];

const RHFExtensionProcessPicker = (props) => {
  return <RHFAutocomplete options={OPTIONS} {...props} />;
};

RHFExtensionProcessPicker.byVal = (val) => OPTIONS.find((o) => o.value === val);

export default RHFExtensionProcessPicker;
