import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Stack,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrescription } from "@fortawesome/free-solid-svg-icons";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { InternalLink } from "../../StandardComponents/InternalLink";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import WarningIconButton from "../../StandardComponents/WarningIconButton";
import {
  BusinessActionManager,
  useDialogCtrl,
} from "../../BusinessActions/BusinessActionManager";
import CurrentRxIconButton from "../../StandardComponents/CurrentRxIconButton";
import MediaShowDialog from "../../PagePartials/MediaShowDialog";
import ReceiptIcon from "@mui/icons-material/Receipt";
import KeyValueList from "../../PagePartials/KeyValueList";
import { genToStr } from "../../../utility";

const RxCardSubheader = ({ rx }) => {
  const name = [rx.rxer_name_first, rx.rxer_name_last, rx.rxer_credential]
    .filter((x) => !!x)
    .join(" ");
  return <InternalLink to={`/prescribers/${rx.rxer_npi}`}>{name}</InternalLink>;
};

const fmtRxCardTitle = (generation, duration) => {
  return `${genToStr(generation)} — ${duration} days`;
};

const getCurRxDoc = (attachList) =>
  attachList.find(
    (att) => att.tags.includes("_rx") && att.tags.includes("_current")
  );

const RxCard = ({ rx, eocRef, api, refresh }) => {
  const navigate = useNavigate();
  const [showCautions, setShowCautions] = useState(true);
  const [showCurRxDoc, setShowCurRxDoc] = useState(null);
  const toggleShowCautions = () => setShowCautions((prev) => !prev);

  const curRxDoc = getCurRxDoc(rx.attach);
  const cautionStrList = rx.rules.map(([, c]) => c);
  const hasCautions = rx.rules.length > 0 || null;

  const { openStates, openDialog, closeDialogs } =
    useDialogCtrl(cautionStrList);

  const rxWithSomeEoc = { ...rx };
  rxWithSomeEoc.addr_street1 = eocRef.addr_street1;
  rxWithSomeEoc.addr_street2 = eocRef.addr_street2;
  rxWithSomeEoc.addr_city = eocRef.addr_city;
  rxWithSomeEoc.addr_state = eocRef.addr_state;
  rxWithSomeEoc.addr_zip = eocRef.addr_zip;
  rxWithSomeEoc.phone = eocRef.phone;
  rxWithSomeEoc.email = eocRef.email;

  return (
    <>
      <Card
        key={rx.id}
        variant="outlined"
        sx={{ "& .MuiCardContent-root:last-child": { pb: 2 } }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ width: 36, height: 36 }}>
              <FontAwesomeIcon icon={faPrescription} />
            </Avatar>
          }
          title={fmtRxCardTitle(rx.gen, rx.duration)}
          subheader={<RxCardSubheader rx={rx} />}
          action={
            <Stack direction="row" spacing={1}>
              <IconButton
                size="small"
                onClick={() => navigate(`/orders/${rx.order.id}`)}
              >
                <ReceiptIcon />
              </IconButton>
              {curRxDoc && (
                <CurrentRxIconButton
                  onClick={() => setShowCurRxDoc(curRxDoc)}
                />
              )}
              <WarningIconButton
                onClick={toggleShowCautions}
                amt={rx.rules.length}
              />
              <IconButton
                size="small"
                onClick={() => navigate(`/rxs/${rx.id}`)}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Stack>
          }
          sx={{ pb: 0 }}
        />
        <CardContent sx={{ pt: 0 }}>
          <KeyValueList
            kvData={{
              "Billing Status": rx.order.status,
              "Service Channel": rx.service_channel,
            }}
          />
        </CardContent>
        {hasCautions && (
          <Collapse in={showCautions}>
            <CardContent sx={{ pt: 0 }}>
              <Stack direction="column" spacing={1}>
                {rx.rules.map((r) => (
                  <Alert
                    key={r[0]}
                    variant="outlined"
                    severity="warning"
                    action={
                      <Button onClick={() => openDialog(r[1])} size="small">
                        Fix
                      </Button>
                    }
                  >
                    {r[0]}
                  </Alert>
                ))}
              </Stack>
            </CardContent>
          </Collapse>
        )}
      </Card>
      {showCurRxDoc && (
        <MediaShowDialog
          api={api}
          onClose={() => setShowCurRxDoc(null)}
          open={true}
          attachment={showCurRxDoc}
        />
      )}
      <BusinessActionManager
        pageData={rxWithSomeEoc}
        openStates={openStates}
        closeDialogs={closeDialogs}
        actionList={cautionStrList}
        api={api}
        refresh={refresh}
      />
    </>
  );
};

export default RxCard;
