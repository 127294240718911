import { InternalLink } from "./Components/StandardComponents/InternalLink";
import { ucWord } from "./utility";
import { OPTIONS as rawServiceCodes } from "../src/Components/FormControlsRHF/RHFUPSServiceCodePicker";

const serviceCodes = rawServiceCodes.reduce((acc, cur) => {
  acc[cur.value] = cur.label;
  return acc;
}, {});

const CUR_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const SIMPLE_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const phoneFmt = (ipt, linkIt = false) => {
  if (!ipt) {
    return "";
  }

  const hoesInDifferentAreaCodes = ipt.substring(0, 3);
  const exchange = ipt.substring(3, 6);
  const number = ipt.substring(6, 10);
  const extension = ipt.substring(10);

  const label = `(${hoesInDifferentAreaCodes}) ${exchange}-${number}${
    extension ? ` x${extension}` : ""
  }`;

  if (linkIt) {
    return <InternalLink to={`tel:${ipt}`}>{label}</InternalLink>;
  }

  return label;
};

export const linkFmt = (ipt, target) => {
  return <InternalLink to={target}>{ipt}</InternalLink>;
};

export const icd10Fmt = (ipt) => {
  if (ipt.length <= 3) {
    return ipt;
  }

  return `${ipt.slice(0, 3)}.${ipt.slice(3)}`;
};

export const ssnFmt = (ipt) => {
  if (!ipt) {
    return "";
  }
  const a = ipt.substring(0, 3);
  const b = ipt.substring(3, 5);
  const c = ipt.substring(5);
  return `${a}-${b}-${c}`;
};

export const bytesToHumanReadable = (ipt) => {
  const prefixes = ["B", "KB", "MB", "GB", "TB", "PB"];

  if (ipt < 1000) {
    return `${ipt.toString()} ${prefixes[0]}`;
  }

  for (let i = 1; i < prefixes.length; i += 1) {
    const localRational = ipt / Math.pow(1024, i);

    if (localRational < 100) {
      const numPart = (Math.floor(localRational * 10) / 10)
        .toFixed(1)
        .toString();
      return `${numPart} ${prefixes[i]}`;
    }

    if (localRational < 1000) {
      const numPart = Math.floor(localRational).toString();
      return `${numPart} ${prefixes[i]}`;
    }
  }
};

export const moneyFmt = (amt, cents = true) => {
  if (cents) return CUR_FORMATTER.format(amt / 100);
  return SIMPLE_FORMATTER.format(amt / 100);
};

export const fmtInjurySite = (injurySite) =>
  injurySite
    .split("-")
    .reverse()
    .map((b) => ucWord(b))
    .join(" ");

export const fmtItemCodeLabel = (item_code, def = "Unknown Item") => {
  const itemCodeToLabel = {
    vt4: "Vascutherm 4",
    sam2: "SAM 2.0",
    "rec+": "Pulsar REC+",
  };

  return itemCodeToLabel[item_code] || def;
};

export const fmtUpsServiceCodeLabel = (code, def = "Unknown Code") => {
  return serviceCodes[code] || def;
};
