import { useEffect, useState } from "react";
import StandardDialog from "../../StandardComponents/StandardDialog";
import { Alert, Box, Button, Stack } from "@mui/material";
import TicketCCAppendPicker from "./TicketCCAppendPicker";
import ExpandableAlert from "../../StandardComponents/ExpandableAlert";
import { apiCallSimplify } from "../../../utility";

const TicketDescriptionFormDialog = ({
  open,
  onClose,
  onSuccess,
  api,
  ticketId,
  curCCList,
}) => {
  const [ccNotifyOnAll, setCCNotifyOnAll] = useState([]);
  const [ccNotifyOnClose, setCCNotifyOnClose] = useState([]);
  const [submitState, setSubmitState] = useState({
    submitting: false,
    error: null,
  });

  useEffect(() => {
    setCCNotifyOnAll([]);
    setCCNotifyOnClose([]);
  }, [curCCList]);

  const handleClose = () => {
    setCCNotifyOnAll([]);
    setCCNotifyOnClose([]);
    onClose();
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();

    const data = { new_cc: [] };
    ccNotifyOnAll.forEach((v) => {
      data.new_cc.push({
        user_id: parseInt(v.user_id, 10),
        cc_mode: "notify_on_all",
      });
    });
    ccNotifyOnClose.forEach((v) => {
      data.new_cc.push({
        user_id: parseInt(v.user_id, 10),
        cc_mode: "notify_on_close",
      });
    });

    try {
      setSubmitState({ submitting: true, error: null });
      await apiCallSimplify(api.addTicketCCUsers(ticketId, data));
      setSubmitState({ submitting: false, error: null });
      onSuccess();
      onClose();
    } catch (err) {
      setSubmitState({
        submitting: false,
        error: err.message,
      });
    }
  };

  return (
    <StandardDialog
      open={open}
      onClose={handleClose}
      component="form"
      onSubmit={onSubmit}
      title="Add Tracking Users"
      actions={
        <Button
          disabled={submitState.submitting}
          type="submit"
          variant="contained"
        >
          Save Change
        </Button>
      }
    >
      <Stack direction="column" spacing={2}>
        <Box />
        {submitState.error !== null && (
          <Alert severity="error">{submitState.error}</Alert>
        )}
        <TicketCCAppendPicker
          label="Tracking Users"
          api={api}
          fixedList={curCCList.filter((c) => c.cc_mode === "notify_on_all")}
          value={ccNotifyOnAll}
          onChange={(a, b, reason, newVal) => {
            if (reason === "clear") {
              setCCNotifyOnAll([]);
              return;
            }
            setCCNotifyOnAll((prev) => [...prev, newVal.option]);
          }}
        />
        <TicketCCAppendPicker
          label="Notify only on Ticket Complete"
          api={api}
          fixedList={curCCList.filter((c) => c.cc_mode === "notify_on_close")}
          value={ccNotifyOnClose}
          onChange={(a, b, reason, newVal) => {
            if (reason === "clear") {
              setCCNotifyOnClose([]);
              return;
            }
            if (reason === "removeOption") {
              setCCNotifyOnClose((prev) => [
                ...prev.filter((x) => x.user_id !== newVal.option.user_id),
              ]);
              return;
            }
            setCCNotifyOnClose((prev) => [...prev, newVal.option]);
          }}
        />
        <ExpandableAlert title="Why Can't I Remove Some Users?" defaultOpen>
          For now, a given user is only allowed to remove themselves from
          tracking a given ticket. Owners of the ticket may add users to be
          tracking, but cannot remove them from it.
        </ExpandableAlert>
      </Stack>
    </StandardDialog>
  );
};

export default TicketDescriptionFormDialog;
