import { useState } from "react";
import { Map } from "immutable";
import { Alert, Button, Divider, Grid } from "@mui/material";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import SectionHeading from "../PagePartials/SectionHeading";
import RPAgencyPicker from "../FormControls/RPAgencyPicker";
import TransferOrderItemListPicker from "./PageTransferOrderNew/TransferOrderItemListPicker";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";

const PageTransferOrderNew = ({ api }) => {
  const navigate = useNavigate();

  const [pageLevelError, setPageLevelError] = useState("");
  const [curInventory, setCurInventory] = useState(null);

  const [fromAgency, setFromAgency] = useState(null);
  const [toAgency, setToAgency] = useState(null);

  const [isShifted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      from_agnc_id: fromAgency,
      to_agnc_id: toAgency,
      lines: curInventory
        .toArray()
        .map(([, l]) => ({
          item_type: l.get("item_type"),
          amount: l.get("xfer"),
          item_id: l.get("item_id"),
        }))
        .filter((l) => l.amount > 0),
    };

    api
      .addTo(payload)
      .then((resp) => {
        if (resp.status === "error") {
          setPageLevelError(resp.message);
          return;
        }

        if (resp.status === "validation") {
          setPageLevelError(
            Object.entries(resp.errors).reduce(
              (acc, [field, errMsg]) => `${acc}, ${field}: ${errMsg}`,
              ""
            )
          );
          return;
        }
        navigate(`/transfer-orders/${resp.data}`);
      })
      .catch((err) => {
        setPageLevelError(err.message);
      });
  };

  const handleChangeToAgency = (id) => setToAgency(id);

  const handleChangeFromAgency = (id) => {
    setFromAgency(id);
    setCurInventory(null);
    api
      .invSummaryForAgency(id)
      .then((resp) => {
        if (resp.status === "ok") {
          const inv = Map(
            resp.data.reduce((acc, cur) => {
              if (cur.available === 0) return acc;
              cur.xfer = 0;
              cur.orig = cur.available;
              acc[cur.item_id] = Map(cur);
              return acc;
            }, {})
          );
          setCurInventory(inv);
          return;
        }
        setPageLevelError(resp.message);
        setFromAgency(null);
      })
      .catch((err) => {
        setPageLevelError(err.message);
        setFromAgency(null);
      });
  };

  const handleMoveInventory = (id, amt) => {
    const idStr = id.toString();
    setCurInventory((prev) => {
      const curAmt = prev.getIn([idStr, "available"]);
      const curXfer = prev.getIn([idStr, "xfer"]);
      const orig = prev.getIn([idStr, "orig"]);

      const newAmt = Math.min(Math.max(0, curAmt - amt), orig);
      const newXfer = Math.min(Math.max(0, curXfer + amt), orig);

      return prev
        .setIn([idStr, "xfer"], newXfer)
        .setIn([idStr, "available"], newAmt);
    });
  };

  const isReadyForSubmission = () => {
    if (!curInventory) {
      return false;
    }
    const total = curInventory.reduce((acc, item) => acc + item.get("xfer"), 0);
    return !!(fromAgency && toAgency && total > 0);
  };

  return (
    <>
      <BackLinkHeader title="Cancel New Transfer" to="/transfer-orders" />
      <SectionHeading headingLabel="New Transfer" />
      {pageLevelError && (
        <Alert
          sx={{ mb: 2 }}
          severity="error"
          onClose={() => setPageLevelError("")}
        >
          {pageLevelError}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Grid container columnSpacing={2}>
          <Grid item xs={5}>
            <RPAgencyPicker
              size="small"
              helperText=" "
              name="from_agency"
              label="From Agency"
              api={api}
              value={fromAgency}
              onChange={handleChangeFromAgency}
            />
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <ArrowRightAltIcon fontSize="large" />
          </Grid>
          <Grid item xs={5}>
            <RPAgencyPicker
              size="small"
              helperText=" "
              name="to_agency"
              label="To Agency"
              api={api}
              value={toAgency}
              onChange={handleChangeToAgency}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mb: 2 }} />
          </Grid>
          {!!curInventory && (
            <Grid item xs={12}>
              <TransferOrderItemListPicker
                curInv={curInventory}
                handleMoveInv={handleMoveInventory}
                isShifted={isShifted}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ flexDirection: "row-reverse", display: "flex", pt: 2 }}
        >
          <Button
            variant="contained"
            color="secondary"
            disabled={!isReadyForSubmission()}
            onClick={handleSubmit}
          >
            Create Transfer
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default PageTransferOrderNew;
