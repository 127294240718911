import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";

const SignaturesNeedingReviewTable = ({ sigs }) => {
  return (
    <Table
      size="small"
      sx={{
        tableLayout: "fixed",
        "& td": {
          whiteSpace: "nowrap",
          overflowX: "clip",
          textOverflow: "ellipsis",
        },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "200px" }}>Prescriber</TableCell>
          <TableCell>Practice</TableCell>
          <TableCell sx={{ width: "170px" }}>Rep</TableCell>
          <TableCell sx={{ width: "110px" }}>Program</TableCell>
          <TableCell sx={{ width: "70px" }}>Rxs</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sigs.map((s) => {
          const key = `${s.rxer_id}-${s.erl_therapy}-${s.erl_item_code}`;
          const prescriber = (
            <InternalLink
              to={`/prescribers/${s.rxer_npi}`}
              state={{ activeTab: "enrollments" }}
            >
              {[s.rxer_name_first, s.rxer_name_last, s.rxer_credential]
                .filter(Boolean)
                .join(" ")}
            </InternalLink>
          );
          const rep = (
            <InternalLink to={`/reps/${s.rep_id}`}>{s.rep_name}</InternalLink>
          );
          const practice = (
            <InternalLink to={`/practices/${s.practice_id}`}>
              {s.practice_name}
            </InternalLink>
          );
          const program = `${s.erl_therapy.toUpperCase()}-${s.erl_item_code.toUpperCase()}`;
          return (
            <TableRow key={key}>
              <TableCell>{prescriber}</TableCell>
              <TableCell>{practice}</TableCell>
              <TableCell>{rep}</TableCell>
              <TableCell>{program}</TableCell>
              <TableCell>{s.rx_count}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const SignaturesNeedingReviewCard = ({ sigs, title, subheader }) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent>
        {sigs.length === 0 ? (
          <Alert severity="success">
            Congrats! All signatures have been reviewed!
          </Alert>
        ) : (
          <SignaturesNeedingReviewTable sigs={sigs} />
        )}
      </CardContent>
    </Card>
  );
};

export default SignaturesNeedingReviewCard;
