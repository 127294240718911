import { FormControl, MenuItem, Select } from "@mui/material";

const BGYearSelect = ({ indexYear = 2023, ...rest }) => {
  const now = new Date();
  const yearCount = now.getFullYear() - indexYear;
  const menuItems = Array(yearCount + 1)
    .fill(indexYear)
    .map((d, a) => d + a)
    .reverse();

  if (menuItems.length === 0) {
    menuItems.push(indexYear);
  }

  return (
    <FormControl>
      <Select {...rest} displayEmpty>
        <MenuItem value="">Select Year</MenuItem>
        {menuItems.map((i) => (
          <MenuItem key={i} value={i}>
            {i.toString()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BGYearSelect;
