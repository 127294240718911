import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import LogEntry from "./LogEntry";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

const AuditNoteIcon = ({ ctx }) => {
  if (ctx.type !== "trueup") return null;
  if (!ctx.memo) return null;
  return (
    <Box sx={{ textAlign: "center" }}>
      <Tooltip title={ctx.memo} arrow placement="top-end">
        <TextSnippetIcon fontSize="small" sx={{ verticalAlign: "middle" }} />
      </Tooltip>
    </Box>
  );
};

/**
 * @param {Array<{
 *   date: string,
 *   change: number,
 *   balance: number
 * }>} tableData
 */
const ItemHistoryTable = ({ tableData }) => {
  return (
    <Table size="small" sx={{ "& tr td:nth-of-type(5)": { px: 0 } }}>
      <TableHead>
        <TableRow>
          <TableCell>Date/Time</TableCell>
          <TableCell>Action</TableCell>
          <TableCell>Change</TableCell>
          <TableCell>Balance</TableCell>
          <TableCell sx={{ width: "35px" }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map((l, i) => {
          return (
            <TableRow key={i}>
              <TableCell>{l.date}</TableCell>
              <TableCell>
                <LogEntry ctx={l.ctx} />
              </TableCell>
              <TableCell>{l.change > 0 ? `+${l.change}` : l.change}</TableCell>
              <TableCell>{l.balance}</TableCell>
              <TableCell>
                <AuditNoteIcon ctx={l.ctx} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ItemHistoryTable;
