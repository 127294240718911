import { format } from "date-fns";
import KeyValueList from "../../PagePartials/KeyValueList";
import { Alert, Box, Button, Divider, Grid, Typography } from "@mui/material";
import { phoneFmt } from "../../../formatters";
import { grey } from "@mui/material/colors";
import SectionHeading from "../../PagePartials/SectionHeading";
import { formatAddr } from "../../../utility";
import MaskedSSN from "../../StandardComponents/MaskedSSN";
import { useNavigate } from "react-router-dom";
import CommentFeed from "../../StandardComponents/CommentFeed";

const Addr = ({ address }) => {
  const [line1, line2] = address.split("\n");
  if (!line2) {
    return line1;
  }

  return (
    <Box component="span" sx={{ display: "inline-block", textAlign: "right" }}>
      {line1}
      <br />
      {line2}
    </Box>
  );
};

const formatName = (fd) => [fd.name_first, fd.name_last].join(" ");

const formatRxer = (fd) =>
  [fd.rxer_name_first, fd.rxer_name_last, fd.rxer_credential].join(" ");

const formatScriptLength = (fd) => {
  if (!fd.rx_length) return "";
  return `${fd.rx_length} days`;
};

const formatYesNoCheckbox = (field) => (field ? "Yes" : "No");

const formatICD10 = (fd) => fd.icd10s.map((i) => i.code).join(", ");

const formatTreatmentSites = (fd) => fd.treatment_sites.join(", ");

const formatPhoneNumber = (fd) => (fd.phone ? phoneFmt(fd.phone) : "");
const formatEmpNumber = (fd) =>
  fd.employer_phone ? phoneFmt(fd.employer_phone) : "";

const ATR_MAP = {
  prescriber: "Prescriber",
  rep_name: "Sales Rep",
  agency_name: "Agency",
  date_received: "Date Received",
  referral: "Referral",
};

const PRS_MAP = {
  name: "Patient Name",
  date_of_birth: "Date of Birth",
  gender: "Gender",
  lang: "Language Preference",
  ssn: "SSN",
};

const CON_MAP = {
  address: "Address",
  email: "Email",
  phone: "Phone Number",
};

const INJ_MAP = {
  date_of_injury: "Date of Injury",
  employer_name: "Employer Name",
  employer_phone: "Employer Phone",
  icd10s: "ICD-10 Codes",
};

const TRT_MAP = {
  item_name: "Unit",
  therapy: "Therapy",
  rx_length: "Script Length",
  surgical: "Surgical",
  date_of_surgery: "Date of Surgery",
  treatment_sites: "Treatment Sites",
};

const INS_MAP = {
  org_id: "Payor Organization",
  claim_number: "Claim Number",
  ins_addr_street1: "Payor Street Address",
  ins_addr_street2: "Payor Suite/PO Box",
  ins_addr_city: "Payor City",
  ins_addr_state: "Payor State",
  ins_addr_zip: "Payor Zip",
  adj_name: "Adjuster Name",
  adj_phone: "Adjuster Phone",
  adj_email: "Adjuster Email",
  adj_fax: "Adjuster Fax",
  ncm_name: "Nurse Case Manager Name",
  ncm_phone: "Nurse Case Manager Phone",
  ncm_email: "Nurse Case Manager Email",
  ncm_fax: "Nurse Case Manager Fax",
};

const toKVDatStore = (lblMap, formData) =>
  Object.fromEntries(
    Object.entries(lblMap).map(([name, label]) => [
      label,
      formData[name] || "[NOT ENTERED]",
    ])
  );

const GridSection = ({ title, data, children, excludeDivider }) => {
  return (
    <>
      <Grid item xs={8} sx={{ pt: 2 }}>
        <Typography sx={{ mt: 1, color: grey[500] }} variant="h6">
          {title}
        </Typography>
        <KeyValueList kvData={data} />
        {children}
      </Grid>
      <Grid item xs={4} />
      {!excludeDivider && (
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Divider />
        </Grid>
      )}
    </>
  );
};

const IntakeRO = ({ pageData, api }) => {
  const navigate = useNavigate();

  const formData = { ...pageData.form };
  formData.name = formatName(pageData.form);
  formData.address = <Addr address={formatAddr(pageData.form)} />;
  formData.phone = formatPhoneNumber(pageData.form);
  formData.prescriber = formatRxer(pageData.form);
  formData.ssn = <MaskedSSN ssn={pageData.form.ssn} />;
  formData.icd10s = formatICD10(pageData.form);
  formData.rx_length = formatScriptLength(pageData.form);
  formData.surgical = formatYesNoCheckbox(pageData.form.surgical);
  formData.treatment_sites = formatTreatmentSites(pageData.form);
  formData.employer_phone = formatEmpNumber(pageData.form);
  formData.referral = formatYesNoCheckbox(pageData.form.referral);

  const atrDat = toKVDatStore(ATR_MAP, formData);
  const prsDat = toKVDatStore(PRS_MAP, formData);
  const conDat = toKVDatStore(CON_MAP, formData);
  const injDat = toKVDatStore(INJ_MAP, formData);
  const trtDat = toKVDatStore(TRT_MAP, formData);
  const insDat = toKVDatStore(INS_MAP, formData);

  return (
    <>
      <SectionHeading
        headingLabel={`Intake #${pageData.form.id.toString().padStart(4, "0")}`}
      />
      {formData.status === "rejected" && (
        <Alert severity="error">
          This script was rejected on{" "}
          {format(new Date(formData.final_status_on), "M/d/yyyy HH:mm a")}
        </Alert>
      )}
      {formData.status === "accepted" && (
        <Alert
          severity="success"
          action={
            <Button
              onClick={() => navigate(`/patients/${formData.id}`)}
              size="small"
            >
              Go To Patient
            </Button>
          }
        >
          This script was accepted on{" "}
          {format(new Date(formData.final_status_on), "M/d/yyyy hh:mm a")}
        </Alert>
      )}
      <Grid container>
        <GridSection title="Attribution" data={atrDat} />
        <GridSection title="Personal" data={prsDat} />
        <GridSection title="Contact" data={conDat} />
        <GridSection title="Injury" data={injDat}>
          <Box sx={{ mx: 2 }}>
            <Box sx={{ mb: 1 }}>Diagnosis:</Box>
            <Typography variant="body2">{formData.diagnosis}</Typography>
          </Box>
        </GridSection>
        <GridSection title="Treatment" data={trtDat} />
        <GridSection title="Insurance" data={insDat} excludeDivider />
      </Grid>
      <CommentFeed type="intake" id={formData.id} api={api} readOnly />
    </>
  );
};

export default IntakeRO;
