import {
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { phoneFmt } from "../../../formatters";
import FontAwesomeSvgIcon from "../../StandardComponents/FontAwesomeSvgIcon";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import WorkIcon from "@mui/icons-material/Work";
import ListIconToolTip from "../../StandardComponents/ListIconToolTip";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import MaskedSSN from "../../StandardComponents/MaskedSSN";
import DaisyBillIcon from "../../StandardComponents/Icons/DaisyBillIcon";
import { daisyLinkToPatient } from "../../../daisy";
import DaisyPatientLinkDialog from "./DaisyPatientLinkDialog";
import useDialogState from "../../../HOC/useDialogState";

const InsuranceCard = ({ pageData, api, refresh }) => {
  const { open, closeFn, openFn } = useDialogState();

  const payorTitle = [pageData.porg_name, pageData.payor_name]
    .filter((p) => !!p)
    .join(" - ");

  const daisyLink = pageData.daisy_id ? (
    <Link
      sx={{ textDecoration: "none" }}
      target="_blank"
      href={daisyLinkToPatient(pageData.daisy_id)}
    >
      #{pageData.daisy_id}
    </Link>
  ) : (
    "unlinked"
  );
  const daisyDisabled = !pageData.daisy_id;

  return (
    <>
      <Paper variant="outlined">
        <List dense>
          <ListItem sx={{ py: 0 }}>
            <ListIconToolTip title="Daisy Bill Link">
              <ListItemIcon>
                <IconButton disabled={!daisyDisabled} onClick={openFn}>
                  <DaisyBillIcon disabled={daisyDisabled} />
                </IconButton>
              </ListItemIcon>
            </ListIconToolTip>
            <ListItemText primary={daisyLink} />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListIconToolTip title="Payor">
              <ListItemIcon>
                <IconButton disabled>
                  <BusinessIcon />
                </IconButton>
              </ListItemIcon>
            </ListIconToolTip>
            <ListItemText primary={payorTitle} />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListIconToolTip title="Claim #">
              <ListItemIcon>
                <IconButton disabled>
                  <FontAwesomeSvgIcon icon={faHashtag} />
                </IconButton>
              </ListItemIcon>
            </ListIconToolTip>
            <ListItemText primary={pageData.claim_number} />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListIconToolTip title="Social Security Number">
              <ListItemIcon>
                <IconButton disabled>
                  <BadgeIcon />
                </IconButton>
              </ListItemIcon>
            </ListIconToolTip>
            <ListItemText>
              <MaskedSSN ssn={pageData.ssn} defaultText="[NOT ENTERED]" />
            </ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListIconToolTip title="Employer">
              <ListItemIcon>
                <IconButton disabled>
                  <WorkIcon />
                </IconButton>
              </ListItemIcon>
            </ListIconToolTip>
            <ListItemText
              primary={pageData.employer_name || "[NOT ENTERED]"}
              secondary={phoneFmt(pageData.employer_phone)}
            />
          </ListItem>
        </List>
      </Paper>
      <DaisyPatientLinkDialog
        onClose={closeFn}
        open={open}
        api={api}
        eocId={pageData.id}
        refresh={refresh}
        daisyId={pageData.daisy_id}
        nameLast={pageData.name_last}
        nameFirst={pageData.name_first}
      />
    </>
  );
};

export default InsuranceCard;
