import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { phoneFmt } from "../../../formatters";
import EmailIcon from "@mui/icons-material/Email";
import BleederGainerInlineStat from "../../StandardComponents/BleederGainerInlineStat";
import { InternalLink } from "../../StandardComponents/InternalLink";
import RepRoleChipList from "../../StandardComponents/RepRoleChipList";
import { productionStringsFromScores } from "../../../utility";

const EmailIndicator = ({ email, size = "small" }) => {
  if (!email) return null;
  return (
    <Tooltip title={email} arrow placement="left">
      <span>
        <IconButton disabled size={size}>
          <EmailIcon fontSize={size} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const RepListTableName = ({ r }) => {
  return (
    <Stack direction="row" spacing={1}>
      <BleederGainerInlineStat
        currentScore={r.bg_current}
        baselineScore={r.bg_baseline}
        age={r.bg_age}
      />
      <InternalLink to={`/reps/${r.id}`}>{r.name}</InternalLink>
    </Stack>
  );
};

const RepListTable = ({ reps }) => {
  return (
    <Table
      size="small"
      sx={{
        "& > thead > tr > th": { p: 0 },
        "& > thead > tr > th:nth-of-type(4)": { textAlign: "center" },
        "& > tbody > tr > td": { p: 0 },
        "& > tbody > tr > td:nth-of-type(4)": { textAlign: "center" },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell sx={{ width: "160px" }}>Production</TableCell>
          <TableCell sx={{ width: "110px" }}>Phone</TableCell>
          <TableCell sx={{ width: "60px" }}>Email</TableCell>
          <TableCell sx={{ width: "90px" }}>Channel</TableCell>
          <TableCell sx={{ width: "150px" }}>Roles</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reps.map((r) => {
          const [score, trend, gain] = productionStringsFromScores(
            r.bg_current,
            r.bg_baseline,
            true
          );
          return (
            <TableRow key={r.id}>
              <TableCell>
                <RepListTableName r={r} />
              </TableCell>
              <TableCell>
                {gain !== null ? (
                  <Stack direction="row" spacing={1}>
                    <Box sx={{ width: "100%" }}>{score}</Box>
                    <Box sx={{ width: "100%" }}>{trend}</Box>
                  </Stack>
                ) : null}
              </TableCell>
              <TableCell>{phoneFmt(r.phone)}</TableCell>
              <TableCell>
                <EmailIndicator email={r.email} />
              </TableCell>
              <TableCell>{r.service_channel}</TableCell>
              <TableCell>
                <RepRoleChipList roleList={r.roles} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default RepListTable;
