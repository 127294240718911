import StandardDialog from "../../StandardComponents/StandardDialog";
import { useForm } from "react-hook-form";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import { Button } from "@mui/material";
import RHFOrderStatusPicker from "../../FormControlsRHF/RHFOrderStatusPicker";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useEffect } from "react";

const genDefaultVals = (initialVals) => {
  return {
    status: RHFOrderStatusPicker.byVal(initialVals.status),
    auth_duration: initialVals.auth_duration,
    memo: "",
  };
};

const OrderStatusChangeDialog = ({
  open,
  onClose,
  orderId,
  initialVals,
  api,
  refresh,
}) => {
  const defaultValues = genDefaultVals(initialVals);

  const { handleSubmit, control, setError, reset } = useForm({ defaultValues });

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      refresh();
      onClose();
    }
  );

  useEffect(() => {
    reset(genDefaultVals(initialVals));
  }, [reset, initialVals]);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      status: data.status.value,
      auth_duration:
        data.auth_duration === "" ? null : parseInt(data.auth_duration, 10),
    };
    apiWrapper(api.setOrderStatus(orderId, payload));
  };

  return (
    <StandardDialog open={open} onClose={onClose} title="Change Status">
      <FormLevelErrorAlert sx={{ mb: 2 }} formErrStruct={formErrState} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={3}>
          <Grid2 xs={6}>
            <RHFOrderStatusPicker
              control={control}
              name="status"
              label="Status"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFTextField
              control={control}
              name="auth_duration"
              label="Authorized Days"
            />
          </Grid2>
          <Grid2 xs={12}>
            <RHFTextField
              rows={4}
              multiline
              control={control}
              name="memo"
              label="Memo - This will be sent along in the notification to the Sales Force!"
            />
          </Grid2>
          <Grid2 xs={12} sx={{ textAlign: "right" }}>
            <Button
              disabled={formErrState.submitting}
              type="submit"
              variant="contained"
            >
              Change Status
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default OrderStatusChangeDialog;
