import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FaxIcon from "@mui/icons-material/Fax";
import { ucWord } from "../../../utility";
import { Tooltip } from "@mui/material";

const iconFromMethod = (method) => {
  switch (method) {
    case "phone":
      return PhoneIcon;
    case "email":
      return EmailIcon;
    case "fax":
      return FaxIcon;
    default:
      return QuestionMarkIcon;
  }
};

const PrefContactIcon = ({ prefContactMethod }) => {
  const Icon = iconFromMethod(prefContactMethod);
  const methodTitle =
    Icon === QuestionMarkIcon ? "Unknown" : ucWord(prefContactMethod);

  return (
    <Tooltip title={`Preferred Contact Method: ${methodTitle}`}>
      <Icon
        fontSize="small"
        color={methodTitle === "Unknown" ? "error" : "success"}
        sx={{
          width: "18px",
          my: "4px",
          ml: "4px",
          mr: "8px",
        }}
      />
    </Tooltip>
  );
};

export default PrefContactIcon;
