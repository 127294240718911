import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const genderOpts = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "U", label: "Unspecified" },
];

const RPGenderPicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={genderOpts} />;
});

RPGenderPicker.defaultValue = null;

export default RPGenderPicker;
