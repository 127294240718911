import SectionHeading from "../PagePartials/SectionHeading";
import DataTable from "../PagePartials/DataTable";
import { linkFmt } from "../../formatters";
import RepRoleChipList from "../StandardComponents/RepRoleChipList";

const repCols = [
  {
    name: "name",
    label: "Name",
    formatFn: linkFmt,
    formatArgs: (row) => [`/reps/${row.id}`],
  },
  {
    name: "roles",
    label: "Roles",
    formatFn: (r) => <RepRoleChipList roleList={r} />,
    size: 150,
  },
];

const PageReps = ({ pageData }) => {
  return (
    <>
      <SectionHeading headingLabel="Sales Force" />
      <DataTable data={pageData} cols={repCols} />
    </>
  );
};

export default PageReps;
