import { forwardRef } from "react";
import RHFLoadOnRenderAutocomplete from "./RHFLoadOnRenderAutocomplete";

const RHFTreatAttachPicker = forwardRef(
  ({ control, label, name, treatId, api, rules = undefined, ...rest }, ref) => {
    const optCall = () => api.acAttachByTreat(treatId);

    return (
      <RHFLoadOnRenderAutocomplete
        control={control}
        label={label}
        ref={ref}
        name={name}
        rules={rules}
        optionListCall={optCall}
        {...rest}
      />
    );
  }
);

export default RHFTreatAttachPicker;
