import { useController } from "react-hook-form";
import { createDynamicNumberMaskGenerator, useWebMask } from "react-hook-mask";
import { TextField } from "@mui/material";
import { basicTextFieldProps } from "./_defaults";

const phMask1 = "(999) 999-9999";
const phMask2 = phMask1 + " x999999";
const maskGenerator = createDynamicNumberMaskGenerator(phMask1, phMask2);

const RHFPhoneField = ({ name, control, rules = undefined, ...rest }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });

  const { value, onChange, ref } = useWebMask({
    maskGenerator,
    value: field.value,
    onChange: field.onChange,
    keepMask: rest.keepMask,
    ref: field.ref,
  });

  return (
    <TextField
      name={field.name}
      value={value}
      inputRef={ref}
      onBlur={field.onBlur}
      onChange={onChange}
      error={!!error}
      helperText={error?.message ?? " "}
      {...basicTextFieldProps}
      {...rest}
    />
  );
};

export default RHFPhoneField;
