import { useMemo, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import StdFormDialog from "../PagePartials/StdFormDialog";
import SectionHeading from "../PagePartials/SectionHeading";
import RPSalesAreaPicker from "../FormControls/RPSalesAreaPicker";
import RPPhoneNumberInput from "../FormControls/RPPhoneNumberInput";
import RPUserPicker from "../FormControls/RPUserPicker";
import RPCommissionModelPicker from "../FormControls/RPCommissionModelPicker";
import AgencyListTable from "./PageAgencies/AgencyListTable";
import {
  CAT_SORT,
  productionCategoryFromScores,
  productionStringsFromScores,
} from "../../utility";

const PageAgencies = ({ pageData, api }) => {
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleFormOpen = () => setDialogOpen(true);
  const handleFormClose = () => setDialogOpen(false);

  // sorry in advance.. this is a mess
  const sortSplit = useMemo(() => {
    return () => {
      const groups = {};
      const groupDefs = [];
      pageData.forEach((a) => {
        if (groups[a.user_id] === undefined) {
          groups[a.user_id] = [];
          groupDefs.push({
            id: a.user_id,
            name: a.user_name,
            pic: a.user_pic,
            role: a.user_role,
            baseline: a.user_baseline,
            current: a.user_current,
          });
        }
        groups[a.user_id].push(a);
      });

      for (const agencies of Object.values(groups)) {
        agencies.sort((a, b) => {
          const aCat = productionCategoryFromScores(
            a.bg_current,
            a.bg_baseline,
            a.age
          );
          const bCat = productionCategoryFromScores(
            b.bg_current,
            b.bg_baseline,
            b.age
          );
          const aCatS = CAT_SORT[aCat];
          const bCatS = CAT_SORT[bCat];
          if (aCatS === 0 && bCatS === 0) {
            return b.bg_current - a.bg_current;
          }
          return aCatS - bCatS;
        });
      }
      groupDefs.sort((a, b) => {
        if (a.role === "sales" && b.role !== "sales") return -1;
        if (b.role === "sales" && b.role !== "sales") return 1;
        if (b.role === "sales" && b.role === "sales") {
          return b.current - a.current;
        }
        return 0;
      });
      const defs = groupDefs.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});

      return [groups, groupDefs, defs];
    };
  }, [pageData]);

  const formFields = [
    { name: "name", component: TextField, label: "Agency Name", width: 7 },
    {
      name: "sales_area_id",
      component: RPSalesAreaPicker,
      label: "Sales Area",
      width: 2,
    },
    {
      name: "comm_model",
      component: RPCommissionModelPicker,
      label: "Commission Model",
      width: 3,
    },
    {
      type: "section",
      label: "Agent Principal Info",
    },
    { name: "agent_name", component: TextField, label: "Agent Name", width: 5 },
    {
      name: "agent_phone",
      component: RPPhoneNumberInput,
      label: "Agent Phone",
      width: 3,
    },
    {
      name: "agent_email",
      component: TextField,
      label: "Agent Email",
      width: 4,
    },
    {
      type: "section",
      label: "WRS Assigned Guides",
    },
    {
      name: "guide_patient_id",
      component: RPUserPicker,
      label: "Patient Guide",
      width: 4,
      extraProps: { api },
    },
    {
      name: "guide_practice_id",
      component: RPUserPicker,
      label: "Practice Guide",
      width: 4,
      extraProps: { api },
    },
    {
      name: "guide_sales_id",
      component: RPUserPicker,
      label: "Sales Guide",
      width: 4,
      extraProps: { api },
    },
  ];

  const [groups, groupDefs, defs] = sortSplit();

  return (
    <>
      <SectionHeading
        headingLabel="Agencies"
        buttonLabel="New Agency"
        buttonOnClick={handleFormOpen}
      />
      {groupDefs.map((guideInfo) => {
        const guideId = guideInfo.id;
        const agncList = groups[guideId];
        const [scoreStr, gainFormatted] = productionStringsFromScores(
          guideInfo.current,
          guideInfo.baseline
        );
        return (
          <Card key={guideId} sx={{ mb: 2, p: 1 }} variant="outlined">
            <CardHeader
              avatar={
                <Avatar alt={defs[guideId].name} src={defs[guideId].pic} />
              }
              title={defs[guideId].name}
              subheader={`Production: ${scoreStr}; Accel: (${gainFormatted})`}
            />
            <CardContent>
              <AgencyListTable agencies={agncList} />
            </CardContent>
          </Card>
        );
      })}
      <StdFormDialog
        title="New Agency"
        fields={formFields}
        open={dialogOpen}
        onClose={handleFormClose}
        onComplete={(data) => {
          handleFormClose();
          navigate(`/agencies/${data.data.id}`);
        }}
        submitCall={(data) => {
          const sdata = {
            ...data,
            service_channel: "drop-ship",
            guide_patient_id: parseInt(data.guide_patient_id, 10),
            guide_practice_id: parseInt(data.guide_practice_id, 10),
            guide_sales_id: parseInt(data.guide_sales_id, 10),
          };
          return api.newAgency(sdata);
        }}
      />
    </>
  );
};

export default PageAgencies;
