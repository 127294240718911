import { useContext, useEffect } from "react";
import ApiDataContext from "../../ApiDataContext";
import { Alert, CircularProgress, Typography } from "@mui/material";
import useSWR from "swr";
import { genericSWRFetcher } from "../../utility";
import { useNavigate } from "react-router-dom";

const PageRCM = ({ api }) => {
  const { me } = useContext(ApiDataContext);
  const { data, isLoading, error } = useSWR(
    "preauth-user-pool",
    genericSWRFetcher(api.preauthUserPool)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (data && !isLoading && !error) {
      let redirTarget = "/rcm/dashboard";
      if (data.some((x) => x.user_id === me.id)) {
        redirTarget = `${redirTarget}/${me.id}`;
      }
      navigate(redirTarget, { replace: true });
    }
  }, [me.id, navigate, data, isLoading, error]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return <Typography>Redirecting...</Typography>;
};

export default PageRCM;
