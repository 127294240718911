import SectionHeading from "../PagePartials/SectionHeading";
import { InternalLink } from "../StandardComponents/InternalLink";
import CommentFeed from "../StandardComponents/CommentFeed";
import {
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyValueList from "../PagePartials/KeyValueList";
import { dateObjFormatToAnnArborDate } from "../../utility";
import TicketContextMenu from "../PagePartials/Tickets/TicketContextMenu";

const UnitLogEntry = ({ type, ctx }) => {
  if (type === "to-patient") {
    return (
      <>
        Unit delivered to{" "}
        <InternalLink to={`/patients/${ctx.eoc_id}`}>
          {[ctx.name_first, ctx.name_last].join(" ")}
        </InternalLink>
      </>
    );
  }

  if (type === "from-patient") {
    return (
      <>
        Unit picked up from{" "}
        <InternalLink to={`/patients/${ctx.eoc_id}`}>
          {[ctx.name_first, ctx.name_last].join(" ")}
        </InternalLink>
      </>
    );
  }

  return "";
};

const PageUnit = ({ pageData, api, refresh }) => {
  const basicInfo = {
    Agency: (
      <InternalLink
        sx={{ typography: "body2" }}
        to={`/agencies/${pageData.loc_agency}`}
        underline="none"
      >
        {pageData.name}
      </InternalLink>
    ),
    "Original PO": (
      <InternalLink
        sx={{ typography: "body2" }}
        to={`/purchase-orders/${pageData.orig_po_id}`}
      >
        PO-{pageData.orig_po_id.toString().padStart(4, "0")}
      </InternalLink>
    ),
    "Entered Service": dateObjFormatToAnnArborDate(pageData.created_on),
  };

  return (
    <Stack direction="column" spacing={2}>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <SectionHeading
          sx={{ mb: 0 }}
          headingLabel={
            <InternalLink to={`/units/${pageData.id}`}>
              Unit {pageData.serial_num} - {pageData.hlabel}
            </InternalLink>
          }
          buttonEl={
            <TicketContextMenu
              api={api}
              context={{
                type: "inv_serials",
                ident: pageData.id,
                label: pageData.serial_num,
              }}
            />
          }
        />
      </Paper>
      <Stack direction="row" spacing={2}>
        <Paper variant="outlined" sx={{ width: "50%" }}>
          <KeyValueList
            kvData={basicInfo}
            ignoreTypos={["Agency", "Original PO"]}
            noItemYPadding
          ></KeyValueList>
        </Paper>
        <Paper
          variant="outlined"
          sx={{ width: "25%", textAlign: "center", pt: 1 }}
        >
          <Typography variant="h5" sx={{ color: "text.secondary" }}>
            Status
          </Typography>
          <Typography variant="h6">{pageData.status}</Typography>
          {pageData.loc_eoc && (
            <>
              (
              <InternalLink
                sx={{ typography: "body1" }}
                to={`/patients/${pageData.loc_eoc}`}
                underline="none"
              >
                {pageData.name_first} {pageData.name_last}
              </InternalLink>
              )
            </>
          )}
        </Paper>
        <Paper
          variant="outlined"
          sx={{ width: "25%", textAlign: "center", pt: 1 }}
        >
          <Typography variant="h5" sx={{ color: "text.secondary" }}>
            Service Days
          </Typography>
          <Typography variant="h5" sx={{ mt: 1 }}>
            (not implemented)
          </Typography>
        </Paper>
      </Stack>
      {pageData.log.length > 0 && (
        <>
          <Divider />
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="caption">
              Note: there will be <strong>many</strong> more events in this log
              as we spend more development time on it!
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Action</TableCell>
                  <TableCell sx={{ width: "150px" }}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageData.log.map((l, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <UnitLogEntry ctx={l.ctx} type={l.type} />
                      </TableCell>
                      <TableCell>{l.date}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </>
      )}
      <CommentFeed
        api={api}
        type="unit"
        id={pageData.id}
        ignoreCategories
        disablePublic
      />
    </Stack>
  );
};

export default PageUnit;
