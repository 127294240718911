import RHFAutocomplete from "./RHFAutocomplete";

const OPTIONS = [
  { value: "incomplete", label: "Incomplete" },
  { value: "pending", label: "Pending" },
  { value: "pending-po", label: "Pending PO" },
  { value: "approved", label: "Approved" },
  { value: "authorized", label: "Authorized" },
  { value: "bill", label: "Bill" },
  { value: "litigated", label: "Litigated" },
  { value: "pro-bono", label: "Pro-Bono" },
  { value: "denied", label: "Denied" },
  { value: "denied-no-response", label: "Denied - No Response" },
  { value: "denied-overturned", label: "Denied - Overturned" },
  { value: "appealing", label: "Appealing" },
  { value: "postponed", label: "Postponed" },
  { value: "cancelled", label: "Cancelled" },
];

const RHFOrderStatusPicker = (props) => {
  return <RHFAutocomplete options={OPTIONS} {...props} />;
};

RHFOrderStatusPicker.byVal = (val) => OPTIONS.find((o) => o.value === val);

export default RHFOrderStatusPicker;
