import { useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import StandardDialog from "../../StandardComponents/StandardDialog";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import RHFRepPicker from "../../FormControlsRHF/RHFRepPicker";
import RHFDateField from "../../FormControlsRHF/RHFDateField";
import RHFTreatAttachPicker from "../../FormControlsRHF/RHFTreatAttachPicker";
import RHFAvailableSerialsPicker from "../../FormControlsRHF/RHFAvailableSerialsPicker";
import RHFAnyConsumablePicker from "../../FormControlsRHF/RHFAnyConsumablePicker";
import { nowDate, reformatDate } from "../../../utility";
import ExpandIcon from "@mui/icons-material/Expand";
import CompressIcon from "@mui/icons-material/Compress";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const genDefaults = (rxSalesRepId, rxServiceRepId) => {
  const repId = rxServiceRepId || rxSalesRepId;

  return {
    serial_id: null,
    picked_consumable: null,
    consumable_ids: [],
    psr_rep_id: repId.toString(),
    date: nowDate(),
    attachment_id: null,
  };
};

const SubmitBtn = ({ control, submitting }) => {
  const val = useWatch({ control, name: "picked_consumable" });
  const disabled = !!val || submitting;
  return (
    <Button variant="contained" type="submit" disabled={disabled}>
      Process Hard Copy PIC
    </Button>
  );
};

const HardCopyPicAgncFormDialog = ({
  open,
  onClose,
  api,
  refresh,
  treatId,
  srvcId,
  agencyId,
  itemId,
  rxSalesRepId,
  rxServiceRepId,
}) => {
  const defaultValues = genDefaults(rxSalesRepId, rxServiceRepId);
  const [showHelp, setShowHelp] = useState(false);
  const { control, handleSubmit, setValue, getValues, setError, reset } =
    useForm({
      defaultValues,
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "consumable_ids",
  });

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      onClose();
      refresh();
    }
  );

  const toggleShowHelp = () => setShowHelp((prev) => !prev);

  const handleOnClose = () => {
    onClose();
    reset();
    clearFormError();
  };

  const addConsumable = () => {
    const picked = getValues("picked_consumable");
    append(picked);
    setValue("picked_consumable", null);
  };

  const onSubmit = (data) => {
    const psr_rep_id =
      typeof data.psr_rep_id === "string"
        ? parseInt(data.psr_rep_id, 10)
        : data.psr_rep_id?.value;
    const payload = {
      attachment_id: data.attachment_id?.value,
      consumable_ids: data.consumable_ids.map((c) => c.value),
      date: reformatDate(data.date),
      psr_rep_id,
      serial_id: data.serial_id?.value,
    };
    apiWrapper(api.hcPic(srvcId, payload));
  };

  return (
    <StandardDialog
      open={open}
      onClose={handleOnClose}
      title="Process Hard Copy Pic"
    >
      <Alert
        severity="info"
        sx={{ mb: 2 }}
        action={
          <IconButton onClick={toggleShowHelp} size="small">
            {showHelp ? (
              <CompressIcon fontSize="small" />
            ) : (
              <ExpandIcon fontSize="small" />
            )}
          </IconButton>
        }
      >
        <AlertTitle>FAQ / Help</AlertTitle>
        <Collapse in={showHelp}>
          <ul>
            <li>
              Make sure the date entered{" "}
              <strong>is the same date on the Hard Copy PIC!</strong>
            </li>
            <li>
              In order to do this, you must have already attached the PIC file
              somewhere on the patient record. If we have "multiple pages" as
              separate attachments (say the PSR took photos of three pages with
              their camera), just pick the "first page" in this dialog. That is
              okay!
            </li>
            <li>
              Make sure to pick the correct PSR. Many agencies rely on which PSR
              fit which patient in order to pay their people.
            </li>
            <li>
              This operation assigns/deducts inventory, starts service, pops a
              notice on the order, tags the Hard Copy PIC as the legal proof
              that we delivered the unit to the patient, and a few other things.
              This is a complex operation that is very difficult to reverse.
              Please use with caution!
            </li>
          </ul>
        </Collapse>
      </Alert>
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2}>
          <Grid2 xs={4}>
            <RHFRepPicker
              api={api}
              control={control}
              label="Rep / PSR who Fit Patient"
              agencyId={agencyId}
              name="psr_rep_id"
            />
          </Grid2>
          <Grid2 xs={2}>
            <RHFDateField control={control} label="Fit Date" name="date" />
          </Grid2>
          <Grid2 xs={6}>
            <RHFTreatAttachPicker
              api={api}
              control={control}
              label="PIC Document"
              name="attachment_id"
              treatId={treatId}
            />
          </Grid2>
          <Grid2 xs={3}>
            <RHFAvailableSerialsPicker
              api={api}
              control={control}
              label="Unit Serial Number Fit"
              name="serial_id"
              agencyId={agencyId}
              itemId={itemId}
            />
          </Grid2>
          <Grid2 xs={7}>
            <RHFAnyConsumablePicker
              control={control}
              label="Add Consumable"
              name="picked_consumable"
            />
          </Grid2>
          <Grid2 xs={2}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              sx={{ mt: 1 }}
              onClick={addConsumable}
            >
              Add
            </Button>
          </Grid2>
          <Grid2 xs={12}>
            <List>
              {fields.map((f, idx) => (
                <ListItem key={f.id}>
                  <ListItemText>{f.label}</ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton color="error" onClick={() => remove(idx)}>
                      <RemoveCircleIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid2>
        </Grid2>
        <Box sx={{ textAlign: "right" }}>
          <SubmitBtn submitting={formErrState.submitting} control={control} />
        </Box>
      </form>
    </StandardDialog>
  );
};

export default HardCopyPicAgncFormDialog;
