import { Link } from "@mui/material";
import { forwardRef } from "react";

const ExternalLink = forwardRef(({ to, children, ...rest }, ref) => {
  return (
    <Link href={to} underline="none" target="_blank" ref={ref} {...rest}>
      {children}
    </Link>
  );
});

export default ExternalLink;
