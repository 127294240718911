import {
  Alert,
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {InternalLink} from "../../StandardComponents/InternalLink";
import {moneyFmt} from "../../../formatters";
import {dateObjFormatToAnnArborDate, dateObjFormatToAnnArborDateTime,} from "../../../utility";

/**
 * @param {string} activeTab
 * @param {number} totalBill
 * @param {Array<{
 *   id: number,
 *   payment_id: number,
 *   payment_number: string,
 *   porg_id: number,
 *   porg_name: string,
 *   amount: number,
 *   created_on: Date,
 *   user_id: number,
 *   user_name: string,
 *   user_pic: string,
 *   ordl_id: number | null,
 *   ordl_desc: string | null
 * }>} allocations
 */
const OrderTabAllocations = ({ activeTab, totalBill, allocations }) => {
  if (activeTab !== "allocs") return null;

  if (allocations.length === 0) {
    return (
      <Alert severity="info">
        No payments have been allocated to this order.
      </Alert>
    );
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "108px" }}>Alloc Date</TableCell>
          <TableCell sx={{ width: "160px" }}>Payor</TableCell>
          <TableCell sx={{ width: "140px" }}>Payment</TableCell>
          <TableCell sx={{ width: "70px" }}>Amount</TableCell>
          <TableCell>Order Line</TableCell>
          <TableCell sx={{ width: "60px" }}>User</TableCell>
        </TableRow>
      </TableHead>
      <TableBody
        sx={{
          "& > tr > td > .MuiBox-root": {
            overflowX: "clip",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        }}
      >
        {allocations.map((a) => (
          <TableRow key={a.id}>
            <TableCell>
              <Box sx={{ maxWidth: "108px" }}>
                <Tooltip
                  arrow
                  placement="left-start"
                  title={dateObjFormatToAnnArborDateTime(a.created_on)}
                >
                  <span>{dateObjFormatToAnnArborDate(a.created_on)}</span>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ maxWidth: "160px" }}>
                <Tooltip title={a.porg_name} arrow placement="left-start">
                  <span>
                    <InternalLink to={`/orgs/${a.porg_id}`}>
                      {a.porg_name}
                    </InternalLink>
                  </span>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ width: "140px" }}>
                <InternalLink to={`/payments/${a.payment_id}`}>
                  {a.payment_number}
                </InternalLink>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ width: "70px" }}>{moneyFmt(a.amount)}</Box>
            </TableCell>
            <TableCell>
              <Box>
                <Tooltip
                  title={`ID: ${a.ordl_id}`}
                  arrow
                  placement="left-start"
                >
                  <span>{a.ordl_desc}</span>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell>
              <Box>
                <Avatar
                  sx={{ height: 24, width: 24 }}
                  alt={a.user_name}
                  src={a.user_pic}
                />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OrderTabAllocations;
