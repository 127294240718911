import { forwardRef } from "react";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";

const OPTIONS = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Authorized", value: "authorized" },
  { label: "Pending PO", value: "pending-po" },
  { label: "Bill", value: "bill" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Postponed", value: "postponed" },
  { label: "Incomplete", value: "incomplete" },
  { label: "Litigated", value: "litigated" },
  { label: "Appealing", value: "appealing" },
  { label: "Denied", value: "denied" },
  { label: "Denied - Overturned", value: "denied-overturned" },
  { label: "Denied - No Response", value: "denied-no-response" },
  { label: "Pro Bono", value: "pro-bono" },
];

const processSlotProps = (slotProps) => {
  if (!slotProps.textField) {
    slotProps.textField = {};
  }
};

const processValue = (value) => {
  if (!Array.isArray(value)) {
    return [];
  }

  const picked = [];
  for (const val of value) {
    const found = OPTIONS.find((i) => i.value === val);
    if (found) {
      picked.push(found);
    }
  }

  return picked;
};

const OrderStatusMultipleAutocomplete = forwardRef(
  ({ value, onChange, slotProps = {}, ...props }, ref) => {
    processSlotProps(slotProps);
    const pickedValue = processValue(value);

    return (
      <Autocomplete
        ref={ref}
        multiple
        limitTags={3}
        disableCloseOnSelect
        options={OPTIONS}
        onChange={(_, newVal) => {
          if (!Array.isArray(newVal)) return [];
          onChange(newVal.map((i) => i.value));
        }}
        value={pickedValue}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox checked={selected} />
              {option.label}
            </li>
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip key={key} label={option.label} size="small" {...tagProps} />
            );
          })
        }
        renderInput={(params) => (
          <TextField {...params} {...slotProps.textField} />
        )}
        {...props}
      />
    );
  }
);

export default OrderStatusMultipleAutocomplete;
