import { useForm } from "react-hook-form";
import StandardDialog from "../../StandardComponents/StandardDialog";
import RHFPhoneField from "../../FormControlsRHF/RHFPhoneField";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import RHFComPrefPicker from "../../FormControlsRHF/RHFComPrefPicker";
import RHFCheckbox from "../../FormControlsRHF/RHFCheckbox";
import { Box, Button } from "@mui/material";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import RHFFitPrefPicker from "../../FormControlsRHF/RHFFitPrefPicker";
import Grid2 from "@mui/material/Unstable_Grid2";
import RHFExtensionProcessPicker from "../../FormControlsRHF/RHFExtensionProcessPicker";

const PrescEditSettingsDialog = ({
  npi,
  open,
  onClose,
  api,
  refresh,
  initialVals,
}) => {
  const defaultValues = {
    allow_ext: initialVals.allow_ext,
    ret_and_growth: initialVals.ret_and_growth,
    com_pref_ext: RHFComPrefPicker.byVal(initialVals.com_pref_ext),
    com_pref_missing: RHFComPrefPicker.byVal(initialVals.com_pref_missing),
    fit_pref: RHFFitPrefPicker.byVal(initialVals.fit_pref),
    ext_process: RHFExtensionProcessPicker.byVal(initialVals.ext_process),
    phone: initialVals.phone,
    email: initialVals.email,
  };
  const { control, handleSubmit, setError } = useForm({ defaultValues });

  const onSuccess = () => {
    refresh();
    onClose();
  };

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    onSuccess
  );

  const onSubmit = (data) => {
    const payload = {
      ...data,
      com_pref_ext: data.com_pref_ext.value,
      com_pref_missing: data.com_pref_missing.value,
      fit_pref: data.fit_pref.value,
      ext_process: data.ext_process.value,
    };
    apiWrapper(api.updatePrescSettings(npi, payload));
  };

  return (
    <StandardDialog open={open} onClose={onClose} title="Edit Settings">
      <FormLevelErrorAlert formErrStruct={formErrState} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2}>
          <Grid2 xs={6}>
            <RHFCheckbox
              control={control}
              name="allow_ext"
              label="Prescriber Allows Extensions"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFCheckbox
              control={control}
              name="ret_and_growth"
              label="Practice Engagement Program"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFComPrefPicker
              control={control}
              name="com_pref_ext"
              label="Contact on Extensions"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFComPrefPicker
              control={control}
              name="com_pref_missing"
              label="Contact on Missing Data"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFFitPrefPicker
              control={control}
              name="fit_pref"
              label="Fitting Strategy"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFExtensionProcessPicker
              control={control}
              name="ext_process"
              label="Extension Process"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFPhoneField
              control={control}
              name="phone"
              label="Direct Phone"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFTextField control={control} name="email" label="Direct Email" />
          </Grid2>
        </Grid2>

        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </form>
    </StandardDialog>
  );
};

export default PrescEditSettingsDialog;
