import { useState } from "react";
import BGYearSelect from "./BGYearSelect";
import BGMonthSelect from "./BGMonthSelect";
import { Stack } from "@mui/material";

const getMonthMin = (year) => {
  if (year === 2023) return 6;
  return 1;
};

const getMonthMax = (year) => {
  const now = new Date();
  if (year === now.getFullYear()) return now.getMonth() + 1;
  return 12;
};

const BGYearMonthSelect = ({ onSelectFn }) => {
  const [selectedVal, setSelectedVal] = useState({ year: null, month: null });

  const monthDisabled = selectedVal.year === null;
  const monthMin = getMonthMin(selectedVal.year);
  const monthMax = getMonthMax(selectedVal.year);

  return (
    <Stack direction="row" spacing={2}>
      <BGYearSelect
        value={selectedVal.year ?? ""}
        onChange={(ev) => {
          const newState = {
            year: ev.target.value === "" ? null : ev.target.value,
            month: null,
          };
          setSelectedVal(newState);
        }}
        size="small"
        sx={{ width: "135px" }}
      />
      <BGMonthSelect
        value={selectedVal.month ?? ""}
        onChange={(ev) => {
          const newState = {
            year: selectedVal.year,
            month: ev.target.value === "" ? null : ev.target.value,
          };
          setSelectedVal(newState);
          onSelectFn(newState);
        }}
        disabled={monthDisabled}
        size="small"
        sx={{ width: "165px" }}
        min={monthMin}
        max={monthMax}
      />
    </Stack>
  );
};

export default BGYearMonthSelect;
