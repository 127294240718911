import StandardDialog from "../../StandardComponents/StandardDialog";
import NewTicketForm from "./NewTicketForm";

const NewTicketFormDialog = ({ open, onClose, context, api, onSuccess }) => {
  return (
    <StandardDialog title="New Task" open={open} onClose={onClose}>
      <NewTicketForm onSuccess={onSuccess} api={api} context={context} />
    </StandardDialog>
  );
};

export default NewTicketFormDialog;
