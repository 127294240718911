import {
  Alert,
  Badge,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import TicketStatusPill from "./TicketStatusPill";
import StdUserAvatar from "../../StandardComponents/StdUserAvatar";
import ChatIcon from "@mui/icons-material/Chat";
import { format } from "date-fns";
import { blueGrey, grey } from "@mui/material/colors";

/**
 * @param {Array<{
 *   id: number,
 *   title: string,
 *   context: {
 *     label: string,
 *     type: string,
 *     ident: number,
 *   }|null|undefined,
 *   status: string,
 *   follow_up_on: Date,
 * }>} tickets
 */
const TicketContextTable = ({ tickets }) => {
  if (tickets.length === 0) {
    return (
      <Alert severity="info">No tasks are attached to this record yet.</Alert>
    );
  }
  return (
    <Table
      size="small"
      padding="none"
      sx={{
        "& td:nth-of-type(1)": { width: "32px", py: "4px" },
        "& td:nth-of-type(1) div": {
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
        },
        "& td:nth-of-type(2)": { width: "78px", py: "4px" },
        "& td:nth-of-type(3)": { width: "32px", py: "4px" },
        "& td:nth-of-type(4)": { width: "32px", py: "4px" },
        "& td:nth-of-type(5)": {
          width: "490px",
          maxWidth: "490px",
          py: "4px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& td:nth-of-type(6)": { width: "90px", py: "4px" },
        "& td:nth-of-type(7)": { width: "90px", py: "4px" },
      }}
    >
      <TableHead>
        <TableRow
          sx={{
            "& th": { py: "4px" },
          }}
        >
          <TableCell></TableCell>
          <TableCell>Task</TableCell>
          <TableCell>Req</TableCell>
          <TableCell>Asn</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Follow Up</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tickets.map((t) => {
          const taskIdLabel = `TSK-${t.id.toString().padStart(4, "0")}`;
          const taskIdContent = (
            <InternalLink to={`/tasks/${t.id}`}>{taskIdLabel}</InternalLink>
          );

          return (
            <TableRow
              key={t.id}
              sx={{
                backgroundColor: t.has_activity ? "bgBlue" : "inherit",
                "& td": { fontWeight: t.has_activity ? "bold" : "normal" },
              }}
            >
              <TableCell>
                <Box>
                  <Badge
                    badgeContent={t.comment_count}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    color={t.has_activity ? "info" : undefined}
                    slotProps={{
                      badge: {
                        sx: {
                          color: t.has_activity ? undefined : grey["100"],
                          backgroundColor: t.has_activity
                            ? undefined
                            : blueGrey["A700"],
                          transform: "scale(0.8) translate(-50%, -30%)",
                        },
                      },
                    }}
                  >
                    <ChatIcon
                      fontSize="small"
                      color={t.has_activity ? "info" : undefined}
                      sx={{
                        color: t.has_activity ? undefined : "text.secondary",
                      }}
                    />
                  </Badge>
                </Box>
              </TableCell>
              <TableCell>{taskIdContent}</TableCell>
              <TableCell>
                <StdUserAvatar
                  user_name={t.requester_name}
                  user_pic={t.requester_picture}
                />
              </TableCell>
              <TableCell>
                <StdUserAvatar
                  user_name={t.assignee_name}
                  user_pic={t.assignee_picture}
                />
              </TableCell>
              <TableCell>{t.title}</TableCell>
              <TableCell>
                <TicketStatusPill ticket={t} />
              </TableCell>
              <TableCell>{format(t.follow_up_on, "EEE MMM d")}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TicketContextTable;
