import { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
} from "@mui/material";
import KeyValueList from "../../PagePartials/KeyValueList";
import RHFUPSServiceCodePicker from "../../FormControlsRHF/RHFUPSServiceCodePicker";
import { saveAs } from "file-saver";
import ExternalLink from "../../StandardComponents/ExternalLink";
import RefreshIcon from "@mui/icons-material/Refresh";
import HistoryIcon from "@mui/icons-material/History";
import {
  dateObjFormatToAnnArborDateTime,
  dateObjFormatToSmallest,
} from "../../../utility";

const kvDataFromLabel = (label) => {
  const data = {
    Status: `${label.trk_status_code} - ${label.trk_status_desc}`,
    "Shipped Date": dateObjFormatToSmallest(label.trk_ship_date),
    "Estimated Delivery": dateObjFormatToAnnArborDateTime(
      label.trk_est_deliv,
      false
    ),
    "Actual Delivery": dateObjFormatToAnnArborDateTime(
      label.trk_act_deliv,
      false
    ),
  };

  return data;
};

const TrkSubhead = ({ label }) => {
  const serviceCode = RHFUPSServiceCodePicker.labelByVal(label.service_code);
  const weight = `${label.weight.toString()} lbs`;

  return (
    <Box component="span">
      <ExternalLink to={label.trk_url}>{label.tracking_number}</ExternalLink> /{" "}
      {serviceCode} / {weight}
    </Box>
  );
};

const stateSetDownloading = (setFn) => {
  setFn({
    status: "downloading",
    errorMessage: "",
    attachData: null,
    labelId: null,
  });
};

const stateSetError = (setFn, errorMessage) => {
  setFn({ status: "error", errorMessage, attachData: null, labelId: null });
};

const stateSetIdle = (setFn) => {
  setFn({ status: "idle", errorMessage: "", attachData: null, labelId: null });
};

const stateSetSaving = (setFn) => {
  setFn((prev) => ({
    ...prev,
    status: "saving",
    errorMessage: "",
    labelId: null,
  }));
};

const stateSetRefreshing = (setFn, labelId) => {
  setFn({
    status: "refreshing",
    errorMessage: "",
    attachData: null,
    labelId: labelId,
  });
};

const ROTATE_ANI = {
  animation: "spin 2s linear infinite",
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

const ShippingCard = ({ srvcId, sendLabel, returnLabel, api, refresh }) => {
  const [state, setState] = useState({
    status: "idle",
    attachData: null,
    errorMessage: "",
    labelId: null,
  });

  useEffect(() => {
    if (state.status === "saving") stateSetIdle(setState);
  }, [state.status]);

  const handleDownloadLabel = (attachId, srvcId, ltype, ftype) => {
    stateSetDownloading(setState);
    api
      .getAttachment(attachId)
      .then((blob) => {
        stateSetSaving(setState);
        saveAs(
          blob,
          `label-${ltype}-sc${srvcId.toString().padStart(4, "0")}.${ftype}`
        );
      })
      .catch((err) => {
        stateSetError(setState, err.message);
      });
  };

  const handleRefreshLabel = (labelId) => {
    stateSetRefreshing(setState, labelId);
    api
      .updateLabel(srvcId, labelId)
      .then((resp) => {
        if (resp.status === "ok") {
          stateSetIdle(setState);
          refresh();
          return;
        }

        stateSetError(setState, resp.message);
      })
      .catch((err) => {
        stateSetError(setState, err.message);
      });
  };

  if (!sendLabel?.id) {
    return null;
  }

  const sendData = kvDataFromLabel(sendLabel);
  const returnData = returnLabel ? kvDataFromLabel(returnLabel) : null;

  const refreshOrigSx = (() => {
    if (state.status === "refreshing" && state.labelId === sendLabel.id) {
      return ROTATE_ANI;
    }
    return {};
  })();

  const refreshRetSx = (() => {
    if (
      state.status === "refreshing" &&
      returnLabel &&
      state.labelId === returnLabel.id
    ) {
      return ROTATE_ANI;
    }
    return {};
  })();

  const sendException =
    sendLabel.trk_cstat_code === "X"
      ? sendLabel.trk_cstat_desc
      : sendLabel.trk_except_desc !== ""
      ? sendLabel.trk_except_desc
      : "";

  const returnException =
    returnLabel.trk_cstat_code === "X"
      ? returnLabel.trk_cstat_desc
      : returnLabel.trk_except_desc !== ""
      ? returnLabel.trk_except_desc
      : "";

  return (
    <>
      {state.status === "error" && (
        <Alert severity="error">{state.errorMessage}</Alert>
      )}
      <Card variant="outlined" sx={{ mt: 3 }}>
        <CardHeader
          title="Initial Shipment"
          titleTypographyProps={{ variant: "h6" }}
          subheader={<TrkSubhead label={sendLabel} />}
          subheaderTypographyProps={{ variant: "body2" }}
          sx={{ pb: 0 }}
          action={
            <Stack direction="row">
              <IconButton onClick={() => handleRefreshLabel(sendLabel.id)}>
                <RefreshIcon
                  sx={refreshOrigSx}
                  disabled={state.status === "refreshing"}
                />
              </IconButton>
              <IconButton onClick={() => {}}>
                <HistoryIcon />
              </IconButton>
            </Stack>
          }
        />
        <CardContent sx={{ py: 0 }}>
          {sendException ? (
            <Alert severity="error" sx={{ mb: 1 }}>
              <AlertTitle>Delivery Exception Detected</AlertTitle>
              {sendException}
            </Alert>
          ) : null}
          <KeyValueList kvData={sendData} />
        </CardContent>
        <CardActions>
          <Button
            onClick={() =>
              handleDownloadLabel(
                sendLabel.pdf_attach_id,
                srvcId,
                "orig",
                "pdf"
              )
            }
          >
            PDF
          </Button>
          <Button
            onClick={() =>
              handleDownloadLabel(
                sendLabel.png_attach_id,
                srvcId,
                "orig",
                "png"
              )
            }
          >
            PNG
          </Button>
          <Button
            onClick={() =>
              handleDownloadLabel(
                sendLabel.zpl_attach_id,
                srvcId,
                "orig",
                "zpl"
              )
            }
          >
            ZPL
          </Button>
        </CardActions>
      </Card>
      {returnLabel && (
        <Card variant="outlined" sx={{ mt: 3 }}>
          <CardHeader
            title="Return Shipment"
            titleTypographyProps={{ variant: "h6" }}
            subheader={<TrkSubhead label={returnLabel} />}
            subheaderTypographyProps={{ variant: "body2" }}
            sx={{ pb: 0 }}
            action={
              <Stack direction="row">
                <IconButton onClick={() => handleRefreshLabel(returnLabel.id)}>
                  <RefreshIcon
                    sx={refreshRetSx}
                    disabled={state.status === "refreshing"}
                  />
                </IconButton>
                <IconButton onClick={() => {}}>
                  <HistoryIcon />
                </IconButton>
              </Stack>
            }
          />
          <CardContent sx={{ py: 0 }}>
            {returnException ? (
              <Alert severity="error" sx={{ mb: 1 }}>
                <AlertTitle>Delivery Exception Detected</AlertTitle>
                {returnException}
              </Alert>
            ) : null}
            <KeyValueList kvData={returnData} />
          </CardContent>
          <CardActions>
            <Button
              onClick={() =>
                handleDownloadLabel(
                  returnLabel.pdf_attach_id,
                  srvcId,
                  "return",
                  "pdf"
                )
              }
            >
              PDF
            </Button>
            <Button
              onClick={() =>
                handleDownloadLabel(
                  returnLabel.png_attach_id,
                  srvcId,
                  "return",
                  "png"
                )
              }
            >
              PNG
            </Button>
            <Button
              onClick={() =>
                handleDownloadLabel(
                  returnLabel.zpl_attach_id,
                  srvcId,
                  "return",
                  "zpl"
                )
              }
            >
              ZPL
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default ShippingCard;
