import { InternalLink } from "../Components/StandardComponents/InternalLink";
import { Avatar, Box, Stack, Tooltip } from "@mui/material";
import ExternalLink from "../Components/StandardComponents/ExternalLink";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import StdUserAvatar from "../Components/StandardComponents/StdUserAvatar";
import BleederGainerInlineStat from "../Components/StandardComponents/BleederGainerInlineStat";
import { productionStringsFromScores } from "../utility";

export const WSPatientWithLangLink = ({
  eoc_id,
  name_first,
  name_last,
  lang,
}) => {
  const parts = [];

  if (lang === undefined) throw new Error("no language passed in");
  if (lang === "spanish") {
    parts.push("🇪🇸");
  }

  parts.push(name_first);
  parts.push(name_last);

  return (
    <InternalLink to={`/patients/${eoc_id}`}>{parts.join(" ")}</InternalLink>
  );
};

export const WSPatientLink = ({ eoc_id, name_first, name_last }) => (
  <InternalLink to={`/patients/${eoc_id}`}>
    {[name_first, name_last].join(" ")}
  </InternalLink>
);

export const WSUserAvatar = ({ guide_name, guide_pic }) => (
  <StdUserAvatar user_name={guide_name} user_pic={guide_pic} />
);

export const WSYesNoIconAvatar = ({ val }) => (
  <Avatar sx={{ height: 24, width: 24, bgcolor: "transparent" }}>
    {val ? (
      <CheckCircleOutlineIcon color="success" sx={{ height: 24, width: 24 }} />
    ) : (
      <HelpOutlineIcon color="error" sx={{ height: 24, width: 24 }} />
    )}
  </Avatar>
);

export const WSPatientAndRxLink = ({
  rx_id,
  eoc_id,
  name_first,
  name_last,
}) => (
  <>
    (<InternalLink to={`/rxs/${rx_id}`}>RX</InternalLink>){" "}
    <InternalLink to={`/patients/${eoc_id}`}>
      {[name_first, name_last].join(" ")}
    </InternalLink>
  </>
);

export const WSPatientLangAndRxLink = ({
  rx_id,
  eoc_id,
  name_first,
  name_last,
  lang,
}) => (
  <>
    (<InternalLink to={`/rxs/${rx_id}`}>RX</InternalLink>){" "}
    <WSPatientWithLangLink
      name_first={name_first}
      name_last={name_last}
      eoc_id={eoc_id}
      lang={lang}
    />
  </>
);

export const WSPatientAndSrvcLink = ({
  srvc_id,
  eoc_id,
  name_first,
  name_last,
}) => (
  <>
    (<InternalLink to={`/service-cycle/${srvc_id}`}>SC</InternalLink>){" "}
    <InternalLink to={`/patients/${eoc_id}`}>
      {[name_first, name_last].join(" ")}
    </InternalLink>
  </>
);

export const WSPatientLangAndSrvcLink = ({
  srvc_id,
  eoc_id,
  name_first,
  name_last,
  lang,
}) => {
  return (
    <>
      (<InternalLink to={`/service-cycle/${srvc_id}`}>SC</InternalLink>){" "}
      <WSPatientWithLangLink
        name_first={name_first}
        name_last={name_last}
        eoc_id={eoc_id}
        lang={lang}
      />
    </>
  );
};

export const WPIncompleteLink = ({
  link_target,
  link_ident,
  name_link_target,
  name_link_ident,
  name_first,
  name_last,
}) => {
  if (link_target === "intake")
    return (
      <WSNameAndIntakeLink
        intake_id={link_ident}
        name_first={name_first}
        name_last={name_last}
      />
    );
  if (link_target === "orders")
    return (
      <WSPatientAndOrdLink
        ord_id={link_ident}
        eoc_id={name_link_ident}
        name_first={name_first}
        name_last={name_last}
      />
    );
  return <></>;
};

export const WSNameAndIntakeLink = ({ intake_id, name_first, name_last }) => (
  <>
    (<InternalLink to={`/intake/${intake_id}`}>INTK</InternalLink>){" "}
    <InternalLink to={`/intake/${intake_id}`}>
      {[name_first, name_last].join(" ")}
    </InternalLink>
  </>
);

export const WSPatientAndOrdLink = ({
  ord_id,
  eoc_id,
  name_first,
  name_last,
}) => (
  <>
    (<InternalLink to={`/orders/${ord_id}`}>ORD</InternalLink>){" "}
    <InternalLink to={`/patients/${eoc_id}`}>
      {[name_first, name_last].join(" ")}
    </InternalLink>
  </>
);

export const WSTrackingLink = ({
  label_id,
  tracking_number,
  tracking_url,
  tracking_status,
}) =>
  label_id ? (
    <Tooltip arrow title={tracking_number} placement="left">
      <ExternalLink to={tracking_url}>{tracking_status}</ExternalLink>
    </Tooltip>
  ) : (
    ""
  );

export const WSBGRepLink = ({
  rep_id,
  rep_name,
  bg_current,
  bg_baseline,
  bg_age,
}) => {
  return (
    <Stack direction="row" spacing={1}>
      <BleederGainerInlineStat
        baselineScore={bg_baseline}
        currentScore={bg_current}
        age={bg_age}
      />
      <Box>
        <InternalLink to={`/reps/${rep_id}`}>{rep_name}</InternalLink>
      </Box>
    </Stack>
  );
};

export const WSProductionCell = ({ bg_current, bg_baseline }) => {
  const [score, accel] = productionStringsFromScores(
    bg_current,
    bg_baseline,
    true
  );
  return (
    <Stack direction="row" spacing={1}>
      <Box sx={{ width: "100%" }}>{score}</Box>
      <Box>{accel}</Box>
    </Stack>
  );
};
