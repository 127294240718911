import SectionHeading from "../PagePartials/SectionHeading";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { InternalLink } from "../StandardComponents/InternalLink";
import { surgeryStr } from "../../utility";

const PagePatients = ({ pageData }) => {
  return (
    <>
      <SectionHeading headingLabel="Patients" />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "90px" }}>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Surgery</TableCell>
            <TableCell>State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pageData.map((p) => (
            <TableRow key={p.id}>
              <TableCell>
                <InternalLink to={`/patients/${p.id}`}>
                  P-{p.id.toString().padStart(4, "0")}
                </InternalLink>
              </TableCell>
              <TableCell>
                <InternalLink to={`/patients/${p.id}`}>
                  {p.name_first} {p.name_last}
                </InternalLink>
              </TableCell>
              <TableCell>{surgeryStr(p.surgical, p.date_of_surgery)}</TableCell>
              <TableCell>{p.addr_state}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default PagePatients;
