import { Avatar, Chip } from "@mui/material";

/**
 * @param {{id: number, name: string, picture: string}} user
 */
const UserChip = ({ user, ...rest }) => {
  return (
    <Chip
      variant="outlined"
      label={user.name}
      avatar={<Avatar src={user.picture} title={user.name} alt={user.name} />}
      {...rest}
    />
  );
};

export default UserChip;
