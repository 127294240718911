import { forwardRef, useContext } from "react";
import ApiDataContext from "../../ApiDataContext";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const xformManData = (mfgrs) => {
  return mfgrs.map((m) => ({ value: m.value, label: m.label }));
};

const RPManufacturerPicker = forwardRef((props, ref) => {
  const { mfgrs } = useContext(ApiDataContext);
  const mfgrsVals = xformManData(mfgrs);

  return <RPAutoCompleteCore options={mfgrsVals} {...props} ref={ref} />;
});

RPManufacturerPicker.defaultValue = null;

export default RPManufacturerPicker;
