import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const handleOnClose = (reason, closeFn) => {
  if (reason && reason === "backdropClick") return;
  closeFn();
};

const StandardDialog = ({
  open,
  onClose,
  title,
  children,
  actions,
  actionProps,
  ...rest
}) => {
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => handleOnClose(reason, onClose)}
      PaperProps={{ sx: { width: 900, minWidth: 900 } }}
      disableScrollLock
      {...rest}
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Box>{title}</Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={() => handleOnClose(null, onClose)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions {...actionProps}>{actions}</DialogActions>}
    </Dialog>
  );
};

export default StandardDialog;
