import { Avatar, Box, Chip, Stack } from "@mui/material";

/**
 * @param {Array<{
 *   user_id: number,
 *   user_name: string,
 *   user_picture: string
 * }>} ccList
 */
const TicketCCList = ({ title, ccList }) => {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      gap={1}
      sx={{ alignItems: "center" }}
    >
      <Box container="span" sx={{ pl: 1, fontStyle: "italic" }}>
        {title}
      </Box>
      {ccList.map((c) => {
        return (
          <Chip
            key={c.user_id}
            label={c.user_name}
            variant="outlined"
            avatar={<Avatar alt={c.user_name} src={c.user_picture} />}
          />
        );
      })}
    </Stack>
  );
};

export default TicketCCList;
