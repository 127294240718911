import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const SideNavItem = ({ to, label, icon, active }) => {
  const NavIcon = icon;
  return (
    <ListItemButton
      selected={active}
      component={RouterLink}
      to={to}
      disableRipple
    >
      <ListItemIcon>
        <NavIcon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

export default SideNavItem;
