import { useController } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { forwardRef } from "react";

const RHFDatePicker = forwardRef(
  ({ name, control, rules = undefined, size, helperText, ...rest }, ref) => {
    const { field } = useController({ name, control, rules });

    return (
      <DatePicker
        value={field.value}
        onChange={field.onChange}
        inputRef={ref}
        slotProps={{
          textField: {
            size,
            helperText,
            margin: "dense",
            ...rest,
          },
        }}
      />
    );
  }
);

export default RHFDatePicker;
