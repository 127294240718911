import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faBoxesStacked } from "@fortawesome/free-solid-svg-icons";
import { grey } from "@mui/material/colors";

const ItemNameWithIcon = ({ name, item_type }) => {
  const icon =
    item_type === "serialized" ? (
      <FontAwesomeIcon icon={faBox} fontSize="large" color={grey["A700"]} />
    ) : (
      <FontAwesomeIcon
        icon={faBoxesStacked}
        fontSize="large"
        color={grey["A700"]}
      />
    );

  return (
    <>
      {icon} &nbsp; {name}
    </>
  );
};

export default ItemNameWithIcon;
