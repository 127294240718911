import RHFAutocomplete from "./RHFAutocomplete";

export const OPTIONS = [
  { value: "none", label: "None" },
  { value: "delivery", label: "Basic Confirmation" },
  { value: "signature", label: "Signature Required" },
  { value: "adult_signature", label: "Adult Signature Required" },
];

const RHFUPSConfirmationPicker = (props) => {
  return <RHFAutocomplete options={OPTIONS} {...props} />;
};

export default RHFUPSConfirmationPicker;
