import { IconButton, Stack, TableCell, TableRow } from "@mui/material";
import { moneyFmt } from "../../../formatters";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const TR_SX = {
  pl: "5px",
  pr: "5px",
  py: "8px",
};

const OrderFormViewRow = ({ line, hasEditRow, onDelete, onEdit }) => {
  return (
    <TableRow>
      <TableCell sx={TR_SX}>{line.commissionable ? "✓" : ""}</TableCell>
      <TableCell sx={TR_SX}>{line.dos_start}</TableCell>
      <TableCell sx={TR_SX}>{line.dos_end}</TableCell>
      <TableCell sx={TR_SX}>{line.hcpc}</TableCell>
      <TableCell sx={TR_SX}>{line.modifier}</TableCell>
      <TableCell sx={TR_SX}>{line.place_of_service}</TableCell>
      <TableCell sx={TR_SX}>{line.description}</TableCell>
      <TableCell sx={TR_SX}>{line.qty}</TableCell>
      <TableCell sx={TR_SX}>{moneyFmt(line.charge)}</TableCell>
      <TableCell sx={TR_SX}>{moneyFmt(line.qty * line.charge)}</TableCell>
      <TableCell sx={TR_SX}>
        <Stack direction="row">
          <IconButton
            disabled={hasEditRow}
            size="small"
            onClick={() => onEdit(line)}
          >
            <EditIcon fontSize="smaller" />
          </IconButton>
          <IconButton
            disabled={hasEditRow}
            size="small"
            onClick={() => onDelete(line)}
          >
            <DeleteIcon fontSize="smaller" />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default OrderFormViewRow;
