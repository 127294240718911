import { Box, Button, Stack } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import useStdFormErrors from "../../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../../StandardComponents/FormLevelErrorAlert";
import { FILTER_CTRL_MAP } from "../../../../utility";
import ReportFilterList from "../ReportFilterList";

const genDefaultValues = (filters) => {
  const filterVals = [];
  filters.forEach((f, i) => {
    const filter = FILTER_CTRL_MAP[f.type];
    filterVals.push({ ...f, idx: i, value: filter?.blankValue });
  });
  return { filterVals };
};

const ReportFilterForm = ({ api, refresh, reportName, filters }) => {
  const defaultValues = genDefaultValues(filters);
  const { handleSubmit, control, setError } = useForm({ defaultValues });

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    defaultValues,
    (result) => {
      const reportData = new Blob([result]);
      const url = URL.createObjectURL(reportData);
      const linkEl = document.createElement("a");
      document.body.appendChild(linkEl);
      linkEl.style = "display: none";
      linkEl.href = url;
      // this next line could use some work... possibly a way to turn a set of filters into a descriptive string?
      linkEl.download = reportName + ".csv";
      linkEl.click();
      URL.revokeObjectURL(linkEl);
      document.body.removeChild(linkEl);
      clearFormError();
    }
  );

  const { fields } = useFieldArray({ name: "filterVals", control });

  const onSubmit = (data) => {
    const payload = {
      filters: data.filterVals.map((f) =>
        JSON.stringify(FILTER_CTRL_MAP[f.type].transformValue(f.value))
      ),
    };
    apiWrapper(api.getReport(reportName, payload));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={1}>
        <FormLevelErrorAlert formErrStruct={formErrState} />
        <ReportFilterList
          api={api}
          refresh={refresh}
          control={control}
          fields={fields}
        />
        <Box sx={{ textAlign: "right" }}>
          <Button type="submit" variant="contained">
            Run Report
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default ReportFilterForm;
