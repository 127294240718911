import {
  Alert,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
} from "@mui/material";
import useSWR from "swr";
import { genericSWRFetcher } from "../../../utility";
import { useNavigate } from "react-router-dom";

const defaultVals = () => ({
  requested: { count: 0, activity: 0, due: 0 },
  assigned: { count: 0, activity: 0, due: 0 },
  reminders: { count: 0, activity: 0, due: 0 },
  tracking_activity: { count: 0, activity: 0, due: 0 },
  tracking_close: { count: 0, activity: 0, due: 0 },
  closed: { count: 0, activity: 0, due: 0 },
});

const TicketCategoryItem = ({ label, dataKey, countData, category }) => {
  const count = countData[dataKey].count;
  const activity = countData[dataKey].activity;
  const due = countData[dataKey].due;
  const navigate = useNavigate();

  const color = due > 0 ? "error" : activity > 0 ? "info" : "default";

  const selected = category === dataKey;

  return (
    <ListItem disableGutters disablePadding>
      <ListItemButton
        selected={selected}
        onClick={() => {
          navigate(`?category=${dataKey}`, { replace: true });
        }}
      >
        <ListItemText primary={label} />
        <ListItemSecondaryAction>
          <Stack direction="row" spacing={1}>
            <Chip size="small" label={count} color={color} />
          </Stack>
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
};

const TicketCategoryList = ({ api, category }) => {
  const fetcher = genericSWRFetcher(api.ticketSummaryCounts);
  const { data, error } = useSWR("ticketSummaryCounts", fetcher);

  const uData = data ?? defaultVals();

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <List sx={{ width: "100%" }}>
      <TicketCategoryItem
        label="Requested"
        dataKey="requested"
        category={category}
        countData={uData}
      />
      <TicketCategoryItem
        label="Assigned"
        dataKey="assigned"
        category={category}
        countData={uData}
      />
      <TicketCategoryItem
        label="Reminders"
        dataKey="reminders"
        category={category}
        countData={uData}
      />
      <TicketCategoryItem
        label="Tracking Activity"
        dataKey="tracking_activity"
        category={category}
        countData={uData}
      />
      <TicketCategoryItem
        label="Tracking Completed"
        dataKey="tracking_close"
        category={category}
        countData={uData}
      />
      <TicketCategoryItem
        label="Closed"
        dataKey="closed"
        category={category}
        countData={uData}
      />
    </List>
  );
};

export default TicketCategoryList;
