import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const TransferOrderItemListPicker = ({ curInv, handleMoveInv, isShifted }) => {
  if (!curInv) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell sx={{ width: "100px", textAlign: "right" }}>
              Current
            </TableCell>
            <TableCell sx={{ width: "160px" }}></TableCell>
            <TableCell sx={{ width: "100px" }}>Transfer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {curInv
            .map((i) => (
              <TableRow key={i.get("item_id")}>
                <TableCell>
                  {i.get("item_name")} ({i.get("item_model_num")})
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {i.get("available")}
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    disabled={i.get("xfer") === 0}
                    onClick={() => handleMoveInv(i.get("item_id"), -1)}
                  >
                    <KeyboardArrowLeftIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    disabled={i.get("xfer") === 0}
                    onClick={() => handleMoveInv(i.get("item_id"), -10)}
                  >
                    <KeyboardDoubleArrowLeftIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    disabled={i.get("available") === 0}
                    onClick={() => handleMoveInv(i.get("item_id"), 10)}
                  >
                    <KeyboardDoubleArrowRightIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    disabled={i.get("available") === 0}
                    onClick={() => handleMoveInv(i.get("item_id"), 1)}
                  >
                    <KeyboardArrowRightIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell>{i.get("xfer")}</TableCell>
              </TableRow>
            ))
            .toArray()
            .map(([k, v]) => v)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransferOrderItemListPicker;
