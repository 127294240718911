import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPContactPicker = forwardRef(({ api, practiceId, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acPConts(practiceId)}
    />
  );
});

RPContactPicker.defaultValue = null;

export default RPContactPicker;
