import { Button } from "@mui/material";
import { useWatch } from "react-hook-form";

const ReceivePOButton = ({ control, name, disabled = false }) => {
  const lines = useWatch({ control, name });

  const isFull = lines.every((l) => l.qty === l.qty_received);
  const label = isFull ? "Full Receive" : "Partial Receive";
  const color = isFull ? "success" : "warning";

  return (
    <Button type="submit" variant="contained" disabled={disabled} color={color}>
      {label}
    </Button>
  );
};

export default ReceivePOButton;
