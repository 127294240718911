import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import { dateObjFormatToAnnArborDate } from "../../../utility";

const PurchaseOrderTable = ({ poList }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>PO</TableCell>
          <TableCell>Manufacturer</TableCell>
          <TableCell>Recipient</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Created</TableCell>
          <TableCell>Received</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {poList.map((po) => {
          const poIdStr = `PO-${po.id.toString().padStart(4, "0")}`;

          return (
            <TableRow key={po.id}>
              <TableCell>
                <InternalLink to={`/purchase-orders/${po.id}`}>
                  {poIdStr}
                </InternalLink>
              </TableCell>
              <TableCell>{po.mfgr_name}</TableCell>
              <TableCell>
                <InternalLink to={`/agencies/${po.agency_id}`}>
                  {po.agency_name}
                </InternalLink>
              </TableCell>
              <TableCell>{po.status}</TableCell>
              <TableCell>
                {dateObjFormatToAnnArborDate(po.created_on)}
              </TableCell>
              <TableCell>
                {dateObjFormatToAnnArborDate(po.received_on)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default PurchaseOrderTable;
