import { Typography, useTheme } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { assign, createMachine } from "xstate";
import LoadingPageError from "../Components/PagePartials/LoadingPageError";

const pageLoadMachine = createMachine({
  id: "pageLoad",
  predictableActionArguments: true,
  context: {
    pageData: null,
    errorMessage: "",
    startLoadTime: 0,
    endLoadTime: 0,
  },
  initial: "loading",
  states: {
    loading: {
      entry: ["makeApiCall", assign({ startLoadTime: () => +new Date() })],
      on: {
        FAIL: { target: "error" },
        LOAD: { target: "loaded" },
      },
      exit: [assign({ endLoadTime: () => +new Date() })],
    },
    error: {
      entry: [assign({ errorMessage: (context, event) => event.message })],
      on: {
        RETRY: { target: "loading" },
      },
      exit: [assign({ errorMessage: "" })],
    },
    loaded: {
      initial: "idle",
      states: {
        idle: {
          entry: [assign({ pageData: (context, event) => event.data })],
          on: {
            RELOAD: { target: "reloading" },
          },
        },
        reloading: {
          entry: ["makeApiCall"],
          on: {
            FAIL: { target: "reloadError" },
            LOAD: { target: "idle" },
          },
        },
        reloadError: {
          entry: [assign({ errorMessage: (context, event) => event.message })],
          on: {
            RELOAD: { target: "reloading" },
          },
          exit: [assign({ errorMessage: "" })],
        },
      },
    },
  },
});

export const withPageLevelCall = (WrappedPage, apiCallFn) => (props) => {
  const theme = useTheme();
  const params = useParams();
  const [queryParams] = useSearchParams();

  const [cur, send] = useMachine(pageLoadMachine, {
    actions: {
      makeApiCall: () => {
        apiCallFn(params, queryParams)
          .then((resp) => {
            if (resp.status !== "ok") {
              return send({ type: "FAIL", message: resp.message });
            }
            return send({ type: "LOAD", data: resp.data });
          })
          .catch((err) => {
            return send({ type: "FAIL", message: err.message });
          });
      },
    },
  });

  const refreshPage = () => {
    send({ type: "RELOAD" });
  };

  if (cur.matches("loading")) {
    return null;
  }

  if (cur.matches("error")) {
    return (
      <LoadingPageError
        msg={cur.context.errorMessage}
        retry={() => send({ type: "RETRY" })}
      />
    );
  }

  const callLen = cur.context.endLoadTime - cur.context.startLoadTime;

  return (
    <>
      <WrappedPage
        pageData={cur.context.pageData}
        refresh={refreshPage}
        refreshError={cur.context.errorMessage}
        {...props}
      />
      <Typography
        mt={3}
        display="block"
        variant="caption"
        sx={{ color: theme.palette.grey["400"] }}
      >
        API Call: {callLen}ms (this is about{" x"}
        {Math.floor(100 * (2000 / callLen)) / 100} faster than Asset Panda)
      </Typography>
    </>
  );
};
