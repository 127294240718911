import { InputAdornment, TextField } from "@mui/material";
import composeRefs from "@seznam/compose-react-refs";
import { parseIntB } from "../../../utility";
import { basicTextFieldProps } from "../../FormControlsRHF/_defaults";
import { useController } from "react-hook-form";
import { forwardRef } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";

const RHFReceiveConsumableField = forwardRef(
  ({ control, name, minVal, maxVal, rules, ...rest }, outerRef) => {
    const {
      field,
      fieldState: { error },
    } = useController({ name, control, rules });

    const fullyReceived = field.value === maxVal;

    return (
      <TextField
        name={field.name}
        value={field.value}
        inputRef={composeRefs(field.ref, outerRef)}
        onBlur={field.onBlur}
        inputProps={{
          sx: {
            pl: 1,
            pr: 0,
            pt: "4px",
            pb: "4px",
            fontSize: "smaller",
          },
        }}
        InputProps={{
          endAdornment: fullyReceived ? (
            <InputAdornment position="end">
              <CheckCircleIcon color="success" fontSize="small" />
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <WarningIcon color="warning" fontSize="small" />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          const parsed = parseIntB(e.target.value);
          if (null === parsed) {
            field.onChange(minVal);
            return;
          }
          if (parsed < minVal) {
            field.onChange(minVal);
            return;
          }
          if (parsed > maxVal) {
            field.onChange(maxVal);
            return;
          }
          field.onChange(parsed);
        }}
        error={!!error}
        helperText={error?.message ?? " "}
        type="number"
        {...basicTextFieldProps}
        margin="none"
        {...rest}
      />
    );
  }
);

export default RHFReceiveConsumableField;
