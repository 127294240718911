import { Button, Tooltip } from "@mui/material";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";

const DaisyPatientLinkPushButton = ({ api, onSuccess, eocId }) => {
  const [submitState, setSubmitState] = useState({
    state: "init",
    error: null,
  });

  const onClick = async () => {
    setSubmitState({
      state: "submitting",
      error: null,
    });
    try {
      const result = await api.daisyPatientPush(eocId);
      if (result.status === "ok") {
        setSubmitState({
          state: "success",
          error: null,
        });
        onSuccess();
      } else {
        setSubmitState({
          state: "error",
          error: result.message,
        });
      }
    } catch (err) {
      setSubmitState({
        state: "error",
        error: err.message,
      });
    }
  };

  const buttonColor = submitState.state === "error" ? "error" : "primary";
  const buttonIcon = submitState.state === "error" ? <ErrorIcon /> : undefined;

  return (
    <Tooltip title={submitState.error}>
      <Button
        variant="contained"
        onClick={onClick}
        color={buttonColor}
        startIcon={buttonIcon}
        disabled={submitState.state === "submitting"}
      >
        Push this patient into DaisyBill
      </Button>
    </Tooltip>
  );
};

export default DaisyPatientLinkPushButton;
