import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { moneyFmt } from "../../../formatters";
import StdUserAvatar from "../../StandardComponents/StdUserAvatar";
import { dateObjFormatToAnnArborDateTime } from "../../../utility";

const CommissionIncentivesTable = ({ incentives }) => {
  if (incentives.length === 0) return null;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Amount</TableCell>
          <TableCell>Memo</TableCell>
          <TableCell>Reimbursable</TableCell>
          <TableCell>Created</TableCell>
          <TableCell>Swept?</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {incentives.map((i) => {
          const swept = i.swept_on ? "Yes" : i.ignored_on ? "Ignored" : "No";
          return (
            <TableRow key={i.id}>
              <TableCell>{moneyFmt(i.amount)}</TableCell>
              <TableCell>{i.memo}</TableCell>
              <TableCell>{i.reimbursable ? "Yes" : "No"}</TableCell>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <StdUserAvatar
                    user_name={i.created_by_name}
                    user_pic={i.created_by_pic}
                  />
                  <Box>
                    {dateObjFormatToAnnArborDateTime(i.created_on, false)}
                  </Box>
                </Stack>
              </TableCell>
              <TableCell>{swept}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default CommissionIncentivesTable;
