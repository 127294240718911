import { useState } from "react";
import { Alert, Divider, Grid, Stack, Typography } from "@mui/material";
import PersonalCard from "./PagePatient/PersonalCard";
import ComplexSplitButton from "../StandardComponents/ComplexSplitButton";
import InsuranceCard from "./PagePatient/InsuranceCard";
import {
  BusinessActionManager,
  useDialogCtrl,
} from "../BusinessActions/BusinessActionManager";
import TreatmentSection from "./PagePatient/TreatmentSection";
import InjuryCard from "./PagePatient/InjuryCard";
import StdFormDialog from "../PagePartials/StdFormDialog";
import RPUserPicker from "../FormControls/RPUserPicker";
import PayorPickerFormDialog from "../PagePartials/PayorPickerFormDialog";
import PatientSummaryCard from "../PagePartials/PatientSummaryCard";
import DosUpdateFormDialog from "./PagePatient/DosUpdateFormDialog";
import useDialogState from "../../HOC/useDialogState";
import TreatSiteUpdateFormDialog from "./PagePatient/TreatSiteUpdateFormDialog";
import Icd10ChangeForm from "./PagePatient/Icd10ChangeForm";
import CommentFeed from "../StandardComponents/CommentFeed";
import { dateObjFormatToAnnArborDateTime } from "../../utility";
import RPTextArea from "../FormControls/RPTextArea";
import PatientFeedbackForm from "./PagePatient/PatientFeedbackForm";
import FeedbackCard from "./PagePatient/FeedbackCard";

const setMemoFormFields = [
  {
    name: "memo",
    component: RPTextArea,
    label: "Memo",
    width: 12,
  },
];

const guideSetForm = (api) => [
  {
    name: "guide_patient",
    component: RPUserPicker,
    label: "Patient Guide",
    width: 6,
    extraProps: { api },
  },
  {
    name: "guide_practice",
    component: RPUserPicker,
    label: "Practice Guide",
    width: 6,
    extraProps: { api },
  },
  {
    name: "guide_biller",
    component: RPUserPicker,
    label: "Billing Guide",
    width: 6,
    extraProps: { api },
  },
  {
    name: "guide_sales",
    component: RPUserPicker,
    label: "Sales Guide",
    width: 6,
    extraProps: { api },
  },
];

const achievementBanner = (pageData) => {
  if (!pageData.achievements) {
    return [];
  }

  const { achievements } = pageData;

  const banners = [];

  const patientName = `${pageData.name_first} ${pageData.name_last}`;
  const repName = pageData.treatments[0].rxs[0].sales_rep_name;

  if (achievements.includes("first_sales_rep_rx")) {
    banners.push(
      <Alert severity="info" key="first_sales_rep_rx_banner">
        {patientName} is {repName}'s First Rx Submission!
      </Alert>
    );
  }

  return banners;
};

const memoBanner = (pageData) => {
  const { memo } = pageData;

  if (!memo) {
    return null;
  }

  return (
    <Alert severity="error" variant="outlined" key="memoBanner">
      <Typography gutterBottom sx={{ whiteSpace: "pre-line" }}>
        {memo}
      </Typography>
      <Typography variant="caption" display="block">
        {dateObjFormatToAnnArborDateTime(pageData.memo_modified_on, false)} (
        {pageData.memo_modified_by})
      </Typography>
    </Alert>
  );
};

const referralBanner = (pageData) => {
  if (!pageData.referral) {
    return null;
  }

  const patientName = `${pageData.name_first} ${pageData.name_last}`;
  const { first_org_name } = pageData;

  return (
    <Alert severity="info" key="referralBanner">
      {patientName} is a referral from {first_org_name}
    </Alert>
  );
};

const operationalBanners = (pageData) => {
  const banners = [];

  const patientName = `${pageData.name_first} ${pageData.name_last}`;

  const rng = pageData.treatments.some((treatment) =>
    treatment.rxs.some((rx) => rx.rxer_ret_and_growth)
  );

  if (rng) {
    banners.push(
      <Alert severity="info" key="opsBannerRnG">
        {patientName} is a patient of a prescriber in the Retention and Growth
        program.
      </Alert>
    );
  }

  return banners;
};

const BannerList = ({ pageData }) => {
  const memo = memoBanner(pageData);
  const achvBanners = achievementBanner(pageData);
  const refBanner = referralBanner(pageData);
  const opsBanners = operationalBanners(pageData);

  const banners = [memo, ...achvBanners, refBanner, ...opsBanners];

  if (banners.filter(Boolean).length === 0) return null;

  return (
    <Grid item xs={12}>
      <Stack spacing={1}>{banners}</Stack>
    </Grid>
  );
};

const PagePatient = ({ pageData, api, refresh }) => {
  const [openGuideSetForm, setOpenGuideSetForm] = useState(false);
  const handleOpenGuideSetForm = () => setOpenGuideSetForm(true);
  const handleCloseGuideSetForm = () => setOpenGuideSetForm(false);

  const [openPayorForm, setOpenPayorForm] = useState(false);
  const handleOpenPayorForm = () => setOpenPayorForm(true);
  const handleClosePayorForm = () => setOpenPayorForm(false);

  const {
    open: openDosChangeForm,
    closeFn: handleCloseDosChangeForm,
    openFn: handleOpenDosChangeForm,
  } = useDialogState();

  const {
    open: openTreatSiteChangeForm,
    closeFn: handleCloseTreatSiteChangeForm,
    openFn: handleOpenTreatSiteChangeForm,
  } = useDialogState();

  const {
    open: openIcd10Form,
    closeFn: handleCloseIcd10Form,
    openFn: handleOpenIcd10Form,
  } = useDialogState();

  const {
    open: openSetBannerNoticeForm,
    closeFn: handleCloseSetBannerNoticeForm,
    openFn: handleOpenSetBannerNoticeForm,
  } = useDialogState();

  const {
    open: openPatientFeedbackForm,
    closeFn: handleClosePatientFeedbackForm,
    openFn: handleOpenPatientFeedbackForm,
  } = useDialogState();

  pageData.name_full = [pageData.name_first, pageData.name_last]
    .filter((n) => !!n)
    .join(" ");
  const hasCautions = pageData.cautions.length > 0 || null;
  const cautionStrList = pageData.cautions
    .map(([, c]) => c)
    .concat(["update-demos"]);
  const { openStates, openDialog, closeDialogs } =
    useDialogCtrl(cautionStrList);

  const BTN_OPTS = [
    {
      label: "Update Demos",
      onClick: () => openDialog("update-demos"),
    },
    {
      label: "Set Primary Payor",
      onClick: handleOpenPayorForm,
    },
    {
      label: "Set Guides",
      onClick: handleOpenGuideSetForm,
    },
    {
      label: "Update Treatment Sites",
      onClick: handleOpenTreatSiteChangeForm,
    },
    {
      label: "Update ICD-10 Codes",
      onClick: handleOpenIcd10Form,
    },
    {
      label: "Set Banner Notice",
      onClick: handleOpenSetBannerNoticeForm,
    },
    {
      label: "Add Patient Feedback",
      onClick: handleOpenPatientFeedbackForm,
    },
  ];

  if (pageData.surgical) {
    BTN_OPTS.push({
      label: "Change Surgery Date",
      onClick: handleOpenDosChangeForm,
    });
  }

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <PatientSummaryCard
            navCtx={pageData.nav}
            actionArea={<ComplexSplitButton options={BTN_OPTS} />}
            api={api}
            taskContext={{
              type: "treatments",
              ident: pageData.treatments[0].id,
              label: pageData.name_full,
            }}
          />
        </Grid>
        <BannerList pageData={pageData} />
        <Grid item xs={7}>
          <Stack spacing={2}>
            <InjuryCard
              pageData={pageData}
              hasCautions={hasCautions}
              openDialog={openDialog}
            />
            {!!pageData.feedback.length && (
              <FeedbackCard
                feedback={pageData.feedback}
                api={api}
                refresh={refresh}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack direction="column" spacing={2}>
            <PersonalCard pageData={pageData} />
            <InsuranceCard pageData={pageData} api={api} refresh={refresh} />
          </Stack>
        </Grid>
        {pageData.treatments.map((treat) => (
          <TreatmentSection
            key={treat.id}
            pageData={pageData}
            treat={treat}
            api={api}
            refresh={refresh}
          />
        ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <CommentFeed
            api={api}
            type="treatment"
            id={pageData.treatments[0].id}
            embedPhone={pageData.phone}
            embedPatientName={[pageData.name_first, pageData.name_last].join(
              " "
            )}
          />
        </Grid>
      </Grid>
      <BusinessActionManager
        pageData={pageData}
        openStates={openStates}
        closeDialogs={closeDialogs}
        actionList={cautionStrList}
        api={api}
        refresh={refresh}
      />
      <StdFormDialog
        title="Set Guides"
        fields={guideSetForm(api)}
        onClose={handleCloseGuideSetForm}
        open={!!openGuideSetForm}
        initialVals={{
          guide_patient: pageData.guides.patient.id,
          guide_practice: pageData.guides.practice.id,
          guide_biller: pageData.guides.biller.id,
          guide_sales: pageData.guides.sales.id,
        }}
        submitCall={(data) => {
          data = Object.fromEntries(
            Object.entries(data).map(([k, v]) => [k, parseInt(v, 10)])
          );
          return api.assignGuides(pageData.id, data);
        }}
        onComplete={() => {
          refresh();
          handleCloseGuideSetForm();
        }}
      />
      <PayorPickerFormDialog
        refresh={refresh}
        api={api}
        eocId={pageData.id}
        curOrgId={pageData.porg_id}
        curPayorId={pageData.payor_id}
        open={openPayorForm}
        onClose={handleClosePayorForm}
      />
      <DosUpdateFormDialog
        open={openDosChangeForm}
        api={api}
        refresh={refresh}
        onClose={handleCloseDosChangeForm}
        eocId={pageData.id}
        curDos={pageData.date_of_surgery}
      />
      <TreatSiteUpdateFormDialog
        open={openTreatSiteChangeForm}
        api={api}
        refresh={refresh}
        onClose={handleCloseTreatSiteChangeForm}
        eocId={pageData.id}
        treatSites={pageData.treatment_sites}
      />
      <Icd10ChangeForm
        open={openIcd10Form}
        api={api}
        refresh={refresh}
        onClose={handleCloseIcd10Form}
        eocId={pageData.id}
        icd10s={pageData.icd10s}
        diag={pageData.diagnosis}
      />
      <StdFormDialog
        title="Set Banner Notice"
        fields={setMemoFormFields}
        onClose={handleCloseSetBannerNoticeForm}
        open={!!openSetBannerNoticeForm}
        initialVals={{
          memo: pageData.memo,
        }}
        submitCall={(data) => {
          return api.ptnUpdateMemo(pageData.id, data);
        }}
        onComplete={() => {
          refresh();
          handleCloseSetBannerNoticeForm();
        }}
      />
      <PatientFeedbackForm
        api={api}
        open={openPatientFeedbackForm}
        eocId={pageData.id}
        onClose={handleClosePatientFeedbackForm}
        refresh={refresh}
      />
    </>
  );
};

export default PagePatient;
