import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import { SvgIcon } from "@mui/material";
import { forwardRef } from "react";

const SerializedItemIcon = forwardRef(({ ...props }, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <FontAwesomeIcon icon={faBox} />
    </SvgIcon>
  );
});

export default SerializedItemIcon;
