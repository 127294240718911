import { TableBody } from "@mui/material";
import DataTableRow from "./DataTableRow";
import { isFunction } from "../../../utility";

const resolveArgs = (args, rowData) => {
  if (Array.isArray(args)) {
    return args;
  }

  if (isFunction(args)) {
    return args(rowData);
  }

  return [];
};

const DataTableBody = ({ cols, data }) => {
  const rowData = data.map((r) => {
    const row = [];
    cols.forEach((c) => {
      const keyName = c.name;
      const formatFn = c.formatFn || ((x) => x);
      const formatFnArgs = resolveArgs(c.formatArgs, r);
      const value = formatFn(r[keyName], ...formatFnArgs);

      if (value === undefined || value === null) {
        row.push("");
        return;
      }
      row.push(value);
    });
    return row;
  });

  return (
    <TableBody>
      {rowData.map((r, i) => (
        <DataTableRow key={`row-${i}`} rowNum={i} rowData={r} />
      ))}
    </TableBody>
  );
};

export default DataTableBody;
