import { Card, CardContent, CardHeader } from "@mui/material";
import GuideChip from "./GuideChip";

const GuideBox = ({ title, guide }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        textAlign: "center",
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: "body1" }}
        sx={{ pb: 0, pt: 1 }}
      />
      <CardContent sx={{ py: 0, "&:last-child": { pb: 1 } }}>
        <GuideChip guide={guide} />
      </CardContent>
    </Card>
  );
};

export default GuideBox;
