import { Box } from "@mui/material";
import { differenceInDays, differenceInYears, format, parse } from "date-fns";
import { formatInTimeZone, toZonedTime } from "date-fns-tz";
import { sprintf } from "sprintf-js";
import ReportFilterDateRange from "./Components/Pages/PageReports/Filters/ReportFilterDateRange";
import ReportFilterEnum from "./Components/Pages/PageReports/Filters/ReportFilterEnum";
import ReportFilterLinkedEntity from "./Components/Pages/PageReports/Filters/ReportFilterLinkedEntity";

export const isFunction = (value) => {
  return (
    value &&
    (Object.prototype.toString.call(value) === "[object Function]" ||
      "function" === typeof value ||
      value instanceof Function)
  );
};

export const surgeryStr = (surgical, dateOfSurgery) => {
  if (!surgical) {
    return "Non-Op";
  }

  if (dateOfSurgery === null) {
    return "(unknown)";
  }

  const nowDateObj = dateStrToDateObj(nowDate());
  const normDate = dateStrToDateObj(dateOfSurgery);

  const diff = differenceInDays(normDate, nowDateObj);
  let extra = "";

  if (diff > 1) extra = ` (in ${diff} days)`;
  if (diff === 1) extra = ` (tomorrow)`;
  if (diff === 0) extra = ` (today)`;
  if (diff === -1) extra = ` (yesterday)`;
  if (diff < -1) extra = ` (${diff * -1} days ago)`;

  return `${dateOfSurgery}${extra}`;
};

export const STATES_N = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
  { value: "AS", label: "American Samoa" },
  { value: "GU", label: "Guam" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "PR", label: "Puerto Rico" },
  { value: "VI", label: "U.S. Virgin Islands" },
];

export const maybeFixLazyVal = (origValue, options) => {
  if (!Array.isArray(options)) {
    return origValue;
  }

  if (typeof origValue === "string" || typeof origValue === "number") {
    const result = options.find(
      (opt) => opt.value.toString() === origValue.toString()
    );
    if (!result) {
      return origValue;
    }
    return result;
  }

  return origValue;
};

export const ucWord = (word) =>
  `${word.substring(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`;

export const npiName = (first, last, cred) => {
  return [
    ucWord(first),
    ucWord(last),
    cred.replaceAll(".", "").toUpperCase(),
  ].join(" ");
};

export const npiDataMeddle = (npiRec) => {
  return {
    ...npiRec,
    name_last: ucWord(npiRec.name_last),
    name_first: ucWord(npiRec.name_first),
    credential: npiRec.credential.replaceAll(".", "").toUpperCase(),
  };
};

export const distillPartLateralFromDesc = (code, desc) => {
  if (code[0] === "Z" || code[0] === "Y" || code[0] === "V") {
    return null;
  }

  const votes = {
    shoulder: 0,
    elbow: 0,
    knee: 0,
    hip: 0,
    arm: 0,
    cervical: 0,
    lumbar: 0,
    ankle: 0,
    wrist: 0,
    leg: 0,
  };

  const lateral = {
    left: 0,
    right: 0,
  };

  const norm = desc
    .toLowerCase()
    .replaceAll(/[^a-z]/g, " ")
    .split(" ")
    .filter((x) => !!x);
  norm.forEach((w, i) => {
    if (
      w === "leg" ||
      w === "tibia" ||
      w === "fibula" ||
      w === "quadriceps" ||
      (w === "lower" && norm[i + 1] === "limb") ||
      (w === "lower" && norm[i + 1] === "limbs") ||
      w === "thigh"
    )
      votes.leg++;
    if (w === "shoulder" || w === "acromioclavicular") votes.shoulder++;
    if (w === "rotator" && norm[i + 1] === "cuff") votes.shoulder++;
    if (w === "elbow") votes.elbow++;
    if (w === "knee" || w === "patella") votes.knee++;
    if (w === "hip") votes.hip++;
    if (
      w === "arm" ||
      w === "humerus" ||
      (w === "upper" && norm[i + 1] === "limb") ||
      (w === "upper" && norm[i + 1] === "limbs")
    )
      votes.arm++;
    if (w === "cervical" || w === "neck" || w === "cervicalgia")
      votes.cervical++;
    if (
      w === "lumbar" ||
      w === "lumbago" ||
      w === "back" ||
      w === "spine" ||
      w === "lumbosacral"
    )
      votes.lumbar++;
    if (
      w === "ankle" ||
      w === "foot" ||
      w === "calcaneus" ||
      (w === "achilles" && norm[i + 1] === "tendinitis") ||
      (w === "achilles" && norm[i + 1] === "tendon") ||
      (w === "peroneal" && norm[i + 1] === "tendinitis") ||
      (w === "peroneal" && norm[i + 1] === "tendon")
    )
      votes.ankle++;
    if (
      w === "wrist" ||
      w === "hand" ||
      w === "radius" ||
      w === "de" ||
      norm[i + 1] === "quervain"
    )
      votes.wrist++;
    if (w === "left") lateral.left++;
    if (w === "right") lateral.right++;
  });

  let choice = "";
  let v = 0;
  Object.keys(votes).forEach((k) => {
    if (votes[k] > v) {
      v = votes[k];
      choice = k;
    }
  });
  let latPick = "";
  let latVotes = 0;
  Object.keys(lateral).forEach((k) => {
    if (lateral[k] > latVotes) {
      latVotes = lateral[k];
      latPick = k;
    }
  });

  if (choice === "hip" || choice === "lumbar" || choice === "cervical") {
    return choice;
  }

  if (!latPick) {
    return null;
  }

  return `${choice}-${latPick}`;
};

export const squeeze = (str) => {
  let prev;
  do {
    prev = str;
    str = str.replaceAll("  ", " ");
  } while (prev !== str);
  return str;
};

export const dateStrHyphenToDateObj = (str) => {
  const tmpDate = parse(str, "yyyy-MM-dd", new Date());
  if (tmpDate.toString() === "Invalid Date") {
    return null;
  }
  return tmpDate;
};

export const dateStrToDateObj = (str) => {
  const tmpDate = parse(str, "MM/dd/yyyy", new Date());
  if (tmpDate.toString() === "Invalid Date") {
    throw new Error(`${str} is not a valid date!`);
  }
  return toZonedTime(tmpDate, "America/Detroit");
};

export const nowDate = () => {
  const now = new Date();
  return formatInTimeZone(now, "America/Detroit", "MM/dd/yyyy");
};

/**
 * @param {string} ipt
 * @returns {Date|null}
 */
export const hydrateDateObj = (ipt) => {
  if (!ipt) return null;
  const d = new Date(ipt);
  if (isNaN(d.valueOf())) return null;
  return d;
};

export const dateObjFormatToAnnArborDateTime = (date, tz = true) => {
  if (!date) {
    return "";
  }
  date = new Date(date);
  const ret = `${formatInTimeZone(date, "America/Detroit", "M/d/yy h:mm a")}${
    tz ? " EST" : ""
  }`;
  return ret;
};

export const dateObjFormatToAnnArborDate = (date) => {
  if (!date) return "";
  date = new Date(date);
  return formatInTimeZone(date, "America/Detroit", "MM/dd/yyyy");
};

export const dateObjFormatToWRSDate = (date) => {
  if (!date) {
    return "";
  }
  date = new Date(date);
  const y = date.getFullYear();
  const m = (date.getMonth() + 1).toString().padStart(2, "0");
  const d = date.getDate().toString().padStart(2, "0");
  return `${m}/${d}/${y}`;
};

export const dateObjFormatToSmallest = (date) => {
  if (!date) {
    return "";
  }
  date = new Date(date);
  const y = date.getFullYear();
  const m = (date.getMonth() + 1).toString();
  const d = date.getDate().toString();
  return `${m}/${d}/${y}`;
};

/**
 * @param {{
 *   addr_street1: string,
 *   addr_street2: string,
 *   addr_city: string,
 *   addr_state: string,
 *   addr_zip: string
 * }} fd
 * @returns {string}
 */
export const formatAddr = (fd) => {
  const line1 = [fd.addr_street1, fd.addr_street2].filter(Boolean).join(", ");
  const line2 = [
    [fd.addr_city, fd.addr_state].filter(Boolean).join(", "),
    fd.addr_zip,
  ]
    .filter(Boolean)
    .join(" ");
  return [line1, line2].filter((x) => !!x).join("\n");
};

export const parseIntB = (ipt) => {
  const res = parseInt(ipt, 10);
  if (isNaN(res)) {
    return null;
  }

  return res;
};

export const sanitizeStr = (str) =>
  squeeze(str.replaceAll(/[\t\n\r]/g, " ")).trim();

export const reformatDate = (dirtyDate) => {
  if (!dirtyDate) {
    return null;
  }

  const normDate = dirtyDate.replaceAll(/\D/g, "");
  const year = normDate.substring(4);
  const month = normDate.substring(0, 2);
  const day = normDate.substring(2, 4);
  return `${year}-${month}-${day}`;
};

/**
 * Takes in a date object and gets back the formatted 'datestring'
 *
 * This is used primarily when a Date object is coming out of a date picker control. The assumption is that the year,
 * month, and day numbers are the correct ones for the date string. Timezones are ignored here since we're talking
 * about dates and not datetimes.
 *
 * @param {Date|null|undefined} dateObj
 * @returns {string|null}
 */
export const dateObjToDateStr = (dateObj) => {
  if (!dateObj) return null;

  return format(dateObj, "yyyy-MM-dd");
};

const SRVC_STATUS = {
  created: {
    current: "Created: %s",
    lc_next: "Waiting on PSR to fit",
    ds_next: "Needs initial contact",
  },
  acked_on: {
    current: "Initial Contact: %s",
    lc_next: "Waiting on PSR to fit",
    ds_next: "Need preferred delivery date",
  },
  pref_arrive: {
    current: "Delivery commitment: %s",
    lc_next: "Waiting on PSR to fit",
    ds_next: "Need shipping plan",
  },
  pref_ship: {
    current: "Planned to ship: %s",
    lc_next: "Waiting on PSR to fit",
    ds_next: "Unit needs to be packed",
  },
  packed: {
    current: "Unit packed for delivery: %s",
    lc_next: "Waiting on PSR to fit",
    ds_next: "Unit needs to be shipped",
  },
  shipped: {
    current: "Unit shipped: %s",
    lc_next: "Waiting on PSR to fit",
    ds_next: "Awaiting unit delivery",
  },
  arrived: {
    current: "Unit arrived: %s",
    lc_next: "Waiting on PSR to fit",
    ds_next: "Patient needs fitting",
  },
  fit: {
    current: "Patient fit: %s",
    lc_next: "Waiting on Service Start",
    ds_next: "Waiting on Service Start",
  },
  service_start: {
    current: "Service start: %s",
    lc_next: "Waiting on Service End",
    ds_next: "Waiting on Service End",
  },
  service_end: {
    current: "Service end: %s",
    lc_next: "Waiting on PSR to pick up",
    ds_next: "Needs pickup plan",
  },
  pref_pickup: {
    current: "Planned pick up: %s",
    lc_next: "Waiting on PSR to pick up",
    ds_next: "Waiting for unit pick up",
  },
  picked_up: {
    current: "Unit picked up: %s",
    lc_next: "Waiting on PSR to pick up",
    ds_next: "Waiting for unit return",
  },
  returned: {
    current: "Unit returned: %s",
    lc_next: "Waiting on PSR to pick up",
    ds_next: "Waiting for unit reprocessing",
  },
  reprocessed: {
    current: "Unit reprocessed: %s",
    lc_next: "Waiting on treatment close",
    ds_next: "Waiting on treatment close",
  },
  closed: {
    current: "Closed: %s",
    lc_next: "",
    ds_next: "",
  },
};

export const srvcStatusUpNext = (serviceChannel, currentStatus) => {
  const stat = SRVC_STATUS[currentStatus];
  return serviceChannel === "drop-ship" ? stat.ds_next : stat.lc_next;
};

export const srvcStatusCurrent = (currentStatus, relevantDate) => {
  const stat = SRVC_STATUS[currentStatus];
  return sprintf(stat.current, relevantDate);
};

export const ageFromBday = (bday) => {
  const now = new Date();
  const bdayObj = dateStrToDateObj(bday);
  return differenceInYears(now, bdayObj);
};

export const demoSubheader = (dateOfBirth, gender) => {
  const age = ageFromBday(dateOfBirth);
  let gen = " (gender unspecified)";
  if (gender && gender !== "U" && gender !== "X")
    gen = gender === "M" ? " male" : " female";
  return `${age} year old${gen}`;
};

export const genToStr = (genNum) => {
  if (genNum < 0) return "??";
  if (genNum === 0) return "Initial";
  return `Extension ${genNum}`;
};

export const maxGenToStr = (maxGen) => {
  if (maxGen < 0) return "??";
  if (maxGen === 0) return "Single Rx";
  if (maxGen === 1) return "Extended";
  if (maxGen === 2) return "Extended Twice";
  if (maxGen === 3) return "Extended Thrice";
  if (maxGen > 3) return `Extended ${maxGen} Times`;
};

export const daysRelative = (dayNum) => {
  if (dayNum > 1) return `${dayNum} days`;
  if (dayNum === 1) return "1 day";
  if (dayNum === 0) return "today";
  if (dayNum === -1) return "yesterday";
  if (dayNum < -1) return `${Math.abs(dayNum)} days ago`;
};

export const canCreateExtension = (rx) =>
  rx.ext_eligible &&
  rx.ext_ptn_res === "accepted" &&
  rx.ext_prac_res === "accepted";

export const getByPath = (obj, path) => {
  const arr = path.split(".");
  while (arr.length) {
    const key = arr.shift();
    obj = obj[key];
    if (obj === undefined) {
      return undefined;
    }
  }
  return obj;
};

// Encodes a UUID string to a URL-safe Base64url-encoded string
export const encodeUUIDToBase64url = (uuid) => {
  const bytes = uuid.match(/[\da-f]{2}/gi).map((h) => parseInt(h, 16));
  const base64url = btoa(String.fromCharCode(...bytes))
    .replace("+", "-")
    .replace("/", "_")
    .replace(/=+$/, "");
  return base64url;
};

export const genNumberToHumanReadable = (generation) => {
  if (generation === 0) return "Initial";
  if (generation === 1) return "Extension";
  return `Extension ${generation}`;
};

export const TREAT_SITES = [
  "shoulder-left",
  "shoulder-right",
  "cervical",
  "arm-left",
  "arm-right",
  "elbow-left",
  "elbow-right",
  "lumbar",
  "wrist-left",
  "wrist-right",
  "finger-left",
  "finger-right",
  "hip",
  "leg-left",
  "leg-right",
  "knee-left",
  "knee-right",
  "ankle-left",
  "ankle-right",
];

/**
 * @param {Array} arr
 * @param {function} predicate
 * @param {string} errMsg
 */
export const arrayFindOrDie = (arr, predicate, errMsg = "") => {
  const result = arr.find(predicate);
  if (result === undefined)
    throw new Error(!!errMsg ? errMsg : "Could not find array item");
  return result;
};

export const productionStringsFromScores = (
  currentScore,
  baselineScore,
  colors = false
) => {
  if (baselineScore === null) return ["?? / ??", "+??%", null];
  if (baselineScore === 0) return ["0 / 0", "+0.0%", null];

  const gain = currentScore / (baselineScore / 3);
  const scoreStr = `${currentScore} / ${
    Math.round((baselineScore / 3) * 100) / 100
  }`;

  let gainFormatted =
    (gain >= 1 ? "+" : "") +
    (Math.round((gain - 1) * 1000) / 10).toString().concat("%");

  if (colors) {
    const color =
      gain <= 0.95
        ? "error.main"
        : gain >= 1.05
        ? "success.main"
        : "warning.main";
    gainFormatted = (
      <Box component="span" sx={{ color }}>
        {gainFormatted}
      </Box>
    );
  }

  return [scoreStr, gainFormatted, gain];
};

export const productionCategoryFromScores = (
  currentScore,
  baselineScore,
  age
) => {
  if (baselineScore === 0 && age < 30) return "baby";
  if (baselineScore === 0) return "dead";
  if (baselineScore === null) return "unknown";
  const [, , gain] = productionStringsFromScores(currentScore, baselineScore);

  if (gain > 1.5) return "fire";
  if (gain > 1.05) return "rising";
  if (gain < 0.95) return "falling";
  return "target";
};

export const CAT_SORT = {
  fire: 0,
  rising: 0,
  target: 0,
  falling: 0,
  baby: 1,
  dead: 2,
  unknown: 3,
};

export const prodCatSort = (a, b) => {
  const catA =
    CAT_SORT[
      productionCategoryFromScores(a.bg_current, a.bg_baseline, a.bg_age)
    ];
  const catB =
    CAT_SORT[
      productionCategoryFromScores(b.bg_current, b.bg_baseline, b.bg_age)
    ];

  if (catA === 0 && catB === 0) {
    return b.bg_current - a.bg_current;
  }

  return catA - catB;
};

export const secondsToHumanFmt = (seconds, zeroDef = "-") => {
  const s = seconds % 60;
  const m = Math.floor(seconds / 60);
  const h = Math.floor(seconds / (60 * 60));
  const bits = [];
  if (h) bits.push(`${h}h`);
  if (m) bits.push(`${m}m`);
  if (s) bits.push(`${s}s`);

  if (bits.length === 0) {
    return zeroDef;
  }

  return bits.join(" ");
};

export const FILTER_CTRL_MAP = {
  "date-range": ReportFilterDateRange,
  enum: ReportFilterEnum,
  "linked-entity": ReportFilterLinkedEntity,
};

/**
 * @param {function} func
 * @param {function|undefined} [argGen]
 */
export const genericSWRFetcher = (func, argGen) => async (key) => {
  let args = [];
  if (argGen) {
    args = argGen(key);
  }

  const resp = await func(...args);
  if (resp.status === "ok") {
    return resp.data;
  }

  throw new Error(resp.message);
};

export const apiCallSimplify = async (apiCallPromise) => {
  const result = await apiCallPromise;
  if (result.status === "ok") return result.data;
  throw new Error(result.message);
};
