import { forwardRef, useState } from "react";
import RPPhoneNumberInput from "../FormControls/RPPhoneNumberInput";

const NPhoneField = forwardRef(({ fieldRecord, updater }, ref) => {
  const [val, setVal] = useState(fieldRecord.value);

  return (
    <RPPhoneNumberInput
      name={fieldRecord.name}
      label={fieldRecord.label}
      helperText={fieldRecord.errstr}
      error={fieldRecord.error}
      onChange={(newVal) => setVal(newVal)}
      value={val}
      onBlur={() => updater(fieldRecord.name, val)}
      inputRef={ref}
      size="small"
      fullWidth
    />
  );
});

export default NPhoneField;
