import { IconButton } from "@mui/material";
import { faFilePrescription } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HorizIconToolTip from "./HorizIconToolTip";

const CurrentRxIconButton = ({ onClick }) => {
  return (
    <HorizIconToolTip title="Current Script Document">
      <IconButton
        sx={{ color: "text.primary", width: "36px", height: "36px" }}
        onClick={onClick}
      >
        <FontAwesomeIcon size="sm" icon={faFilePrescription} />
      </IconButton>
    </HorizIconToolTip>
  );
};

export default CurrentRxIconButton;
