import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ListIconToolTip from "../../StandardComponents/ListIconToolTip";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import { phoneFmt } from "../../../formatters";
import EmailIcon from "@mui/icons-material/Email";
import CakeIcon from "@mui/icons-material/Cake";
import LanguageIcon from "@mui/icons-material/Language";
import { formatAddr, ucWord } from "../../../utility";

const PersonalInfoCard = ({ eoc }) => {
  const addr = formatAddr(eoc);
  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography sx={{ fontSize: "larger", mb: 1 }}>Personal</Typography>
      <Divider sx={{ mb: 2 }} />
      <List dense sx={{ p: 0 }}>
        <ListItem sx={{ p: 0 }}>
          <ListIconToolTip title="Home Address">
            <ListItemIcon>
              <IconButton disabled>
                <HomeIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          {addr === "" ? (
            <ListItemText primary="[NOT ENTERED]" />
          ) : (
            <ListItemText
              primary={addr.split("\n")[0] || ""}
              secondary={addr.split("\n")[1] || ""}
            />
          )}
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListIconToolTip title="Phone">
            <ListItemIcon>
              <IconButton disabled>
                <PhoneIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText primary={phoneFmt(eoc.phone) || "[NOT ENTERED]"} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListIconToolTip title="Email">
            <ListItemIcon>
              <IconButton disabled>
                <EmailIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText primary={eoc.email || "[NOT ENTERED]"} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListIconToolTip title="Date of Birth">
            <ListItemIcon>
              <IconButton disabled>
                <CakeIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText primary={eoc.date_of_birth} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListIconToolTip title="Language Preference">
            <ListItemIcon>
              <IconButton disabled>
                <LanguageIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText>{ucWord(eoc.lang)}</ListItemText>
        </ListItem>
      </List>
    </Paper>
  );
};

export default PersonalInfoCard;
