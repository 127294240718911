import IntakeRO from "./PageIntake/IntakeRO";
import IntakeRW from "./PageIntake/IntakeRW";
import BackLinkHeader from "../PagePartials/BackLinkHeader";

const PageIntake = ({ pageData, api, refresh }) => {
  const status = pageData.form.status;
  const readOnly = status !== "drafting" && status !== "incomplete";

  return (
    <>
      <BackLinkHeader title="Back to List" to="/intake" />
      {readOnly ? (
        <IntakeRO pageData={pageData} api={api} />
      ) : (
        <IntakeRW pageData={pageData} api={api} refresh={refresh} />
      )}
    </>
  );
};

export default PageIntake;
