import { Stack } from "@mui/material";
import EnrollmentCard from "./EnrollmentCard";

const PrescriberTabPanelEnrollments = ({
  activeTab,
  programs,
  npi,
  api,
  refresh,
}) => {
  if (activeTab !== "enrollments") {
    return null;
  }

  return (
    <Stack spacing={2}>
      {programs.map((prg) => (
        <EnrollmentCard
          key={`${prg.therapy}-${prg.item_code}`}
          program={prg}
          npi={npi}
          api={api}
          refresh={refresh}
        />
      ))}
    </Stack>
  );
};

export default PrescriberTabPanelEnrollments;
