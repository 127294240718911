import LinkEntity from "./LinkEntity";
import RxerIcon from "../Icons/RxerIcon";

// note for dumb reasons, the id here must be the prescribers NPI, not their db id
// yes it's confusing, sorry!

const LinkRxer = ({ id, name_first, name_last, credential }) => {
  const label = LinkRxer.lblPriByCtx({ name_first, name_last, credential });
  const url = LinkRxer.url(id);
  return <LinkEntity url={url} label={label} />;
};

LinkRxer.url = (npi) => `/prescribers/${npi}`;
LinkRxer.lblPriByCtx = ({ name_first, name_last, credential }) =>
  [name_first, name_last, credential].filter(Boolean).join(" ");
LinkRxer.icon = RxerIcon;
LinkRxer.subheader = (ctx) => `NPI: ${ctx.id}`;

export default LinkRxer;
