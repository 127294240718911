import { Stack } from "@mui/material";
import DemoInjuryCard from "./DemoInjuryCard";
import Grid2 from "@mui/material/Unstable_Grid2";
import RxInfoCard from "./RxInfoCard";
import PersonalInfoCard from "./PersonalInfoCard";
import InsuranceCard from "./InsuranceCard";
import IntakeInfoCard from "./IntakeInfoCard";

const OrderTabDemos = ({ activeTab, eoc, rx, rxer, api, contacts }) => {
  if (activeTab !== "demos") return null;
  return (
    <Grid2 container rowSpacing={2} columnSpacing={2}>
      <Grid2 xs={7}>
        <Stack spacing={2}>
          <DemoInjuryCard {...eoc} />
          <RxInfoCard
            therapy={rx.therapy}
            duration={rx.duration}
            npi={rxer.npi}
            name_first={rxer.name_first}
            name_last={rxer.name_last}
            credential={rxer.credential}
            service_channel={rx.service_channel}
            rxDoc={rx.doc}
            api={api}
          />
          <IntakeInfoCard contacts={contacts} />
        </Stack>
      </Grid2>
      <Grid2 xs={5}>
        <Stack spacing={2}>
          <PersonalInfoCard eoc={eoc} />
          <InsuranceCard eoc={eoc} />
        </Stack>
      </Grid2>
    </Grid2>
  );
};

export default OrderTabDemos;
