import { Tooltip } from "@mui/material";

const ListIconToolTip = ({ title, children }) => {
  return (
    <Tooltip title={title} placement="left" arrow>
      {children}
    </Tooltip>
  );
};

export default ListIconToolTip;
