import { forwardRef } from "react";
import { SvgIcon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsDownToPeople } from "@fortawesome/pro-regular-svg-icons";

const ArrowsDownToPeopleIcon = forwardRef(({ ...props }, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <FontAwesomeIcon icon={faArrowsDownToPeople} />
    </SvgIcon>
  );
});

export default ArrowsDownToPeopleIcon;
