import { dateObjFormatToAnnArborDateTime } from "../../../utility";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import { moneyFmt } from "../../../formatters";
import RHFCheckboxSimple from "../../FormControlsRHF/RHFCheckboxSimple";
import WarningIcon from "@mui/icons-material/Warning";

const fieldFind = (fields, id) => {
  const found = fields.findIndex((f) => f.commlid === id);
  if (found === -1) throw new Error("did not find field in field list");

  return [found, fields[found]];
};

const CommissionsToSweepTableRow = ({
  control,
  fields,
  i,
  setValue,
  disabled,
}) => {
  const orderUrl = `/orders/${i.order_id}`;
  const orderLbl = `ORD-${i.order_id.toString().padStart(4, "0")}`;
  const eventLbl = i.payment_id
    ? `PMT-${i.payment_id.toString().padStart(4, "0")}`
    : `INV-${i.invoice_id.toString().padStart(4, "0")}`;
  const eventUrl = i.payment_id
    ? `/payments/${i.payment_id}`
    : `/orders/${i.order_id}`;
  const eventTime = dateObjFormatToAnnArborDateTime(i.created_on, false);
  const [idx] = fieldFind(fields, i.id);

  const caution =
    i.charge_hcpc.trim().toUpperCase() === "E1399"
      ? ""
      : `Charge has HCPC of ${i.charge_hcpc}. This might be okay, but likely is not.`;

  return (
    <TableRow>
      <TableCell>
        <Tooltip title={i.charge_desc} arrow placement="left">
          <span>
            <InternalLink to={orderUrl}>{orderLbl}</InternalLink>
          </span>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>{moneyFmt(i.amount)}</TableCell>
      <TableCell>
        <InternalLink to={eventUrl}>{eventLbl}</InternalLink>
      </TableCell>
      <TableCell>{eventTime}</TableCell>
      <TableCell>{i.lockbox_date}</TableCell>
      <TableCell>
        {!!caution && (
          <Tooltip title={caution} arrow placement="top">
            <WarningIcon fontSize="small" color="warning" />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <RHFCheckboxSimple
          extraLabelProps={{ sx: { m: 0 } }}
          control={control}
          size="small"
          name={`items.${idx}.ignore`}
          disabled={disabled}
          extraOnChange={(ev) => {
            if (ev.target.checked) {
              setValue(`items.${idx}.sweep`, false);
            }
          }}
        />
      </TableCell>
      <TableCell>
        <RHFCheckboxSimple
          extraLabelProps={{ sx: { m: 0 } }}
          control={control}
          size="small"
          name={`items.${idx}.sweep`}
          disabled={disabled}
          extraOnChange={(ev) => {
            if (ev.target.checked) {
              setValue(`items.${idx}.ignore`, false);
            }
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default CommissionsToSweepTableRow;
