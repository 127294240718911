import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StdForm from "./StdForm";

const StdFormDialog = ({
  open,
  onClose,
  title,
  onComplete,
  fields,
  submitCall,
  initialVals,
}) => {
  const handleCloseRequest = (_, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseRequest}
      PaperProps={{ sx: { width: 900, minWidth: 900 } }}
      disableEscapeKeyDown
      disableScrollLock
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Box>{title}</Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={handleCloseRequest}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ p: 1 }}>
          <StdForm
            onComplete={onComplete}
            onCancel={handleCloseRequest}
            fields={fields}
            submitCall={submitCall}
            initialVals={initialVals}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StdFormDialog;
