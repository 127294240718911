import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

const locPrimary = (loc) => {
  return `${loc.title}`;
};

const LocSecondary = ({ loc }) => {
  const line1 = `${loc.addr_street1}${
    loc.addr_street2 && ` ${loc.addr_street2}`
  }`;
  const line2 = `${loc.addr_city}, ${loc.addr_state}, ${loc.addr_zip}`;
  return (
    <Box>
      <Box>{line1}</Box>
      <Box>{line2}</Box>
    </Box>
  );
};

const NoItems = () => (
  <Box>
    <Typography>No Addresses</Typography>
  </Box>
);

const LocationList = ({ locs }) => (
  <List dense disablePadding>
    {locs.map((l) => (
      <ListItem key={l.id} disablePadding>
        <ListItemText
          primary={locPrimary(l)}
          secondary={<LocSecondary loc={l} />}
        />
      </ListItem>
    ))}
  </List>
);

const LocationListCard = ({ locs }) => {
  return (
    <Card>
      <CardHeader title="Address Book" />
      <CardContent sx={{ py: 0 }}>
        {locs.length > 0 ? <LocationList locs={locs} /> : <NoItems />}
      </CardContent>
    </Card>
  );
};

export default LocationListCard;
