import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import RHFDateField from "../FormControlsRHF/RHFDateField";
import { useForm } from "react-hook-form";
import Grid2 from "@mui/material/Unstable_Grid2";
import { dateObjFormatToAnnArborDateTime, reformatDate } from "../../utility";
import useStdFormErrors from "../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../StandardComponents/FormLevelErrorAlert";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import StdConfirmSubmitDialog from "../PagePartials/StdConfirmSubmitDialog";
import useDialogState from "../../HOC/useDialogState";
import { moneyFmt } from "../../formatters";
import { InternalLink } from "../StandardComponents/InternalLink";
import StdUserAvatar from "../StandardComponents/StdUserAvatar";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DownloadButton from "../StandardComponents/DownloadButton";

const PageCommissionDates = ({ pageData, api, refresh }) => {
  const { handleSubmit, control, setError, reset } = useForm({
    defaultValues: { payout_date: "" },
  });
  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    { payout_date: "" },
    () => {
      reset();
      clearFormError();
      refresh();
    }
  );

  const {
    open: lockOpen,
    openFn: lockHandleOpen,
    closeFn: lockHandleClose,
  } = useDialogState(false);

  const {
    open: delOpen,
    openFn: delHandleOpen,
    closeFn: delHandleClose,
  } = useDialogState(false);

  const onSubmit = (data) => {
    clearFormError();
    apiWrapper(api.setCommDate(reformatDate(data.payout_date) || ""));
  };

  return (
    <>
      <TableContainer sx={{ mb: 4 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Payout Date</TableCell>
              <TableCell sx={{ width: "140px" }}>Total Swept</TableCell>
              <TableCell sx={{ width: "220px" }}>Last Swept</TableCell>
              <TableCell sx={{ width: "220px" }}>Locked</TableCell>
              <TableCell sx={{ width: "110px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.map((r) => {
              const hasBeenTouched = !!r.last_swept_on;
              const lockable = hasBeenTouched && r.locked_on === null;
              const locked = !!r.locked_on;

              const sweptCell = r.last_swept_on ? (
                <Stack direction="row" spacing={1}>
                  <StdUserAvatar
                    user_name={r.swept_user_name}
                    user_pic={r.swept_user_pic}
                  />

                  <Box>
                    {dateObjFormatToAnnArborDateTime(r.last_swept_on, false)}
                  </Box>
                </Stack>
              ) : (
                "--"
              );

              const lockedCell = r.locked_on ? (
                <Stack direction="row" spacing={1}>
                  <StdUserAvatar
                    user_name={r.locked_user_name}
                    user_pic={r.locked_user_pic}
                  />
                  <Box>
                    {dateObjFormatToAnnArborDateTime(r.locked_on, false)}
                  </Box>
                </Stack>
              ) : (
                "--"
              );

              return (
                <TableRow key={r.payout_date}>
                  <TableCell>
                    <InternalLink
                      to={`/commissions/${reformatDate(r.payout_date)}`}
                    >
                      {r.payout_date}
                    </InternalLink>
                  </TableCell>
                  <TableCell>{moneyFmt(r.total_swept) || "$0.00"}</TableCell>
                  <TableCell>{sweptCell}</TableCell>
                  <TableCell>{lockedCell}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {!locked && (
                        <IconButton
                          disabled={!lockable}
                          size="small"
                          color="error"
                          sx={{ ":hover": { color: "primary.main" } }}
                          onClick={() =>
                            lockHandleOpen(reformatDate(r.payout_date))
                          }
                        >
                          <LockOpenIcon fontSize="small" />
                        </IconButton>
                      )}
                      {locked && (
                        <DownloadButton
                          icon={<AccountBalanceIcon fontSize="small" />}
                          apiCallFn={async () => {
                            return api.getCommissionReport(
                              reformatDate(r.payout_date)
                            );
                          }}
                          filename={`${r.payout_date}.aps.csv`}
                        />
                      )}
                      <IconButton
                        disabled={hasBeenTouched}
                        color="error"
                        size="small"
                        onClick={() =>
                          delHandleOpen(reformatDate(r.payout_date))
                        }
                      >
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2}>
          {formErrState.hasErrors && (
            <Grid2 xs={12}>
              <FormLevelErrorAlert
                formErrStruct={formErrState}
                sx={{ mt: 2 }}
              />
            </Grid2>
          )}
          <Grid2 xs={7} />
          <Grid2 xs={3}>
            <RHFDateField
              control={control}
              name="payout_date"
              label="New Commission Date"
            />
          </Grid2>
          <Grid2 xs={2}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ mt: 1 }}
            >
              Add Date
            </Button>
          </Grid2>
        </Grid2>
      </form>
      <StdConfirmSubmitDialog
        open={!!delOpen}
        title="Delete Payout Date?"
        onSubmit={() => api.deleteCommDate(delOpen)}
        onComplete={() => {
          delHandleClose();
          refresh();
        }}
        handleClose={delHandleClose}
      >
        Removes the payout date. Can only be done if nothing has been swept to
        this date.
      </StdConfirmSubmitDialog>
      <StdConfirmSubmitDialog
        open={!!lockOpen}
        title="Lock Payout Date?"
        onSubmit={() => api.lockCommDate(lockOpen)}
        onComplete={() => {
          lockHandleClose();
          refresh();
        }}
        handleClose={lockHandleClose}
      >
        Locks the payout date. After this action, no more commissions will be
        assignable to this date.
      </StdConfirmSubmitDialog>
    </>
  );
};

export default PageCommissionDates;
