import { Autocomplete, TextField } from "@mui/material";
import { forwardRef } from "react";

const REP_ROLES = ["Sales", "Service", "Agent", "Principal"];

const RPRepRolesPicker = forwardRef(
  ({ onChange, label, error, helperText, size, ...rest }, ref) => {
    return (
      <Autocomplete
        {...rest}
        options={REP_ROLES}
        size={size}
        multiple
        filterSelectedOptions
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            label={label}
          />
        )}
        onChange={(_, newVal) => {
          onChange(newVal);
        }}
      />
    );
  }
);

RPRepRolesPicker.defaultValue = [];

export default RPRepRolesPicker;
