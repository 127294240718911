import { Alert, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import Grid2 from "@mui/material/Unstable_Grid2";
import StandardDialog from "../../StandardComponents/StandardDialog";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import RHFDateField from "../../FormControlsRHF/RHFDateField";
import RHFAvailableSerialsPicker from "../../FormControlsRHF/RHFAvailableSerialsPicker";
import RHFStatePicker from "../../FormControlsRHF/RHFStatePicker";
import RHFZipCodeField from "../../FormControlsRHF/RHFZipCodeField";
import { nowDate, reformatDate } from "../../../utility";

const genDefaults = (srvc) => {
  return {
    service_stop_on: nowDate(),
    pref_arrive_on: "",
    inv_serial_id: null,
    abort_memo: "",
    addr_street1: srvc.addr_street1,
    addr_street2: srvc.addr_street2,
    addr_city: srvc.addr_city,
    addr_state: RHFStatePicker.byVal(srvc.addr_state) || null,
    addr_zip: srvc.addr_zip,
  };
};

const DropShipFormControls = ({ control, srvc }) => {
  return (
    <>
      <Grid2 xs={3}>
        <Typography variant="h6">Address</Typography>
      </Grid2>
      <Grid2 xs={5}>
        <RHFTextField
          control={control}
          name="addr_street1"
          label="Street Address"
        />
      </Grid2>
      <Grid2 xs={4}>
        <RHFTextField
          control={control}
          name="addr_street2"
          label="Apt / Suite Number"
        />
      </Grid2>
      <Grid2 xs={3}></Grid2>
      <Grid2 xs={4}>
        <RHFTextField control={control} name="addr_city" label="City" />
      </Grid2>
      <Grid2 xs={3}>
        <RHFStatePicker control={control} name="addr_state" label="State" />
      </Grid2>
      <Grid2 xs={2}>
        <RHFZipCodeField control={control} name="addr_zip" label="Zip Code" />
      </Grid2>
      <Grid2 xs={6}>
        The day the unit stopped working for the patient (must be between{" "}
        <strong>{srvc.service_start_on}</strong> and{" "}
        <strong>{nowDate()}</strong>).
      </Grid2>
      <Grid2 xs={3}>
        <RHFDateField
          name="service_stop_on"
          control={control}
          label="Service Stop Date"
        />
      </Grid2>
      <Grid2 xs={3} />

      <Grid2 xs={6}>
        <Typography>
          We would like the replacement unit to arrive at the above address on
          date:
        </Typography>
      </Grid2>
      <Grid2 xs={3}>
        <RHFDateField
          name="pref_arrive_on"
          control={control}
          label="Preferred Arrival Date"
        />
      </Grid2>
      <Grid2 xs={3} />

      <Grid2 xs={12}>
        <RHFTextField
          name="abort_memo"
          control={control}
          multiline
          label="Enter a note about why we're stopping service early"
          rows={4}
        />
      </Grid2>
    </>
  );
};

const AgencyFormControls = ({ control, srvc, agencyId, api }) => {
  return (
    <>
      <Grid2 xs={6}>
        <Typography>
          Swap current {srvc.item.item_therapy.toUpperCase()}-
          {srvc.item.item_code.toUpperCase()} (SN:{" "}
          <strong>{srvc.item.serial_num}</strong>) with this unit from the
          agency's inventory.
        </Typography>
      </Grid2>
      <Grid2 xs={3}>
        <RHFAvailableSerialsPicker
          control={control}
          name="inv_serial_id"
          agencyId={agencyId}
          itemId={srvc.item.item_id}
          api={api}
          label="Replacement Unit"
        />
      </Grid2>
      <Grid2 xs={3} />

      <Grid2 xs={6}>The day the unit was actually swapped out.</Grid2>
      <Grid2 xs={3}>
        <RHFDateField
          name="service_stop_on"
          control={control}
          label="Service Stop Date"
        />
      </Grid2>
      <Grid2 xs={3} />
    </>
  );
};

/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {number} agencyId
 * @param {{
 *   treat_id: number,
 *   service_channel: "drop-ship"|"agency",
 *   service_start_on: string,
 *   addr_street1: string,
 *   addr_street2: string,
 *   addr_city: string,
 *   addr_state: string,
 *   addr_zip: string,
 *   item: {
 *     item_id: number,
 *     item_code: string,
 *     item_therapy: string,
 *     serial_id: number,
 *     serial_num: string
 *   }
 * }} srvc
 * @param {object} api
 * @param {function} refresh
 */
const SwapUnitFormDialog = ({
  open,
  onClose,
  agencyId,
  srvc,
  api,
  refresh,
}) => {
  const defaultValues = genDefaults(srvc);
  const { handleSubmit, control, setError, reset } = useForm({ defaultValues });
  const handleClose = () => {
    onClose();
    reset();
  };
  const onSuccess = () => {
    handleClose();
    refresh();
  };
  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    onSuccess
  );

  const onSubmit = (data) => {
    const payload = {
      service_stop_on: reformatDate(data.service_stop_on),
      pref_arrive_on: reformatDate(data.pref_arrive_on),
      inv_serial_id: data.inv_serial_id?.value,
      abort_memo: data.abort_memo,
      // if this is an agency swap, we technically don't need these, but whatever... send anyway
      addr_street1: data.addr_street1,
      addr_street2: data.addr_street2,
      addr_city: data.addr_city,
      addr_state: data.addr_state?.value,
      addr_zip: data.addr_zip,
    };
    apiWrapper(api.initiateUnitSwap(srvc.treat_id, payload));
  };

  if (!srvc.item) return null;

  return (
    <StandardDialog title="Swap Unit" open={open} onClose={handleClose}>
      <Alert severity="info" sx={{ mb: 2 }}>
        Swapping a unit for Service Channel{" "}
        <strong>{srvc.service_channel}</strong>
      </Alert>
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2}>
          {srvc.service_channel === "agency" ? (
            <AgencyFormControls
              control={control}
              srvc={srvc}
              agencyId={agencyId}
              api={api}
            />
          ) : (
            <DropShipFormControls control={control} srvc={srvc} />
          )}
        </Grid2>
        <Grid2 xs={12} sx={{ textAlign: "right" }}>
          <Button type="submit" variant="contained">
            Initiate Swap
          </Button>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default SwapUnitFormDialog;
