import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CommissionsToSweepTableRow from "./CommissionsToSweepTableRow";

const CommissionsToSweepTable = ({
  items,
  control,
  fields,
  setValue,
  disabled,
}) => {
  if (items.length === 0) return null;

  return (
    <TableContainer>
      <Typography>
        <strong>Commission Ledger</strong>
      </Typography>
      <Table
        size="small"
        sx={{
          "& tbody td": { py: 0 },
          "& > tbody > tr > td:nth-of-type(7)": { textAlign: "center" },
          "& > tbody > tr > td:nth-of-type(8)": { textAlign: "center" },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "115px" }}>Order</TableCell>
            <TableCell sx={{ width: "120px", textAlign: "right" }}>
              Amount
            </TableCell>
            <TableCell sx={{ width: "115px" }}>Event</TableCell>
            <TableCell sx={{ width: "170px" }}>Event Date</TableCell>
            <TableCell sx={{ width: "130px" }}>Lockbox Date</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{ width: "80px", textAlign: "center" }}>
              Ignore
            </TableCell>
            <TableCell sx={{ width: "80px", textAlign: "center" }}>
              Accept
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((i) => (
            <CommissionsToSweepTableRow
              key={i.id}
              control={control}
              fields={fields}
              i={i}
              setValue={setValue}
              disabled={disabled}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommissionsToSweepTable;
