import SectionHeading from "../PagePartials/SectionHeading";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  FormControl,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import StdFormDialog from "../PagePartials/StdFormDialog";
import useDialogState from "../../HOC/useDialogState";
import RPAgencyPicker from "../FormControls/RPAgencyPicker";
import RPManufacturerPicker from "../FormControls/RPManufacturerPicker";
import { parseIntB } from "../../utility";
import RHFAgencyPicker from "../FormControlsRHF/RHFAgencyPicker";
import { useForm } from "react-hook-form";
import RHFManufacturerPicker from "../FormControlsRHF/RHFManufacturerPicker";
import RHFDateRangePicker from "../FormControlsRHF/RHFDateRangePicker";
import Grid2 from "@mui/material/Unstable_Grid2";
import RHFCheckbox from "../FormControlsRHF/RHFCheckbox";
import PurchaseOrderLoader from "./PagePurchaseOrders/PurchaseOrderLoader";

const newPoFields = (api) => [
  {
    name: "mfgr_id",
    component: RPManufacturerPicker,
    label: "Manufacturer",
    width: 6,
    extraProps: { api },
  },
  {
    name: "agency_id",
    component: RPAgencyPicker,
    label: "Target Agency",
    width: 6,
    extraProps: { api },
  },
];

const PagePurchaseOrders = ({ api }) => {
  const navigate = useNavigate();
  const { open, closeFn, openFn } = useDialogState();
  const defaultValues = {
    agency_id: null,
    mfgr_id: null,
    status: {
      open: true,
      "in-progress": true,
      partial: true,
      cancelled: false,
      closed: false,
    },
    received_on: [null, null],
    created_on: [null, null],
  };
  const { control, setValue } = useForm({ defaultValues });

  return (
    <>
      <Stack direction="column" spacing={2}>
        <SectionHeading
          headingLabel="Purchase Orders"
          buttonLabel="New PO"
          buttonOnClick={() => openFn()}
        />
        <Stack direction="row" spacing={2}>
          <Paper variant="outlined" sx={{ p: 2, width: "67%" }}>
            <Grid2 container columnSpacing={2} rowSpacing={2}>
              <Grid2 xs={4} sx={{ alignItems: "center", display: "flex" }}>
                Manufacturer
              </Grid2>
              <Grid2 xs={8}>
                <RHFManufacturerPicker
                  control={control}
                  name="mfgr_id"
                  label="Manufacturer"
                  ignoreErrors
                />
              </Grid2>

              <Grid2 xs={4} sx={{ alignItems: "center", display: "flex" }}>
                PO Recipient
              </Grid2>
              <Grid2 xs={8}>
                <RHFAgencyPicker
                  api={api}
                  control={control}
                  name="agency_id"
                  label="Agency"
                  ignoreErrors
                />
              </Grid2>
              <Grid2 xs={4} sx={{ alignItems: "center", display: "flex" }}>
                Received Date
              </Grid2>
              <Grid2 xs={8}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <RHFDateRangePicker
                    size="small"
                    control={control}
                    name="received_on"
                    helperText=""
                  />
                </FormControl>
              </Grid2>
              <Grid2 xs={4} sx={{ alignItems: "center", display: "flex" }}>
                Created Date
              </Grid2>
              <Grid2 xs={8}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <RHFDateRangePicker
                    size="small"
                    control={control}
                    name="created_on"
                    helperText=""
                  />
                </FormControl>
              </Grid2>
            </Grid2>
          </Paper>
          <Paper variant="outlined" sx={{ p: 2, width: "33%" }}>
            <Typography fontSize="larger">Statuses</Typography>
            <Divider />
            <RHFCheckbox
              control={control}
              name="status.open"
              label="Open"
              ignoreError
            />
            <RHFCheckbox
              control={control}
              name="status.in-progress"
              label="In Progress"
              ignoreError
            />
            <RHFCheckbox
              control={control}
              name="status.partial"
              label="Partial"
              ignoreError
            />
            <RHFCheckbox
              control={control}
              name="status.closed"
              label="Closed"
              ignoreError
            />
            <RHFCheckbox
              control={control}
              name="status.cancelled"
              label="Cancelled"
              ignoreError
            />
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                setValue("status.open", false);
                setValue("status.in-progress", false);
                setValue("status.partial", false);
                setValue("status.cancelled", false);
                setValue("status.closed", false);
              }}
            >
              Any Status
            </Button>
          </Paper>
        </Stack>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <PurchaseOrderLoader control={control} api={api} />
        </Paper>
      </Stack>
      <StdFormDialog
        open={open}
        onClose={closeFn}
        title="Create New Purchase Order"
        fields={newPoFields(api)}
        initialVals={{
          agency_id: parseIntB(process.env.REACT_APP_WRS_WAREHOUSE_ID),
        }}
        submitCall={(data) => api.addPo(data)}
        onComplete={(resp) => navigate(`/purchase-orders/${resp.data}`)}
      />
    </>
  );
};

export default PagePurchaseOrders;
