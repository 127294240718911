import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScalpel } from "@fortawesome/pro-regular-svg-icons";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import HorizIconToolTip from "./HorizIconToolTip";

const SurgicalIcon = ({ surgical }) => {
  if (surgical) {
    return (
      <HorizIconToolTip title="Operative">
        <Box
          sx={{
            pt: "5px",
            left: "3px",
            color: "text.primary",
            position: "relative",
          }}
        >
          <FontAwesomeIcon size="lg" icon={faScalpel} />
        </Box>
      </HorizIconToolTip>
    );
  }

  return (
    <HorizIconToolTip title="Non-Op">
      <Box
        sx={{
          pt: "5px",
          color: "text.secondary",
          position: "relative",
        }}
      >
        <FontAwesomeIcon size="lg" icon={faScalpel} />
        <Box component="span" sx={{ position: "absolute", left: "1px" }}>
          <FontAwesomeIcon size="lg" icon={faBan} />
        </Box>
      </Box>
    </HorizIconToolTip>
  );
};

export default SurgicalIcon;
