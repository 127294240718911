import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import KeyValueList from "../PagePartials/KeyValueList";
import { dateObjFormatToSmallest } from "../../utility";

// TODO: Handle cases where either large or small joint is omitted

const VT4 = ({ vt4 }) => {
  const { settings } = vt4;

  return (
    <Card variant="outlined">
      <CardHeader
        title="Vascutherm 4 Program"
        subheader={`Enrolled ${dateObjFormatToSmallest(vt4.enrolled_on)}`}
      />
      <CardContent>
        <Stack direction="row" spacing={2}>
          <Paper variant="outlined" elevation={0} sx={{ width: "100%" }}>
            <Typography variant="h6" sx={{ mt: 2, ml: 2 }}>
              Large Joint Settings
            </Typography>
            <KeyValueList
              kvData={{
                "Initial Temp": `${settings.lg.temp}°`,
                "Temp Adjustable": settings.lg.temp_change ? "Yes" : "No",
                Compression: `${settings.lg.compress} mm/Hg`,
                "Compression Adjustable": settings.lg.compress_change
                  ? "Yes"
                  : "No",
                "Treatment Frequency": `${settings.lg.freq}m on / 45m off`,
              }}
            />
          </Paper>
          <Paper variant="outlined" elevation={0} sx={{ width: "100%" }}>
            <Typography variant="h6" sx={{ mt: 2, ml: 2 }}>
              Small Joint Settings
            </Typography>
            <KeyValueList
              kvData={{
                "Initial Temp": `${settings.sm.temp}°`,
                "Temp Adjustable": "No",
                Compression: `${settings.sm.compress} mm/Hg`,
                "Compression Adjustable": settings.sm.compress_change
                  ? "Yes"
                  : "No",
                "Treatment Frequency": `${settings.sm.freq}m on / 45m off`,
              }}
            />
          </Paper>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default VT4;
