import { Paper } from "@mui/material";
import TicketCCList from "./TicketCCList";

const TicketCCCard = ({ title, ccList }) => {
  if (ccList.length === 0) return null;

  return (
    <Paper sx={{ p: 1 }}>
      <TicketCCList ccList={ccList} title={title} />
    </Paper>
  );
};

export default TicketCCCard;
