import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import KeyValueList from "../../PagePartials/KeyValueList";
import SignatureInline from "../../StandardComponents/SignatureInline";
import {
  dateObjFormatToAnnArborDateTime,
  dateObjFormatToSmallest,
} from "../../../utility";
import EnrollmentApprovalIcon from "./EnrollmentApprovalIcon";
import useDialogState from "../../../HOC/useDialogState";
import EnrollApprovalDialog from "./EnrollApprovalDialog";
import { styled } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EnrollRemoveDialog from "./EnrollRemoveDialog";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
  },
});

const StatusToolTip = ({ status, reviewed_on, reviewed_user_name }) => {
  const stsMap = {
    "needs-review": "This enrollment needs the collected signatures reviewed.",
    suspect:
      "This enrollment needs further verification on the collected signatures.",
  };

  const callToAction =
    stsMap[status] ?? "This enrollment is accepted and active.";
  const reviewed = reviewed_on
    ? `Last reviewed by ${reviewed_user_name} on ${dateObjFormatToAnnArborDateTime(
        reviewed_on,
        false
      )}`
    : null;

  return (
    <>
      <Typography sx={{ mb: 1 }}>{callToAction}</Typography>
      {reviewed && <Typography variant="body2">{reviewed}</Typography>}
    </>
  );
};

const JointSettings = ({ joint }) => {
  return (
    <KeyValueList
      kvData={{
        "Initial Temp": `${joint.temp}°`,
        "Temp Adjustable": joint.temp_change ? "Yes" : "No",
        Compression: `${joint.compress} mm/Hg`,
        "Compression Adjustable": joint.compress_change ? "Yes" : "No",
        "Treatment Frequency": `${joint.freq}m on / 45m off`,
      }}
    />
  );
};

const Vt4Settings = ({ settings }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Paper elevation={0} sx={{ width: "100%" }}>
        <Typography variant="body2" sx={{ fontSize: "larger", mt: 2, ml: 2 }}>
          Large Joint Settings
        </Typography>

        {settings?.lg ? (
          <JointSettings joint={settings.lg} />
        ) : (
          <Alert sx={{ mt: 2 }} severity="error">
            No Large Joint Settings!
          </Alert>
        )}
      </Paper>
      <Paper elevation={0} sx={{ width: "100%" }}>
        <Typography variant="body2" sx={{ fontSize: "larger", mt: 2, ml: 2 }}>
          Small Joint Settings
        </Typography>

        {settings?.sm ? (
          <JointSettings joint={settings.sm} />
        ) : (
          <Alert sx={{ mt: 2 }} severity="error">
            No Small Joint Settings!
          </Alert>
        )}
      </Paper>
    </Stack>
  );
};

const EnrollmentDetails = ({ program, api }) => {
  const isEnrolled = program.prescriber_id;

  if (!isEnrolled) {
    if (program.item_code === "rec+") {
      return (
        <Alert severity="info" sx={{ my: 2 }}>
          Program is not yet active.
        </Alert>
      );
    }
    return (
      <Alert severity="warning" sx={{ my: 2 }}>
        Prescriber not enrolled in this program.
      </Alert>
    );
  }

  return (
    <>
      {program.item_code === "vt4" ? (
        <>
          <Vt4Settings settings={program.settings} />
          <Divider sx={{ mb: 2 }} />
        </>
      ) : null}
      <Stack spacing={2} direction="row">
        <Paper
          variant="outlined"
          sx={{
            width: "100%",
            height: "200px",
            p: 1,
            textAlign: "center",
          }}
        >
          <SignatureInline
            height={200 - 16}
            api={api}
            s3BucketStr={program.sig_init}
            alt="Initial Signature"
          />
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            width: "100%",
            height: "200px",
            p: 1,
            textAlign: "center",
          }}
        >
          <SignatureInline
            height={200 - 16}
            api={api}
            s3BucketStr={program.sig_ext}
            alt="Extension Signature"
          />
        </Paper>
      </Stack>
    </>
  );
};

const EnrollmentCard = ({ npi, program, api, refresh }) => {
  const { open, openFn, closeFn } = useDialogState();
  const {
    open: openDel,
    openFn: openDelFn,
    closeFn: closeDelFn,
  } = useDialogState();

  const cardSubTitle = program?.created_on
    ? `Enrolled ${dateObjFormatToSmallest(program.created_on)}`
    : undefined;

  const action = program.status ? (
    <Stack direction="row" spacing={1}>
      <CustomWidthTooltip
        arrow
        placement="left"
        title={
          <StatusToolTip
            status={program.status}
            reviewed_user_name={program.reviewed_user_name}
            reviewed_on={program.reviewed_on}
          />
        }
      >
        <IconButton onClick={() => openFn()}>
          <EnrollmentApprovalIcon status={program.status} />
        </IconButton>
      </CustomWidthTooltip>
      <IconButton onClick={openDelFn}>
        <DeleteForeverIcon color="error" />
      </IconButton>
    </Stack>
  ) : null;

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={program.name}
          titleTypographyProps={{ variant: "h6" }}
          subheader={cardSubTitle}
          action={action}
        />
        <CardContent sx={{ pt: 0 }}>
          <EnrollmentDetails program={program} api={api} />
        </CardContent>
      </Card>
      <EnrollApprovalDialog
        refresh={refresh}
        open={open}
        onClose={closeFn}
        api={api}
        therapy={program.therapy}
        item_code={program.item_code}
        npi={npi}
        status={program.status}
      />
      <EnrollRemoveDialog
        refresh={refresh}
        open={openDel}
        onClose={closeDelFn}
        api={api}
        therapy={program.therapy}
        item_code={program.item_code}
        npi={npi}
      />
    </>
  );
};

export default EnrollmentCard;
