import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import {
  Alert,
  CircularProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import OrderDistributionPanel from "./PageRCMDash/OrderDistributionPanel";
import LatestOrdersTable from "./PageRCMDash/LatestOrdersTable";
import BillerUserAutocomplete from "../RawControls/BillerUserAutocomplete";
import OrderStatusMultipleAutocomplete from "../RawControls/OrderStatusMultipleAutocomplete";
import { dateStrHyphenToDateObj, genericSWRFetcher } from "../../utility";

/**
 * @param {Date|null} start
 * @param {Date|null} end
 * @param {URLSearchParams} curQueryStr
 */
const dateRangeFilterChange = ([start, end], curQueryStr) => {
  if (start && start.toString() !== "Invalid Date") {
    curQueryStr.set("created_on_start", format(start, "yyyy-MM-dd"));
  } else {
    curQueryStr.delete("created_on_start");
  }

  if (end && end.toString() !== "Invalid Date") {
    curQueryStr.set("created_on_end", format(end, "yyyy-MM-dd"));
  } else {
    curQueryStr.delete("created_on_end");
  }
};

const genTypeFilterChange = (newVal, curQueryStr) => {
  if (newVal === "initial") {
    curQueryStr.set("gen_type", "0");
    return;
  }
  if (newVal === "extension") {
    curQueryStr.set("gen_type", "1");
    return;
  }
  curQueryStr.delete("gen_type");
};

/**
 * @param newVal
 * @param curQueryStr
 */
const userFilterChange = (newVal, curQueryStr) => {
  if (!newVal || newVal < 1) {
    curQueryStr.delete("user_id");
    return;
  }
  curQueryStr.set("user_id", newVal.toString());
};

/**
 * @param {string[]} newVal
 * @param {URLSearchParams} curQueryStr
 */
const statusFilterChange = (newVal, curQueryStr) => {
  if (newVal.length === 0) {
    curQueryStr.delete("status");
    return;
  }
  const sorted = [...newVal].sort();
  curQueryStr.set("status", sorted.join(","));
};

/**
 * @param {URLSearchParams} queryStr
 * @returns {[Date|null,Date|null]}
 */
const queryToDateRangeFilter = (queryStr) => {
  const created_on_start = dateStrHyphenToDateObj(
    queryStr.get("created_on_start") ?? ""
  );
  const created_on_end = dateStrHyphenToDateObj(
    queryStr.get("created_on_end") ?? ""
  );

  return [created_on_start, created_on_end];
};

/**
 * @param {URLSearchParams} queryStr
 * @returns {"initial"|"extension"|"all"}
 */
const queryToGenTypeFilter = (queryStr) => {
  const qgentype = queryStr.get("gen_type");
  switch (qgentype) {
    case "0":
      return "initial";
    case "1":
      return "extension";
    default:
      return "all";
  }
};

/**
 * @param {URLSearchParams} queryStr
 * @return {number|null}
 */
const queryToUserFilter = (queryStr) => {
  const userId = Math.floor(parseInt(queryStr.get("user_id"), 10));
  if (isNaN(userId)) {
    return null;
  }
  if (userId < 1) {
    return null;
  }
  return userId;
};

const queryToStatusFilter = (queryStr) => {
  const statusStr = queryStr.get("status");
  if (!statusStr) {
    return [];
  }
  return statusStr.split(",");
};

const shortCutItems = [
  {
    label: "This Week",
    getValue: () => {
      const now = new Date();
      const start = startOfWeek(now);
      const end = endOfWeek(now);
      return [start, end];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const now = new Date();
      const prev7Days = addDays(now, -7);
      const start = startOfWeek(prev7Days);
      const end = endOfWeek(prev7Days);
      return [start, end];
    },
  },
  {
    label: "This Month",
    getValue: () => {
      const now = new Date();
      const start = startOfMonth(now);
      const end = endOfMonth(now);
      return [start, end];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const prevMonth = subMonths(new Date(), 1);
      const start = startOfMonth(prevMonth);
      const end = endOfMonth(prevMonth);
      return [start, end];
    },
  },
  {
    label: "Clear",
    getValue: () => [null, null],
  },
];

const mkSWRKey = (urlSearchParams) => {
  const sortedParams = new URLSearchParams(
    [...urlSearchParams.entries()].sort(([keyA], [keyB]) =>
      keyA.localeCompare(keyB)
    )
  );
  // hack for statuses
  const statuses = sortedParams.get("status");
  sortedParams.delete("status");
  if (statuses === null) return `orders|${sortedParams.toString()}`;
  statuses.split(",").forEach((s) => sortedParams.append("status", s));
  return `orders|${sortedParams.toString()}`;
};

const PageRCMDash = ({ api }) => {
  const [goTime, itIsGoTime] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const dateRange = queryToDateRangeFilter(searchParams);
  const generationType = queryToGenTypeFilter(searchParams);
  const userFilter = queryToUserFilter(searchParams);
  const statusFilter = queryToStatusFilter(searchParams);

  const { data, isLoading, error } = useSWR(
    goTime ? mkSWRKey(searchParams) : null,
    genericSWRFetcher(api.latestOrders, (key) => {
      const params = new URLSearchParams(key.split("|")[1]);
      return [params];
    })
  );

  useEffect(() => {
    if (goTime === false && [...searchParams.entries()].length === 0) {
      const [dateStart, dateEnd] = shortCutItems[0].getValue();
      searchParams.set("created_on_start", format(dateStart, "yyyy-MM-dd"));
      searchParams.set("created_on_end", format(dateEnd, "yyyy-MM-dd"));
      searchParams.set("gen_type", "0");
      searchParams.set("status", "pending");
      setSearchParams(searchParams, { replace: true });
      itIsGoTime(true);
      return;
    }

    if (goTime === false) {
      itIsGoTime(true);
    }
  }, []);

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={9}>
        <Stack spacing={2}>
          <Typography variant="h6">Orders</Typography>
          <Stack spacing={2} direction="row">
            <ToggleButtonGroup
              size="small"
              value={generationType}
              onChange={(ev, newVal) => {
                genTypeFilterChange(newVal, searchParams);
                setSearchParams(searchParams, { replace: true });
              }}
              exclusive
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="initial">Initials</ToggleButton>
              <ToggleButton value="extension">Extensions</ToggleButton>
            </ToggleButtonGroup>
            <DateRangePicker
              value={dateRange}
              localeText={{
                start: "Accepted Date Start",
                end: "Accepted Date End",
              }}
              onChange={(newVal) => {
                dateRangeFilterChange(newVal, searchParams);
                setSearchParams(searchParams, { replace: true });
              }}
              slotProps={{
                textField: { size: "small" },
                shortcuts: { items: shortCutItems },
              }}
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <BillerUserAutocomplete
              api={api}
              sx={{ minWidth: "230px" }}
              slotProps={{
                textField: { size: "small", label: "Billing Guide" },
              }}
              onChange={(newVal) => {
                userFilterChange(newVal, searchParams);
                setSearchParams(searchParams, { replace: true });
              }}
              value={userFilter}
            />
            <OrderStatusMultipleAutocomplete
              onChange={(newVal) => {
                statusFilterChange(newVal, searchParams);
                setSearchParams(searchParams, { replace: true });
              }}
              value={statusFilter}
              fullWidth
              slotProps={{
                textField: {
                  size: "small",
                  label: "Status",
                },
              }}
            />
          </Stack>
        </Stack>
      </Grid2>
      <Grid2 xs={3}>
        <OrderDistributionPanel api={api} />
      </Grid2>
      <Grid2 xs={12}>
        {isLoading && <CircularProgress />}
        {error && <Alert severity="error">{error.message}</Alert>}
        {data && <LatestOrdersTable orders={data} />}
      </Grid2>
    </Grid2>
  );
};

export default PageRCMDash;
