import StdUploadDialog from "./StdUploadDialog";

const GenericFileUploadDialog = ({
  open,
  api,
  onClose,
  refresh,
  title,
  targetEntity,
  targetId,
}) => {
  const genUrl = (apiBase, fileName) =>
    [
      apiBase,
      "attachments/",
      targetEntity,
      "/",
      targetId,
      "?title=",
      encodeURIComponent(fileName),
    ].join("");

  return (
    <StdUploadDialog
      open={open}
      api={api}
      onClose={onClose}
      refresh={refresh}
      title={title}
      genUrl={genUrl}
    />
  );
};

export default GenericFileUploadDialog;
