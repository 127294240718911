import KeyValueList from "../PagePartials/KeyValueList";

const JointSettingsList = ({ joint }) => {
  if (!joint) return null;

  return (
    <KeyValueList
      kvData={{
        "Initial Temp": `${joint.temp}°`,
        "Temp Adjustable": joint.temp_change ? "Yes" : "No",
        Compression: `${joint.compress} mm/Hg`,
        "Compression Adjustable": joint.compress_change ? "Yes" : "No",
        "Treatment Frequency": `${joint.freq}m on / 45m off`,
      }}
    />
  );
};

export default JointSettingsList;
