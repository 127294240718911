import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackHandIcon from "@mui/icons-material/BackHand";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import ComplexSplitButton from "../../StandardComponents/ComplexSplitButton";

/** @typedef {"description" | "follow_up" | "requester" | "assignee" | "tracking"} TicketEditMenuStateChangeItems */

const genStatusChangeItems = (
  curStatus,
  onStatChangeFn,
  assigneeId,
  requesterId,
  actorId
) => {
  const items = [];

  if (curStatus === "closed" || curStatus === "cancelled") {
    return items;
  }

  const p = {
    in_progress: { icon: ArrowForwardIcon, label: "IN PROGRESS" },
    closed: { icon: CheckCircleOutlineIcon, label: "CLOSE" },
    blocked: { icon: BackHandIcon, label: "BLOCKED" },
    open: { icon: ArrowBackIcon, label: "OPEN" },
    cancelled: { icon: CancelIcon, label: "CANCEL" },
  };

  if (actorId === requesterId && requesterId !== assigneeId) {
    items.push({
      icon: p.cancelled.icon,
      label: `Status: ${p.cancelled.label}`,
      onClick: () => onStatChangeFn("cancelled"),
    });
    items.push({
      icon: p.closed.icon,
      label: `Status: ${p.closed.label}`,
      onClick: () => onStatChangeFn("closed"),
    });
    return items;
  }

  Object.entries(p)
    .filter(([stat]) => stat !== curStatus)
    .filter(([stat]) => stat !== "cancelled")
    .forEach(([stat, item]) => {
      items.push({
        icon: item.icon,
        onClick: () => onStatChangeFn(stat),
        label: `Status: ${item.label}`,
      });
    });

  return items;
};

/**
 * @param {DBTicketStatus} curStatus
 * @param {function} onStatChangeFn
 * @param {Object<TicketEditMenuStateChangeItems, function>} onStateChangeFnMap
 * @param {number} assigneeId
 * @param {number} requesterId
 * @param {number} actorId
 * @constructor
 */
const TicketEditMenu = ({
  curStatus,
  onStatChangeFn,
  onStateChangeFnMap,
  assigneeId,
  requesterId,
  actorId,
}) => {
  const menu = [];

  if (actorId !== requesterId && actorId !== assigneeId) {
    return menu;
  }

  const statusChangeItems = genStatusChangeItems(
    curStatus,
    onStatChangeFn,
    assigneeId,
    requesterId,
    actorId
  );

  if (statusChangeItems.length > 0) {
    menu.push(...statusChangeItems, { divider: true });
  }

  const stateChangeItems = {
    description: { icon: EditIcon, label: "Title / Description" },
    follow_up: { icon: EditCalendarIcon, label: "Follow Up Date" },
    requester: { icon: ManageAccountsIcon, label: "Owner" },
    assignee: { icon: ManageAccountsIcon, label: "Reassign" },
    tracking: { icon: EditNotificationsIcon, label: "Tracking Parties" },
  };

  if (actorId === assigneeId) {
    delete stateChangeItems.requester;
  }

  if (curStatus !== "closed" && curStatus !== "cancelled") {
    Object.entries(stateChangeItems).forEach(([key, item]) => {
      menu.push({
        icon: item.icon,
        label: item.label,
        onClick: () => onStateChangeFnMap[key](),
      });
    });
  }

  return <ComplexSplitButton options={menu} />;
};

export default TicketEditMenu;
