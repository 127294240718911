import { useWatch } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import PostButton from "./PostButton";
import KeyValueList from "../../PagePartials/KeyValueList";
import { moneyFmt } from "../../../formatters";
import BalanceIcon from "@mui/icons-material/Balance";
import ErrorIcon from "@mui/icons-material/Error";

const DiffSpan = ({ diff }) => {
  const diffFmt = diff < 0 ? moneyFmt(diff) : `+${moneyFmt(diff)}`;
  const sx = { color: "text.primary" };
  if (diff < 0) sx.color = "error.main";
  if (diff > 0) sx.color = "success.main";
  return (
    <Box component="span" sx={sx}>
      {diffFmt}
    </Box>
  );
};

const changeDesc = (cardState, formDirty) => {
  if (!formDirty) return "No allocation changes yet.";
  if (cardState === "error") return "Allocating more than the check amount.";
  if (cardState === "success") return "Allocating exactly the check amount.";
  if (cardState === "unfinished")
    return "Allocating only some of the check amount.";
};

const PostDetailsBox = ({ control, checkTotal, reset, formDirty }) => {
  const results = useWatch({ control, name: "allocations" });

  const [initAlloc, currentAlloc] = results.reduce(
    (acc, cur) => {
      const [curInitAlloc, curCurAlloc] = [
        parseInt(cur.alloc_init, 10),
        parseInt(cur.alloc, 10),
      ];
      return [acc[0] + curInitAlloc, acc[1] + curCurAlloc];
    },
    [0, 0]
  );

  const currentUnalloc = checkTotal - currentAlloc;
  const diff = currentAlloc - initAlloc;

  const displayData = {
    Unapplied: moneyFmt(currentUnalloc),
    Allocated: moneyFmt(currentAlloc),
    Change: <DiffSpan diff={diff} />,
  };

  const cardState =
    currentUnalloc < 0
      ? "error"
      : currentUnalloc === 0
      ? "success"
      : "unfinished";

  const cardSx = { flexGrow: 1 };
  if (cardState === "error") cardSx.borderColor = "error.main";
  if (cardState === "success") cardSx.borderColor = "success.main";

  const cardAction =
    currentUnalloc < 0 ? (
      <ErrorIcon sx={{ color: "error.main" }} />
    ) : currentUnalloc === 0 ? (
      <BalanceIcon sx={{ color: "success.main" }} />
    ) : undefined;

  return (
    <Card variant="outlined" sx={cardSx}>
      <CardHeader
        title="Posting"
        titleTypographyProps={{ variant: "h6" }}
        subheader={changeDesc(cardState, formDirty)}
        subheaderTypographyProps={{ variant: "caption" }}
        action={cardAction}
        sx={{ mb: 0, pb: 0 }}
      />
      <CardContent sx={{ mt: 0, pt: 0 }}>
        <KeyValueList kvData={displayData} />
      </CardContent>
      <CardActions sx={{ flexDirection: "row-reverse" }}>
        <PostButton cardState={cardState} formDirty={formDirty} />
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="error"
          variant="outlined"
          disabled={!formDirty}
          onClick={() => reset()}
        >
          Reset
        </Button>
      </CardActions>
    </Card>
  );
};

export default PostDetailsBox;
