import { Alert } from "@mui/material";

const FitableAlert = ({ fitable, fitable_until, fitable_memo }) => {
  if (!fitable) {
    return (
      <Alert severity="error">Patient should not be fit: {fitable_memo}</Alert>
    );
  }

  if (fitable_until) {
    return (
      <Alert severity="warning">
        Patient should not be fit: {fitable_memo}
      </Alert>
    );
  }

  return <Alert severity="success">Patient can be fit: {fitable_memo}</Alert>;
};

export default FitableAlert;
