import { Step, StepLabel, Stepper } from "@mui/material";

const STAT_TO_STEP = {
  open: 0,
  "in-progress": 1,
  cancelled: 1,
  partial: 2,
  closed: 2,
};

const PurchaseOrderStepper = ({ poStatus }) => {
  const activeStep = STAT_TO_STEP[poStatus] || 0;
  const midLabel = poStatus === "cancelled" ? "Cancelled" : "In Progress";
  const midError = poStatus === "cancelled";
  const endLabel = poStatus === "partial" ? "Partial" : "Closed";
  const endError = poStatus === "partial";

  return (
    <Stepper activeStep={activeStep}>
      <Step>
        <StepLabel>Open</StepLabel>
      </Step>
      <Step>
        <StepLabel error={midError}>{midLabel}</StepLabel>
      </Step>
      <Step>
        <StepLabel error={endError}>{endLabel}</StepLabel>
      </Step>
    </Stepper>
  );
};

export default PurchaseOrderStepper;
