import { forwardRef, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { basicTextFieldProps } from "./_defaults";
import { useController } from "react-hook-form";
import composeRefs from "@seznam/compose-react-refs/composeRefs";

const CURRENCY_PATTERN = new RegExp("^[+-]?[0-9]{1,14}(?:\\.[0-9]{0,4})?$");
const SANITY_MAX = 10000000000000;

/**
 * This is an opinionated parse function for monetary values going from string to integer
 *
 * @param {string} val A string value to parse as a currency.
 * @returns {number|null} Returns null if the input is empty string, NaN if the input is a
 *    non-empty string but is not something that's obviously a money value, and an integer of the
 *    value of the money in cents otherwise.
 */
const uiValToRhfVal = (val) => {
  if (val === "") return null;
  if (!CURRENCY_PATTERN.test(val)) return NaN;
  return Math.round(parseFloat(val) * 100);
};

/**
 * Tries to take a "parsed value" and format it suitable for an input value
 *
 * @param {*} val
 * @returns {string|null} Returns string or null. Null means the value isn't one that can't be
 *    safely interpreted to be displayable in an input field.
 */
const rhfValToUiVal = (val, toFixed = false) => {
  if (null === val || undefined === val) return "";

  if (
    typeof val !== "number" ||
    isNaN(val) ||
    !isFinite(val) ||
    val > SANITY_MAX ||
    val < SANITY_MAX * -1
  )
    return null;

  if (!Number.isInteger(val)) {
    val = Math.round(val);
  }

  if (toFixed) {
    return (val / 100).toFixed(2);
  }
  return (val / 100).toString();
};

const RHFMoneyField2 = forwardRef(({ name, control, ...rest }, outerRef) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  const [uiVal, setUiVal] = useState(
    rhfValToUiVal(field.value, true) || "0.00"
  );

  // this is a hack and a half
  useEffect(() => {
    const val = rhfValToUiVal(field.value, true) || "0.00";
    if (val !== uiVal) setUiVal(val);
  }, [field.value]);

  const onChange = (e) => setUiVal(e.target.value);

  const onBlur = () => {
    field.onBlur();
    const parsed = uiValToRhfVal(uiVal);
    if (isNaN(parsed)) {
      setUiVal("0");
      field.onChange(0);
      return;
    }
    if (parsed === null) {
      setUiVal("");
      field.onChange(0);
      return;
    }
    setUiVal(rhfValToUiVal(parsed, true));
    field.onChange(parsed);
  };

  return (
    <TextField
      name={field.name}
      value={uiVal}
      inputRef={composeRefs(outerRef, field.ref)}
      onBlur={onBlur}
      onChange={onChange}
      error={!!error}
      helperText={error?.message ?? " "}
      {...basicTextFieldProps}
      {...rest}
    />
  );
});

export default RHFMoneyField2;
