import { useState } from "react";
import SectionHeading from "../PagePartials/SectionHeading";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { moneyFmt } from "../../formatters";
import RPPhoneNumberInput from "../FormControls/RPPhoneNumberInput";
import StdFormDialog from "../PagePartials/StdFormDialog";
import { InternalLink } from "../StandardComponents/InternalLink";

const orgAddForm = [
  { name: "name", label: "Company Name", component: TextField, width: 8 },
  {
    name: "main_phone",
    label: "Main Phone Number",
    component: RPPhoneNumberInput,
    width: 4,
  },
];

const PageOrgs = ({ pageData, api, refresh }) => {
  const [addOrgOpen, setAddOrgOpen] = useState(false);
  const handleOpenOrgForm = () => setAddOrgOpen(true);
  const handleCloseOrgForm = () => setAddOrgOpen(false);

  return (
    <>
      <SectionHeading
        headingLabel="Payor Organizations"
        buttonLabel="Add Organization"
        buttonOnClick={handleOpenOrgForm}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Order Count</TableCell>
            <TableCell>Total Billed</TableCell>
            <TableCell>Total Paid</TableCell>
            <TableCell>Total Unallocated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pageData.map((po) => (
            <TableRow key={po.id}>
              <TableCell>
                <InternalLink to={`/orgs/${po.id}`}>{po.name}</InternalLink>
              </TableCell>
              <TableCell>{po.billed_order_count}</TableCell>
              <TableCell>{moneyFmt(po.billed_total, false)}</TableCell>
              <TableCell>{moneyFmt(po.alloced_total, false)}</TableCell>
              <TableCell>
                {moneyFmt(po.collected_total - po.alloced_total, false)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StdFormDialog
        title="Add Organization"
        fields={orgAddForm}
        onClose={handleCloseOrgForm}
        open={!!addOrgOpen}
        submitCall={(data) => {
          return api.addOrg(data);
        }}
        onComplete={() => {
          refresh();
          handleCloseOrgForm();
        }}
      />
    </>
  );
};

export default PageOrgs;
