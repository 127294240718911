import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ListIconToolTip from "../../StandardComponents/ListIconToolTip";
import BusinessIcon from "@mui/icons-material/Business";
import FontAwesomeSvgIcon from "../../StandardComponents/FontAwesomeSvgIcon";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import BadgeIcon from "@mui/icons-material/Badge";
import MaskedSSN from "../../StandardComponents/MaskedSSN";
import WorkIcon from "@mui/icons-material/Work";
import { phoneFmt } from "../../../formatters";

const InsuranceCard = ({ eoc }) => {
  const payorTitle = [eoc.porg_name, eoc.payor_name]
    .filter(Boolean)
    .join(" - ");

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography sx={{ fontSize: "larger", mb: 1 }}>Other</Typography>
      <Divider />
      <List dense>
        <ListItem sx={{ p: 0 }}>
          <ListIconToolTip title="Payor">
            <ListItemIcon>
              <IconButton disabled>
                <BusinessIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText primary={payorTitle} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListIconToolTip title="Claim #">
            <ListItemIcon>
              <IconButton disabled>
                <FontAwesomeSvgIcon icon={faHashtag} />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText primary={eoc.claim_number} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListIconToolTip title="Social Security Number">
            <ListItemIcon>
              <IconButton disabled>
                <BadgeIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText>
            <MaskedSSN ssn={eoc.ssn} defaultText="[NOT ENTERED]" />
          </ListItemText>
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListIconToolTip title="Employer">
            <ListItemIcon>
              <IconButton disabled>
                <WorkIcon />
              </IconButton>
            </ListItemIcon>
          </ListIconToolTip>
          <ListItemText
            primary={eoc.employer_name || "[NOT ENTERED]"}
            secondary={phoneFmt(eoc.employer_phone)}
          />
        </ListItem>
      </List>
    </Paper>
  );
};

export default InsuranceCard;
