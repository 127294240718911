import StdFormDialog from "../PagePartials/StdFormDialog";
import RPDateInput from "../FormControls/RPDateInput";
import { nowDate, reformatDate } from "../../utility";

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  const FIELDS = [
    {
      name: "returned_on",
      label: "Returned Date",
      component: RPDateInput,
      width: 6,
    },
  ];

  return (
    <StdFormDialog
      open={open}
      onClose={onClose}
      title="Mark Unit Returned"
      onComplete={() => {
        onClose();
        refresh();
      }}
      fields={FIELDS}
      submitCall={(data) => {
        const payload = { returned_on: reformatDate(data.returned_on) };
        return api.srvcSetReturned(initialVals.id, payload);
      }}
      initialVals={{ ...initialVals, returned_on: nowDate() }}
    />
  );
};
