import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ApiDataContext from "../../../ApiDataContext";
import { ucWord } from "../../../utility";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";

const CATS_ALL = ["service", "billing", "sales"];

const CCBoxes = ({ curTab, categories, setCategories }) => {
  const leftOverCats = CATS_ALL.filter((c) => c !== curTab);

  const handleCheck = (e, cat) => {
    if (e.target.checked && !categories.includes(cat)) {
      const newVal = [...categories];
      newVal.push(cat);
      setCategories(newVal);
    }
    if (!e.target.checked && categories.includes(cat)) {
      const newVal = [...categories].filter((c) => c !== cat);
      setCategories(newVal);
    }
  };

  return (
    <>
      <FormControlLabel sx={{ m: 0, p: 0 }} control={<></>} label={"CC: "} />
      {leftOverCats.map((c) => (
        <FormControlLabel
          key={c}
          sx={{ m: 0 }}
          control={
            <Checkbox
              value={categories.includes(c)}
              onChange={(e) => handleCheck(e, c)}
            />
          }
          label={`${ucWord(c)}`}
        />
      ))}
    </>
  );
};

const isDefaultState = (commentVal, severity, isPublic, categories) => {
  if (commentVal !== "") return false;
  if (severity !== null) return false;
  if (isPublic === true) return false;
  return categories.length <= 1;
};

const CommentNewForm = ({
  curTab,
  onNewComment,
  isMutating,
  disablePublic = false,
  ignoreCategories = false,
}) => {
  const { me } = useContext(ApiDataContext);
  const [isFocused, setIsFocused] = useState(false);
  const [commentVal, setCommentVal] = useState("");
  const [severity, setSeverity] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [categories, setCategories] = useState([curTab]);

  const cmtIptEl = useRef(null);

  useEffect(() => {
    if (
      document.hasFocus() &&
      cmtIptEl.current.contains(document.activeElement)
    )
      setIsFocused(true);
  }, []);
  useEffect(() => {
    setCategories([curTab]);
    setIsFocused(false);
  }, [curTab]);

  const handleOnChange = (e) => setCommentVal(e.target.value);

  const handlePostClick = () => {
    const payload = {
      id: 0,
      parent_id: null,
      user_id: me.id,
      user_name: me.name,
      user_pic: me.picture,
      categories,
      category: curTab,
      comment: commentVal,
      severity: severity || "normal",
      is_pinned: false,
      is_public: isPublic,
      created_on: new Date(),
    };
    onNewComment(payload);
    setCommentVal("");
    setSeverity(null);
    setIsPublic(false);
    setCategories([curTab]);
  };

  const isActive =
    isFocused || !isDefaultState(commentVal, severity, isPublic, categories);

  const placeholderText = ignoreCategories
    ? "Add a comment..."
    : `Add a ${curTab} comment...`;

  return (
    <Box>
      <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
        <TextField
          fullWidth
          multiline
          sx={{ "& .MuiInputBase-root": { p: 1 } }}
          placeholder={placeholderText}
          minRows={isActive ? 2 : 1}
          maxRows={8}
          value={commentVal}
          onChange={handleOnChange}
          inputRef={cmtIptEl}
          onFocus={() => setIsFocused(true)}
        />
      </Box>
      {isActive && (
        <Box sx={{ mt: -1, ml: 2, pb: 1, mr: 2, textAlign: "right" }}>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flexGrow: 1 }} />
            {!ignoreCategories && (
              <>
                <CCBoxes
                  categories={categories}
                  curTab={curTab}
                  setCategories={setCategories}
                />
                <Box>
                  <Divider orientation="vertical" />
                </Box>
              </>
            )}
            <ToggleButtonGroup
              exclusive
              value={severity}
              onChange={(e, val) => setSeverity(val)}
            >
              <ToggleButton value="caution" color="warning">
                <WarningIcon />
              </ToggleButton>
              <ToggleButton value="serious" color="error">
                <ErrorIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <Box>
              <Divider orientation="vertical" />
            </Box>
            <FormControlLabel
              disabled={disablePublic}
              control={
                <Checkbox
                  sx={{ ml: -1 }}
                  value={isPublic}
                  onChange={(e) => setIsPublic(e.target.checked)}
                />
              }
              label="Public"
            />
            <Box sx={{ display: "flex" }}>
              <Button
                variant="contained"
                size="small"
                onClick={handlePostClick}
                sx={{ my: 1 }}
                disabled={isMutating}
              >
                Post
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
      <Divider />
    </Box>
  );
};

export default CommentNewForm;
