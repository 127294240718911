import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  endOfMonth,
  endOfQuarter,
  format,
  setQuarter,
  startOfMonth,
  startOfQuarter,
  sub as dateSubtract,
} from "date-fns";
import BGYearMonthSelect from "./BleederGainerCard/BGYearMonthSelect";
import BleederGainerGraph from "./BleederGainerGraph";
import BGYearQuarterSelect from "./BleederGainerCard/BGYearQuarterSelect";

const dateFmt = (dateObj) => format(dateObj, "yyyy-MM-dd");

const dateFromDaysAgo = (daysAgo, fmt = true) => {
  if (fmt) {
    return dateFmt(dateSubtract(new Date(), { days: daysAgo }));
  }
  return dateSubtract(new Date(), { days: daysAgo });
};

const DATE_MODES = {
  last90: {
    calc: () => {
      const startDate = dateFromDaysAgo(91);
      const endDate = dateFromDaysAgo(1);
      return [startDate, endDate];
    },
    label: () => {
      const startDate = dateFromDaysAgo(91, false);
      const endDate = dateFromDaysAgo(1, false);
      return `Last 90 Days; (${format(startDate, "MMM do, yyyy")} - ${format(
        endDate,
        "MMM do, yyyy"
      )})`;
    },
    OptComp: null,
  },
  last30: {
    calc: () => {
      const startDate = dateFromDaysAgo(31);
      const endDate = dateFromDaysAgo(1);
      return [startDate, endDate];
    },
    label: () => {
      const startDate = dateFromDaysAgo(31, false);
      const endDate = dateFromDaysAgo(1, false);

      return `Last 30 Days; (${format(startDate, "MMM do, yyyy")} - ${format(
        endDate,
        "MMM do, yyyy"
      )})`;
    },
    OptComp: null,
  },
  month: {
    calc: ({ year, month }) => {
      const index = new Date(year, month - 1);
      const startDate = dateFmt(startOfMonth(index));
      const endDate = dateFmt(endOfMonth(index));
      return [startDate, endDate];
    },
    label: ({ year, month }) => {
      const index = new Date(year, month - 1);
      const startDate = startOfMonth(index);
      const endDate = endOfMonth(index);
      return `${format(index, "MMMM yyyy")}; (${format(
        startDate,
        "MMM do, yyyy"
      )} - ${format(endDate, "MMM do, yyyy")})`;
    },
    OptComp: BGYearMonthSelect,
  },
  quarter: {
    calc: ({ year, quarter }) => {
      const index = new Date(year, 0);
      const startDate = dateFmt(startOfQuarter(setQuarter(index, quarter)));
      const endDate = dateFmt(endOfQuarter(setQuarter(index, quarter)));
      return [startDate, endDate];
    },
    label: ({ year, quarter }) => {
      const index = new Date(year, 0);
      const startDate = startOfQuarter(setQuarter(index, quarter));
      const endDate = endOfQuarter(setQuarter(index, quarter));

      return `${year} Q${quarter}; (${format(
        startDate,
        "MMM do, yyyy"
      )} - ${format(endDate, "MMM do, yyyy")})`;
    },
    OptComp: BGYearQuarterSelect,
  },
};

const StatBox = ({ title, stat, color = null }) => {
  const statSx = {};
  if (color) statSx.color = color;

  return (
    <Paper variant="outlined" sx={{ width: "100%", p: 1, textAlign: "center" }}>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="h4" sx={statSx}>
        {stat}
      </Typography>
    </Paper>
  );
};

const BleederGainerCard = ({
  api,
  entity,
  ident,
  title = "Production History",
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuOpen = (ev) => setMenuAnchorEl(ev.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);
  const [curData, setCurData] = useState([]);

  const [filterState, setFilterState] = useState({
    mode: "last90",
    opts: null,
  });
  const [curRange, setCurRange] = useState(DATE_MODES.last90.calc());
  const [curLabel, setCurLabel] = useState(DATE_MODES.last90.label());

  const {
    calc: rangeCalcFn,
    OptComp,
    label: labelFn,
  } = DATE_MODES[filterState.mode];

  const genHandleChangeFilterMode = (mode) => {
    return () => {
      handleMenuClose();
      setFilterState({
        mode,
        opts: null,
      });
      const { calc: rangeCalcFn, OptComp, label } = DATE_MODES[mode];
      if (OptComp === null) {
        const newRange = rangeCalcFn();
        setCurRange(newRange);
        setCurLabel(label());
      }
    };
  };

  const showStatBoxes = !!curData.length;
  const curRxRate = curData[curData.length - 1]?.num_count;
  const baseRxRate = curData[curData.length - 1]?.denom_count;
  const baseChange =
    Math.round((baseRxRate - curData[0]?.denom_count) * 10) / 10;
  const accel = baseRxRate > 0 ? curRxRate / baseRxRate : 1;

  const baseChangeFmt = {
    str: baseChange > 0 ? `+${baseChange.toString()}` : baseChange.toString(),
    color: baseChange > 0 ? "success.main" : "error.main",
  };
  const accelFmt = {
    str:
      accel >= 1
        ? `+${Math.round((accel - 1) * 1000) / 10}%`
        : `${Math.round((accel - 1) * 1000) / 10}%`,
    color: accel >= 1 ? "success.main" : "error.main",
  };

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={title}
          subheader={curLabel}
          action={
            <IconButton onClick={handleMenuOpen}>
              <FilterListIcon />
            </IconButton>
          }
        />
        {showStatBoxes && (
          <CardContent>
            <Stack direction="row" spacing={2}>
              <StatBox title="Current Rx Rate" stat={curRxRate} />
              <StatBox title="Baseline Rx Rate" stat={baseRxRate} />
              <StatBox
                title="Acceleration"
                stat={accelFmt.str}
                color={accelFmt.color}
              />
              <StatBox
                title="Baseline Change"
                stat={baseChangeFmt.str}
                color={baseChangeFmt.color}
              />
            </Stack>
          </CardContent>
        )}
        <CardContent>
          {OptComp !== null ? (
            <OptComp
              onSelectFn={(opts) => {
                setFilterState((prev) => {
                  return { ...prev, opts };
                });
                const newRange = rangeCalcFn(opts);
                setCurRange(newRange);
                setCurLabel(labelFn(opts));
              }}
            />
          ) : null}
          <Box sx={{ my: 2 }} />
          <BleederGainerGraph
            api={api}
            entity={entity}
            ident={ident}
            dayStart={curRange[0]}
            dayEnd={curRange[1]}
            onData={(data) => setCurData(data)}
          />
        </CardContent>
      </Card>
      <Menu open={menuOpen} onClose={handleMenuClose} anchorEl={menuAnchorEl}>
        <MenuItem onClick={genHandleChangeFilterMode("last90")}>
          Last 90 Days
        </MenuItem>
        <MenuItem onClick={genHandleChangeFilterMode("last30")}>
          Last 30 Days
        </MenuItem>
        <MenuItem onClick={genHandleChangeFilterMode("month")}>
          By Month
        </MenuItem>
        <MenuItem onClick={genHandleChangeFilterMode("quarter")}>
          By Quarter
        </MenuItem>
      </Menu>
    </>
  );
};

export default BleederGainerCard;
