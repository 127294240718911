import RHFAutocomplete from "./RHFAutocomplete";

export const OPTIONS = [
  { value: "ups_ground", label: "UPS® Ground" },
  { value: "ups_3_day_select", label: "UPS 3 Day Select®" },
  { value: "ups_2nd_day_air", label: "UPS 2nd Day Air®" },
  { value: "ups_2nd_day_air_am", label: "UPS 2nd Day Air AM®" },
  { value: "ups_next_day_air_saver", label: "UPS Next Day Air Saver®" },
  { value: "ups_next_day_air", label: "UPS Next Day Air®" },
  { value: "ups_next_day_air_early_am", label: "UPS Next Day Air® Early" },
];

const RHFUPSServiceCodePicker = (props) => {
  return <RHFAutocomplete options={OPTIONS} {...props} />;
};

RHFUPSServiceCodePicker.findOpt = (val) =>
  OPTIONS.find((o) => o.value === val) || null;
RHFUPSServiceCodePicker.labelByVal = (val) => {
  const found = OPTIONS.find((o) => o.value === val);
  if (!found) return "";
  return found.label;
};

export default RHFUPSServiceCodePicker;
