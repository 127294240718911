import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button } from "@mui/material";
import { useWatch } from "react-hook-form";

const ReceiveSerialButton = ({ control, name, onClick }) => {
  const record = useWatch({ control, name });

  const fulfilled = record.qty_received === record.qty;

  const label = fulfilled ? (
    <>
      {record.qty} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <CheckCircleIcon fontSize="small" color="success" />
    </>
  ) : (
    <>
      {record.qty_received} / {record.qty}
    </>
  );

  return (
    <Button
      size="small"
      sx={{ minWidth: "90px" }}
      variant="outlined"
      color={fulfilled ? "success" : "warning"}
      onClick={() => onClick(record)}
    >
      {label}
    </Button>
  );
};

export default ReceiveSerialButton;
