import LinkEntity from "./LinkEntity";
import PersonIcon from "@mui/icons-material/Person";

const LinkEoc = ({
  id,
  name_first,
  name_last,
  alt = false,
  copyBtn = true,
  right = 0,
  top = 0,
}) => {
  const label = !!alt
    ? `P-${id.toString().padStart(4, "0")}`
    : LinkEoc.lblPriByCtx({ name_first, name_last });
  const url = LinkEoc.url(id);

  return (
    <LinkEntity
      url={url}
      label={label}
      copyBtn={copyBtn}
      right={right}
      top={top}
    />
  );
};

LinkEoc.url = (id) => `/patients/${id}`;
LinkEoc.lblPriByCtx = ({ name_first, name_last }) => {
  return [name_first, name_last].filter(Boolean).join(" ");
};
LinkEoc.icon = PersonIcon;
LinkEoc.subheader = () => "";

export default LinkEoc;
