import { useEffect, useState } from "react";
import { Button } from "@mui/material";

const CountdownButton = ({ ticks = 5, ...props }) => {
  const [curTicks, setTicks] = useState(+ticks);

  useEffect(() => {
    if (curTicks <= 0) return;
    const timeOut = setTimeout(() => setTicks((prev) => prev - 1), 980);
    return () => clearTimeout(timeOut);
  }, [curTicks]);

  const modProps = { ...props };
  modProps.disabled = curTicks <= 0 ? props.disabled : true;
  modProps.children = curTicks <= 0 ? props.children : ` ... (${curTicks})`;

  return <Button {...modProps} />;
};

export default CountdownButton;
