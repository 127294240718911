import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import StandardDialog from "../../StandardComponents/StandardDialog";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import RHFDateField from "../../FormControlsRHF/RHFDateField";
import { reformatDate } from "../../../utility";

const genDefaultValues = (curDos) => ({ date_of_surgery: curDos ?? "" });

const DosUpdateFormDialog = ({
  open,
  onClose,
  api,
  refresh,
  eocId,
  curDos,
}) => {
  const defaultValues = genDefaultValues(curDos);

  const { handleSubmit, control, setError, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(genDefaultValues(curDos));
  }, [reset, curDos]);

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      refresh();
      onClose();
    }
  );

  const onSubmit = (data) => {
    const payload = { date_of_surgery: reformatDate(data.date_of_surgery) };
    apiWrapper(api.updateDos(eocId, payload));
  };

  return (
    <StandardDialog
      open={open}
      onClose={onClose}
      title="Change Date of Surgery"
    >
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container>
          <Grid2 xs={12}>
            Change current Date of Surgery from {curDos ?? "(unknown)"} to:
          </Grid2>
          <Grid2 xs={3}>
            <RHFDateField control={control} name="date_of_surgery" />
          </Grid2>
          <Grid2 xs={12}>
            <Button
              disabled={formErrState.submitting}
              type="submit"
              variant="contained"
            >
              Save Change
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default DosUpdateFormDialog;
