import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { InternalLink } from "../../StandardComponents/InternalLink";
import FaceIcon from "@mui/icons-material/Face";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const AttributionCard = ({
  agency_id,
  agency_name,
  sales_rep_id,
  sales_rep_name,
  service_rep_id,
  service_rep_name,
}) => {
  return (
    <Card variant="outlined">
      <CardHeader
        sx={{ pb: 0 }}
        title="Attribution"
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent sx={{ py: 0 }}>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <Tooltip title={"Agency"} placement="left">
                <CorporateFareIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={
                <InternalLink to={`/agencies/${agency_id}`}>
                  {agency_name}
                </InternalLink>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Tooltip title={"Sales Rep"} placement="left">
                <FaceIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={
                <InternalLink to={`/reps/${sales_rep_id}`}>
                  {sales_rep_name}
                </InternalLink>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Tooltip title={"Service Rep"} placement="left">
                <SupportAgentIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={
                service_rep_id ? (
                  <InternalLink to={`/reps/${service_rep_id}`}>
                    {service_rep_name}
                  </InternalLink>
                ) : (
                  "[NONE]"
                )
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default AttributionCard;
