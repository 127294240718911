import { TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { basicTextFieldProps } from "./_defaults";
import { forwardRef } from "react";
import composeRefs from "@seznam/compose-react-refs";

const RHFTextField = forwardRef(
  ({ name, control, rules = undefined, ...rest }, outerRef) => {
    const {
      field,
      fieldState: { error },
    } = useController({ name, control, rules });

    return (
      <TextField
        name={field.name}
        value={field.value}
        inputRef={composeRefs(field.ref, outerRef)}
        onBlur={field.onBlur}
        onChange={field.onChange}
        error={!!error}
        helperText={error?.message ?? " "}
        {...basicTextFieldProps}
        {...rest}
      />
    );
  }
);

export default RHFTextField;
