import { Avatar, Tooltip } from "@mui/material";

const StdUserAvatar = ({ user_name, user_pic }) => (
  <Tooltip title={user_name} placement="left" arrow>
    <Avatar alt={user_name} src={user_pic} sx={{ height: 24, width: 24 }}>
      {user_name}
    </Avatar>
  </Tooltip>
);

export default StdUserAvatar;
