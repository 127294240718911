/**
 * Gets an item from the items list
 *
 * @param {Array<InvItem>} items
 * @param {number} itemId
 * @return {InvItem|undefined}
 */
export const itemById = (items, itemId) => items.find((i) => i.id === itemId);

/**
 * Gets an item from the items list and throws an exception if it's not found
 *
 * @param {Array<InvItem>} items
 * @param {number} itemId
 * @return {InvItem}
 */
export const itemByIdOrDie = (items, itemId) => {
  const item = itemById(items, itemId);
  if (!item) throw new Error(`Item id ${itemId} not found`);
  return item;
};
