import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import FormLevelErrorAlert from "./FormLevelErrorAlert";
import RHFTextField from "../FormControlsRHF/RHFTextField";
import { useForm } from "react-hook-form";
import useStdFormErrors from "../../HOC/useStdFormErrors";

const ActionButton = ({ editMode, setEditMode }) => {
  if (editMode) {
    return (
      <IconButton onClick={() => setEditMode(false)} size="small">
        <ClearIcon fontSize="small" />
      </IconButton>
    );
  }
  return (
    <IconButton onClick={() => setEditMode(true)} size="small">
      <EditIcon fontSize="small" />
    </IconButton>
  );
};

const TextboxEditForm = ({ content, onSuccess, onSubmit, rows }) => {
  const { control, handleSubmit, setError } = useForm({
    defaultValues: { content },
  });
  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    { content },
    onSuccess
  );
  const textFldRef = useRef();

  useEffect(() => {
    textFldRef.current.focus();
    textFldRef.current.select();
  }, []);

  const onSubmitInner = (data) => onSubmit(data, apiWrapper);

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <CardContent sx={{ py: 0 }}>
        <FormLevelErrorAlert formErrStruct={formErrState} />
        <RHFTextField
          ref={textFldRef}
          name="content"
          control={control}
          multiline
          rows={rows}
          InputProps={{ sx: { fontFamily: "monospace", fontSize: "smaller" } }}
        />
      </CardContent>
      <CardActions sx={{ flexDirection: "row-reverse", py: 0 }}>
        <Button type="submit">Save</Button>
      </CardActions>
    </form>
  );
};

const TextboxEditableCard = ({
  onSuccess,
  onSubmit,
  content,
  rows = 8,
  minHeight = "220px",
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Card variant="outlined">
      <CardHeader
        title="Notes"
        titleTypographyProps={{ variant: "h6" }}
        sx={{ pb: 0 }}
        action={<ActionButton editMode={editMode} setEditMode={setEditMode} />}
      />
      {editMode ? (
        <TextboxEditForm
          content={content}
          onSuccess={() => {
            setEditMode(false);
            onSuccess();
          }}
          onSubmit={onSubmit}
          rows={rows}
        />
      ) : (
        <CardContent>
          <Box sx={{ minHeight }}>
            <Typography
              sx={{
                fontFamily: "monospace",
                whiteSpace: "pre-line",
                fontSize: "smaller",
              }}
            >
              {content}
            </Typography>
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default TextboxEditableCard;
