import { useController } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { forwardRef } from "react";

const RHFDatePicker = forwardRef(
  (
    {
      name,
      control,
      rules = undefined,
      size,
      helperText,
      pickerProps = {},
      ...rest
    },
    ref
  ) => {
    const {
      field,
      fieldState: { error },
    } = useController({ name, control, rules });

    helperText = error?.message
      ? error.message
      : !!helperText
      ? helperText
      : " ";

    return (
      <DateTimePicker
        value={field.value}
        onChange={field.onChange}
        inputRef={ref}
        {...pickerProps}
        slotProps={{
          textField: {
            size,
            helperText,
            error: !!error,
            margin: "dense",
            ...rest,
          },
        }}
      />
    );
  }
);

export default RHFDatePicker;
