import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const OPTIONS = [
  { value: "accepted", label: "Accepted" },
  { value: "declined", label: "Declined" },
  { value: "unreachable", label: "Giving Up - Unreachable" },
];

const RPPatientExtResponsePicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={OPTIONS} />;
});

RPPatientExtResponsePicker.defaultValue = null;

export default RPPatientExtResponsePicker;
