import { Typography } from "@mui/material";
import RHFAgencyPicker from "../../../FormControlsRHF/RHFAgencyPicker";
import RHFPrescriberPicker from "../../../FormControlsRHF/RHFPrescriberPicker";

const ENTITY_CTRL_MAP = {
  agencies: RHFAgencyPicker,
  prescribers: RHFPrescriberPicker,
};

const ReportFilterLinkedEntity = ({ name, control, api, meta }) => {
  const Comp = ENTITY_CTRL_MAP[meta];
  if (!Comp) {
    return <Typography>{meta} filter type not yet defined.</Typography>;
  }

  return (
    <Comp
      name={name}
      control={control}
      api={api}
      sx={{ minWidth: "400px" }}
      ignoreErrors
    />
  );
};

ReportFilterLinkedEntity.blankValue = null;
ReportFilterLinkedEntity.transformValue = (val) => val?.value || "";

export default ReportFilterLinkedEntity;
