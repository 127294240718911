import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import FontAwesomeSvgIcon from "../../StandardComponents/FontAwesomeSvgIcon";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  faFileImage,
  faFilePdf,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { bytesToHumanReadable } from "../../../formatters";

const tagsToStr = (tags) => {
  if (tags.length === 0) {
    return "";
  }
  const str = tags.join(", ");
  return `, ${str}`;
};

const AttachmentList = ({
  items,
  onClick,
  onMainClick,
  onDelete,
  editable,
}) => {
  return (
    <List dense sx={{ width: "100%" }}>
      {items.map((i) => (
        <ListItem
          key={i.id}
          sx={{ py: 0 }}
          secondaryAction={
            editable ? (
              <Stack direction="row">
                <IconButton size="small" onClick={() => onClick(i.id)}>
                  <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton size="small" onClick={() => onDelete(i.id)}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            ) : null
          }
        >
          <ListItemIcon sx={{ justifyContent: "center" }}>
            {i.mime === "application/pdf" ? (
              <FontAwesomeSvgIcon icon={faFilePdf} />
            ) : i.mime ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
              <FontAwesomeSvgIcon icon={faFileWord} />
            ) : (
              <FontAwesomeSvgIcon icon={faFileImage} />
            )}
          </ListItemIcon>
          <ListItemButton onClick={() => onMainClick(i.id)} sx={{ mr: 4 }}>
            <ListItemText
              primary={i.title}
              secondary={bytesToHumanReadable(i.size) + tagsToStr(i.tags)}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default AttachmentList;
