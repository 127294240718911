import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import {
  Button,
  IconButton,
  Input,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import DangerousIcon from "@mui/icons-material/Dangerous";

/**
 * @enum {string}
 * @readonly
 */
const UPSTATE = {
  UPLOADING: "uploading",
  ERRORED: "errored",
  ABORTED: "aborted",
  DONE: "done",
};

/**
 * @enum {string}
 * @readonly
 */
const MUTTYPE = {
  UPLOAD: "upload",
  CLEAR: "clear",
  ABORT: "abort",
  PROGRESS: "upload_progress",
  FCOMPLETE: "file_completed",
  FFAILED: "file_failed",
  FABORTED: "file_aborted",
};

const removeFilenameExt = (filename) => {
  const ptn = new RegExp("(\\.[^.]{1,6})$", "g");
  return filename.replaceAll(ptn, "");
};

/**
 * Function fires off the actual XHR requests for file uploads
 *
 * @param {string} token
 * @param {File[]} files
 * @param {Function} genUrl
 * @param {Function} onProgressFn
 * @param {Function} onCompleteFn
 * @param {Function} onErrorFn
 * @param {Function} onAbortFn
 * @returns {Array}
 */
const startFileUploads = (
  token,
  files,
  genUrl,
  onProgressFn,
  onCompleteFn,
  onErrorFn,
  onAbortFn
) => {
  const state = [];
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const fileState = {
      name: removeFilenameExt(file.name),
      size: file.size,
      type: file.type,
      file,
      xhr: new XMLHttpRequest(),
      state: UPSTATE.UPLOADING,
      progress: 0,
    };
    const xhr = fileState.xhr;
    const url = genUrl(process.env.REACT_APP_API_BASE, fileState.name);
    xhr.open("POST", url);

    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    xhr.upload.addEventListener("abort", onAbortFn);
    xhr.upload.addEventListener("error", onErrorFn);
    xhr.upload.addEventListener("progress", onProgressFn);
    xhr.addEventListener("load", onCompleteFn);

    state.push(fileState);
  }

  return state;
};

/**
 * Main dispatch handler for upload widget state changes
 *
 * @param {Array.<{
 *  name: string,
 *  size: number,
 *  type: string,
 *  xhr: XMLHttpRequest|null,
 *  state: UPSTATE
 * }>} fileList
 * @param {{
 *   type: MUTTYPE,
 *   idx: number|null,
 *   el: File[],
 *   onProgress: Function|null
 * }} event
 */
const fileListMutate = (fileList, event) => {
  const { type } = event;

  if (MUTTYPE.CLEAR === type) {
    return [];
  }

  if (MUTTYPE.UPLOAD === type) {
    return startFileUploads(
      event.token,
      event.el,
      event.genUrl,
      event.onProgress,
      event.onComplete,
      event.onError,
      event.onAbort
    );
  }

  if (MUTTYPE.PROGRESS === type) {
    return fileList.map((f) => {
      if (event.target === f.xhr.upload) {
        return { ...f, progress: event.progress };
      } else {
        return f;
      }
    });
  }

  if (MUTTYPE.FCOMPLETE === type) {
    return fileList.map((f) => {
      if (event.target === f.xhr) {
        if (f.xhr.status === 200) {
          return { ...f, state: UPSTATE.DONE, progress: 100 };
        } else {
          return { ...f, state: UPSTATE.ERRORED, progress: 100 };
        }
      } else {
        return f;
      }
    });
  }

  if (MUTTYPE.FFAILED === type) {
    return fileList.map((f) => {
      if (event.target === f.xhr.upload) {
        return { ...f, state: UPSTATE.ERRORED };
      } else {
        return f;
      }
    });
  }

  if (MUTTYPE.FABORTED === type) {
    return fileList.map((f) => {
      if (event.target === f.xhr.upload) {
        return { ...f, state: UPSTATE.ABORTED };
      } else {
        return f;
      }
    });
  }

  if (MUTTYPE.ABORT === type) {
    fileList.find((f) => f.xhr.upload === event.target).xhr.abort();
    return fileList;
  }

  throw new Error("Invalid event type");
};

const UploadStatusIcon = ({ upstate }) => {
  if (upstate === UPSTATE.UPLOADING) {
    return <UploadIcon />;
  }

  if (upstate === UPSTATE.DONE) {
    return <DoneIcon color="success" />;
  }

  if (upstate === UPSTATE.ABORTED) {
    return <CancelIcon color="warning" />;
  }

  if (upstate === UPSTATE.ERRORED) {
    return <ErrorIcon color="error" />;
  }

  return <QuestionMarkIcon />;
};

const SecondaryActionButton = ({ upstate, fileListObj, dispatch }) => {
  if (UPSTATE.UPLOADING === upstate) {
    return (
      <IconButton
        onClick={() => {
          dispatch({ type: MUTTYPE.ABORT, target: fileListObj.xhr.upload });
        }}
      >
        <DangerousIcon />
      </IconButton>
    );
  }

  return null;
};

const UploaderControl = ({
  apiToken,
  onAbort,
  onComplete,
  title,
  genUrl,
  multiple = true,
  sx = {},
  BtnProps,
}) => {
  const fileEl = useRef(null); // ref to the <input type="file" ...>
  const buttonEl = useRef(null); // ref to hidden upload button hack situation for firefox
  const [fileList, dispatch] = useReducer(fileListMutate, []); // main state!
  const [readyToDrop, setReadyToDrop] = useState(false); // true if we're about to drop a file on the main area for uploads

  const controlState = fileList.reduce((acc, cur) => {
    if (cur.state === UPSTATE.UPLOADING || acc === "uploading") {
      return "uploading";
    }
    return "done";
  }, "need-files");

  const handleUploadFiles = (fileListRaw) => {
    dispatch({
      type: MUTTYPE.UPLOAD,
      el: fileListRaw,
      genUrl,
      token: apiToken,
      onProgress: (ev) => {
        const progress = (ev.loaded / ev.total) * 100;
        dispatch({ type: MUTTYPE.PROGRESS, target: ev.target, progress });
      },
      onComplete: (ev) => {
        dispatch({ type: MUTTYPE.FCOMPLETE, target: ev.target });
      },
      onError: (ev) => {
        dispatch({ type: MUTTYPE.FFAILED, target: ev.target });
      },
      onAbort: (ev) => {
        dispatch({ type: MUTTYPE.FABORTED, target: ev.target });
      },
    });
  };

  const handleClearFiles = useCallback(() => {
    fileEl.current.value = "";
    dispatch({ type: MUTTYPE.CLEAR });
  }, [fileEl]);

  const handleCloseRequest = useCallback(() => {
    // loop through any in progress uploads and abort?
    fileList.forEach((f) => {
      if (f.state === UPSTATE.UPLOADING) {
        dispatch({ type: MUTTYPE.ABORT, target: f.xhr.upload });
      }
    });
    handleClearFiles();
    onAbort();
  }, [fileList, handleClearFiles, onAbort]);

  useEffect(() => {
    if (controlState === "done") {
      onComplete();
      handleCloseRequest();
    }
  }, [onComplete, handleCloseRequest, controlState]);

  const btnSx = {
    height: "300px",
    ...sx,
    display: controlState === "need-files" ? "block" : "none",
  };

  return (
    <>
      <Button
        {...BtnProps}
        fullWidth
        sx={btnSx}
        onClick={() => fileEl.current.click()}
        onDragEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setReadyToDrop(true);
        }}
        onDragExit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setReadyToDrop(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleUploadFiles(e.dataTransfer.files);
          setReadyToDrop(false);
          // WHY?? Because Firefox. That's why.
          setTimeout(() => buttonEl.current.click(), 10);
        }}
      >
        {readyToDrop ? "Drop!" : title || "Click or Drag/Drop Files Here"}
      </Button>
      <Input
        sx={{ display: "none" }}
        type="file"
        name="file_upload"
        inputProps={{ multiple }}
        inputRef={fileEl}
        onChange={(e) => {
          e.stopPropagation();
          handleUploadFiles(e.target.files);
          // WHY?? Because Firefox. That's why.
          setTimeout(() => buttonEl.current.click(), 10);
        }}
      />
      {/* this button exists because Firefox does some weird shit with drag/drop and binary ajax calls */}
      <Button
        sx={{ display: "none" }}
        ref={buttonEl}
        onClick={() => fileList.forEach((f) => f.xhr.send(f.file))}
        variant="contained"
      >
        Upload
      </Button>
      <List sx={{ mx: 3 }}>
        {fileList.map((f, i) => {
          return (
            <ListItem
              key={i}
              secondaryAction={
                <SecondaryActionButton
                  upstate={f.state}
                  fileListObj={f}
                  dispatch={dispatch}
                />
              }
            >
              <ListItemIcon>
                <UploadStatusIcon upstate={f.state} />
              </ListItemIcon>
              <ListItemText
                sx={{ pr: 3 }}
                primary={f.name}
                secondary={
                  <LinearProgress
                    variant="determinate"
                    sx={{ transition: "none" }}
                    value={f.progress}
                  />
                }
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default UploaderControl;
