import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AddButton = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<AddIcon />}
      disableRipple
      {...props}
    />
  );
};

export default AddButton;
