import { Alert, Card, CardContent, CardHeader } from "@mui/material";
import KeyValueList from "../../PagePartials/KeyValueList";
import { phoneFmt } from "../../../formatters";

const PrimaryContactCard = ({ pracContact }) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title="Primary Contact"
        titleTypographyProps={{ variant: "h6" }}
        sx={{ pb: 0 }}
      />
      <CardContent sx={{ pt: 0 }}>
        {!!pracContact ? (
          <KeyValueList
            kvData={{
              Name: [pracContact.name_first, pracContact.name_last]
                .filter(Boolean)
                .join(" "),
              "Contact Type": pracContact.type,
              "Contact Pref": pracContact.pref_cont_method,
              Phone: phoneFmt(pracContact.phone),
              Fax: phoneFmt(pracContact.fax),
              Email: pracContact.email,
            }}
          />
        ) : (
          <Alert severity="error" sx={{ mt: 1 }}>
            Primary Contact not set.
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default PrimaryContactCard;
