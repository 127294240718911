import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { formatRelative } from "date-fns";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Fragment, useContext } from "react";
import ApiDataContext from "../../../ApiDataContext";
import AttachDownloadButton from "../../StandardComponents/AttachDownloadButton";

const FeedbackCardActionList = ({ feedbackItem, user, api, refresh }) => {
  const items = [];

  if (feedbackItem.attachment) {
    items.push(
      <AttachDownloadButton
        key="dl"
        attachId={feedbackItem.attachment.id}
        api={api}
        filename={feedbackItem.attachment.title}
      />
    );
  }

  if (user.id === feedbackItem.created_by) {
    items.push(
      <IconButton
        key="del"
        size="small"
        onClick={() => {
          api.deleteFeedback(feedbackItem.id).then(() => refresh());
        }}
      >
        <DeleteForeverIcon fontSize="small" />
      </IconButton>
    );
  }

  if (items.length === 0) return null;

  return <Stack direction="row">{items}</Stack>;
};

const FeedbackCard = ({ feedback, api, refresh }) => {
  const { me } = useContext(ApiDataContext);

  return (
    <Paper variant="outlined">
      <List dense>
        {feedback.map((f, i) => {
          const date = formatRelative(new Date(f.created_on), new Date());
          return (
            <Fragment key={f.id}>
              {i !== 0 && <Divider component="li" />}
              <ListItem
                secondaryAction={
                  <FeedbackCardActionList
                    feedbackItem={f}
                    user={me}
                    api={api}
                    refresh={refresh}
                  />
                }
              >
                <ListItemIcon sx={{ minWidth: "38px" }}>
                  {f.is_positive ? (
                    <ThumbUpIcon color="success" />
                  ) : (
                    <ThumbDownIcon color="error" />
                  )}
                </ListItemIcon>
                <ListItemText
                  sx={{ whiteSpace: "pre-line", fontStyle: "italic" }}
                  primary={f.feedback}
                  secondary={`— ${date}`}
                />
              </ListItem>
            </Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

export default FeedbackCard;
