import { Box, Button, ButtonGroup, Collapse } from "@mui/material";
import { grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import ConsumableItemIcon from "../../StandardComponents/Icons/ConsumableItemIcon";

/**
 * @param {boolean} open
 * @param {function} onReceiveAllConsumables
 * @param {function} onReset
 * @param {function} onClose
 */
const ReceiveQuickButtonGroup = ({
  open,
  onReceiveAllConsumables,
  onReset,
  onClose,
}) => {
  return (
    <Collapse in={open}>
      <Box sx={{ textAlign: "right", mb: 1 }}>
        <ButtonGroup
          sx={{
            "& > .MuiButton-root.MuiButton-contained": {
              borderColor: grey["A700"],
            },
          }}
          variant="contained"
        >
          <Button color="success" onClick={onReceiveAllConsumables}>
            <ConsumableItemIcon /> &nbsp; All
          </Button>
          <Button color="warning" onClick={onReset}>
            Reset
          </Button>
          <Button color="error" onClick={onClose}>
            <CloseIcon />
          </Button>
        </ButtonGroup>
      </Box>
    </Collapse>
  );
};

export default ReceiveQuickButtonGroup;
