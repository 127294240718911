import { InternalLink } from "../../StandardComponents/InternalLink";

/**
 * @param {{
 *   type: "po",
 *   id: number
 * }} ctx
 */
const LogEntryPO = ({ ctx }) => {
  const linkUrl = `/purchase-orders/${ctx.id}`;
  const linkLabel = `PO-${ctx.id.toString().padStart(4, "0")}`;

  return (
    <>
      Added from <InternalLink to={linkUrl}>{linkLabel}</InternalLink>
    </>
  );
};

export default LogEntryPO;
