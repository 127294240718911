import { SvgIcon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckFast } from "@fortawesome/pro-regular-svg-icons";

const TruckFastIcon = () => {
  return (
    <SvgIcon>
      <FontAwesomeIcon icon={faTruckFast} />
    </SvgIcon>
  );
};

export default TruckFastIcon;
