import { forwardRef } from "react";
import { SvgIcon } from "@mui/material";

const DaisyBillIcon = forwardRef((props, ref) => {
  const filter = !!props.disabled ? "saturate(0)" : "none";
  return (
    <SvgIcon ref={ref} {...props} viewBox="0 0 75 75" filter={filter}>
      <circle cx="36" cy="36" r="36" fill="#F745A7" />
      <path
        d="M43.2 57.695c0 3.924-3.224 7.105-7.2 7.105-3.977 0-7.2-3.181-7.2-7.105v-4.651l14.4-14.456v19.107z"
        fill="url(#paint0_linear_2_292)"
      />
      <path
        d="M14.305 43.2C10.381 43.2 7.2 39.976 7.2 36c0-3.977 3.181-7.2 7.105-7.2h4.651l14.457 14.4H14.305z"
        fill="url(#paint1_linear_2_292)"
      />
      <path
        d="M57.695 28.8c3.924 0 7.105 3.223 7.105 7.2 0 3.977-3.181 7.2-7.105 7.2h-4.651L38.588 28.8h19.107z"
        fill="url(#paint2_linear_2_292)"
      />
      <path
        d="M15.569 25.75c-2.775-2.774-2.745-7.303.066-10.115 2.812-2.811 7.341-2.841 10.116-.067l3.289 3.29.04 20.404L15.568 25.75z"
        fill="url(#paint3_linear_2_292)"
      />
      <path
        d="M56.431 46.25c2.775 2.774 2.745 7.303-.066 10.115-2.812 2.811-7.341 2.841-10.116.067l-3.289-3.29-.04-20.404L56.432 46.25z"
        fill="url(#paint4_linear_2_292)"
      />
      <path
        d="M25.75 56.432c-2.774 2.774-7.303 2.744-10.115-.067-2.812-2.812-2.842-7.34-.067-10.116l3.289-3.288 20.404-.04-13.51 13.51z"
        fill="url(#paint5_linear_2_292)"
      />
      <path
        d="M46.25 15.568c2.774-2.774 7.303-2.744 10.115.067 2.812 2.812 2.842 7.341.067 10.116l-3.289 3.288-20.404.04 13.51-13.51z"
        fill="url(#paint6_linear_2_292)"
      />
      <path
        d="M28.8 14.305c0-3.924 3.224-7.105 7.2-7.105 3.977 0 7.2 3.181 7.2 7.105v4.651L28.8 33.413V14.305z"
        fill="url(#paint7_linear_2_292)"
      />
      <circle cx="36" cy="36" r="7.74" fill="#fff" />
      <circle cx="36" cy="36" r="7.2" fill="#FFDA00" />
      <defs>
        <linearGradient
          id="paint0_linear_2_292"
          x1="42.84"
          y1="53.55"
          x2="31.482"
          y2="48.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".211" stopColor="#fff" />
          <stop offset=".815" stopColor="#fff" stopOpacity=".75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_292"
          x1="18.45"
          y1="42.84"
          x2="23.054"
          y2="31.482"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".211" stopColor="#fff" />
          <stop offset=".815" stopColor="#fff" stopOpacity=".75" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_292"
          x1="53.55"
          y1="29.16"
          x2="48.946"
          y2="40.518"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".211" stopColor="#fff" />
          <stop offset=".815" stopColor="#fff" stopOpacity=".75" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_292"
          x1="18.754"
          y1="28.427"
          x2="30.04"
          y2="23.651"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".211" stopColor="#fff" />
          <stop offset=".815" stopColor="#fff" stopOpacity=".75" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2_292"
          x1="53.246"
          y1="43.573"
          x2="41.96"
          y2="48.349"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".211" stopColor="#fff" />
          <stop offset=".815" stopColor="#fff" stopOpacity=".75" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2_292"
          x1="28.427"
          y1="53.246"
          x2="23.651"
          y2="41.96"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".211" stopColor="#fff" />
          <stop offset=".815" stopColor="#fff" stopOpacity=".75" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2_292"
          x1="43.573"
          y1="18.754"
          x2="48.349"
          y2="30.04"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".211" stopColor="#fff" />
          <stop offset=".815" stopColor="#fff" stopOpacity=".75" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2_292"
          x1="29.16"
          y1="18.45"
          x2="40.518"
          y2="23.054"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".211" stopColor="#fff" />
          <stop offset=".815" stopColor="#fff" stopOpacity=".75" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
});

/*
const DaisyBillIcon = forwardRef((props, ref) => {
  console.log(rawIcon);
  return <Box component="img" sx={{ width: 24, height: 24 }} src={rawIcon} />;
});
 */

export default DaisyBillIcon;
