import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import BleederGainerInlineStat from "../../StandardComponents/BleederGainerInlineStat";
import { productionStringsFromScores } from "../../../utility";

const AgencyListTable = ({ agencies }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Agency</TableCell>
          <TableCell>Production</TableCell>
          <TableCell>Reps</TableCell>
          <TableCell>Channel</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {agencies.map((a) => {
          const [score, trend, gain] = productionStringsFromScores(
            a.bg_current,
            a.bg_baseline,
            true
          );

          return (
            <TableRow key={a.id}>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <BleederGainerInlineStat
                    currentScore={a.bg_current}
                    baselineScore={a.bg_baseline}
                    age={a.bg_age}
                  />
                  <InternalLink to={`/agencies/${a.id}`}>{a.name}</InternalLink>
                </Stack>
              </TableCell>
              <TableCell>
                {gain !== null ? (
                  <Stack direction="row" spacing={1}>
                    <Box sx={{ width: "100%" }}>{score}</Box>
                    <Box sx={{ width: "100%" }}>{trend}</Box>
                  </Stack>
                ) : null}
              </TableCell>
              <TableCell>{a.active_reps}</TableCell>
              <TableCell>{a.service_channel}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default AgencyListTable;
