import { Avatar, Chip, Tooltip } from "@mui/material";

const GuideChip = ({ guide, type }) => {
  return (
    <Tooltip title={type} placement="top" arrow>
      <Chip
        avatar={<Avatar alt={guide.name} src={guide.picture} />}
        label={guide.name}
        variant="outlined"
      />
    </Tooltip>
  );
};

export default GuideChip;
