import StdFormDialog from "../PagePartials/StdFormDialog";
import RPDateInput from "../FormControls/RPDateInput";
import { reformatDate } from "../../utility";

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  const FIELDS = [
    {
      name: "fit_on",
      label: "Fitting Date",
      component: RPDateInput,
      width: 6,
    },
  ];

  return (
    <StdFormDialog
      open={open}
      onClose={onClose}
      title="Fit Patient"
      onComplete={() => {
        onClose();
        refresh();
      }}
      fields={FIELDS}
      submitCall={(data) => {
        const payload = { fit_on: reformatDate(data.fit_on) };
        return api.srvcSetFit(initialVals.id, payload);
      }}
      initialVals={initialVals}
    />
  );
};
