import AttachmentListCard from "../../PagePartials/AttachmentListCard";
import GuideBox from "../../StandardComponents/GuideBox";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Paper, Stack, Typography } from "@mui/material";
import BleederGainerCard from "../../StandardComponents/BleederGainerCard";
import KeyValueList from "../../PagePartials/KeyValueList";
import { moneyFmt } from "../../../formatters";
import CommentFeed from "../../StandardComponents/CommentFeed";

const AgencyTabPanelDashboard = ({
  activeTab,
  attachments,
  prescribers,
  refresh,
  api,
  agencyId,
  guides,
  totalSweptComms,
  salesArea,
  serviceChannel,
  reps,
}) => {
  if (activeTab !== "main") {
    return null;
  }

  return (
    <>
      <Grid2 container columnSpacing={2} sx={{ mb: 2 }} rowSpacing={2}>
        <Grid2 xs={3}>
          <Paper variant="outlined">
            <KeyValueList
              kvData={{
                "Sales Area": salesArea,
                Channel: serviceChannel,
              }}
            />
          </Paper>
        </Grid2>
        <Grid2 xs={3}>
          <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
            <Typography>Total Reps</Typography>
            <Typography variant="h4">{reps.length}</Typography>
          </Paper>
        </Grid2>
        <Grid2 xs={3}>
          <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
            <Typography>Total Prescribers</Typography>
            <Typography variant="h4">{prescribers.length}</Typography>
          </Paper>
        </Grid2>
        <Grid2 xs={3}>
          <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
            <Typography>Total Commissions</Typography>
            <Typography variant="h4">
              {totalSweptComms === null
                ? "None Yet"
                : moneyFmt(Math.floor(totalSweptComms / 100) * 100, false)}
            </Typography>
          </Paper>
        </Grid2>
        <Grid2 xs={4}>
          <GuideBox title="Assigned Patient Guide" guide={guides.patient} />
        </Grid2>
        <Grid2 xs={4}>
          <GuideBox title="Assigned Practice Guide" guide={guides.practice} />
        </Grid2>
        <Grid2 xs={4}>
          <GuideBox title="Assigned Sales Guide" guide={guides.sales} />
        </Grid2>
      </Grid2>
      <Stack direction="column" spacing={2}>
        <BleederGainerCard api={api} entity="agencies" ident={agencyId} />

        <CommentFeed
          id={agencyId}
          api={api}
          disablePublic
          type="agency"
          ignoreCategories
        />

        <AttachmentListCard
          editable
          attachmentData={attachments}
          refresh={refresh}
          api={api}
          targetEntity="agencies"
          targetId={agencyId}
        />
      </Stack>
    </>
  );
};

export default AgencyTabPanelDashboard;
