import { Divider, Paper, Typography } from "@mui/material";
import KeyValueList from "../../PagePartials/KeyValueList";
import { phoneFmt } from "../../../formatters";
import { formatAddr } from "../../../utility";

const IntakeInfoCard = ({ contacts }) => {
  const payorAddr = formatAddr({
    addr_street1: contacts.intake_addr_street1,
    addr_street2: contacts.intake_addr_street2,
    addr_city: contacts.intake_addr_city,
    addr_state: contacts.intake_addr_state,
    addr_zip: contacts.intake_addr_zip,
  });

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography sx={{ fontSize: "larger", mb: 1 }}>Intake Info</Typography>
      <Divider />
      <KeyValueList
        kvData={{
          "Payor Address": payorAddr,
          "Adjuster Name": contacts.intake_adj_name,
          "Adjuster Phone": phoneFmt(contacts.intake_adj_phone),
          "Adjuster Email": contacts.intake_adj_email,
          "Adjuster Fax": phoneFmt(contacts.intake_adj_fax),
        }}
      />
    </Paper>
  );
};

export default IntakeInfoCard;
