import StandardDialog from "../../StandardComponents/StandardDialog";
import { Alert, AlertTitle } from "@mui/material";
import CountdownButton from "../../StandardComponents/CountdownButton";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import useStdFormErrors from "../../../HOC/useStdFormErrors";

const EnrollRemoveDialog = ({
  open,
  onClose,
  refresh,
  api,
  therapy,
  item_code,
  npi,
}) => {
  const { apiWrapper, formErrState } = useStdFormErrors(
    () => {},
    {},
    () => {
      refresh();
      onClose();
    }
  );

  const submitRemove = () => {
    apiWrapper(api.removeEnrollment(npi, therapy, item_code));
  };

  const actions = (
    <CountdownButton
      onClick={submitRemove}
      variant="contained"
      color="error"
      sx={{ width: "250px" }}
    >
      Remove Enrollment
    </CountdownButton>
  );

  return (
    <StandardDialog
      open={open}
      onClose={onClose}
      title="Remove Enrollment?"
      actions={actions}
    >
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <Alert severity="error">
        <AlertTitle>🚨🚨🚨 WARNING 🚨🚨🚨</AlertTitle>
        Removing a prescriber from a program is a{" "}
        <strong>permanent operation</strong>. Taking this action will result in
        being unable to generate prescriptions on the prescriber's behalf!
        Please be sure you want to do this!
      </Alert>
    </StandardDialog>
  );
};

export default EnrollRemoveDialog;
