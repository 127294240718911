import { Card, CardContent, CardHeader } from "@mui/material";
import AgencySweepForm from "./AgencySweepForm";
import { InternalLink } from "../../StandardComponents/InternalLink";

const AgencySweepCard = ({ payoutDate, agencyInfo, api, refresh }) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <InternalLink to={`/agencies/${agencyInfo.id}`}>
            {agencyInfo.name}
          </InternalLink>
        }
      />
      <CardContent>
        <AgencySweepForm
          payoutDate={payoutDate}
          agencyInfo={agencyInfo}
          api={api}
          refresh={refresh}
          disabled={!agencyInfo.aps}
        />
      </CardContent>
    </Card>
  );
};

export default AgencySweepCard;
