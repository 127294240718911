import LinkEntity from "./LinkEntity";
import TruckFastIcon from "../Icons/TruckFastIcon";

const LinkSrvc = ({ id }) => {
  const label = LinkSrvc.lblPriByCtx({ id });
  const url = LinkSrvc.url(id);
  return <LinkEntity label={label} url={url} />;
};

LinkSrvc.url = (id) => `/service-cycle/${id}`;
LinkSrvc.lblPriByCtx = ({ id }) => `SC-${id.toString().padStart(4, "0")}`;
LinkSrvc.icon = TruckFastIcon;
LinkSrvc.subheader = (ctx) => {
  return ctx.status;
};

export default LinkSrvc;
