import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const opts = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
];

const RPLanguagePicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={opts} />;
});

RPLanguagePicker.defaultValue = null;

export default RPLanguagePicker;
