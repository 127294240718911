import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import NewTicketForm from "./NewTicketForm";
import TicketContextTable from "./TicketContextTable";

/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {object} api
 * @param {{
 *   type: TicketContext,
 *   ident: number,
 *   label: string,
 * }} context
 */
const TicketContextDialog = ({
  open,
  onClose,
  api,
  context,
  data,
  refreshData,
}) => {
  const [viewMode, setViewMode] = useState("view");

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ display: "flex" }}>
        <Box>Tasks Attached to {context.label}</Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {viewMode === "view" && <TicketContextTable tickets={data} />}
        {viewMode === "edit" && (
          <NewTicketForm
            api={api}
            onClose={() => setViewMode("view")}
            onSuccess={() => {
              setViewMode("view");
              refreshData();
            }}
            context={context}
          />
        )}
      </DialogContent>
      {viewMode === "view" && (
        <>
          <Divider />
          <DialogActions sx={{ py: 2 }}>
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              onClick={() => setViewMode("edit")}
            >
              <AddIcon sx={{ mr: 1 }} /> New Task
            </Fab>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default TicketContextDialog;
