import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import useSWR from "swr";
import { genericSWRFetcher } from "../../utility";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import OrderTable from "./PageRCMDashUser/OrderTable";

const PageRCMDashUser = ({ api }) => {
  const { userId } = useParams();
  const { data, isLoading, error, mutate } = useSWR(
    `order_user_dash-${userId}`,
    genericSWRFetcher(api.userOrders, (key) => [key.split("-")[1]])
  );
  const [curTab, setCurTab] = useState("new");
  const [genFilter, setGenFilter] = useState("all");

  if (isLoading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  const { ordersNew, ordersSnoozed, ordersFollowUp, ordersAppealing } =
    data.orders.reduce(
      (acc, cur) => {
        if (genFilter === "initial" && cur.generation !== 0) return acc;
        if (genFilter === "extension" && cur.generation === 0) return acc;
        if (cur.snoozed) {
          acc.ordersSnoozed.push(cur);
        } else if (cur.status === "pending" && !cur.snooze_until) {
          acc.ordersNew.push(cur);
        } else if (cur.status === "appealing") {
          acc.ordersAppealing.push(cur);
        } else {
          acc.ordersFollowUp.push(cur);
        }
        return acc;
      },
      {
        ordersNew: [],
        ordersSnoozed: [],
        ordersFollowUp: [],
        ordersAppealing: [],
      }
    );

  const handleTabChange = (_, newValue) => setCurTab(newValue);
  const handleGenFilterChange = (_, newValue) => setGenFilter(newValue);

  return (
    <Stack spacing={2}>
      <BackLinkHeader title="All Orders" to={`/rcm/dashboard`} />
      <Typography variant="h5">{data.user.name}'s Orders</Typography>
      <ToggleButtonGroup
        size="small"
        value={genFilter}
        onChange={handleGenFilterChange}
        exclusive
      >
        <ToggleButton value="all">All</ToggleButton>
        <ToggleButton value="initial">Initials</ToggleButton>
        <ToggleButton value="extension">Extensions</ToggleButton>
      </ToggleButtonGroup>
      <Tabs value={curTab} onChange={handleTabChange}>
        <Tab label={`New Orders (${ordersNew.length})`} value="new" />
        <Tab
          label={`Follow Ups (${ordersFollowUp.length})`}
          value="follow-up"
        />
        <Tab
          label={`Appealing (${ordersAppealing.length})`}
          value="appealing"
        />
        <Tab label={`Snoozed (${ordersSnoozed.length})`} value="snoozed" />
      </Tabs>
      <OrderTable
        api={api}
        orders={ordersNew}
        onChange={() => mutate()}
        visible={curTab === "new"}
      />
      <OrderTable
        api={api}
        orders={ordersFollowUp}
        onChange={() => mutate()}
        visible={curTab === "follow-up"}
      />
      <OrderTable
        api={api}
        orders={ordersAppealing}
        onChange={() => mutate()}
        visible={curTab === "appealing"}
      />
      <OrderTable
        api={api}
        orders={ordersSnoozed}
        snoozed
        onChange={() => mutate()}
        visible={curTab === "snoozed"}
      />
    </Stack>
  );
};

export default PageRCMDashUser;
