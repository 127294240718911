import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import { phoneFmt } from "../../../formatters";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

const LocationCard = ({ loc, onRequestEdit, onMakePrimary }) => {
  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardHeader
        sx={{ mb: 0, pb: 0 }}
        title={loc.is_primary === true ? loc.name || "Primary" : loc.name}
        titleTypographyProps={{ variant: "body1" }}
        action={
          <Stack direction="row">
            {loc.is_primary ? (
              <Tooltip title="Primary Location">
                <StarIcon
                  fontSize="small"
                  sx={{
                    color: "text.secondary",
                    width: "18px",
                    my: "4px",
                    mr: "4px",
                  }}
                />
              </Tooltip>
            ) : (
              <IconButton onClick={() => onMakePrimary(loc.id)} size="small">
                <StarOutlineIcon fontSize="inherit" />
              </IconButton>
            )}

            <IconButton size="small" onClick={() => onRequestEdit(loc)}>
              <ModeEditIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        }
      />
      <CardContent sx={{ p: 0, m: 0, "&:last-child": { p: 0 } }}>
        <List dense>
          <ListItem sx={{ py: 0 }}>
            <ListItemIcon sx={{ p: 0 }}>
              <IconButton size="small" disabled>
                <HomeIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              primary={`${loc.addr_street1} ${loc.addr_street2}`}
              secondary={`${loc.addr_city}, ${loc.addr_state} ${loc.addr_zip}`}
            />
          </ListItem>

          <ListItem sx={{ py: 0 }}>
            <ListItemIcon>
              <IconButton size="small" disabled>
                <PhoneIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={phoneFmt(loc.main_phone) || "unknown"} />
          </ListItem>
          <ListItem sx={{ pt: 0 }}>
            <ListItemIcon>
              <IconButton size="small" disabled>
                <FaxIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={phoneFmt(loc.main_fax) || "unknown"} />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default LocationCard;
