import { forwardRef } from "react";
import RHFLoadOnRenderAutocomplete from "./RHFLoadOnRenderAutocomplete";

const RHFAvailableSerialsPicker = forwardRef(
  (
    { control, label, name, api, agencyId, itemId, rules = undefined, ...rest },
    ref
  ) => {
    const optCall = () => api.acAvailableSerialsByItem(agencyId, itemId);

    return (
      <RHFLoadOnRenderAutocomplete
        control={control}
        label={label}
        ref={ref}
        name={name}
        rules={rules}
        optionListCall={optCall}
        {...rest}
      />
    );
  }
);

export default RHFAvailableSerialsPicker;
