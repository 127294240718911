import { forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxesStacked } from "@fortawesome/free-solid-svg-icons";
import { SvgIcon } from "@mui/material";

const ConsumableItemIcon = forwardRef(({ ...props }, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <FontAwesomeIcon icon={faBoxesStacked} />
    </SvgIcon>
  );
});

export default ConsumableItemIcon;
