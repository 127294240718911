import { forwardRef, useContext } from "react";
import ApiDataContext from "../../ApiDataContext";
import RHFAutocomplete from "./RHFAutocomplete";

const RHFAnyConsumablePicker = forwardRef((props, ref) => {
  const { items } = useContext(ApiDataContext);
  const options = items
    .filter((i) => i.item_type === "consumable")
    .map((i) => ({
      value: i.id,
      label: `${i.hlabel} (${i.model_number})`,
    }));
  return <RHFAutocomplete ref={ref} options={options} {...props} />;
});

export default RHFAnyConsumablePicker;
