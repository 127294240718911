import { forwardRef, useContext } from "react";
import ApiDataContext from "../../ApiDataContext";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const RPUnitPicker = forwardRef(({ ...props }, ref) => {
  const { items } = useContext(ApiDataContext);
  const itemValues = items
    .filter((i) => i.item_type === "serialized")
    .map((i) => ({
      value: i.id,
      label: `${i.hlabel} (${i.therapy.toUpperCase()})`,
    }));

  return <RPAutoCompleteCore options={itemValues} {...props} ref={ref} />;
});

RPUnitPicker.defaultValue = null;

export default RPUnitPicker;
