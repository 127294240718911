import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

const EnrollmentApprovalIcon = ({ status, size = "medium" }) => {
  if (!status) return null;

  if (status === "needs-review") {
    return <InfoIcon color="info" fontSize={size} />;
  }

  if (status === "suspect") {
    return <WarningIcon color="warning" fontSize={size} />;
  }

  return <CheckCircleIcon color="success" fontSize={size} />;
};

export default EnrollmentApprovalIcon;
