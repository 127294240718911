import { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { TREAT_SITES } from "../../../utility";
import { fmtInjurySite } from "../../../formatters";

const siteOpts = TREAT_SITES.map((ts) => ({
  label: fmtInjurySite(ts),
  value: ts,
}));

const TreatmentSiteDialog = ({ addSiteFn, ignore, open, onClose }) => {
  const [sitePicks, setSitePicks] = useState([]);

  const selectSite = (site) =>
    setSitePicks((prev) =>
      prev.includes(site) ? prev.filter((s) => s !== site) : [...prev, site]
    );

  const handleClose = () => {
    setSitePicks([]);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: 400, minWidth: 400 } }}
      disableScrollLock
    >
      <DialogTitle>Treatment Sites</DialogTitle>
      <DialogContent>
        <List disablePadding>
          {siteOpts.map((opt) => (
            <ListItem key={opt.value} disablePadding>
              <ListItemButton
                disabled={ignore.has(opt.value)}
                onClick={() => {
                  selectSite(opt.value);
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    disabled={ignore.has(opt.value)}
                    value={opt.value}
                    checked={sitePicks.includes(opt.value)}
                  />
                </ListItemIcon>
                <ListItemText primary={opt.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            addSiteFn(sitePicks);
            handleClose();
          }}
          disabled={!sitePicks.length}
        >
          Add Site{sitePicks.length > 1 ? "s" : ""}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TreatmentSiteDialog.getLabelFromVal = (val) => {
  const found = siteOpts.find((opt) => val === opt.value);
  return !!found ? found.label : "";
};

export default TreatmentSiteDialog;
