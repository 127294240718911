import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPAvailableSerialPicker = forwardRef(
  ({ api, agencyId, itemId, ...rest }, ref) => {
    return (
      <RPLoadOnRenderAutoComplete
        ref={ref}
        {...rest}
        optionListCall={() => api.acAvailableSerialsByItem(agencyId, itemId)}
      />
    );
  }
);

RPAvailableSerialPicker.defaultValue = null;

export default RPAvailableSerialPicker;
