import { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";

const initState = { downloading: false, blob: null, error: false, errMsg: "" };

const DownloadButton = ({ apiCallFn, filename, icon, iconBtnProps = {} }) => {
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!state.blob) return;
    const url = URL.createObjectURL(state.blob);
    const linkEl = document.createElement("a");
    document.body.appendChild(linkEl);
    linkEl.style = "display: none";
    linkEl.href = url;
    linkEl.download = filename;
    linkEl.click();
    URL.revokeObjectURL(linkEl);
    document.body.removeChild(linkEl);
    setState(initState);
  }, [state.blob, filename]);

  const startDownload = () => {
    setState({ downloading: true, blob: null, error: false, errMsg: "" });
    apiCallFn()
      .then((blob) => {
        setState({
          downloading: false,
          blob: blob,
          error: false,
          errMsg: "",
        });
      })
      .catch((err) => {
        setState({
          downloading: false,
          blob: null,
          error: true,
          errMsg: err.message,
        });
      });
  };

  if (state.error) {
    return (
      <Tooltip title={state.errMsg}>
        <IconButton onClick={startDownload} size="small">
          <ErrorIcon fontSize="small" color="error" />
        </IconButton>
      </Tooltip>
    );
  }

  if (state.downloading) {
    return (
      <IconButton disabled size="small" {...iconBtnProps}>
        <CircularProgress size={16} />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={startDownload} size="small" {...iconBtnProps}>
      {icon}
    </IconButton>
  );
};

export default DownloadButton;
