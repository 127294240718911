import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { canCreateExtension } from "../../../utility";

const SuccessAlert = ({ content }) => (
  <Alert severity="success" icon={<TaskAltIcon />}>
    {content}
  </Alert>
);

const UnknownAlert = ({ content }) => (
  <Alert severity="info" icon={<HelpOutlineIcon />}>
    {content}
  </Alert>
);

const DeclinedAlert = ({ content }) => (
  <Alert severity="error" icon={<ReportGmailerrorredIcon />}>
    {content}
  </Alert>
);

const ResponseAlert = ({ AlertComp, label, response }) => (
  <AlertComp content={`${label}: ${response}`} />
);

const respToEligibilityStep = (label, response) => {
  if (!response) return { alert: UnknownAlert, label, value: "unknown" };
  if (response === "accepted")
    return { alert: SuccessAlert, label, value: response };
  return { alert: DeclinedAlert, label, value: response };
};

const stepResultOrder = new Map([
  [SuccessAlert, [0, TaskAltIcon, "success.main"]],
  [UnknownAlert, [1, HelpOutlineIcon, "info.main"]],
  [DeclinedAlert, [2, ReportGmailerrorredIcon, "error.main"]],
]);

/**
 * @param {{
 *   ext_eligible: boolean,
 *   ext_nonel_reas: string,
 *   ext_ptn_res: boolean,
 *   ext_prac_res: boolean
 * }} rx
 * @returns {{ alert: function, label: string, value: string }[]}
 */
const rxToEligibilitySteps = (rx) => {
  const steps = [];

  if (rx.ext_eligible) {
    steps.push({ alert: SuccessAlert, label: "System Rules", value: "passed" });
  } else {
    steps.push({
      alert: DeclinedAlert,
      label: "System Rules",
      value: rx.ext_nonel_reas,
    });
  }

  steps.push(respToEligibilityStep("Patient Response", rx.ext_ptn_res));
  steps.push(respToEligibilityStep("Practice Response", rx.ext_prac_res));

  return steps;
};

const EligibilityCard = ({ rx, onExtendClick }) => {
  const eligSteps = rxToEligibilitySteps(rx).map((s) => ({
    ...s,
    res: stepResultOrder.get(s.alert),
  }));

  const [, ResultIcon, color] = eligSteps.reduce((acc, cur) => {
    return cur.res[0] > acc[0] ? cur.res : acc;
  }, stepResultOrder.get(SuccessAlert));

  return (
    <Card variant="outlined">
      <CardHeader
        sx={{ pb: 0 }}
        title="Extension Eligibility"
        titleTypographyProps={{ variant: "h6" }}
        action={
          <Box sx={{ pt: 1, pr: 1 }}>
            <ResultIcon sx={{ color }} />
          </Box>
        }
      />
      <CardContent>
        <Stack spacing={1} direction="column">
          {eligSteps.map((s) => (
            <ResponseAlert
              key={s.label}
              AlertComp={s.alert}
              label={s.label}
              response={s.value}
            />
          ))}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="success"
          fullWidth
          disabled={!canCreateExtension(rx) || rx.has_been_extended}
          onClick={onExtendClick}
        >
          Extend!
        </Button>
      </CardActions>
    </Card>
  );
};

export default EligibilityCard;
