import { forwardRef } from "react";
import { SvgIcon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBaby } from "@fortawesome/pro-regular-svg-icons";

const BabyIcon = forwardRef(({ ...props }, ref) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <FontAwesomeIcon icon={faBaby} />
    </SvgIcon>
  );
});

export default BabyIcon;
