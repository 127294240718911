import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import PrescriberTabPanelMain from "./PagePrescriber/PrescriberTabPanelMain";
import PrescriberTabPanelEnrollments from "./PagePrescriber/PrescriberTabPanelEnrollments";
import PrescriberTabPanelPatients from "./PagePrescriber/PrescriberTabPanelPatients";
import PrescriberTabPanelProduction from "./PagePrescriber/PrescriberTabPanelProduction";
import ApiDataContext from "../../ApiDataContext";
import TicketContextMenu from "../PagePartials/Tickets/TicketContextMenu";
import { InternalLink } from "../StandardComponents/InternalLink";
import Grid2 from "@mui/material/Unstable_Grid2";

const PagePrescriber = ({ pageData, api, refresh }) => {
  const { npiBlacklist } = useContext(ApiDataContext);
  const { state: navState } = useLocation();
  const { npi: urlId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(navState?.activeTab || "main");

  useEffect(() => {
    if (pageData.npi !== urlId) {
      navigate(`/prescribers/${pageData.npi}`, { replace: true });
    }
  }, [navigate, pageData.npi, urlId]);

  const handleTabChange = (e, newTab) => {
    setActiveTab(newTab);
  };

  const cardTitle = [
    pageData.name_first,
    pageData.name_last,
    pageData.credential,
  ]
    .filter(Boolean)
    .join(" ");
  const cardSubheader = (
    <Grid2 spacing={1} sx={{ mt: 2 }} container>
      <Grid2 xs={6}>
        <Stack spacing={1} direction="row">
          <Box sx={{ width: "80px" }}>NPI</Box>
          <Box>{pageData.npi}</Box>
        </Stack>
        <Stack spacing={1} direction="row">
          <Box sx={{ width: "80px" }}>Practice</Box>
          <Box>
            <InternalLink to={`/practices/${pageData.practice_id}`}>
              {pageData.practice_name}
            </InternalLink>
          </Box>
        </Stack>
      </Grid2>

      <Grid2 xs={6}>
        <Stack spacing={1} direction="row">
          <Box sx={{ width: "80px" }}>Agency</Box>
          <Box>
            <InternalLink to={`/agencies/${pageData.agency_id}`}>
              {pageData.agency_name}
            </InternalLink>
          </Box>
        </Stack>

        <Stack spacing={1} direction="row">
          <Box sx={{ width: "80px" }}>Rep</Box>
          <Box>
            <InternalLink to={`/reps/${pageData.rep_id}`}>
              {pageData.rep_name}
            </InternalLink>
          </Box>
        </Stack>
      </Grid2>
    </Grid2>
  );

  const isBlacklisted = npiBlacklist.includes(pageData.npi);

  return (
    <>
      <BackLinkHeader
        to={`/practices/${pageData.practice_id}`}
        title={`Back to ${pageData.practice_name}`}
      />
      <Card variant="outlined" sx={{ mb: 3 }}>
        <CardHeader
          title={cardTitle}
          subheader={cardSubheader}
          action={
            <TicketContextMenu
              api={api}
              context={{
                type: "prescribers",
                ident: pageData.id,
                label: cardTitle,
              }}
            />
          }
        />
        {isBlacklisted && (
          <CardContent>
            <Alert severity="error">
              <AlertTitle>Prescriber On NPI Blacklist</AlertTitle>This
              prescriber is on our "NPI Blacklist" meaning they are marked as an
              active ORS prescriber. We <strong>may not</strong> process the
              script for WRS while this prescriber is an active ORS prescriber.
            </Alert>
          </CardContent>
        )}
      </Card>

      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab index={0} value="main" label="Main" />
        <Tab
          index={1}
          value="enrollments"
          label="Enrollments"
          programs={pageData.programs}
        />
        <Tab index={2} value="patients" label="Scripts" />
        <Tab index={3} value="production" label="Production" />
      </Tabs>

      <Box sx={{ my: 3 }} />

      <PrescriberTabPanelMain
        activeTab={activeTab}
        api={api}
        pageData={pageData}
        refresh={refresh}
      />
      <PrescriberTabPanelEnrollments
        activeTab={activeTab}
        programs={pageData.programs}
        npi={pageData.npi}
        api={api}
        refresh={refresh}
      />
      <PrescriberTabPanelPatients
        activeTab={activeTab}
        patients={pageData.patients}
      />
      <PrescriberTabPanelProduction
        activeTab={activeTab}
        api={api}
        prescriberId={pageData.id}
      />
    </>
  );
};

export default PagePrescriber;
