import { useEffect, useState } from "react";
import { Autocomplete, Avatar, Chip, TextField } from "@mui/material";

const dedupVals = (optionList) => {
  const ids = new Set();
  const cleanList = [];
  for (const opt of optionList) {
    if (ids.has(opt.user_id)) continue;
    ids.add(opt.user_id);
    cleanList.push(opt);
  }
  return cleanList;
};

const idInFixedList = (fixedList, id) => {
  return !!fixedList.find((item) => item.user_id === id);
};

/**
 * @param {Array<{
 *   user_id: number,
 *   user_name: string,
 *   user_email: string,
 *   user_picture: string,
 * }>} fixedList
 * @param {Array<{
 *   user_id: number,
 *   user_name: string,
 *   user_email: string,
 *   user_picture: string,
 * }>} value
 */
const TicketCCAppendPicker = ({ api, label, fixedList, value, onChange }) => {
  const [optState, setOptState] = useState({
    error: null,
    isLoading: false,
    shouldAttempt: true,
    options: [],
  });

  useEffect(() => {
    if (optState.shouldAttempt === true) {
      api
        .acUsersExpanded()
        .then((dat) => {
          if (dat.status === "ok") {
            setOptState({
              options: dat.data,
              error: null,
              isLoading: false,
              shouldAttempt: false,
            });
            return;
          }

          setOptState({
            options: [],
            error: dat.message,
            shouldAttempt: false,
            isLoading: false,
          });
        })
        .catch((err) => {
          setOptState((prev) => ({
            options: prev.options,
            shouldAttempt: false,
            isLoading: false,
            error: err.message,
          }));
        });
      setOptState((prev) => ({
        options: prev.options,
        error: null,
        isLoading: true,
        shouldAttempt: false,
      }));
    }
  }, [optState.shouldAttempt, api]);

  const cleanVals = dedupVals([...fixedList, ...value]);

  return (
    <Autocomplete
      multiple
      loading={optState.isLoading}
      options={optState.options}
      getOptionKey={(option) => option.user_id}
      getOptionLabel={(option) => `${option.user_name} (${option.user_email})`}
      isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
      getOptionDisabled={(option) => idInFixedList(cleanVals, option.user_id)}
      value={cleanVals}
      onChange={(...vals) => {
        onChange(...vals);
      }}
      sx={{ mt: 1 }}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          const label = `${option.user_name}`;
          return (
            <Chip
              variant="outlined"
              label={label}
              avatar={
                <Avatar src={option.user_picture} alt={option.user_name} />
              }
              key={key}
              {...tagProps}
              onDelete={
                idInFixedList(fixedList, option.user_id)
                  ? undefined
                  : tagProps.onDelete
              }
            />
          );
        });
      }}
      renderInput={(params) => (
        <TextField {...params} size="small" label={label} />
      )}
    />
  );
};

export default TicketCCAppendPicker;
