import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { phoneFmt } from "../../../formatters";
import { InternalLink } from "../../StandardComponents/InternalLink";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect, useState } from "react";

const TargetListIcon = ({ status, tooltip }) => {
  const STLOOKUP = {
    idle: { icon: CircleOutlinedIcon, color: "action" },
    progress: { icon: CircleIcon, color: "warning" },
    success: { icon: CircleIcon, color: "success" },
    error: { icon: CircleIcon, color: "error" },
  };

  const { icon: Icon, color } = STLOOKUP[status] ?? STLOOKUP.idle;

  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow placement="left">
        <Icon color={color} fontSize="smaller" />
      </Tooltip>
    );
  }

  return <Icon color={color} fontSize="smaller" />;
};

const TargetListItem = ({
  rep,
  phone,
  status,
  errMsg,
  onStateChange,
  api,
  message,
}) => {
  const [submitState, setSubmitState] = useState("idle");

  useEffect(() => {
    if (status === "progress" && submitState === "idle") {
      setSubmitState("progress");
      api
        .sendText(phone, message)
        .then((resp) => {
          if (resp.status === "ok") {
            onStateChange(phone, "success", null);
            return;
          }
          onStateChange(phone, "error", resp.message);
        })
        .catch((err) => {
          onStateChange(phone, "error", err.message);
        });
    }
  }, [status, api, phone, message, submitState, onStateChange]);

  const primary = !!rep ? (
    <>
      {phoneFmt(phone, true)} -{" "}
      <InternalLink target="_blank" to={`/reps/${rep.rep_id}`}>
        {rep.rep}
      </InternalLink>
    </>
  ) : (
    <>{phoneFmt(phone, true)} - Unknown</>
  );

  return (
    <ListItem disablePadding divider>
      <ListItemText primary={primary} />
      <ListItemSecondaryAction sx={{ pt: "4px" }}>
        <TargetListIcon status={status} tooltip={errMsg || null} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const CurrentTargetsList = ({
  targetsParsed,
  onTargetChangeState,
  api,
  message,
}) => {
  return (
    <List dense disablePadding>
      {targetsParsed.map(({ phone, rep, status, errMsg }) => {
        return (
          <TargetListItem
            key={phone}
            rep={rep}
            phone={phone}
            status={status}
            onStateChange={onTargetChangeState}
            errMsg={errMsg}
            api={api}
            message={message}
          />
        );
      })}
    </List>
  );
};

export default CurrentTargetsList;
