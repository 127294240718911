import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";
import UserChip from "../../StandardComponents/UserChip";

/**
 * @type {Object<DBTicketChangelogField, function>}
 */
const VAL_FORMATTERS = {
  title: (x) => x,
  description: (x) => x,
  requester: (x) => {
    return <UserChip user={x} size="small" />;
  },
  assignee: (x) => {
    return <UserChip user={x} size="small" />;
  },
  status: (x) => x,
  follow_up: (x) => {
    const d = new Date(x);
    return format(d, "MMM d, yyyy h:mm a");
  },
};

const TicketChangelogTable = ({ changelog }) => {
  return (
    <Table
      size="small"
      sx={{
        "& th": { whiteSpace: "nowrap" },
        "& td:nth-of-type(3)": { whiteSpace: "nowrap" },
        "& td:nth-of-type(4)": { whiteSpace: "nowrap" },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Field</TableCell>
          <TableCell>New Value</TableCell>
          <TableCell>Changed By</TableCell>
          <TableCell>Changed On</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {changelog.map((cl) => {
          const key = `${cl.change.valueOf().toString()}-${cl.field}`;
          const valFmtr = VAL_FORMATTERS[cl.field] ?? ((x) => x);
          const valFmtd = valFmtr(cl.val_new);
          return (
            <TableRow key={key}>
              <TableCell>{cl.field}</TableCell>
              <TableCell>{valFmtd}</TableCell>
              <TableCell>
                <UserChip
                  size="small"
                  user={{
                    id: cl.changed_by,
                    name: cl.changed_by_name,
                    picture: cl.changed_by_picture,
                  }}
                />
              </TableCell>
              <TableCell>{format(cl.change, "MMM d, yyyy h:mm a")}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TicketChangelogTable;
