import { Alert } from "@mui/material";

const FormLevelErrorAlert = ({ formErrStruct, ...rest }) => {
  if (!formErrStruct.hasErrors) {
    return null;
  }

  return (
    <Alert severity="error" {...rest}>
      {Array.isArray(formErrStruct.globalErrMsg) ? (
        <ul>
          {formErrStruct.globalErrMsg.map((m) => (
            <li key={m}>{m}</li>
          ))}
        </ul>
      ) : (
        formErrStruct.globalErrMsg
      )}
    </Alert>
  );
};

export default FormLevelErrorAlert;
