import { StrictMode } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Auth0Provider } from "@auth0/auth0-react";
import { LicenseInfo } from "@mui/x-license-pro";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import App from "./Components/App.js";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
  environment: process.env.REACT_APP_SENTRY_ENV,
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_KEY);

polyfillCountryFlagEmojis();

ReactDOM.render(
  <StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <App initialThemeMode={localStorage.getItem("themeMode") || "light"} />
    </Auth0Provider>
  </StrictMode>,
  document.getElementById("root")
);
