import BleederGainerCard from "../../StandardComponents/BleederGainerCard";

const PrescriberTabPanelProduction = ({ activeTab, api, prescriberId }) => {
  if (activeTab !== "production") return null;
  return (
    <BleederGainerCard
      api={api}
      entity="prescribers"
      ident={prescriberId}
      title="Production History"
    />
  );
};

export default PrescriberTabPanelProduction;
