import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { forwardRef } from "react";
import composeRefs from "@seznam/compose-react-refs/composeRefs";

const RHFAutocomplete = forwardRef(
  (
    { name, control, rules = undefined, options, label, ignoreErrors, ...rest },
    ref
  ) => {
    const {
      field,
      fieldState: { error },
    } = useController({ name, control, rules });

    const helperText = ignoreErrors ? "" : error?.message ?? " ";

    return (
      <Autocomplete
        name={field.name}
        value={field.value}
        onBlur={field.onBlur}
        onChange={(_, newVal) => field.onChange(newVal)}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={composeRefs(field.ref, ref)}
            error={!!error}
            helperText={helperText}
            size="small"
            label={label}
            fullWidth
            autoComplete="off"
            margin="dense"
            variant="outlined"
          />
        )}
        options={options}
        isOptionEqualToValue={(o, v) => o.label === v.label}
        autoSelect
        autoHighlight
        autoComplete
        clearOnEscape
        disableClearable
        {...rest}
      />
    );
  }
);

export default RHFAutocomplete;
