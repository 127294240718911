import LogEntryPO from "./LogEntryPO";
import LogEntryTxoOut from "./LogEntryTxoOut";
import LogEntryTxoIn from "./LogEntryTxoIn";
import LogEntryScon from "./LogEntryScon";
import LogEntryTrueup from "./LogEntryTrueup";

const TYPE_MAP = {
  po: LogEntryPO,
  "txo-out": LogEntryTxoOut,
  "txo-in": LogEntryTxoIn,
  scon: LogEntryScon,
  trueup: LogEntryTrueup,
};

const LogEntry = ({ ctx }) => {
  const type = ctx.type;
  const EntryComp = TYPE_MAP[type];
  if (!EntryComp) return "Unknown log entry type";
  return <EntryComp ctx={ctx} />;
};

export default LogEntry;
