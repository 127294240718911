import { useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RPInvoiceFormatPicker from "../../FormControls/RPInvoiceFormatPicker";
import Grid2 from "@mui/material/Unstable_Grid2";

const Address = ({ payor }) => {
  return (
    <Box>
      <Box>{payor.addr_recipient}</Box>
      <Box>{`${[payor.addr_street1, payor.addr_street2]
        .filter((x) => !!x)
        .join(" ")}`}</Box>
      <Box>
        {`${[payor.addr_city, payor.addr_state, payor.addr_zip]
          .filter((x) => !!x)
          .join(", ")}`}
      </Box>
    </Box>
  );
};

const BillGenNotAllowed = () => {
  return (
    <DialogContent>
      <Alert severity="error">
        <AlertTitle>Bill Generation not allowed!</AlertTitle>
        This order is still on the default payor. This must be fixed in order to
        generate a bill.
      </Alert>
    </DialogContent>
  );
};

const BillGenContent = ({
  orderId,
  payor,
  api,
  refresh,
  onClose,
  currentPoNumber,
}) => {
  const [invFormat, setInvFormat] = useState(payor.invoice_format);
  const [poNumber, setPoNumber] = useState(currentPoNumber);
  const [submitState, setSubmitState] = useState({
    submitting: false,
    error: "",
  });
  const setSubmitError = (msg) =>
    setSubmitState({ submitting: false, error: msg });

  const formatDeviation = !!(invFormat && payor.invoice_format !== invFormat);

  const handleSubmit = () => {
    setSubmitState({ submitting: true, error: "" });
    api
      .genBill(orderId, invFormat, poNumber)
      .then((resp) => {
        if (resp.status !== "ok") {
          setSubmitError(resp.message);
          return;
        }
        onClose();
        refresh();
      })
      .catch((err) => setSubmitError(err.message));
  };

  return (
    <>
      <DialogContent>
        {submitState.error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitState.error}
          </Alert>
        )}
        <Alert severity="info" sx={{ mb: 2 }}>
          This action will cause notifications to be sent and commissions to be
          set.
        </Alert>
        {formatDeviation && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <AlertTitle>Invoice Format Mismatch</AlertTitle>
            This payor is set to use a <strong>
              {payor.invoice_format}
            </strong>{" "}
            invoice format. You have selected <strong>{invFormat}</strong>{" "}
            instead. Please make sure that you want to generate a bill that is
            not using the payor's set format.
          </Alert>
        )}
        <Grid2 container rowSpacing={2}>
          <Grid2 xs={3}>
            <Typography>Payor</Typography>
          </Grid2>
          <Grid2 xs={9}>
            <Typography>{`${payor.porg_name} - ${payor.name}`}</Typography>
          </Grid2>
          <Grid2 xs={3}>
            <Typography>Address</Typography>
          </Grid2>
          <Grid2 xs={9}>
            <Address payor={payor} />
          </Grid2>
          <Grid2 xs={3}>Format</Grid2>
          <Grid2 xs={9}>
            <RPInvoiceFormatPicker
              fullWidth
              size="small"
              value={invFormat}
              onChange={(val) => setInvFormat(val)}
            />
          </Grid2>
          {invFormat === "Invoice" && (
            <>
              <Grid2 xs={3}></Grid2>
              <Grid2 xs={9}>
                <TextField
                  label="Enter PO Number"
                  fullWidth
                  size="small"
                  value={poNumber}
                  onChange={(e) => setPoNumber(e.target.value)}
                />
              </Grid2>
            </>
          )}
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button disabled={submitState.submitting} onClick={handleSubmit}>
          {submitState.submitting ? "Generating..." : "Generate Bill"}
        </Button>
      </DialogActions>
    </>
  );
};

const GenBillDialog = ({
  open,
  onClose,
  orderId,
  payor,
  api,
  refresh,
  currentPoNumber,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      disableScrollLock
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Box>
          <Typography variant="h5">Generate Billing Statement</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton edge="end" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {payor ? (
        <BillGenContent
          orderId={orderId}
          payor={payor}
          api={api}
          refresh={refresh}
          onClose={onClose}
          currentPoNumber={currentPoNumber}
        />
      ) : (
        <BillGenNotAllowed />
      )}
    </Dialog>
  );
};

export default GenBillDialog;
