import { Alert, Snackbar } from "@mui/material";

const SubmitSnackbar = ({ open, onClose, severity, message }) => {
  return (
    <Snackbar
      autoHideDuration={6000}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      {severity ? <Alert severity={severity}>{message}</Alert> : { message }}
    </Snackbar>
  );
};

export default SubmitSnackbar;
