import { Button, Divider, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import useStdFormErrors from "../../HOC/useStdFormErrors";
import StandardDialog from "../StandardComponents/StandardDialog";
import FormLevelErrorAlert from "../StandardComponents/FormLevelErrorAlert";
import RHFDateField from "../FormControlsRHF/RHFDateField";
import RHFUPSServiceCodePicker from "../FormControlsRHF/RHFUPSServiceCodePicker";
import { formatAddr, nowDate, reformatDate } from "../../utility";
import { fmtItemCodeLabel } from "../../formatters";

const genDefaultValues = () => {
  return {
    pref_ship_on: nowDate(),
    pref_service_code: null,
  };
};

export const Dialog = ({ initialVals, api, open, onClose, refresh }) => {
  const defaultValues = genDefaultValues();
  const { control, handleSubmit, setError, reset } = useForm({ defaultValues });
  const onSuccess = () => {
    refresh();
    handleClose();
  };
  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    defaultValues,
    onSuccess
  );

  const handleClose = () => {
    reset();
    clearFormError();
    onClose();
  };

  const onSubmit = (data) => {
    const payload = {
      pref_ship_on: reformatDate(data.pref_ship_on),
      pref_service_code: data.pref_service_code?.value,
    };
    return apiWrapper(api.srvcSetPrefShip(initialVals.id, payload));
  };

  return (
    <StandardDialog
      open={open}
      onClose={handleClose}
      title="Set Expected Shipping Plan"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2} rowSpacing={2}>
          {!!formErrState.hasErrors && (
            <Grid2 xs={12}>
              <FormLevelErrorAlert formErrStruct={formErrState} />
            </Grid2>
          )}
          <Grid2 xs={12}>
            <Paper variant="outlined" sx={{ p: 1 }}>
              <Typography fontSize="larger">Patient Expectations</Typography>
              <Divider sx={{ mb: 2, mt: 1 }} />
              <Typography>
                The patient team has committed to a{" "}
                <strong>
                  {fmtItemCodeLabel(initialVals.latest_item_code)}
                </strong>{" "}
                arriving by <strong>{initialVals.pref_arrive_on}</strong> at
                address:
              </Typography>
              <Typography
                sx={{ mt: 1, whiteSpace: "pre-line", fontWeight: "bold", p: 1 }}
              >
                {formatAddr(initialVals)}
              </Typography>
            </Paper>
          </Grid2>
          <Grid2 xs={6}>
            <RHFDateField
              control={control}
              name="pref_ship_on"
              label="We Will Ship On"
            />
          </Grid2>
          <Grid2 xs={6}>
            <RHFUPSServiceCodePicker
              control={control}
              name="pref_service_code"
              label="With UPS Shipping Method"
            />
          </Grid2>
          <Grid2 xs={12} sx={{ textAlign: "right" }}>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </StandardDialog>
  );
};
