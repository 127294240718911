import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { phoneFmt } from "../../../formatters";
import ContactTypeIcon from "./ContactTypeIcon";
import PrefContactIcon from "./PrefContactIcon";
import FaxIcon from "@mui/icons-material/Fax";

const ContactCard = ({ contact, onRequestEdit }) => {
  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardHeader
        sx={{ mb: 0, pb: 0 }}
        title={`${contact.name_first} ${contact.name_last}`}
        titleTypographyProps={{ variant: "body1" }}
        action={
          <Stack direction="row">
            <ContactTypeIcon contactType={contact.type} />
            <PrefContactIcon prefContactMethod={contact.pref_cont_method} />
            <IconButton size="small" onClick={() => onRequestEdit(contact)}>
              <ModeEditIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        }
      />
      <CardContent sx={{ p: 0, m: 0, "&:last-child": { p: 0 } }}>
        <List dense>
          <ListItem sx={{ py: 0 }}>
            <ListItemIcon sx={{ p: 0 }}>
              <IconButton size="small" disabled>
                <PhoneIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText>{phoneFmt(contact.phone) || "unknown"}</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon xs={{ p: 0 }}>
              <IconButton size="small" disabled>
                <FaxIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText>{phoneFmt(contact.fax) || "unknown"}</ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemIcon sx={{ p: 0 }}>
              <IconButton size="small" disabled>
                <EmailIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText>{contact.email || "unknown"}</ListItemText>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default ContactCard;
