import { TableCell, TableRow } from "@mui/material";

const DataTableRow = ({ rowNum, rowData }) => {
  return (
    <TableRow
      sx={{
        "&:last-child th, &:last-child td": { borderBottom: 0 },
      }}
    >
      {rowData.map((cell, i) => (
        <TableCell
          sx={{
            whiteSpace: "nowrap",
            overflowX: "clip",
            textOverflow: "ellipsis",
          }}
          key={`row-${rowNum}-col-${i}`}
        >
          {cell}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default DataTableRow;
