import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const opts = [
  { value: "ups-pickup", label: "UPS Pick Up at Patient's Location" },
  { value: "ups-dropoff", label: "Drop Off at UPS Location" },
];

const RPPickupPicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={opts} />;
});

RPPickupPicker.defaultValue = null;

export default RPPickupPicker;
