import { forwardRef } from "react";
import { createDefaultMaskGenerator, useWebMask } from "react-hook-mask";
import { TextField } from "@mui/material";

const maskGenerator = createDefaultMaskGenerator("99999");

const RPZipInput = forwardRef((props, ref) => {
  const {
    value,
    onChange,
    ref: maskRef,
  } = useWebMask({
    maskGenerator,
    value: props.value,
    onChange: props.onChange,
    keepMask: props.keepMask,
    ref,
  });

  return (
    <TextField
      {...props}
      value={value}
      onChange={onChange}
      inputRef={maskRef}
    />
  );
});

export default RPZipInput;
