import { useState } from "react";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import KeyValueList from "../PagePartials/KeyValueList";
import { npiName, parseIntB, ucWord } from "../../utility";
import NotesBlock from "../StandardComponents/NotesBlock";
import SectionHeading from "../PagePartials/SectionHeading";
import VT4 from "../TherapySettings/VT4";
import RPPracticePicker from "../FormControls/RPPracticePicker";
import StdFormDialog from "../PagePartials/StdFormDialog";
import RPLocationPicker from "../FormControls/RPLocationPicker";
import RPContactPicker from "../FormControls/RPContactPicker";
import { useNavigate } from "react-router-dom";
import PracticePreview from "./PagePrescriberOnboarding/PracticePreview";

const PagePrescriberOnboarding = ({ pageData, api }) => {
  const navigate = useNavigate();

  const [pracFormOpen, setPracFormOpen] = useState(false);
  const openPracForm = () => setPracFormOpen(true);
  const closePracForm = () => setPracFormOpen(false);

  const [pracFileChoice, setPracFileChoice] = useState(null);
  const closeFileForm = () => setPracFileChoice(null);

  const presc_name = npiName(
    pageData.npidb_name_first,
    pageData.npidb_name_last,
    pageData.npidb_credential
  );
  const { classification, license_num, in_state } =
    pageData.npidb_taxonomies.find((t) => t.is_pri === "Y");

  const repSubmittedInfo = {
    "Preferred Name": pageData.name_preferred || "[NOT ENTERED]",
    "Prescriber Email": pageData.email || "[NOT ENTERED]",
    "Practice Name": pageData.practice_name,
    "Practice Address": pageData.practice_address || "[NOT ENTERED]",
    "Scheduler Name": pageData.sched_name || "[NOT ENTERED]",
    "Scheduler Phone": pageData.sched_phone || "[NOT ENTERED]",
    "Scheduler Email": pageData.sched_email || "[NOT ENTERED]",
  };

  const FORM_PRAC = [
    {
      name: "practice_id",
      component: RPPracticePicker,
      label: "Practice",
      extraProps: { api: api },
    },
  ];

  const FORM_FILE = [
    { type: "section", label: "Rep Submitted Info" },
    {
      type: "plain",
      component: PracticePreview,
      extraProps: { practiceId: pracFileChoice, repInfo: repSubmittedInfo },
    },
    { type: "section", label: "Prescriber" },
    { name: "name_first", component: TextField, label: "First Name", width: 4 },
    { name: "name_last", component: TextField, label: "Last Name", width: 4 },
    { name: "credential", component: TextField, label: "Credential", width: 4 },
    {
      name: "ploc_id",
      component: RPLocationPicker,
      label: "Practice Location",
      width: 7,
      extraProps: { practiceId: pracFileChoice, api },
    },
    {
      name: "pcont_id",
      component: RPContactPicker,
      label: "Scheduler",
      width: 5,
      extraProps: { practiceId: pracFileChoice, api },
    },
    {
      name: "email",
      component: TextField,
      label: "Prescriber Email",
      width: 6,
    },
  ];

  return (
    <>
      <SectionHeading
        headingLabel={`Onboard ${presc_name}`}
        buttonLabel="File Prescriber"
        buttonOnClick={openPracForm}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">
            Examine the information below. This prescriber needs to be attached
            to an existing practice in our system. If the practice does not yet
            exist in our system, it needs to be created and then the prescriber
            can be attached.
          </Alert>
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardHeader
              title="NPI Database Lookup"
              subheader={`data on NPI ${pageData.npi}`}
            />
            <CardContent>
              <KeyValueList
                kvData={{
                  Prescriber: presc_name,
                  "Practice State":
                    pageData.npidb_practice_state || "[NOT AVAILABLE]",
                  "Primary Taxonomy": classification || "[NOT AVAILALBE]",
                  "License Number": license_num || "[NOT AVAILABLE]",
                  "License State": in_state || "[NOT AVAILABLE]",
                  "NPPES Link": (
                    <Link
                      target="_blank"
                      href={`https://npiregistry.cms.hhs.gov/provider-view/${pageData.npi}`}
                    >
                      View More Info
                    </Link>
                  ),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardHeader
              title="Rep Submitted Info"
              subheader={`from ${pageData.rep_name}`}
            />
            <CardContent>
              <Stack spacing={2} direction="row">
                <KeyValueList kvData={repSubmittedInfo} />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        {pageData.programs.cct?.vt4 && (
          <Grid item xs={12}>
            <VT4 vt4={pageData.programs.cct.vt4} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardHeader title="Rep Notes" />
            {pageData.notes ? (
              <CardContent>
                <NotesBlock notes={pageData.notes} />
              </CardContent>
            ) : (
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ color: "text.secondary", textAlign: "center" }}
                >
                  No Notes Entered
                </Typography>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
      <StdFormDialog
        open={pracFormOpen}
        title="File Under"
        onClose={closePracForm}
        onComplete={(result) => {
          setPracFileChoice(result.data);
          closePracForm();
        }}
        submitCall={(data) => {
          if (!data.practice_id) {
            return Promise.reject({ message: "You must pick a practice!" });
          }
          return Promise.resolve({ status: "ok", data: data.practice_id });
        }}
        fields={FORM_PRAC}
      />
      <StdFormDialog
        open={!!pracFileChoice}
        title="Prescriber Info"
        onClose={closeFileForm}
        onComplete={() => navigate(`/practices/${pracFileChoice}`)}
        submitCall={(data) => {
          const payload = {
            ...data,
            practice_id: pracFileChoice,
            ploc_id: parseIntB(data.ploc_id),
            pcont_id: parseIntB(data.pcont_id),
          };
          return api.filePrescriber(pageData.id, payload);
        }}
        fields={FORM_FILE}
        initialVals={{
          name_first: ucWord(pageData.npidb_name_first),
          name_last: ucWord(pageData.npidb_name_last),
          credential: pageData.npidb_credential
            .replaceAll(".", "")
            .toUpperCase(),
          email: pageData.email,
        }}
      />
    </>
  );
};

export default PagePrescriberOnboarding;
