import { Box, Grid } from "@mui/material";
import SerialListCard from "./SerialListCard";
import LocationListCard from "./LocationListCard";
import ConsumablesSummaryCard from "./ConsumablesSummaryCard";

const AgencyTabPanelInventory = ({
  activeTab,
  agencyId,
  inventory,
  locations,
}) => {
  if (activeTab !== "inventory") {
    return null;
  }

  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      <Grid item xs={12}>
        <ConsumablesSummaryCard
          consumables={inventory.consumables}
          agencyId={agencyId}
        />
        <Box sx={{ my: 3 }} />
        <SerialListCard serialItemList={inventory.serials} />
      </Grid>
      <Grid item xs={6}>
        <LocationListCard locs={locations} />
      </Grid>
    </Grid>
  );
};

export default AgencyTabPanelInventory;
