import { Step, StepLabel, Stepper } from "@mui/material";

const STAT_TO_STEP = {
  open: 0,
  "in-progress": 1,
  closed: 2,
};

const TransferOrderStepper = ({ status }) => {
  const activeStep = STAT_TO_STEP[status];
  return (
    <Stepper activeStep={activeStep}>
      <Step>
        <StepLabel>Open</StepLabel>
      </Step>
      <Step>
        <StepLabel>In Progress</StepLabel>
      </Step>
      <Step>
        <StepLabel>Closed</StepLabel>
      </Step>
    </Stepper>
  );
};

export default TransferOrderStepper;
