import { useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import StandardDialog from "../../StandardComponents/StandardDialog";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import RHFTreatAttachPicker from "../../FormControlsRHF/RHFTreatAttachPicker";

const HardCopyPicAgncFormDialog = ({
  open,
  onClose,
  api,
  refresh,
  treatId,
}) => {
  const defaultValues = { attachment_id: null };
  const { control, handleSubmit, setError, reset } = useForm({ defaultValues });

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      onClose();
      refresh();
    }
  );

  const handleOnClose = () => {
    onClose();
    reset();
    clearFormError();
  };

  const onSubmit = (data) => {
    const attachmentId = data.attachment_id?.value;
    apiWrapper(api.hcDsPic(treatId, attachmentId));
  };

  return (
    <StandardDialog
      open={open}
      onClose={handleOnClose}
      title="Process Hard Copy Pic"
    >
      <FormLevelErrorAlert formErrStruct={formErrState} sx={{ mb: 2 }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2}>
          <Grid2 xs={6}>
            <RHFTreatAttachPicker
              api={api}
              control={control}
              label="PIC Document"
              name="attachment_id"
              treatId={treatId}
            />
          </Grid2>
        </Grid2>
        <Grid2 xs={12}>
          <Box sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              type="submit"
              disabled={formErrState.submitting}
            >
              Process Hard Copy PIC
            </Button>
          </Box>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default HardCopyPicAgncFormDialog;
