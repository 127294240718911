import { forwardRef } from "react";
import { STATES_N } from "../../utility";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const RPStatePicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={STATES_N} />;
});

RPStatePicker.defaultValue = null;

export default RPStatePicker;
