import { dateObjFormatToWRSDate, surgeryStr } from "../utility";
import {
  WSPatientAndSrvcLink,
  WSTrackingLink,
  WSUserAvatar,
} from "./ws-shared";
import { Box, Tooltip } from "@mui/material";
import { fmtUpsServiceCodeLabel } from "../formatters";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const logisticsSection = {
  title: "Logistics Team",
  key: "sec-logistics",
  buckets: [
    "planned-shipping",
    "packing-needed",
    "shipping-needed",
    "in-transit",
    "pickup-date",
    "returning",
    "reprocessing",
  ],
};

export const plannedShipping = [
  "planned-shipping",
  {
    title: "Shipping Plan",
    description:
      "WRS has committed to a date that a unit will arrive for these patients. The date that we need to ship the units out to fulfill that commitment needs to be set. This is drop-ship only.",
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <WSPatientAndSrvcLink
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
            srvc_id={r.id}
          />
        ),
      },
      {
        label: "Promised By",
        cell: (r) => r.pref_arrive_on,
      },
      {
        label: "Surgery",
        cell: (r) => surgeryStr(r.surgical, r.date_of_surgery),
      },
      {
        label: "Script Type",
        cell: (r) => `${r.therapy}-${r.item_code}`.toUpperCase(),
      },
      {
        label: "Location",
        cell: (r) => [r.addr_city, r.addr_state].filter(Boolean).join(", "),
      },
      {
        label: "",
        cell: (r) =>
          r.shipped_without_contact ? (
            <Tooltip
              arrow
              placement="right-start"
              title={
                "The Patient Team has indicated they want this unit shipped but they have not been able to contact the patient!"
              }
            >
              <ErrorOutlineIcon fontSize="small" color="error" />
            </Tooltip>
          ) : (
            ""
          ),
        sx: { width: "54px" },
      },
    ],
  },
];

export const packingNeeded = [
  "packing-needed",
  {
    title: "Pack",
    description:
      "These patients need their unit and wraps packed for shipment. Only drop-ship is shown here.",
    highlight: (r) => {
      return r.ship_on_diff === 0
        ? "bgCaution"
        : r.ship_on_diff < 0
        ? "bgError"
        : null;
    },
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <WSPatientAndSrvcLink
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
            srvc_id={r.id}
          />
        ),
      },
      {
        label: "Ship By",
        cell: (r) => r.pref_ship_on,
      },
      {
        label: "Promised By",
        cell: (r) => r.pref_arrive_on,
      },
      {
        label: "Service Code",
        cell: (r) => fmtUpsServiceCodeLabel(r.pref_service_code, ""),
      },
      {
        label: "Script Type",
        cell: (r) => [r.therapy, r.item_code].join("-").toUpperCase(),
      },
      {
        label: "Location",
        cell: (r) => [r.addr_city, r.addr_state].filter(Boolean).join(", "),
      },
      {
        label: "",
        cell: (r) =>
          r.shipped_without_contact ? (
            <Tooltip
              arrow
              placement="right-start"
              title={
                "The Patient Team has indicated they want this unit shipped but they have not been able to contact the patient!"
              }
            >
              <ErrorOutlineIcon fontSize="small" color="error" />
            </Tooltip>
          ) : (
            ""
          ),
        sx: { width: "54px" },
      },
    ],
  },
];

export const shippingNeeded = [
  "shipping-needed",
  {
    title: "Ship",
    description:
      "The units/wraps need to be shipped! Only drop-ship patients will be shown here.",
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientAndSrvcLink
            srvc_id={r.id}
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
          />
        ),
      },
      {
        label: "Ship By",
        cell: (r) => r.pref_ship_on,
      },
      {
        label: "Promised By",
        cell: (r) => r.pref_arrive_on,
      },
      {
        label: "Serial",
        cell: (r) =>
          `${
            r.serial_num
          } (${r.therapy.toUpperCase()}-${r.item_code.toUpperCase()})`,
      },
      {
        label: "Consumables",
        cell: (r) => r.consumables?.join("\n"),
        sx: { whiteSpace: "pre-line" },
      },
      {
        label: "Location",
        cell: (r) => [r.addr_city, r.addr_state].filter(Boolean).join(", "),
      },
    ],
  },
];

export const inTransit = [
  "in-transit",
  {
    title: "In Transit",
    description:
      "The unit/wraps have been shipped and their arrival date must be set when we know the unit/wraps have arrived. This is drop-ship only.",
    highlight: (r) =>
      r.late > 0 ? "bgError" : r.late === 0 ? "bgCaution" : null,
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientAndSrvcLink
            srvc_id={r.id}
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
          />
        ),
      },
      { label: "Shipped On", cell: (r) => r.shipped_on },
      {
        label: "Est Delivery",
        cell: (r) =>
          r.tracking_status === "Delivered"
            ? ""
            : dateObjFormatToWRSDate(r.estimated_delivery),
      },
      {
        label: "Promised By",
        cell: (r) => r.pref_arrive_on,
      },
      {
        label: "Serial",
        cell: (r) =>
          `${
            r.serial_num
          } (${r.therapy.toUpperCase()}-${r.item_code.toUpperCase()})`,
      },
      {
        label: "Track Stat",
        cell: (r) => (
          <WSTrackingLink
            label_id={r.label_id}
            tracking_number={r.tracking_number}
            tracking_status={r.tracking_status}
            tracking_url={r.tracking_url}
          />
        ),
      },
    ],
  },
];

export const awaitingPickup = [
  "pickup-date",
  {
    title: "Awaiting Pickup",
    description: "",
    highlight: (r) => (r.expired ? "bgError" : null),
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <WSPatientAndSrvcLink
            srvc_id={r.id}
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
          />
        ),
      },
      {
        label: "Retrieval Date",
        cell: (r) => r.pref_pickup_on,
      },
      {
        label: "Pickup Plan",
        cell: (r) => (
          <Tooltip title={r.pickup_plan_memo}>
            <Box>{r.pickup_plan}</Box>
          </Tooltip>
        ),
      },
      {
        label: "Serial",
        cell: (r) =>
          `${r.serial_num} (${`${r.therapy}-${r.item_code}`.toUpperCase()})`,
      },
      {
        label: "Track Stat",
        cell: (r) => (
          <WSTrackingLink
            label_id={r.label_id}
            tracking_number={r.tracking_number}
            tracking_status={r.tracking_status}
            tracking_url={r.tracking_url}
          />
        ),
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const returnedDevices = [
  "returning",
  {
    title: "Units Returning",
    description: "",
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <WSPatientAndSrvcLink
            srvc_id={r.service_cycle_id}
            eoc_id={r.id}
            name_first={r.name_first}
            name_last={r.name_last}
          />
        ),
      },
      {
        label: "Serial",
        cell: (r) =>
          `${r.serial_num} (${`${r.therapy}-${r.item_code}`.toUpperCase()})`,
      },
      {
        label: "Est Delivery",
        cell: (r) =>
          r.tracking_status === "Delivered"
            ? ""
            : dateObjFormatToWRSDate(r.estimated_delivery),
      },
      {
        label: "Track Stat",
        cell: (r) => (
          <WSTrackingLink
            label_id={r.label_id}
            tracking_number={r.tracking_number}
            tracking_status={r.tracking_status}
            tracking_url={r.tracking_url}
          />
        ),
      },
    ],
  },
];

export const reprocessing = [
  "reprocessing",
  {
    title: "Reprocess",
    description: "",
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <WSPatientAndSrvcLink
            srvc_id={r.service_cycle_id}
            eoc_id={r.id}
            name_first={r.name_first}
            name_last={r.name_last}
          />
        ),
      },
      {
        label: "Serial",
        cell: (r) =>
          `${r.serial_num} (${`${r.therapy}-${r.item_code}`.toUpperCase()})`,
      },
      {
        label: "Returned On",
        cell: (r) => r.returned_on,
      },
    ],
  },
];
