import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPPayorPicker = forwardRef(({ api, orgId, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acPayors(orgId)}
    />
  );
});

RPPayorPicker.defaultValue = null;

export default RPPayorPicker;
