import { SvgIcon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire } from "@fortawesome/pro-regular-svg-icons";
import { forwardRef } from "react";

const FireIcon = forwardRef(({ ...rest }, ref) => {
  return (
    <SvgIcon ref={ref} {...rest}>
      <FontAwesomeIcon icon={faFire} />
    </SvgIcon>
  );
});

export default FireIcon;
