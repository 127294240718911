import { useState } from "react";
import { IconButton, Stack } from "@mui/material";
import ConsumablesSummaryFilterMenu from "./ConsumablesSummaryFilterMenu";
import FilterListIcon from "@mui/icons-material/FilterList";

/**
 * @param {{
 *   showZeros: boolean,
 *   showDeprecated: boolean,
 *   toggleShowZeros: function,
 *   toggleShowDeprecated: function
 * }} filters
 */
const ConsumablesSummaryActions = ({ filters }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);

  return (
    <>
      <Stack direction="row" spacing={1}>
        <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
          <FilterListIcon />
        </IconButton>
      </Stack>
      <ConsumablesSummaryFilterMenu
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        {...filters}
      />
    </>
  );
};

export default ConsumablesSummaryActions;
