import { forwardRef } from "react";
import { createDynamicNumberMaskGenerator, useWebMask } from "react-hook-mask";
import { TextField } from "@mui/material";
import { phoneFmt } from "../../formatters";

const phMask1 = "(999) 999-9999";
const phMask2 = phMask1 + " x999999";
const maskGenerator = createDynamicNumberMaskGenerator(phMask1, phMask2);

const PTN_PHONE_RAW = new RegExp("^[0-9]{10,16}$");

const RPPhoneNumberInput = forwardRef(
  (
    { value: outerValue, onChange: onChangeOuter, keepMask, ...otherProps },
    outerRef
  ) => {
    const { value, onChange, ref } = useWebMask({
      maskGenerator,
      value: outerValue,
      onChange: onChangeOuter,
      keepMask,
      ref: outerRef,
    });

    // hack for displaying a passed in raw value
    let override = null;
    if (PTN_PHONE_RAW.test(value)) {
      override = phoneFmt(value);
    }

    return (
      <TextField
        {...otherProps}
        value={override || value}
        inputRef={ref}
        onChange={onChange}
      />
    );
  }
);

export default RPPhoneNumberInput;
