import { useState } from "react";

const useDialogState = (isBoolean = true) => {
  const [open, setOpen] = useState(isBoolean ? false : null);

  const handleOpen = (val = null) => {
    if (isBoolean || val === null) {
      setOpen(true);
      return;
    }
    setOpen(val);
  };

  const handleClose = () => setOpen(isBoolean ? false : null);
  return { open, openFn: handleOpen, closeFn: handleClose };
};

export default useDialogState;
