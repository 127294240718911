import LinkEntity from "./LinkEntity";
import ReceiptIcon from "@mui/icons-material/Receipt";

const LinkOrd = ({ id, copyBtn = true }) => {
  const label = LinkOrd.lblPriByCtx({ id });
  const url = LinkOrd.url(id);
  return <LinkEntity url={url} label={label} copyBtn={copyBtn} />;
};

LinkOrd.url = (id) => `/orders/${id}`;
LinkOrd.lblPriByCtx = ({ id }) => `ORD-${id.toString().padStart(4, "0")}`;
LinkOrd.icon = ReceiptIcon;
LinkOrd.subheader = (ctx) => {
  const gen =
    ctx.generation === 0
      ? "Initial"
      : ctx.generation === 1
      ? "Extension"
      : `Extension ${ctx.generation}`;

  return `${gen} - ${ctx.status}`;
};

export default LinkOrd;
