import { TextField } from "@mui/material";
import { forwardRef } from "react";

const RPTextArea = forwardRef((props, ref) => {
  return (
    <TextField
      multiline
      rows={8}
      {...props}
      inputRef={ref}
      sx={{ width: "100%" }}
    />
  );
});

export default RPTextArea;
