import { Box, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ButtonLink = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: "normal",
  "&:hover": {
    background: "none",
  },
  "&:focus": {
    background: "none",
    outlineStyle: "solid",
    outlineWidth: "2px",
  },
});

const BackLinkHeader = ({ title, to }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box mb={2}>
      <ButtonLink
        sx={{
          fontSize: theme.typography.htmlFontSize,
          color: theme.palette.text.secondary,
          outlineColor: theme.palette.primary.main,
        }}
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(to)}
        disableRipple
      >
        {title}
      </ButtonLink>
    </Box>
  );
};

export default BackLinkHeader;
