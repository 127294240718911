import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const options = [
  { value: "billables-v01", label: "Paid On Billables" },
  { value: "receivables-v01", label: "Paid On Receivables" },
];

const RPCommissionModelPicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={options} />;
});

RPCommissionModelPicker.defaultValue = null;

export default RPCommissionModelPicker;
