import { forwardRef } from "react";
import RPLoadOnRenderAutoComplete from "./RPLoadOnRenderAutoComplete";

const RPAgencyPicker = forwardRef(({ api, ...rest }, ref) => {
  return (
    <RPLoadOnRenderAutoComplete
      ref={ref}
      {...rest}
      optionListCall={() => api.acAgencies()}
    />
  );
});

RPAgencyPicker.defaultValue = null;

export default RPAgencyPicker;
