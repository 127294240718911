import { Fragment, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import CircleIcon from "@mui/icons-material/Circle";
import { InternalLink } from "../StandardComponents/InternalLink";
import useSWR, { useSWRConfig } from "swr";
import { genericSWRFetcher } from "../../utility";

/**
 * @param {{
 *   id: number,
 *   title: string,
 *   context_type: TicketContext,
 *   context_ident: number,
 *   context_label: string,
 * }} ticket
 * @param {{
 *   id: number,
 *   name: string,
 *   picture: string,
 * }} user
 * @param {string} content
 * @param {boolean} unread
 * @param {function} onClick
 */
const NotificationPreviewListItem = ({
  ticket,
  user,
  content,
  unread,
  onClick,
}) => {
  const tskId = `TSK-${ticket.id.toString().padStart(4, "0")}`;

  const title = `${tskId} - ${ticket.context_label || ticket.title}`;
  return (
    <ListItem
      alignItems="flex-start"
      disablePadding
      sx={{
        backgroundColor: unread ? "bgBlue" : null,
      }}
    >
      <ListItemButton
        sx={{
          height: "68px",
        }}
        component={InternalLink}
        to={`/tasks/${ticket.id}`}
        onClick={onClick}
      >
        <ListItemAvatar>
          <Avatar alt={user.name} src={user.picture} />
        </ListItemAvatar>
        <ListItemText
          sx={{
            mr: 2,
            overflow: "hidden",
            height: "68px",
            pt: "6px",
          }}
          primary={title}
          primaryTypographyProps={{
            fontWeight: "bold",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          secondary={content}
          secondaryTypographyProps={{
            sx: {
              fontWeight: unread ? "bold" : "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            },
          }}
        />
        <ListItemSecondaryAction>
          <CircleIcon
            sx={{ fontSize: "0.7em" }}
            color={unread ? "primary" : "disabled"}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
};

const NotificationPreviewer = ({ api }) => {
  const [previewEl, setPreviewEl] = useState(null);
  const fetcher = genericSWRFetcher(api.notificationPreviewList);
  const { mutate: mutateG, cache } = useSWRConfig();
  const { error, data, mutate } = useSWR(`notificationPreviews`, fetcher, {
    refreshInterval: 60000,
  });

  const handleIconClick = (ev) =>
    setPreviewEl(previewEl ? null : ev.currentTarget);
  const closeMenu = () => setPreviewEl(null);

  const previewIsOpen = Boolean(previewEl);

  if (error) {
    return (
      <IconButton color="inherit" sx={{ ml: 1 }} disabled>
        <Badge badgeContent="!!" color="error">
          <MailIcon />
        </Badge>
      </IconButton>
    );
  }

  if (!data) {
    return (
      <IconButton color="inherit" sx={{ ml: 1 }} disabled>
        <Badge badgeContent={0}>
          <MailIcon />
        </Badge>
      </IconButton>
    );
  }

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Box>
        <IconButton color="inherit" sx={{ ml: 1 }} onClick={handleIconClick}>
          <Badge
            badgeContent={data.filter((n) => n.read_on === null).length}
            color="error"
          >
            <MailIcon />
          </Badge>
        </IconButton>
        <Popper
          open={previewIsOpen}
          anchorEl={previewEl}
          placement="bottom-end"
          sx={{ zIndex: 9999 }}
          disablePortal
        >
          <Paper sx={{ maxWidth: "370px" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  pl: 2,
                  fontWeight: "bold",
                }}
              >
                Notifications
              </Box>
              <Button
                size="small"
                onClick={async () => {
                  await api.markNotificationsRead();
                  await mutate();
                  cache.keys().forEach((key) => {
                    if (key.startsWith("notificationList")) {
                      mutateG(key);
                    }
                  });
                }}
              >
                Mark All Read
              </Button>
            </Box>
            <Divider />
            <List dense sx={{ py: 0 }}>
              {data.map((n, idx) => {
                const unread = n.read_on === null;
                const ticket = {
                  id: n.ticket_id,
                  title: n.title,
                  context_ident: n.context_ident,
                  context_type: n.context_type,
                  context_label: n.context_label,
                };
                const user = {
                  id: n.from_user_id,
                  name: n.from_user_name,
                  picture: n.from_user_picture,
                };
                return (
                  <Fragment key={n.id}>
                    {idx > 0 && <Divider />}
                    <NotificationPreviewListItem
                      ticket={ticket}
                      user={user}
                      content={n.content}
                      unread={unread}
                      onClick={closeMenu}
                    />
                  </Fragment>
                );
              })}
              <Divider />
            </List>
            <Button
              fullWidth
              component={InternalLink}
              to="/notifications"
              onClick={closeMenu}
            >
              View All
            </Button>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default NotificationPreviewer;
