import { Card, CardContent, CardHeader } from "@mui/material";
import RepListTable from "./RepListTable";
import { prodCatSort } from "../../../utility";

const AgencyTabPanelRoster = ({ activeTab, reps }) => {
  if (activeTab !== "roster") {
    return null;
  }

  const title = `Rep Roster`;
  const sortedReps = [...reps].sort(prodCatSort);

  return (
    <Card variant="outlined">
      <CardHeader title={title} />
      <CardContent>
        <RepListTable reps={sortedReps} />
      </CardContent>
    </Card>
  );
};

export default AgencyTabPanelRoster;
